import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Row,
  Nav,
  NavDropdown,
  Navbar,
  Modal,
  Form,
  Col,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
// import meta from "../Assets/metamask.png";
// import walletconnect from "../Assets/wallet_connect.png";
// import coinbase from "../Assets/coinbase_wallet.png";
// import trust from "../Assets/trust_wallet.png";
// import tick from "../Assets/Json/tickk.json";
import { isEmpty } from "../../Hooks/UseValidation";
import {
  Extregistration,
  userPayPrice,
  checkRegister,
  connectWallet,
} from "../../Hooks/UseWallet";
import { CheckUserRegisterHooks, CreateUserHooks, Registerhooks } from "../../Hooks/usebackend";
import { toast } from "react-hot-toast";
import { getBalance, Getuseraddress, RegisterX2 } from "../../Hooks/UseContract";
import { DecryptString, EncryptString } from "../../Hooks/UseSecure";
import { useDispatch } from "react-redux";
export default function Register(props) {
  console.log("modalprops", props);
  const navigate = useNavigate();
  const [register, setRegister] = useState(true);
  const [wallet, setWallet] = useState(false);
  const [network, setNetwork] = useState(true);
  const [registration, setRegistration] = useState(true);
  const [balance, setBalance] = useState(false);
  const [approve, setApprove] = useState(null);
  const [walletAddress, setWalletAddress] = useState("");
  const [id, setid] = useState(isNaN(parseInt(localStorage.getItem("REFERER"))) ?  456786 : parseInt(localStorage.getItem("REFERER")));
  const [fee, setFee] = useState("");
  const [disable , setDisable] = useState(false);
  const dispatch = useDispatch();
  // const [Registered, setRegistered] = useState(true);
console.log("isNaN(parseInt(localStorage.getItem" , parseInt(localStorage.getItem("REFERER")));
  const address = localStorage.getItem("address");

  const fetchdata = async() => {
    let payload = {
      walletAddress : address
    }
    let result = await CheckUserRegisterHooks(payload);
    console.log("Result in check user register hooks" , result);
    if(result?.data?.type == "Success"){
      setRegistration(true);
    }
    else if(result?.data?.type == "failed"){
      setRegistration(false);
      props.onDismiss()
    }
  }

  useEffect(() => {
    fetch()
    
    // isAlreadRegisterCheck();
  }, []);

  const fetch = async() => {
    setWalletAddress(address);
    if (isEmpty(address)) {
      setWallet(false);
    } else {
      setWallet(true);
    }
    const accountBalance = await getBalance(Getuseraddress()); //localStorage.getItem("balance");
    console.log("check balance" , (153.5+10) < (parseFloat(accountBalance)/10**6) , accountBalance);
    if ((153.5+10) < (parseFloat(accountBalance)/10**6) ) { //accountBalance / 10 ** 6 >= fee
      setBalance(true);
    } else {
      setBalance(false);
    }
    // if (isEmpty(accountBalance)) {
    //   setBalance(false);
    // }
    userPayPriceLevel();
    fetchdata();
  }


  const refresh = async() => {
    setWalletAddress(localStorage.getItem("address"));
    if (isEmpty(localStorage.getItem("address"))) {
      setWallet(false);
    } else {
      setWallet(true);
    }
    const accountBalance = await getBalance(Getuseraddress())  //localStorage.getItem("balance");
    console.log("accountBalance" , accountBalance);
    if ((153.5+10) < (parseFloat(accountBalance)/10**6)) {
      setBalance(true);
    } else {
      setBalance(false);
    }
    // if (isEmpty(accountBalance)) {
    //   setBalance(false);
    // }
    userPayPriceLevel();
    fetchdata();
  }

  const userPayPriceLevel = async () => {
    try {
      const pay = await userPayPrice(id);
      let percenvalue = pay - pay * (5 / 100);
      let fee = pay + percenvalue;
      console.log(fee, "feefee");
      setFee(fee);
    } catch (err) {
      console.log(err, "err__pay");
    }
  };
  const isAlreadRegisterCheck = async () => {
    try {
      const isRegistered = await checkRegister(address);
      console.log(isRegistered,"isRegisteredisRegistered")
      if (isRegistered) {
        setRegistration(false);
      } else {
        setRegistration(true);
      }
    } catch (err) {
      console.log(err, "err__pay");
    }
  };
  // const userRegisteration = async (address) => {
  //   try {
  //     setDisable(true);
  //     // const register = await Extregistration(address, id, fee);
  //     Extregistration(address, id, fee).then((register) => {
  //       console.log("Register" , register);
  //       if(register?.type == "Success"){
  //         navigate("/dashboard");
  //       }
  //       else{
  //         toast.error("Network Error!");
  //         setDisable(false);
  //       }
  //     if (register != undefined) {
  //       setTimeout(async() => {
  //         let payload = {
  //           "hash":register
  //         };
  //         // let result =   Registerhooks(payload); 
  //         // console.log("Register" , result);
  //       } ,  1000)
  //     }
  //     })
      
  //   } catch (err) {
  //     console.log(err, "err__userRegisteration");
  //   }
  // };

  const userRegisteration = async (address) => {
    try {
      setDisable(true);
      let payload = {
        walletAddress : address,
        refererid : id
      }
      let resultdata = await CreateUserHooks(payload);
      let resdata = resultdata?.result
      // .then(async(register) => {
        let register = DecryptString(resdata?.data?.record , resultdata?.key)
        console.log("Register" , register);

        if(register){
          let payload = register
          payload.user = register?.userInfo?.walletAddress;
          var balance = await getBalance(Getuseraddress());
           var fee = 10
           if(153.5+fee < (parseFloat(balance)/10**6)){
            let result = await RegisterX2(payload?.address , payload?.amount , payload)
          // .then((result) => {
            console.log("REsult in register" , result);
            // if(result?.type == "Success"){
            //   navigate("/dashboard");
            // }
          // });
          // navigate("/dashboard");
          }
          else{
            setDisable(false);
            toast.error("Insufficient Balance!..")
          }
        }
        else{
          toast.error("Network Error!");
          setDisable(false);
        }
      // })
      
    } catch (err) {
      console.log(err, "err__userRegisteration");
    }
  };

  const HandleConnect = async () => {
    try {
      const connect = await connectWallet(dispatch);
      // if(window.location?.href?.split("/")[3]){
      //   props.onReg()
      // }
      // if (connect) {
      //   props.onDismiss();
      // }
      setTimeout(refresh , 1000)
      // refresh();
    } catch (e) {
      console.log("connectTronWallet_err", e);
    }
  };

  return (
    <>
      <div>
        <Modal
          className="walletmodal"
          onHide={props.onDismiss}
          show={register}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header closeButton onClick={props.onDismiss}>
            {/* Login or Register */}
          </Modal.Header>
          <Modal.Body>
            <h2 className="text-start mb-4 mdl_heads">
              Registration in Universal Tron TRC20
            </h2>
            <Form>
              <Form.Group
                className="mb-3 formctrl_bg"
                controlId="exampleForm.ControlInput1">
                <Form.Label className="mdl_lbl">Your upline</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="id"
                  className="mdl_inpt"
                  value={id}
                  readOnly = {true}
                  onChange={(event) => {
                    setid(event.target.value);
                  }}
                />
              </Form.Group>
            </Form>

            <Row className="mt-4">
              <Col xs={2} className="pe-0">
                <div
                  className={`icn_crl d-flex align-items-center justify-content-center ${
                    wallet
                      ? "grn_clr"
                      : wallet === false
                      ? "red_clr"
                      : "gry_clr "
                  }`}>
                  {wallet ? (
                    <>
                      <i class="fa-solid fa-check "></i>
                    </>
                  ) : wallet === false ? (
                    <>
                      <i class="fa-solid fa-exclamation"></i>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col xs={10} className="ps-1">
                <p
                  className={`mb-0 ${
                    wallet
                      ? "grn_clr_txt"
                      : wallet === false
                      ? "red_clr_txt"
                      : "gry_clr_txt"
                  }`}>
                  Wallet: Connected
                </p>
              </Col>
              {/* <Col xs={2} className="pe-0 mt-4">
                <div
                  className={`icn_crl d-flex align-items-center justify-content-center ${
                    network
                      ? "grn_clr"
                      : network === false
                      ? "red_clr"
                      : "gry_clr "
                  }`}
                >
                  {network ? (
                    <>
                      <i class="fa-solid fa-check "></i>
                    </>
                  ) : network === false ? (
                    <>
                      <i class="fa-solid fa-exclamation"></i>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col xs={10} className="ps-1 mt-4">
                <p
                  className={`mb-0 ${
                    network
                      ? "grn_clr_txt"
                      : network === false
                      ? "red_clr_txt"
                      : "gry_clr_txt"
                  }`}
                >
                  Network: Smart chain
                </p>
              </Col> */}
              <Col xs={2} className="pe-0 mt-4">
                <div
                  className={`icn_crl d-flex align-items-center justify-content-center ${
                    registration
                      ? "grn_clr"
                      : registration === false
                      ? "red_clr"
                      : "gry_clr "
                  }`}>
                  {registration ? (
                    <>
                      <i class="fa-solid fa-check "></i>
                    </>
                  ) : registration === false ? (
                    <>
                      <i class="fa-solid fa-exclamation"></i>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col xs={10} className="ps-1 mt-4">
                <p
                  className={`mb-0 ${
                    registration
                      ? "grn_clr_txt"
                      : registration == false
                      ? "red_clr_txt"
                      : "gry_clr_txt"
                  }`}>
                  Registration: available
                </p>
              </Col>
              <Col xs={2} className="pe-0 mt-4">
                <div
                  className={`icn_crl d-flex align-items-center justify-content-center ${
                    balance
                      ? "grn_clr"
                      : balance === false
                      ? "red_clr"
                      : "gry_clr "
                  }`}>
                  {balance ? (
                    <>
                      <i class="fa-solid fa-check "></i>
                    </>
                  ) : balance === false ? (
                    <>
                      <i class="fa-solid fa-exclamation"></i>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col xs={10} className="ps-1 mt-4">
                <p
                  className={`mb-0 ${
                    balance
                      ? "grn_clr_txt"
                      : balance === false
                      ? "red_clr_txt"
                      : "gry_clr_txt"
                  }`}>
                  Balance: Available
                </p>
              </Col>
              {/* <Col xs={2} className="pe-0 mt-4">
                <div
                  className={`icn_crl d-flex align-items-center justify-content-center ${
                    approve
                      ? "grn_clr"
                      : approve === false
                      ? "red_clr"
                      : "gry_clr "
                  }`}
                >
                  {approve ? (
                    <>
                      <i class="fa-solid fa-check "></i>
                    </>
                  ) : approve === false ? (
                    <>
                      <i class="fa-solid fa-exclamation"></i>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col xs={10} className="ps-1 mt-4">
                <p
                  className={`mb-0 ${
                    approve
                      ? "grn_clr_txt"
                      : approve === false
                      ? "red_clr_txt"
                      : "gry_clr_txt"
                  }`}
                >
                  Approve BUSD
                </p>
              </Col> */}
            </Row>

            <Row className="mt-4 mb-2">
              <Col xs={12} sm={6}>
               {!window.location?.href?.split("/")[3]  || localStorage.getItem("address") ? <button
                  className="rgs_btn d-flex align-items-center justify-content-center"
                  disabled = {!balance || !wallet || !registration}
                  onClick={() => {
                    userRegisteration(address);
                  }}>
                  {disable ? "Loading..." : "Register"}
                </button> : ""}
                {window.location?.href?.split("/")[3] && !localStorage.getItem("address") && 
                <button
                className="rgs_btn d-flex align-items-center justify-content-center"
               onClick={HandleConnect}
                >
                Connect Wallet
              </button>
                }
              </Col>
              <Col
                xs={12}
                sm={6}
                className="d-flex align-items-center justify-content-sm-end mt-4 mt-sm-0 justify-content-center">
                {/* <Link
                  className="lgn_btn d-flex align-items-center gap-2"
                  onClick={() => {
                    props.onLogin();
                  }}>
                  Back to Login <i class="fa-solid fa-arrow-right"></i>
                </Link> */}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
