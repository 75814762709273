import React, { useEffect, useState, useRef, useCallback } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Adminsidebar from "../Admin/AdminSidebar";
import AdminHeader from "../Admin/AdminHeader";
import { Link, useLocation } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Modal from 'react-bootstrap/Modal';
import Table from "react-bootstrap/Table";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Virtual,
} from "swiper/modules";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import cardIcon from "../Assets/card_logo.svg";

import Card from "react-bootstrap/Card";
import { IoIosArrowRoundUp } from "react-icons/io";
import { FaArrowUp } from "react-icons/fa6";
import Form from "react-bootstrap/Form";
import info from "../Assets/info_logo.svg";
import plangrp from "../Assets/plan_grp.svg";
import logo from "../Assets/universal_logo.png";
import lockIcon from "../Assets/lock_icon.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import UniversalTronPlan from "../Components/UniversalTronPlan";
import { FaUsers } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";
import coin from "../Assets/coin.svg";
import dummy from "../Assets/metamask.png";
import userGroup from "../Assets/user_group.svg";
import reload from "../Assets/reload.svg";
// SwiperCore.use([Navigation, Pagination, Autoplay, Virtual]);
import singleUser from "../Assets/single-user.svg";
import RedInfo from "../Assets/red_err.svg";
import {
  Getuseraddress,
  GetuserX2matrix,
  GetuserX2matrixplan2,
  GetuserX2matrixplan3,
  SearchuserX2level,
  SearchuserX2levelplan2,
  SearchuserX2levelplan3,
} from "../Hooks/UseContract";
import { usersDetails } from "../Hooks/UseWallet";
import { toast } from "react-hot-toast";
import Buy from "./Modals/Buymodal";
import SkeletonLoadingParticular from "./SkeletonLoadingParticular";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {
  GetAddresstoIdHooks,
  GetLevelProfitHooks,
  GetMissedhistoryHooks,
  GetP1ProfitHooks,
  GetP2ProfitHooks,
  GetpaidnextlevelhistoryHooks,
  GetX2Plan2Hooks,
  Getx2plan3Hooks,
  IdtoAddressHooksX2,
  UserX2Plan1Hooks,
} from "../Hooks/usebackend";
import { data } from "jquery";

export default function PlanParticular() {
  const swiperRef = useRef(null);

  useEffect(() => {
    //...some logic
    //if() {
    if (parseFloat(window.location.href.split("/")[4]) > 0) {
      setPrev(parseFloat(window.location.href.split("/")[4]) - 1);
      setNext(parseFloat(window.location.href.split("/")[4]) + 1);
    }
    const timer = setTimeout(() => {
      console.log(
        swiperRef,
        "swiperRef",
        parseFloat(window.location.href.split("/")[5]) - 1
      );

      swiperRef.current.swiper?.slideTo(
        parseFloat(window.location.href.split("/")[5]) - 1
      );
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  const location = useLocation();
  const dispatch = useDispatch();

  const countValue = location?.state?.count;
  console.log("count val", countValue);
  // const Level = [
  //   {
  //     level: 1,
  //     levelId: "2532531",
  //     coin: "500",
  //     value: 1602561,
  //   },
  //   {
  //     level: 2,
  //     levelId: "2532532",
  //     coin: "600",
  //     value: 1602562,
  //   },
  //   {
  //     level: 3,
  //     levelId: "2532533",
  //     coin: "700",
  //     value: 1602563,
  //     missed: [
  //       {
  //         status: true,
  //         logo: RedInfo,
  //         txt: "Missed Partners",
  //         count: 1,
  //       },
  //     ],
  //   },
  // ];
  var levelcount = 1;
  const { matrixx2data } = useSelector((state) => state.User);
  const [Level, setLevel] = useState([]);
  // matrixx2data?.matrix ? matrixx2data?.matrix : []

  const [levelstatus, setLevelstatus] = useState(
    matrixx2data?.levelstatus ? matrixx2data?.levelstatus : []
  );
  const [userdetail, setUserdetail] = useState({});
  const [startbuy, setStartbuy] = useState(false);
  const [singlelevel, setSinglelevel] = useState({});
  const [singlelevelstatus, setSinglelevelstatus] = useState({});
  const [level, setLevela] = useState(0);
  const [p2, Setp2] = useState([]);

  const [split2user, setSplituser] = useState([]);
  const [refresh , setRefresh] = useState(1);
  const [transindex , setTransindex] = useState(0)
  // const [levelcount , setLevelcount] = useState(1);
  // const [referer , setReferer] = useState({});

  const renderTooltip = (props, partner, reinvest) => {
    return (<></>
      // <Tooltip id="button-tooltip" {...props} className="my_tooltip">
      //   <div className="d-flex px-1 py-1">
      //     <div className="d-flex align-items-center me-3">
      //       <span>
      //         <img
      //           src={userGroup}
      //           alt="usergroup"
      //           className="img-fluid tooltip_user_icon pe-2"
      //         />
      //       </span>
      //       <span>{partner}</span>
      //     </div>
      //     {/* <div className="d-flex align-items-center">
      //       <span>
      //         <img
      //           src={reload}
      //           alt="reload"
      //           className="img-fluid tooltip_reload_icon pe-2"
      //         />
      //       </span>
      //       <span>{reinvest}</span>
      //     </div> */}
      //   </div>
      // </Tooltip>
    );
  };

  const swiper = useSwiper();


  const [show, setShow] = useState(false);
  const [amount , setAmount] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = async(data) => {
    // await getpaidnxtlevelhistory(data) ; 
    setPaidhistory(data);
    setShow(true);
    setTimeout(() => {} , 200) 
  };

  const handleShowprofit = async(data , key , index) => {
    var newdata = []
    if(key == "profit"){
      for(var i =0 ; i<data?.length ; i++){
        if(userplans?.levelUsers[index]?.referral?.includes(data[i]?.from) || data[i]?.key == "OWN"){
          newdata.push(data[i])
        }
      }
    }
    else{
      for(var i =0 ; i<data?.length ; i++){
        if(!userplans?.levelUsers[index]?.referral?.includes(data[i]?.from)){
          newdata.push(data[i])
        }
      }
    }
    setPaidhistory(newdata);
    setShow(true);
    setTimeout(() => {} , 200) 
  }

    const handleShowpaid = async(data) => {
      await getpaidnxtlevelhistory(data) ; 
      // setPaidhistory(data);
      // setShow(true);
      setTimeout(() => {} , 200) };

  const [prev, setPrev] = useState(0);
  const [next, setNext] = useState(1);
  const [swipeloader, setSwipeloader] = useState(false);
  const [levelprofit, setLevelprofit] = useState({});
  const [bonus, setBonus] = useState([]);
  const [keyref, setkeyref] = useState(window?.location?.href?.split("/")[4] == 1 ? 0 : 6);
  const [paidhistory , setPaidhistory] = useState([]);

  const [userplanprofit , setUserplanprofit] = useState([]);
  const [topic , setTopic] = useState("");

  //new scope
  const [x2plan1, setX2plan1] = useState([]);
  const [userplans, setUserplans] = useState({});
  const toMatrix = (arr, width) => {
    console.log("to matrix", arr, width);
    return arr?.reduce(function (rows, key, index) {
      return (
        (index % width == 0
          ? rows.push([key])
          : rows[rows.length - 1].push(key)) && rows
      );
    }, []);
  };

  const fetchPlans = async () => {
    setSwipeloader(true);
    var addres;
    if (window.location.href.split("/")[6]) {
      let idpayload = {
        id: window?.location?.href?.split("/")[6],
      };
      let idtoadd = await IdtoAddressHooksX2(idpayload);
      addres = idtoadd?.data?.record?.walletAddress;
    }
    let payload = {
      walletAddress: window.location.href.split("/")[6]
        ? addres
        : Getuseraddress(),
    };

    let res = await UserX2Plan1Hooks(payload);
    console.log("REsult in x2plan1 , local", res);
    if (res?.data?.type == "Success") {
      // let data = toMatrix( , 2)
      setUserplans(res?.data?.user);
    }


    let result = await UserX2Plan1Hooks(payload);
    console.log("REsult in x2plan1", result);
    if (result?.data?.type == "Success") {
      // let data = toMatrix( , 2)
      // setUserplans(result?.data?.user);
      setX2plan1(result?.data?.plan);

      var newuser = JSON.parse(JSON.stringify(result?.data?.user));
      await result?.data?.user?.levelUsers?.map((item, i) => {
        console.log("to matrix item", item);
        if (item?.referral?.length > 0) {
          let res = toMatrix(item?.referralid, 2);
          // let ref = item.referral = res
          item.referral = res;
          newuser.levelUsers[i] = item;
        } else {
          // let res = toMatrix(item?.referal , 2);
          newuser.levelUsers[i] = item;
        }
      });


      setSplituser(newuser);
      // setPlan1loader(false);
      setSwipeloader(false);
      let income = await GetLevelProfitHooks({ walletAddress: result?.data?.user?.walletAddress });
      console.log("income : ", income);

      setLevelprofit(income?.data?.user);
      let profit = await GetP1ProfitHooks({ walletAddress: result?.data?.user?.walletAddress });
      setUserplanprofit(profit?.data?.record);
    }
  };

  const getp2plan = async () => {
    var addres;
    if (window.location.href.split("/")[6]) {
      let idpayload = {
        id: window?.location?.href?.split("/")[6],
      };
      let idtoadd = await IdtoAddressHooksX2(idpayload);
      addres = idtoadd?.data?.record?.walletAddress;
    }
    let payload = {
      walletAddress: window.location.href.split("/")[6]
        ? addres
        : Getuseraddress(),
    };

    let res = await UserX2Plan1Hooks(payload);
    let temparr = res?.data?.plan ? res?.data?.plan : []
    let resdata = await GetX2Plan2Hooks(payload);
    Setp2(temparr.concat(resdata?.data?.plan));

  }
  const getpaidnxtlevelhistory = async(data) => {
    var addres;
    if (window.location.href.split("/")[6]) {
      let idpayload = {
        id: window?.location?.href?.split("/")[6],
      };
      let idtoadd = await IdtoAddressHooksX2(idpayload);
      addres = idtoadd?.data?.record?.walletAddress;
    }

    let payload = {
      level : data,
      type : "X2",
      // missed : JSON.stringify(data),
      walletAddress : window.location.href.split("/")[6]
      ? addres
      : Getuseraddress(),
      
    }
    console.log("DAta inmised" , data);
    let result = await GetpaidnextlevelhistoryHooks(payload);
    // let result = await GetMissedhistoryHooks(payload);
    setPaidhistory(result?.data?.record);
    setRefresh(refresh+1);
    setShow(true)
  }

  const fetchx2plan2 = async () => {
    setSwipeloader(true);
    var addres;
    if (window.location.href.split("/")[6]) {
      let idpayload = {
        id: window?.location?.href?.split("/")[6],
      };
      let idtoadd = await IdtoAddressHooksX2(idpayload);
      addres = idtoadd?.data?.record?.walletAddress;
    }
    let payload = {
      walletAddress: window.location.href.split("/")[6]
        ? addres
        : Getuseraddress(),
    };

    let res = await GetX2Plan2Hooks(payload);
    setUserplans(res?.data?.user);

    let result = await GetX2Plan2Hooks(payload);
    console.log("REsult", result);
    setX2plan1(result?.data?.plan);
    // setUserplans(result?.data?.user);
    var newuser = result?.data?.user;
    await result?.data?.user?.levelUsers?.map((item, i) => {
      console.log("to matrix item", item);
      if (item?.referral?.length > 0) {
        let res = toMatrix(item?.referralid, 2);
        // let ref = item.referral = res
        item.referral = res;
        newuser.levelUsers[i] = item;
      } else {
        // let res = toMatrix(item?.referal , 2);
        newuser.levelUsers[i] = item;
      }
    });
    setSplituser(newuser);
    setSwipeloader(false);
    let income = await GetLevelProfitHooks({ walletAddress: result?.data?.user?.walletAddress });
    console.log("income : ", income);
    // setLevelprofit(income?.data?.user);
    setLevelprofit(income?.data?.userv2);
    let profit = await GetP2ProfitHooks({ walletAddress: result?.data?.user?.walletAddress });
    setUserplanprofit(profit?.data?.record);

  };

  const fetchx2plan3 = async () => {
    try {
      setSwipeloader(true);
      var addres;
      if (window.location.href.split("/")[6]) {
        let idpayload = {
          id: window?.location?.href?.split("/")[6],
        };
        let idtoadd = await IdtoAddressHooksX2(idpayload);
        addres = idtoadd?.data?.record?.walletAddress;
      }
      let payload = {
        walletAddress: window.location.href.split("/")[6]
          ? addres
          : Getuseraddress(),
      };
      let result = await Getx2plan3Hooks(payload);
      setX2plan1(result?.data?.plan);
      setUserplans(result?.data?.user);
      var newuser = result?.data?.user;
      await result?.data?.user?.levelUsers?.map((item, i) => {
        console.log("to matrix item", item);
        if (item?.referral?.length > 0) {
          let res = toMatrix(item?.referralid, 2);
          // let ref = item.referral = res
          item.referral = res;
          newuser.levelUsers[i] = item;
        } else {
          // let res = toMatrix(item?.referal , 2);
          newuser.levelUsers[i] = item;
        }
      });
      setSplituser(newuser);
      setSwipeloader(false);
    } catch (e) {
      console.log("Errro on fetch x2 plan3", e);
    }
  };

  const fetchbonusprofit = async () => {
    let payload = {
      walletAddress: Getuseraddress()
    }
    let income = await GetLevelProfitHooks(payload);
    console.log("income : ", income);
    if (location.state?.plan == 2 ||
      window?.location?.href?.split("/")[4] == 2) {
      setBonus(income?.data?.userv2?.levelUsers ? income?.data?.userv2?.levelUsers : []);
    }
    else {
      setBonus(income?.data?.user?.levelUsers);
    }
  }

  // useEffect(() => {
  //   fetchPlans();
  // }, [])

  const fetchdata = async () => {
    setSwipeloader(true);
    console.log("url in hrf", window?.location?.href?.split("/"));
    setUserdetail(location?.state?.userdetail);
    if (window?.location?.href?.split("/")[6]) {
      let user = Getuseraddress();
      if (window?.location?.href?.split("/")[4] == 3) {
        fetchx2plan3();
        // let matrixx2 = await SearchuserX2levelplan3(
        //   window?.location?.href?.split("/")[6]
        // );
        // setLevel(matrixx2?.matrix);
        // setLevelstatus(matrixx2?.levelstatus);
      } else if (
        location.state?.plan == 2 ||
        window?.location?.href?.split("/")[4] == 2
      ) {
        fetchx2plan2();
        // let matrixx2 = await SearchuserX2levelplan2(
        //   window?.location?.href?.split("/")[6]
        // );
        // setLevel(matrixx2?.matrix);
        // setLevelstatus(matrixx2?.levelstatus);
      } else {
        fetchPlans();
        // let matrixx2 = await SearchuserX2level(
        //   window?.location?.href?.split("/")[6]
        // );
        // setLevel(matrixx2?.matrix);
        // setLevelstatus(matrixx2?.levelstatus);
      }
    } else {
      let user = Getuseraddress();
      if (
        location.state?.plan == 2 ||
        window?.location?.href?.split("/")[4] == 2
      ) {
        fetchx2plan2();
        // let matrixx2 = await GetuserX2matrixplan2(user);
        // setLevel(matrixx2?.matrix);
        // setLevelstatus(matrixx2?.levelstatus);
      } else if (
        location.state?.plan == 3 ||
        window?.location?.href?.split("/")[4] == 3
      ) {
        fetchx2plan3();
        // let matrixx2 = await GetuserX2matrixplan3(user);
        // setLevel(matrixx2?.matrix);
        // setLevelstatus(matrixx2?.levelstatus);
      } else {
        fetchPlans();
        // let matrixx2 = await GetuserX2matrix(user, dispatch);
        // setLevel(matrixx2?.matrix);
        // setLevelstatus(matrixx2?.levelstatus);
      }
    }
    setUserdetail(location?.state?.userdetail);
    // let usrid = await usersDetails(Getuseraddress());
    // setReferer(usrid);
    setSwipeloader(false);
  };

  useEffect(() => {
    getp2plan();
    fetchdata();
    fetchbonusprofit();
    console.log("useffect", location.state, matrixx2data);
    // if (window?.location?.href?.split("/")[4] == 1) {
    //   setkeyref(0)
    // }
    // else {
    //   setkeyref(6)
    // }
    // setLevel(location?.state?.level);
    // setLevelstatus(location?.state?.levelstatus);
    // setUserdetail(location?.state?.userdetail);
    // handlePrevNext();
  }, []);

  const handlePrevNext = (prev) => {
    setPrev(prev?.activeIndex);
    setNext(prev?.activeIndex + 2);
    // window.location.href = window.location.origin + "/plan/"+ prev?.activeIndex
  };

  console.log("prev vallll", prev);
  console.log("next vallll", next);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const activeIndex = React.useRef(null);
  // const setcount = (data) => {setLevelcount(data)}

  const settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const getcalculation = (missed, referal) => {
    console.log("missed , referal", missed, referal);
    var length = referal?.length;
    for (var i = 0; i < missed?.length; i++) {
      if (referal?.includes(missed[i])) {
        length = length - 1;
      }
    }
    console.log("length", length);
    return length;
  }

  const getdata = (item, index) => {
    // debugger
    console.log("Debugger");
    var finalvalue = 0
    console.log("Debugger in condition", p2[index + 1 + keyref]?.isAutoPool, index + 1 + keyref, p2);
    if (!p2[index + 1 + keyref]?.isAutoPool) {
      if (window?.location?.href?.split("/")[4] == 1) {
        if (index <= 0) {
          let calculatemissedreferal = getcalculation(userplans?.levelUsers[index]?.missedReward, userplans?.levelUsers[index]?.referral)
          finalvalue = (parseFloat(item?.planAmount) * calculatemissedreferal);
          console.log("final value", finalvalue);
          return finalvalue ? finalvalue : 0;
        }
        else {
          let calculatemissedreferal = getcalculation(userplans?.levelUsers[index]?.missedReward, userplans?.levelUsers[index]?.referral)
          let profit60 = ((parseFloat(item?.planAmount) * calculatemissedreferal) * 60 / 100);
          let adminfee = (((parseFloat(item?.planAmount) * calculatemissedreferal) * 60 / 100) * 2.5 / 100);
          finalvalue = profit60 - adminfee;
          console.log("final value", finalvalue);
          return finalvalue ? finalvalue : 0;;
        }
      }
      // else{
      //   let paidnextlevel = 
      // }
    }
    else {
      // if(true){
      //   if(index <= 0 && keyref == 0){
      //     let calculatemissedreferal = getcalculation(userplans?.levelUsers[index]?.missedReward, userplans?.levelUsers[index]?.referral)
      //     finalvalue  =  (parseFloat(item?.planAmount)*calculatemissedreferal);
      //     console.log("final value" , finalvalue);
      //     return finalvalue;
      //   }
      //   else{
      if (userplans?.id == 1) {
        let calculatemissedreferal = getcalculation(userplans?.levelUsers[index]?.missedReward, userplans?.levelUsers[index]?.referral)
        let profit60 = ((parseFloat(item?.planAmount) * calculatemissedreferal) * 60 / 100);
        let adminfee = (((parseFloat(item?.planAmount) * calculatemissedreferal) * 60 / 100) * 2.5 / 100);
        let profitwithourpaidlevel = profit60 - adminfee;
        let paidnextlevel = p2[index + 1 + keyref]?.planAmount ? (parseFloat(p2[index + 1 + keyref]?.planAmount) / parseFloat(p2[index + keyref]?.planAmount)) : 0
        finalvalue = profitwithourpaidlevel - paidnextlevel;
        console.log("final value", calculatemissedreferal, profit60, adminfee, profitwithourpaidlevel, paidnextlevel, finalvalue);
        return profit60 ? profit60 : 0;
      }
      else {
        //debugger
        let calculatemissedreferal = getcalculation(userplans?.levelUsers[index]?.missedReward, userplans?.levelUsers[index]?.referral)
        let profit60 = ((parseFloat(item?.planAmount) * calculatemissedreferal) * 60 / 100);
        let adminfee = (((parseFloat(item?.planAmount) * calculatemissedreferal) * 60 / 100) * 2.5 / 100);
        let profitwithourpaidlevel = profit60 - adminfee;
        let paidnextlevel = p2[index + 1 + keyref]?.planAmount ? (parseFloat(p2[index + 1 + keyref]?.planAmount) / ((parseFloat(p2[index + keyref]?.planAmount) * 60) / 100)) : 0
        finalvalue = profitwithourpaidlevel - (paidnextlevel * parseFloat(item?.planAmount));
        console.log("final value", keyref);
        return finalvalue > 0 ? finalvalue : 0;
      }

      //   }
      // }
    }
    console.log("final value", finalvalue);
    return finalvalue > 0 ? finalvalue : 0;
  }

  const getcalculationmissed = (missed, referal) => {
    console.log("missed , referal", missed, referal);
    var length = referal?.length;
    // for (var i = 0; i < missed?.length; i++) {
    //   if (referal?.includes(missed[i])) {
    //     length = length - 1;
    //   }
    // }
  
    console.log("length", length);
    return missed?.length;
  }

  const getdatamissed = (item, index) => {
    // debugger
    console.log("Debugger");
    var finalvalue = 0
    console.log("Debugger in condition", p2[index + 1 + keyref]?.isAutoPool, index + 1 + keyref, p2);
    if(userplans?.levelUsers[index]?.missedReward?.length > 0){
      if (!p2[index + 1 + keyref]?.isAutoPool) {
        if (window?.location?.href?.split("/")[4] == 1) {
          if (index <= 0) {
            let calculatemissedreferal = getcalculationmissed(userplans?.levelUsers[index]?.missedReward, userplans?.levelUsers[index]?.referral)
            finalvalue = (parseFloat(item?.planAmount) * calculatemissedreferal);
            console.log("final value", finalvalue);
            return finalvalue ? finalvalue : 0;
          }
          else {
            let calculatemissedreferal = getcalculationmissed(userplans?.levelUsers[index]?.missedReward, userplans?.levelUsers[index]?.referral)
            let profit60 = ((parseFloat(item?.planAmount) * calculatemissedreferal) * 60 / 100);
            let adminfee = (((parseFloat(item?.planAmount) * calculatemissedreferal) * 60 / 100) * 2.5 / 100);
            finalvalue = profit60 - adminfee;
            console.log("final value", finalvalue);
            return finalvalue ? finalvalue : 0;;
          }
        }
        // else{
        //   let paidnextlevel = 
        // }
      }
      else {
        // if(true){
        //   if(index <= 0 && keyref == 0){
        //     let calculatemissedreferal = getcalculation(userplans?.levelUsers[index]?.missedReward, userplans?.levelUsers[index]?.referral)
        //     finalvalue  =  (parseFloat(item?.planAmount)*calculatemissedreferal);
        //     console.log("final value" , finalvalue);
        //     return finalvalue;
        //   }
        //   else{
        if (userplans?.id == 1) {
          let calculatemissedreferal = getcalculation(userplans?.levelUsers[index]?.missedReward, userplans?.levelUsers[index]?.referral)
          let profit60 = ((parseFloat(item?.planAmount) * calculatemissedreferal) * 60 / 100);
          let adminfee = (((parseFloat(item?.planAmount) * calculatemissedreferal) * 60 / 100) * 2.5 / 100);
          let profitwithourpaidlevel = profit60 - adminfee;
          let paidnextlevel = p2[index + 1 + keyref]?.planAmount ? (parseFloat(p2[index + 1 + keyref]?.planAmount) / parseFloat(p2[index + keyref]?.planAmount)) : 0
          finalvalue = profitwithourpaidlevel - paidnextlevel;
          console.log("final value", calculatemissedreferal, profit60, adminfee, profitwithourpaidlevel, paidnextlevel, finalvalue);
          return profit60 ? profit60 : 0;
        }
        else {
          // debugger
          let calculatemissedreferal = getcalculation(userplans?.levelUsers[index]?.missedReward, userplans?.levelUsers[index]?.referral)
          let profit60 = ((parseFloat(item?.planAmount) * calculatemissedreferal) * 60 / 100);
          let adminfee = (((parseFloat(item?.planAmount) * calculatemissedreferal) * 60 / 100) * 2.5 / 100);
          let profitwithourpaidlevel = profit60 - adminfee;
          let paidnextlevel = p2[index + 1 + keyref]?.planAmount ? (parseFloat(p2[index + 1 + keyref]?.planAmount) / ((parseFloat(p2[index + keyref]?.planAmount) * 60) / 100)) : 0
          finalvalue = profitwithourpaidlevel - (paidnextlevel * parseFloat(item?.planAmount));
          console.log("final value", calculatemissedreferal, profit60, adminfee, profitwithourpaidlevel, paidnextlevel, finalvalue);
          return finalvalue > 0 ? finalvalue : 0;
        }
  
        //   }
        // }
      }
    }
    console.log("final value", finalvalue);
    return finalvalue > 0 ? finalvalue : 0;
  }


  const getBonus = (transaction , index) => {
    var amount = 0
    for(var i =0 ; i<transaction?.length ; i++){
      if(!userplans?.levelUsers[index]?.referral?.includes(transaction[i]?.from) && transaction[i]?.key != "OWN"){
        amount = amount + parseFloat(transaction[i]?.amount)
      }
    }
    return amount
  }

  return (
    <>
      <div id="dashboard" className="showsidebar plan_particular_content">
        <div id="sidebar">
          <Adminsidebar />
        </div>
        {console.log("userplansuserplans", userplans)}
        <div className="headandcont">
          <div className="adminheader">
            <AdminHeader />
          </div>

          <div className="cont p-sm-5 p-4 cont cont_padding">
            <h5 className="mb-4 heading">
              <div>
                Plan{window?.location?.href?.split("/")[4]} / Universal Tron x2
                {/* {window?.location?.href?.split("/")[5]}{" "} */}
                <span className="level">( level {prev + 1} )</span>
              </div>
              <span className="back_btn">
                <Link to={window?.location?.href?.split("/")[6] ? `/universal-plan/${window?.location?.href?.split("/")[6]}/${window.location.href.split("/")[4]}` : "/dashboard"}>Back</Link>
              </span>
            </h5>
            <div className="my-4 plan_level_slider demo_slider">
              {!swipeloader && (
                <Swiper
                  ref={swiperRef}
                  modules={[Navigation, Pagination, A11y]}
                  spaceBetween={50}
                  slidesPerView={1}
                  onSlideChange={(prev) => handlePrevNext(prev)}
                  navigation={{
                    // Both prevEl & nextEl are null at render so this does not work
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                  }}
                  onSwiper={(swiper) => {
                    // setTimeout(() => {
                    // Override prevEl & nextEl now that refs are defined
                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                    swiper.params.navigation.nextEl = navigationNextRef.current;

                    swiper.activeIndex = activeIndex.current;

                    // Re-init navigation
                    swiper.navigation.destroy();
                    swiper.navigation.init();
                    swiper.navigation.update();
                    // });
                  }}
                >
                  {x2plan1.map((item, index) => (
                    <>
                      <SwiperSlide key={index}>
                        <div className="row d-flex laign-items-center justify-content-center">
                          <div className="col-xl-5 col-lg-7 col-md-6 col-sm-8 col-8">
                            <div className="level_details">
                              <div className="position-relative">
                                <div
                                  className={
                                    // item[5] && item[5]?.toString
                                    userplans?.levelUsers[index]?.missedid
                                      ?.length > 0 && !userplans?.purchasedLevels?.includes(
                                        item?._id
                                      )
                                      ? "missed_tron_level_sec_bg fst_sec position-relative "
                                      : !userplans?.purchasedLevels?.includes(
                                        item?._id
                                      )
                                        ? "fst_sec position-relative blur_card_sec"
                                        : "fst_sec position-relative"
                                  }
                                >
                                  {/* {console.log("status111", item?.missed && item?.missed[0]?.status)}
                              {console.log("status11111 ", levelstatus[index]?.status)} */}
                                  <div className="fst_row">
                                    <span>Level {index + 1}</span>
                                    <span>
                                      ID{" "}
                                      {window?.location?.href?.split("/")[6]
                                        ? window?.location?.href?.split("/")[6]
                                        : userplans?.id}
                                    </span>
                                    <span className="d-flex align-items-center">
                                      <img
                                        src={coin}
                                        alt="coin"
                                        className="img-fluid coin me-2"
                                      />
                                      {item?.planAmount}
                                    </span>
                                  </div>
                                  <div className="snd_row">
                                    {/* {levelstatus[index]?.status == false && item[5] && item[5]?.toString() <= 0 ? (
                                  <>
                                    <div className="missed_part_details">
                                      <img
                                        src={RedInfo}
                                        alt="missed_icon"
                                        className="img-fluid icons"
                                      />
                                      <p className="mb-0">Missed partners</p>
                                      <p className="mb-0">{item[5]?.toString()}</p>
                                    </div>
                                  </>
                                ) : (
                                  <> */}
                                    <Slider
                                      {...settings}
                                      className="custom_slider"
                                    >
                                      {split2user?.levelUsers[
                                        index
                                      ]?.referral?.map((data, i) => {
                                        return (
                                          <div className="inside_cnt">
                                            {/* <div> */}
                                            {data[0] ? (
                                              // <Link
                                              //   // to={parseFloat(item[1].length) > 0 && "/plan/12/12"}
                                              //   className="custom_link_nav"
                                              // >

                                              // </Link>
                                              <OverlayTrigger
                                                placement="bottom"
                                                delay={{
                                                  show: 250,
                                                  hide: 400,
                                                }}
                                                overlay={(props) =>
                                                  renderTooltip(
                                                    props,
                                                    userplans?.levelUsers[
                                                      index
                                                    ]?.referral?.length,
                                                    userplans?.levelUsers[
                                                      index
                                                    ]?.maxpartner
                                                  )
                                                }
                                              >
                                                <a target="_blank" href={data[0] ? window.location.origin + "/user-dashboard/" + data[0] : ""} className="text-decoration-none">
                                                  <span
                                                    className={
                                                      data[0]
                                                        ? split2user?.levelUsers[index]?.missedid?.includes(data[0]) ? "cmn_bg red_bg" : "cmn_bg ylw_bg"
                                                        : "cmn_bg"
                                                    }
                                                  >
                                                    {data[0]?.length > 5 ? `${data[0]?.substring(
                                                      0,
                                                      2
                                                    )}...${data[0]?.substring(32, 34)}` : data[0]}
                                                  </span></a>
                                              </OverlayTrigger>
                                            ) : (
                                              <span className="cmn_bg"></span>
                                            )}
                                            {data[1] ? (
                                              // <Link
                                              //   // to={parseFloat(item[1].length) > 1 && "/plan/12/12"}
                                              //   className="custom_link_nav"
                                              // >

                                              // </Link>
                                              <OverlayTrigger
                                                placement="bottom"
                                                delay={{
                                                  show: 250,
                                                  hide: 400,
                                                }}
                                                overlay={(props) =>
                                                  renderTooltip(
                                                    props,
                                                    userplans?.levelUsers[
                                                      index
                                                    ]?.referral?.length,
                                                    userplans?.levelUsers[
                                                      index
                                                    ]?.maxpartner
                                                  )
                                                }
                                              >

                                                <a target="_blank" href={data[1] ? window.location.origin + "/user-dashboard/" + data[1] : ""} className="text-decoration-none">
                                                  <span
                                                    className={
                                                      data[1]
                                                        ? split2user?.levelUsers[index]?.missedid?.includes(data[1]) ? "cmn_bg red_bg" : "cmn_bg ylw_bg"
                                                        : "cmn_bg"
                                                    }
                                                  >
                                                    {data[1]?.length > 5 ? `${data[1]?.substring(
                                                      0,
                                                      2
                                                    )}...${data[1]?.substring(32, 34)}` : data[1]}
                                                  </span>
                                                </a>

                                              </OverlayTrigger>
                                            ) : (
                                              <span className="cmn_bg"></span>
                                            )}
                                            {/* <span className="cmn_bg"></span>
                                      <span className="cmn_bg"></span> */}
                                            {/* </div> */}
                                            {/* {parseFloat(item?.reinvestCount) == 2 ? (
                                        <>
                                          <span className="cmn_bg"></span>
                                        </>
                                      ) : (
                                        <>
                                          <span className="cmn_bg"></span>
                                          <span className="cmn_bg"></span>
                                        </>
                                      )} */}
                                          </div>
                                        );
                                      })}
                                      {/* {console.log("split2user[index]?.referral?.length <= 0" , split2user[index]?.referral?.length <= 0 , split2user[index]?.referral?.length , split2user)} */}
                                      {split2user?.levelUsers[index]?.referral
                                        ?.length <= 0 && (
                                          <div className="inside_cnt">
                                            {/* <div> */}
                                            <span className="cmn_bg"></span>{" "}
                                            <span className="cmn_bg"></span>
                                            {/* </div> */}
                                            {/* {parseFloat(item?.reinvestCount) == 2 ? (
                                        <>
                                          <span className="cmn_bg"></span>
                                        </>
                                      ) : (
                                        <>
                                          <span className="cmn_bg"></span>
                                          <span className="cmn_bg"></span>
                                        </>
                                      )} */}
                                          </div>
                                        )}
                                    </Slider>

                                    {/* </>
                                )} */}
                                  </div>
                                </div>
                                {!userplans?.purchasedLevels?.includes(
                                  item?._id
                                ) &&
                                  userplans?.levelUsers[index]?.missedid
                                    ?.length <= 0 ? (
                                  <>
                                    <div className="custom_plan_particular_blur">
                                      <div
                                        className="locked_plan d-flex align-items-center flex-column"
                                        onClick={() => {
                                          if (
                                            window.location.href.split(
                                              "/"
                                            )[4] == 1
                                          ) {
                                            if (userplans?.purchasedLevels?.includes(x2plan1[index - 1]?._id)) {
                                              setSinglelevel(item);
                                              setSinglelevelstatus(
                                                levelstatus[index]
                                              );
                                              setLevela(index + 1);
                                              setStartbuy(true);
                                            }
                                            else {
                                              toast.error("You should unlock previous plans!")
                                            }
                                          }
                                          if (
                                            window.location.href.split(
                                              "/"
                                            )[4] == 2
                                          ) {
                                            toast.error(
                                              "You can't buy plan2 It will automatically upgrade"
                                            );
                                          }
                                        }}
                                      >
                                        <p className="mb-2 plan_level">
                                          Level {index + 1}
                                        </p>
                                        {/* <p className="mb-2 plan_level">Amount{item?.planAmount}</p> */}
                                        <img
                                          src={lockIcon}
                                          alt="plan 2"
                                          className="img-fluid lock_logo"
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}

                                {!userplans?.purchasedLevels?.includes(
                                  item?._id
                                ) &&
                                  userplans?.levelUsers[index]?.missedid
                                    ?.length > 0 ? (
                                  <>
                                    <div className="custom_universal_plan_bg_clr">
                                      <div className="custom_plan_particular_blurr">
                                        <div
                                          className="locked_plan d-flex align-items-center flex-cloumn"
                                          onClick={() => {
                                            if (
                                              window.location.href.split(
                                                "/"
                                              )[4] == 1
                                            ) {
                                              if (userplans?.purchasedLevels?.includes(x2plan1[index - 1]?._id)) {
                                                setSinglelevel(item);
                                                setSinglelevelstatus(
                                                  levelstatus[index]
                                                );
                                                setLevela(index + 1);
                                                setStartbuy(true);
                                              }
                                              else {
                                                toast.error("You should unlock previous plans!")
                                              }

                                            }
                                            if (
                                              window.location.href.split(
                                                "/"
                                              )[4] == 2
                                            ) {
                                              toast.error(
                                                "You can't buy plan2 It will automatically upgrade"
                                              );
                                            }
                                          }}
                                        >
                                          <p>
                                            Missed reward :{" "}
                                            {
                                              userplans?.levelUsers[index]
                                                ?.missedid?.length
                                            }
                                          </p>
                                          {/*<p className="mb-0">
                                          Missed amount :{" "}
                                          {}
                                      </p>*/}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                              {console.log(
                                "userplans?.levelUsers[index]",
                                userplans?.levelUsers[index]
                              )}
                              <div className="partner_total_profits new_party_profit">
                                <div className="content_grp">
                                  <p className="header mb-2">Team Size</p>
                                  <div className="d-flex justify-content-center align-items-center content">
                                    <span>
                                      <img
                                        src={singleUser}
                                        alt="usergroup"
                                        className="img-fluid user_icon me-1 single_user_icon"
                                      />
                                    </span>
                                    <span>
                                      {
                                        userplans?.levelUsers[index]?.referral?.length
                                      }
                                    </span>
                                  </div>
                                </div>
                                <div className="content_grp">
                                  <p className="header mb-2">Max Teamsize</p>
                                  <div className="d-flex justify-content-center align-items-center content">
                                    <span>
                                      <img
                                        src={userGroup}
                                        alt="reload"
                                        className="img-fluid reload_icon pe-2"
                                      />
                                    </span>
                                    <span>
                                      {/* {userplans?.levelUsers[index]?.maxpartner} */}
                                      {2 ** (index + 1)}
                                    </span>
                                  </div>
                                </div>
                                <div className="content_grp">
                                  <p className="header mb-2">
                                    Total Profits
                                  </p>
                                  <div className="d-flex justify-content-center align-items-center content">
                                    <span>
                                      <img
                                        src={cardIcon}
                                        alt="usergroup"
                                        className="img-fluid card_icon pe-2"
                                      />
                                    </span>
                                    {console.log("p2data", p2)}
                                    {console.log("bonus in plan", bonus)}

                                    <span onClick={() => {setTopic("Profit");handleShowprofit(userplanprofit[index]?.totaltransaction , "profit" , index); setTransindex(index)}}>
                                      {/* {getdata(item, index) >= 0 ? getdata(item, index) : 0} */}
                                      {(userplanprofit[index]?.profit && getBonus(userplanprofit[index]?.totaltransaction , index) )
                                      ? (userplanprofit[index]?.profit - getBonus(userplanprofit[index]?.totaltransaction , index) ) : userplanprofit[index]?.profit
                                      }+
                                    </span>

                                      
                                      <span onClick={() => {setTopic("Bonus");handleShowprofit(userplanprofit[index]?.totaltransaction , "bonus" , index); setTransindex(index)}} style={{ color: "green" }}>
                                      {getBonus(userplanprofit[index]?.totaltransaction , index) ? getBonus(userplanprofit[index]?.totaltransaction , index) : 0}
                                      </span>

                                    <span className="red_err_txt" 
                                    onClick={() => {
                                      setTopic("Missed Rewards");
                                      setAmount(parseFloat(userplanprofit[index]?.missed));handleShow(userplanprofit[index]?.misshistory)}}>
                                      -{userplanprofit[index]?.missed}</span>

                                      {p2[index + 1 + keyref]?.isAutoPool && <span style={{ color: "orange" }} 
                                      onClick={() => {setTopic("Paid For NextLevel");handleShowpaid(index+keyref+2)}}
                                      > &nbsp;|&nbsp;
                                      {userplanprofit[index]?.paidnextlevel ? userplanprofit[index]?.paidnextlevel : 0}
                                      </span>}
                                    {/* index+(window?.location?.href?.split("/")[4] == 1 ? 2 : 8) */}
                                    {/* userplans?.levelUsers[index]?.paidfornextlevel */}
                                    {/* levelstatus[index]?.profit */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="direct_part d-flex align-items-center justify-content-center">
                            <div className="foot_partner_level_collec">
                              <div className="d-flex align-items-center">
                                <span className="direct_partner me-2"></span>
                                <span>Direct Partner</span>
                              </div>
                              <div className="d-flex align-items-center">
                                <span>
                                  <img
                                    src={userGroup}
                                    alt="usergroup"
                                    className="img-fluid user_icon pe-2"
                                  />
                                </span>
                                <span>Partner Level</span>
                              </div>
                              <div className="d-flex align-items-center">
                                <span>
                                  <img
                                    src={reload}
                                    alt="reload"
                                    className="img-fluid reload_icon pe-2"
                                  />
                                </span>
                                <span>level Cycles</span>
                              </div>
                            </div>
                          </div> */}
                          </div>
                        </div>
                      </SwiperSlide>
                    </>
                  ))}
                  <div ref={navigationPrevRef} className="custom_prev_slide">
                    <div className="content">
                      <span>L{prev}</span>
                    </div>
                  </div>
                  <div ref={navigationNextRef} className="custom_next_slide">
                    <div className="content">
                      <span>L{next}</span>
                    </div>
                  </div>
                </Swiper>
              )}
              {swipeloader && <SkeletonLoadingParticular />}
            </div>
          </div>
        </div>
      </div>
      {startbuy && (
        <Buy
          level={singlelevel}
          levelstatus={singlelevelstatus}
          lvl={level}
          user = {userplans}
          onDismiss={() => setStartbuy(false)}
          refresh={fetchdata}
        />
      )}
      {/* modal */}
      <Modal show={show} onHide={handleClose} centered size="lg" className="partners_side_section">
        <Modal.Header closeButton>
          <Modal.Title>
            Transaction
            <div className="d-flex gap-3 align-items-center" >
              <h6 className="mb-0">{topic}</h6>
              {/* <Button className="partner_id total_partner_cnt">
                1
              </Button> */}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table_sec">
            <Table responsive>
              <thead>
                <tr className="t_head">
                  <th>From</th>
                 {paidhistory[0]?.to&&  <th>To</th>}
                  {/* <th>Level</th> */}
                  <th>{topic == "Missed Rewards" ? "Missed Income" : "Income"}</th>
                </tr>
              </thead>

              <tbody>
                {paidhistory && paidhistory?.length > 0 ? paidhistory?.map((item, i) => {
                  console.log("history trans" , item);
                  return(
                    <tr className="t_body">
                    <td>
                      <a target="_blank" href = {window.location.origin +"/user-dashboard/"+(item?.id ? item?.id : item?.fromId)} className="custom_a_wht" > 
                      {item?.id ? item?.id : item?.fromId}</a>
                                   
                                    </td>
                   { paidhistory[0]?.to&& <td>
                      {/* <a target="_blank" href = {window.location.origin +"/user-dashboard/"+item?.to} className="custom_a_wht" >{`${item?.to?.substring(
                                      0,
                                      5
                                    )}...${
                                      item?.to
                                    ?.substring(31, 34)}`}</a> */}
                                    {item?.toId}
                                    </td>}
                    {/* <td>{item?.level}</td> */}
                    <td className="partner_id_td custom_plan_partner_id" style={{ width: "unset", minWidth: "unset" }}>
                      <Button className={(userplans?.levelUsers[transindex]?.referral?.includes(item?.from) || topic == "Paid For NextLevel"
                      || topic == "Missed Rewards"
                      )? "partner_id" : "partner_id partner_id_green"}>
                        {item?.amount ? item?.amount : amount/paidhistory?.length}
                      </Button>
                    </td>

                  </tr>
                  )
                }) :<td colSpan={2}>
                <p className="mb-0 data_not_found_txt">No data found...</p>
              </td> }
               
              </tbody>

{console.log("paidnextlevel" , paidhistory)}
              
              {/* <tbody>
                <tr>
                  <td colSpan={4} style={{ borderBottom: "none" }}>
                    <p className="mb-0 data_not_found_txt">No Transaction Found</p>
                  </td>
                </tr>
              </tbody> */}




            </Table>
            {/* <div className="d-flex justify-content-center align-items-center">
              <button className="common_yellow_btn">Loading...</button>
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} className="common_yellow_btn btn btn-primary">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
