const BASE_URL = "x3";

const NEW_BASE_URL = "mlm"

export const GetBoardPlansApi = BASE_URL+"/get-board-plans";

// export const CreateUserApi = BASE_URL + "/create-x3-user";

// export const CreateUserSaveApi = BASE_URL + "/create-x3-user-save";

export const GetBoard1planApi = BASE_URL + "/get-board1";

export const GetBoard2planApi = BASE_URL + "/get-board2";

export const GetBoard3planApi = BASE_URL + "/get-board3";

export const GetBoard4planApi = BASE_URL + "/get-board4";

export const GetIdtoAddressX3Api = BASE_URL + "/get-user-to-id";

export const GetuserX3Api = BASE_URL + "/get-user-x3";

export const GetPartnersApi = BASE_URL + "/get-partners";

export const GetTransactionApi = BASE_URL + "/get-transaction";

export const CreateUserApi = NEW_BASE_URL + "/x3/buy";

export const CreateUserSaveApi = NEW_BASE_URL + "/x3/buy-save";