import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { FaUsers } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";
import lockIcon from "../../Assets/lock_icon.svg";
import { Button } from "react-bootstrap";
import Lottie from "lottie-react";
import lock from "../../Assets/Json/lockk.json";
import { Link } from "react-router-dom";
import coin from "../../Assets/coin.svg";
import userGroup from "../../Assets/user_group.svg";
import reload from "../../Assets/reload.svg";
import { Getuseraddress, SearchuserX2level, SearchuserX2levelplan2, SearchuserX2levelplan3 } from "../../Hooks/UseContract";
import SkeletonLoadingPlan from "../SkeletonLoadingPlan";
import { GetX2Plan2Hooks, Getx2plan3Hooks, IdtoAddressHooksX2, UserX2Plan1Hooks } from "../../Hooks/usebackend";

function OUTotalPlanDetails({ plan, ut, lockedPlan, countVal  , currentuser}) {
  // const PlanLevel = [
  //   {
  //     level: 1,
  //     totalNo: 500,
  //     user: 24,
  //     count: 4,
  //     locked: false,
  //     missed: "420 TRX",
  //     countVal: 2,
  //   },
  //   {
  //     level: 2,
  //     totalNo: 500,
  //     user: 24,
  //     count: 4,
  //     locked: false,
  //     missed: "420 TRX",
  //     countVal: 3,
  //   },
  //   {
  //     level: 3,
  //     totalNo: 500,
  //     user: 24,
  //     count: 4,
  //     locked: false,
  //     missed: "420 TRX",
  //     countVal: 2,
  //   },
  //   {
  //     level: 4,
  //     totalNo: 500,
  //     user: 24,
  //     count: 4,
  //     locked: false,
  //     missed: "420 TRX",
  //     countVal: 2,
  //   },
  //   {
  //     level: 5,
  //     totalNo: 500,
  //     user: 24,
  //     count: 4,
  //     locked: true,
  //     missed: "420 TRX",
  //     countVal: 2,
  //   },
  //   {
  //     level: 6,
  //     totalNo: 500,
  //     user: 24,
  //     count: 4,
  //     locked: true,
  //     missed: "1",
  //     countVal: 2,
  //   },
  // ];
  const [x2matrix , setX2matrix] = useState([]);
  const [x2matrixstatus , setMatrixstatus] = useState([]);
  const [PlanLevel , setPlanlevel] = useState([]);
  const [levelstatus , setLevelstatus] = useState([]);
  const [startbuy , setStartbuy] = useState(false);
  const [singlelevel , setSinglelevel] = useState({});
  const [singlelevelstatus , setSinglelevelstatus] = useState({});
  const [level , setLevel] = useState(0);
  const [userdetail , setUserdetail] = useState({});
  const [x2plan2 , setX2plan2] = useState([]);
  const [x2plan2status , setX2plan2status] = useState([]);
  // const [plan1loader , setPlan1loader] = useState(false);
  // const [plan2loader , setPlan2loader] = useState(false);
  const [loader , setLoader] = useState(false);
  const [userplan , setUserplans] = useState({});
  const [key , setKey] = useState(0)



  const fetchdata = async()=>{
    setLoader(true)
    // let user = Getuseraddress()
    let matrixx2 = await SearchuserX2level(window?.location?.href?.split("/")[4]);
    console.log("checking array" , matrixx2);
    // setX2matrix(matrixx2?.matrix);
    // setMatrixstatus(matrixx2?.levelstatus)
    setPlanlevel(matrixx2?.matrix);
    setLevelstatus(matrixx2?.levelstatus)
    setLoader(false)
  }

  const fetchplan2 = async() => {
    // let user = Getuseraddress();
    setLoader(true)
    let matrixx2 = await SearchuserX2levelplan2(window?.location?.href?.split("/")[4]);
    setPlanlevel(matrixx2?.matrix);
    setLevelstatus(matrixx2?.levelstatus);
    setLoader(false)
  }

  const fetchplan3 = async() => {
    setLoader(true);
    let user = Getuseraddress();
    let matrixx2 = await SearchuserX2levelplan3(window?.location?.href?.split("/")[4]);
    setPlanlevel(matrixx2?.matrix);
    setLevelstatus(matrixx2?.levelstatus);
    setLoader(false);
  }

  //new scope

  const fetchx2plan1 = async() => {
    setLoader(true)
    let idpayload = {
      id : window?.location?.href?.split("/")[4]
    };
    let idtoadd = await IdtoAddressHooksX2(idpayload);
    console.log("idtadd" , idtoadd);
    let payload = {
      walletAddress : idtoadd?.data?.record?.walletAddress
    }
    let result = await UserX2Plan1Hooks(payload);
    console.log("REsult in x2plan1" , result);
    if(result?.data?.type == "Success"){
      setPlanlevel(result?.data?.plan);
      setUserplans(result?.data?.user);
      setLoader(false);
    }
  }

  const fetchx2plan2 = async() => {
    setLoader(true);
    let idpayload = {
      id :window?.location?.href?.split("/")[4]
    };
    let idtoadd = await IdtoAddressHooksX2(idpayload);
    console.log("idtadd" , idtoadd);
    let payload = {
      walletAddress : idtoadd?.data?.record?.walletAddress
    }
    let result = await GetX2Plan2Hooks(payload);
    setPlanlevel(result?.data?.plan);
    setUserplans(result?.data?.user);
    setLoader(false);
  }

  const fetchx2plan3 = async() => {
    setLoader(true);
    let idpayload = {
      id : window?.location?.href?.split("/")[4]
    };
    let idtoadd = await IdtoAddressHooksX2(idpayload);
    console.log("idtadd" , idtoadd);
    let payload = {
      walletAddress : idtoadd?.data?.record?.walletAddress
    }
    let result = await Getx2plan3Hooks(payload);
    setPlanlevel(result?.data?.plan);
    setUserplans(result?.data?.user);
    setLoader(false);
  }

  useEffect(()=> {
    if(window?.location?.href?.split("/")[5] == 1){
      fetchx2plan1();
      setKey(0)
    }
    if(window?.location?.href?.split("/")[5] == 2){
      fetchx2plan2();
      setKey(7)
    }
    if(window?.location?.href?.split("/")[5] == 3){
      fetchx2plan3();
      setKey(13);
    }
  } , []);

  return (
    <div className="plan_card_sec mt-5">
      <div className="head_section">
        <h6 className="sub_heding">
          {/* Plan {plan} - UT x {ut} */}
          UT x {window?.location?.href?.split("/")[5]}
        </h6>
        {/* <h6 className="sub_heding_val">24 TRX</h6> */}
      </div>
      <div className="plan_collection mt-4">
        <Card className="">
          <Card.Body className="card_custom_pad">
            {/* className={lockedPlan ? "blur_card_sec" : ""} */}
            <div
            // className={lockedPlan ? "plan_level_missed_sec_bg_content" : ""}
            >
              <div className="level_collection">
                <div className="common_level_card">
                  <Card className="">
          <Card.Body className="card_custom_pad">
            <div className={lockedPlan ? "blur_card_sec" : ""}>
              <div className="level_collection">
                <div className="common_level_card">
                  {!loader && PlanLevel?.length > 0 && PlanLevel?.map((item , i) => {
                    return (
                      <>
                        <Card className="level_card">
                          <Card.Body className="position-relative p-3">
                            <div className={!userplan?.purchasedLevels?.includes(item?._id) ? "blur_card_sec" : ""}>
                              <Link
                                to={`/plan/${1}/${i+1}/${currentuser?.id}`}
                                state={{ count: 2 , level : PlanLevel , levelstatus : levelstatus , userdetail : userdetail}}
                                className="plan_custom_navi"
                              >
                                <div className="head_sec d-flex justify-content-between">
                                  <span>Level {i+1}</span>
                                  <div className="d-flex align-items-center">
                                    {/* <span className="sml_circle me-1"></span> */}
                                    <img src={coin} className="coin_img me-1" />
                                    <span>{item?.planAmount ? parseFloat(item?.planAmount) : 0}</span>
                                  </div>
                                </div>
                                <div className="body_sec">
                                  <span className={userplan?.levelUsers[key+i]?.referral?.length > 0 ? userplan?.levelUsers[key+i]?.missedReward?.includes(userplan?.levelUsers[i]?.referral[0]) ? "cmn_bg_color red_bg me-3" :"cmn_bg_color completed me-3" : "cmn_bg_color in_completed me-3"}></span>
                                  <span className={userplan?.levelUsers[key+i]?.referral?.length > 1 ? userplan?.levelUsers[key+i]?.missedReward?.includes(userplan?.levelUsers[i]?.referral[0]) ? "cmn_bg_color red_bg me-3" :"cmn_bg_color completed" : "cmn_bg_color in_completed"}></span>
                                </div>
                                <div className="foot_sec d-flex justify-content-between">
                                  <span>
                                    <img
                                      src={userGroup}
                                      alt="usergroup"
                                      className="img-fluid user_icon pe-2"
                                    />
                                    {userplan?.levelUsers[i]?.referral?.length ? userplan?.levelUsers[i]?.referral?.length : 0}
                                    {/* {levelstatus[i]?.partnercount} */}
                                    {/* {userdetail?.partnersCount} */}
                                  </span>
                                  {/* <span>
                                    <img
                                      src={reload}
                                      alt="reload"
                                      className="img-fluid reload_icon pe-2"
                                    />
                                    {item[3] ? item[3]?.toString() : 0}
                                  </span> */}
                                </div>
                              </Link>
                            </div>
                            {!userplan?.purchasedLevels?.includes(item?._id)  ? (
                              <>
                                <div className="locked_plan" onClick={() => {
                                  setSinglelevel(item);
                                  setSinglelevelstatus(levelstatus[i]);
                                  setLevel(i+1);
                                  setStartbuy(true);
                                }}>
                                  <p className="mb-2 plan_level">
                                    Level {i+1}
                                  </p>
                                  <img
                                    src={lockIcon}
                                    alt="plan 2"
                                    className="img-fluid lock_logo"
                                  />
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </Card.Body>
                        </Card>
                      </>
                    );
                  })}
                  {loader &&   
                  [...Array(6)].map((e, i) =>
                                    <SkeletonLoadingPlan />
                                )
                              }
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center">
               
              </div>
            </div>
            {lockedPlan == true ? (
              <>
                <div className="locked_plan">
                  <p className="mb-2 plan_level">Plan {plan}</p>
                  <Lottie
                    className="lock_lottie"
                    animationData={lock}
                    loop={true}
                  />
                  <Button className="unlock_btn common_yellow_btn">
                    Unlock
                  </Button>
                </div>
              </>
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <div className="foot_partner_level_collec">
                  <div className="d-flex align-items-center">
                    <span className="direct_partner me-2"></span>
                    <span>Direct Partner</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span>
                      <FaUsers className="me-2" size={23} />
                    </span>
                    <span>Partner Level</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span>
                      <TfiReload className="me-2" size={21} />
                    </span>
                    <span>level Cycles</span>
                  </div>
                </div>
              </div>
            </div>
            {lockedPlan == true ? (
              <>
                <div className="locked_plan">
                  <p className="mb-2 plan_level">Plan {plan}</p>
                  <Lottie
                    className="lock_lottie"
                    animationData={lock}
                    loop={true}
                  />
                  <Button className="unlock_btn common_yellow_btn">
                    Unlock
                  </Button>
                </div>
              </>
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default OUTotalPlanDetails;
