import React, { useEffect, useState } from "react";
import { Button, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Adminsidebar from "../../Admin/AdminSidebar";
import AdminHeader from "../../Admin/AdminHeader";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { IoIosArrowRoundUp } from "react-icons/io";
import { FaArrowUp } from "react-icons/fa6";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
// import info from "../Assets/info_logo.svg";
// import plangrp from "../Assets/plan_grp.svg";
// import logo from "../Assets/universal_logo.png";
// import lockIcon from "../Assets/lock_icon.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OUTotalPlanDetails from "./OUTotalPlanDetail";
// import UniversalTronPlan from "../Components/UniversalTronPlan";
import Table from "react-bootstrap/Table";
import userLogo from "../../Assets/userNot.svg";
import user1 from "../../Assets/user1.png";
import { Convertaddress, Getidtoaddress, Getuseraddress, GetuserX2matrix, GetuserX2matrixplan2, SearchuserX2level, SearchuserX2levelplan2, Userreferlist, UserreferlistX3 } from "../../Hooks/UseContract";
import { useDispatch } from "react-redux";
import { IdtoAddressHooksX2 } from "../../Hooks/usebackend";
import { GetPartnersHook, GetUserX3hooks } from "../../Hooks/usebackendX3";
export default function OUTotalPlanList() {
  const dispatch = useDispatch();
  const location = useLocation();

  const countVal = location?.state?.count;
  console.log("countVal", countVal);

  const [x2matrix , setX2matrix] = useState([]);
  const [x2matrixstatus , setMatrixstatus] = useState([]);
  const [referals , setReferals] = useState([]);
  const [x2plan2 , setX2plan2] = useState([]);
  const [x2plan2status , setX2plan2status] = useState([]);
  const [address , setAddress] = useState([]);
  const [referals1 , setReferals1] = useState([]);
  const [address1 , setAddress1] = useState([]);

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [button, setButton] = useState(false);
  const [loader , setLoader] = useState(false);
  const [PartnerDetails, setPartnerdetails] = useState([]);
  const [userdetail, setUserdetail] = useState({});


  const fetchdata = async () => {
    if (Getuseraddress()) {
      setLoader(true);
      let idpayload = {
        id : window?.location?.href?.split("/")[4]
      };
      let idtoadd = await IdtoAddressHooksX2(idpayload);
      setUserdetail(idtoadd?.data?.record);
      console.log("idtadd" , idtoadd);
      let payload = {
        skip: 0,
        limit: 10,
        user: idtoadd?.data?.record?.walletAddress
      }
      let partners = await GetPartnersHook(payload);
      // let usr = await GetUserX3hooks({ walletAddress: Getuseraddress() })
      // setUserdetail(usr?.data?.record);

      console.log("partners", partners);
      setPartnerdetails(partners?.data?.record);
      setCount(partners?.data?.count);
      setSkip(skip + limit);
      // setPartneradd(partners?.partnersadd);
      setLoader(false);
    }
  };

  const loadmore = async () => {
    if (parseInt(count) > PartnerDetails?.length) {
      setButton(true);
      let payload = {
        skip: 0,
        limit: 10,
        user: userdetail?.walletAddress
      }
      let partners = await GetPartnersHook(payload);
      setPartnerdetails(PartnerDetails?.concat(partners?.data?.record));
      console.log("partners", partners);
      setSkip(skip+limit);
      setButton(false);
    }
  }
  

  useEffect(()=> {console.log("data in checkingurl" , window?.location?.href?.split("/"));
    // if(window?.location?.href?.split("/")[5] == 1){
      fetchdata();
    // }
    // if(window?.location?.href?.split("/")[5] == 2){
    //   fetchplan2();
    // }
  } , []);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" className="rct_cust_tltp" {...props}>
      {props}
    </Tooltip>
  );

  

  return (
    <>
      <div
        id="dashboard"
        className="showsidebar dashboard_sec_content universal_tron_plans"
      >
        <div id="sidebar">
          <Adminsidebar />
        </div>

        <div className="headandcont">
          <div className="adminheader">
            <AdminHeader />
          </div>

          <div className="cont p-sm-5 p-4 cont cont_padding">
            <h5 className="mb-4 heading">
              <div>
                ID {userdetail?.id} / Universal Tron x {window?.location?.href?.split("/")[5]}{" "}
                <span className="level"> ( 6 Out of 6 levels )</span>
              </div>
              <span className="back_btn">
                <Link to={`/user-dashboard/${window?.location?.href?.split("/")[4]}`}>Back</Link>
              </span>
            </h5>
            {/* <UniversalTronPlan plan={1} ut={2} locked={false} /> */}
            <OUTotalPlanDetails countVal={countVal} currentuser = {userdetail}/>
            <div className="partners_side_section">
              <div className="mt-5 table_sec">
                <Table responsive>
                  <thead>
                  <tr className="t_head">
                    {/* <th>Date</th> */}
                    <th>Address</th>
                    <th>Referer</th>
                    {/* <th>Plan</th> */}
                    <th>ID</th>
                    {/* <th>X2</th>
                    <th>X3</th> */}
                    {/* <th>Partners</th> */}
                    {/* <th className="profits_th">Profits</th> */}
                  </tr>
                  </thead>
                 {!loader ?  <tbody>
                  
                    {PartnerDetails && PartnerDetails?.map((item, index) => {
                      return (
                        <>
                          <tr className="t_body">
                            {/* <td>
                            <div>{new Date(parseFloat(item.timeStamp)).toLocaleDateString()}</div>
                          </td> */}

                            <td>
                              {" "}
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip(
                                  item?.walletAddress
                                  // Convertaddress(partneradd[index])
                                )}
                                name="hariharan"
                              >
                                <p className="mb-0">
                                  {`${item?.walletAddress
                                    ?.substring(0, 5)}...${item?.walletAddress?.substring(31, 34)}`}
                                </p>
                              </OverlayTrigger>
                            </td>
                            {/* <td>{item?.id}</td> */}
                            {/* <td>
                              {" "}
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip(
                                  item?.originalReferrer
                                )}
                                name="hariharan"
                              >
                                <p className="mb-0">
                                  {`${item?.originalReferrer?.substring(
                                    0,
                                    5
                                  )}...${item?.originalReferrer?.substring(31, 34)}`}
                                </p>
                              </OverlayTrigger>
                            </td> */}
                            <td>{item?.refererId}</td>
                            {/* <td>{item?.type}</td> */}
                            {/* <td>{`${Convertaddress(partneradd[index])?.substring(0, 5)}...${Convertaddress(partneradd[index])?.substring(31,34)}`}</td>
                          <td>{`${Convertaddress(item?.referrer)?.substring(0, 5)}...${Convertaddress(item?.referrer)?.substring(31,34)}`}</td> */}
                            <td className="partner_id_td">
                              <Button className="partner_id">{item?.id}</Button>
                            </td>
                            {/* <td>{item.x2}</td>
                          <td>{item.x3}</td> */}
                            {/* <td>{item?.partnersCount}</td> */}
                            {/* <td>{item?.x3profit ? item?.x3profit : item?.x2profit}</td> */}
                          </tr>
                        </>
                      );
                    })}
                  </tbody>:
                  <tbody>
                  <td colSpan={3}>
                    <p className="mb-0 data_not_found_txt">Loading...</p>
                  </td>
                </tbody>
                  }

                  {!loader && PartnerDetails?.length <= 0 && <tbody>
                  <td colSpan={3}>
                    <p className="mb-0 data_not_found_txt">No Partner Found</p>
                  </td>
                </tbody>}
                </Table>
                <div className="d-flex align-items-center justify-content-center mt-2">
                {count > PartnerDetails?.length && <button onClick={loadmore} className="common_yellow_btn">{button ? "Loading..." : "Loadmore"}</button>}
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
