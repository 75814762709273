import React, { useEffect, useState } from "react";
import { Button, Container, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Adminsidebar from "./AdminSidebar";
import AdminHeader from "./AdminHeader";
import Card from "react-bootstrap/Card";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowRoundUp } from "react-icons/io";
import { FaArrowUp } from "react-icons/fa6";
import Form from "react-bootstrap/Form";
import info from "../Assets/info_logo.svg";
import plangrp from "../Assets/plan_grp.svg";
import logo from "../Assets/universal_logo.png";
import lockIcon from "../Assets/lock_icon.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import UniversalTronPlan from "../Components/UniversalTronPlan";
import UniversalTronPlanTrans from "../Components/universaltronplantrans";
import MyCurrentPlan from "../Components/MyCurrentPlan";
import DashTotalCounts from "../Components/DashTotalCounts";
import BoardPlan from "../Components/BoardPlan";
import BoardPlanParticular from "../Components/BoardPlanParticular";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Checkuserexist, Getuseraddress, GetuserX2matrix, GetuserX2matrixplan2, GetuserX2matrixplan3, GetuserX3matrixplan1, GetuserX3matrixplan2, GetuserX3matrixplan3, GetuserX3matrixplan4 } from "../Hooks/UseContract";
import Boardx3plan from "../Components/boardx3plan";
import { GetLevelProfitHooks, GetPartnerplan4Hooks, GetTransactionLevelHooks, GetX2Plan2Hooks, Getx2plan3Hooks, UserX2Plan1Hooks } from "../Hooks/usebackend";
import { GetX3BoardplanHooks } from "../Hooks/usebackendX3";

export default function Admindashboard() {
  const dispatch = useDispatch();

  const options = {
    loop: true,
    margin: 40,
    nav: false,
    dots: false,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        // nav: true,
      },
      380: {
        items: 1,
        // nav: true,
      },
      480: {
        items: 1,
      },
      576: {
        items: 2,
        // nav: false,
      },
      767: {
        items: 2,
        // nav: false,
      },
      991: {
        items: 2,
        // nav: true,
        // loop: false,
      },
      1200: {
        items: 3,
        // nav: true,
        // loop: false,
      },
    },
  };

  const CurrentPlan = [
    {
      planNo: 1,
      trx: 0,
      active: 1,
      locked: false,
    },
    {
      planNo: 2,
      trx: 2,
      active: 2,
      locked: true,
    },
    {
      planNo: 3,
      trx: 3,
      active: 3,
      locked: true,
    },
    {
      planNo: 4,
      trx: 4,
      active: 4,
      locked: true,
    },
    {
      planNo: 5,
      trx: 5,
      active: 5,
      locked: true,
    },
    {
      planNo: 6,
      trx: 6,
      active: 6,
      locked: true,
    },
  ];
  const PlanDetails = [
    {
      plan: 1,
      ut: 2,
    },
  ];
  const { matrixx2data } = useSelector((state) => (state.User))
  const [scroll, setScroll] = useState("");
  const [x2matrix, setX2matrix] = useState(matrixx2data?.matrix ? matrixx2data?.matrix : []);
  const [x2matrixstatus, setMatrixstatus] = useState(matrixx2data?.levelstatus ? matrixx2data?.levelstatus : []);
  const [dummyuser, setDummyyuser] = useState({
    "_id": "",
    "walletAddress": "",
    "id": "",
    "refererWallet": "",
    "refererId": "",
    "referrals": [],
    "allreferals": [],
    "originalReferrer": "",
    "currentLevel": "65b27378aaf8ffd8b8279924",
    "purchasedLevels": [

    ],
    "x2profit": 0,
    "x3profit": 0,
    "lastFreeUserId": "",
    "lastFreeUserWallet": "",
    "downlimit": "2",
    "levelUsers": [
      {
        "level": "8",
        "referer": "",
        "referral": [],
        "filled": false,
        "partners": [],
        "totalProfit": "0",
        "missedReward": [],
        "paidfornextlevel": 10368,

      },
      {
        "level": "9",
        "referer": "",
        "referral": [],
        "filled": false,
        "partners": [],
        "totalProfit": "0",
        "missedReward": [],
        "paidfornextlevel": 20736,

      },
      {
        "level": "10",
        "referer": "",
        "referral": [],
        "filled": false,
        "partners": [],
        "totalProfit": "0",
        "missedReward": [],
        "paidfornextlevel": 0,

      },
      {
        "level": "11",
        "referer": "",
        "referral": [],
        "filled": false,
        "partners": [],
        "totalProfit": "0",
        "missedReward": [],
        "paidfornextlevel": 0,

      },
      {
        "level": "12",
        "referer": "",
        "referral": [],
        "filled": false,
        "partners": [],
        "totalProfit": "0",
        "missedReward": [],
        "paidfornextlevel": 0,

      },
      {
        "level": "13",
        "referer": "",
        "referral": [],
        "filled": false,
        "partners": [],
        "totalProfit": "0",
        "missedReward": [],
        "paidfornextlevel": 0,

      }
    ],
  })
  const [x2plan2status, setX2plan2status] = useState([]);
  const [x2plan3status, setX2plan3status] = useState([]);
  const [board1, setBoard1] = useState([]);
  const [board1status, setBoard1status] = useState([]);
  const [board2, setBoard2] = useState([]);
  const [board2status, setBoard2status] = useState([]);
  const [board3, setBoard3] = useState([]);
  const [board3status, setBoard3status] = useState([]);
  const [board4, setBoard4] = useState([]);
  const [board4status, setBoard4status] = useState([]);

  const [search, setSearch] = useState("");

  const [plan1loader, setPlan1loader] = useState(false);
  const [plan2loader, setPlan2loader] = useState(false);
  const [plan3loader, setPlan3loader] = useState(false);
  const [board1loader, setBoard1loader] = useState(false);
  const [board2loader, setBoard2loader] = useState(false);
  const [board3loader, setBoard3loader] = useState(false);
  const [board4loader, setBoard4loader] = useState(false);
  const navigate = useNavigate();

  //new scope
  const [x2plan1, setX2plan1] = useState([]);
  const [userplans, setUserplans] = useState([]);
  const [x2plan2, setX2plan2] = useState([]);
  const [x2plan3, setX2plan3] = useState([]);
  const [userplans2, setUserplans2] = useState({});
  const [boardloader, setBoardloader] = useState(false);
  const [userplanx3, setUserplanx3] = useState({});
  const [userincome, setUserincome] = useState({});
  const [userincomev2, setUserincmev2] = useState({});
  const [loader, setLoader] = useState(false);
  const [plan4, setPlan4] = useState([]);
  const [plan4count , setPlan4count] = useState([]);



  const fetchx2plan1 = async () => {
    setPlan1loader(false);
    let payload = {
      walletAddress: Getuseraddress()
    }
    let result = await UserX2Plan1Hooks(payload);
    // console.log("REsult in x2plan1" , result);
    if (result?.data?.type == "Success") {
      setX2plan1(result?.data?.plan);
      setUserplans(result?.data?.user);
      setPlan1loader(false);
    }
  }

  const fetchx2plan2 = async () => {
    setPlan2loader(true);
    let payload = {
      walletAddress: Getuseraddress()
    }
    let result = await GetX2Plan2Hooks(payload);
    setX2plan2(result?.data?.plan);
    setUserplans2(result?.data?.user);
    setPlan2loader(false);
  }

  useEffect(() => {
    if(!Getuseraddress()){
      window.location.href = window.location.origin;
    }
  },[])

  const fetchx2plan3 = async () => {
    try {
      let payload = {
        walletAddress: Getuseraddress()
      }
      let result = await Getx2plan3Hooks(payload);
      setX2plan3(result?.data?.plan);
    }
    catch (e) {
      console.log("Errro on fetch x2 plan3", e);
    }
  }

  const fetchBoardPlans = async () => {
    setBoardloader(true);
    let payload = {
      walletAddress: Getuseraddress()
    }
    let board = await GetX3BoardplanHooks(payload);
    console.log("boardpan result ", board);
    setBoard1(board?.data?.board1);
    setBoard2(board?.data?.board2);
    setBoard3(board?.data?.board3);
    setBoard4(board?.data?.board4);
    setUserplanx3(board?.data?.userplans);
    setBoardloader(false);
  }

  const levelincome = async () => {
    let payload = {
      walletAddress: Getuseraddress()
    }
    let income = await GetLevelProfitHooks(payload);
    console.log("income : ", income);
    setUserincome(income?.data?.user);
    setUserincmev2(income?.data?.userv2);
  }

  const fetchtransaction = async () => {
    setLoader(true);
    let result = await GetTransactionLevelHooks({ walletAddress: Getuseraddress() });
    let userres = await GetPartnerplan4Hooks({walletAddress : Getuseraddress()});
    console.log("Result ijn tansler", result);
    setPlan4(result);
    setPlan4count(userres?.data?.record);
    setLoader(false);
  }


  useEffect(() => {
    fetchx2plan1();
    fetchx2plan2();
    fetchx2plan3();
    fetchBoardPlans();
    levelincome();
    fetchtransaction();
  }, [])









  const handleMoveBottom = (plan) => {
    console.log("plan", plan);
    setScroll(plan);
    // window.scrollTo({
    //   bottom: 0,
    //   behavior: "smooth", // Optional: Add smooth scrolling behavior
    // });
  };

  const fetchdata = async () => {
    setPlan1loader(true);
    let user = Getuseraddress()
    let matrixx2 = await GetuserX2matrix(user, dispatch);
    console.log("checking array", matrixx2);
    setX2matrix(matrixx2?.matrix);
    setMatrixstatus(matrixx2?.levelstatus)
    setPlan1loader(false);
  }

  const fetchplan2 = async () => {
    setPlan2loader(true);
    let user = Getuseraddress();
    let matrixx2 = await GetuserX2matrixplan2(user);
    setX2plan2(matrixx2?.matrix);
    setX2plan2status(matrixx2?.levelstatus);
    setPlan2loader(false);
  }

  const fetchplan3 = async () => {
    setPlan3loader(true);
    let user = Getuseraddress();
    let matrixx2 = await GetuserX2matrixplan3(user);
    setX2plan3(matrixx2?.matrix);
    setX2plan3status(matrixx2?.levelstatus);
    setPlan3loader(false);
  }


  const fetchboardplan1 = async () => {
    setBoard1loader(true);
    let user = Getuseraddress();
    let matrixx3 = await GetuserX3matrixplan1(user);
    console.log("matrix x3 level", matrixx3);
    setBoard1(matrixx3?.matrix);
    setBoard1status(matrixx3?.levelstatus);
    setBoard1loader(false);
  }
  const fetchboardplan2 = async () => {
    setBoard2loader(true);
    let user = Getuseraddress();
    let matrixx3 = await GetuserX3matrixplan2(user);
    setBoard2(matrixx3?.matrix);
    setBoard2status(matrixx3?.levelstatus);
    setBoard2loader(false);
  }
  const fetchboardplan3 = async () => {
    setBoard3loader(true);
    let user = Getuseraddress();
    let matrixx3 = await GetuserX3matrixplan3(user);
    setBoard3(matrixx3?.matrix);
    setBoard3status(matrixx3?.levelstatus);
    setBoard3loader(false);
  }
  const fetchboardplan4 = async () => {
    setBoard4loader(true)
    let user = Getuseraddress();
    let matrixx3 = await GetuserX3matrixplan4(user);
    setBoard4(matrixx3?.matrix);
    setBoard4status(matrixx3?.levelstatus);
    setBoard4loader(false);
  }

  const checkuser = async () => {
    let chekc = await Checkuserexist(Getuseraddress());
    if (!chekc) {
      navigate("/")
    }
  }
  // useEffect(() => {
  //   // checkuser();
  // }, [])
  useEffect(() => {
    if(!Getuseraddress()){
      window.location.href = window.location.origin;
    }
  },[])

  // useEffect(()=> {
  // checkuser();
  // fetchdata();
  // fetchplan2();
  // fetchplan3();
  // fetchboardplan1();
  // fetchboardplan2();
  // fetchboardplan3();
  // fetchboardplan4();
  // } , []);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} className="my_current_plan_tooltip">
      Universal Tron Plan Detailed View
    </Tooltip>
  );

  return (
    <>
      <div id="dashboard" className="partners_side_section showsidebar dashboard_sec_content ">
        <div id="sidebar">
          <Adminsidebar />
        </div>

        <div className="headandcont">
          <div className="adminheader">
            <AdminHeader />
          </div>
{console.log("DATA in id" , userplans?.id)}
          <div className="cont p-sm-5 p-4 cont cont_padding">
            <h5>Dashboard</h5><Button className="partner_id_btn ms-3">ID {userplans?.id}</Button>
            <DashTotalCounts />
            {/* <MyCurrentPlan handleClick={handleMoveBottom} /> */}
            <div className="universal_tron_tron mt-5" id={scroll}>
              <h5 className="heading">
                Universal Tron Plan Detailed View{" "}
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <img
                    src={info}
                    alt="info"
                    className="img-fluid ps-2 tooltip_img_cursor"
                  />
                </OverlayTrigger>
              </h5>
              <UniversalTronPlan
                plan={1}
                ut={2}
                lockedPlan={false}
                matrixx2={x2plan1 ? x2plan1 : []}
                // first={first}
                levelstatus={x2matrixstatus ? x2matrixstatus : []}
                refresh={fetchx2plan1}
                loader={plan1loader}
                userplan={userplans}
                plan2 = {x2plan2}
                key={0}
              />
              <UniversalTronPlan
                plan={2}
                ut={2}
                lockedPlan={false}
                matrixx2={x2plan2 ? x2plan2 : []}
                // first={first}
                levelstatus={x2plan2status ? x2plan2status : []}
                refresh={fetchx2plan2}
                loader={plan2loader}
                userplan={userplans2}
                key={7}
              />
              <Boardx3plan
                plan={1}
                lockedPlan={false}
                levelstatus={board1status ? board1status : []}
                matrixx3={board1 ? board1 : []}
                refresh={fetchBoardPlans}
                loader={board1loader}
                user={userplanx3}
                keyval={0}
              />
              <Boardx3plan
                plan={2}
                lockedPlan={false}
                levelstatus={board2status ? board2status : []}
                matrixx3={board2 ? board2 : []}
                refresh={fetchBoardPlans}
                loader={board2loader}
                user={userplanx3}
                keyval={2}
              />
              <Boardx3plan
                plan={3}
                lockedPlan={false}
                levelstatus={board3status ? board3status : []}
                matrixx3={board3 ? board3 : []}
                refresh={fetchBoardPlans}
                loader={board3loader}
                user={userplanx3}
                keyval={4}
              />
              <Boardx3plan
                plan={4}
                lockedPlan={false}
                levelstatus={board4status ? board4status : []}
                matrixx3={board4 ? board4 : []}
                refresh={fetchBoardPlans}
                loader={board4loader}
                user={userplanx3}
                keyval={6}
              />

              <div className="table_sec mt-4 level_tab">
                <h6 className="sub_heding mb-4">Plan - 4</h6>
                <Table responsive>
                  <thead>
                    <tr className="t_head">
                      <th>Level</th>
                      <th>User Count</th>
                      <th>Income</th>
                      {/* <th>Level Income</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {plan4?.length > 0 &&
                      plan4?.map((item, index) => {
                        if (index < 2) {
                          return (
                            <>{console.log("item in map", item)}
                              <tr className="t_body">
                                <td>Level {item?.level}</td>
                                <td>{plan4count[index]?.length}</td>
                                {console.log("type ", item)}
                                {/* <td>{}</td> */}
                                {/* <td>{`${Convertaddress(item?.from)?.substring(0, 5)}...${Convertaddress(item?.from)?.substring(31,34)}`}</td> */}
                                {/* <td>{`${Convertaddress(item?.to)?.substring(0, 5)}...${Convertaddress(item?.to)?.substring(31,34)}`}</td> */}
                                <td className="partner_id_td" style={{ width: "unset", minWidth: "unset" }}>
                                  <Button className="partner_id">
                                    {parseFloat(item?.amount).toFixed(4)}
                                  </Button>
                                </td>
                                {/* <td>{item.x2}</td>
                          <td>{item.x3}</td> */}
                                {/* <td>{item?.purpose}</td> */}
                                {/* <td>{parseFloat(item?.amount?.toString()).toFixed(3)}</td> */}
                              </tr>
                            </>
                          );
                        }
                      })}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-center align-items-center">
                  <button onClick={() => {
                    navigate("/plan2-list");
                    // window.location.href = window?.location?.origin + 
                  }} className="common_yellow_btn btn btn-primary">See More...</button>
                </div>
              </div>

              {/* {userincome?.id && <UniversalTronPlanTrans
                plan={1}
                ut={2}
                lockedPlan={false}
                matrixx2 = {x2plan1 ? x2plan1 : []}
                // first={first}
                levelstatus = {x2matrixstatus ? x2matrixstatus : []}
                refresh = {fetchx2plan1}
                loader = {plan1loader}
                userplan = {userincome}
                key = {0}
              />}
              {userincomev2?.id && <UniversalTronPlanTrans
                plan={2}
                ut={2}
                lockedPlan={false}
                matrixx2 = {x2plan2 ? x2plan2 : []}
                // first={first}
                levelstatus = {x2plan2status ? x2plan2status : []}
                refresh = {fetchx2plan2}
                loader = {plan2loader}
                userplan = {userincomev2?.id ? userincomev2 : dummyuser}
                key = {7}
              />} */}

              {/* <UniversalTronPlan
                plan={3}
                ut={2}
                lockedPlan={false}
                matrixx2 = {x2plan3 ? x2plan3 : []}
                // first={first}
                levelstatus = {x2plan3status ? x2plan3status : []}
                refresh = {fetchplan3}
                loader = {plan3loader}
                userplan = {userplans}
                key = {13}
              /> */}
              {/* <UniversalTronPlan plan={2} ut={2} lockedPlan={true} /> */}

              {/* <div className="board_plan_sec container custom_contai_overflow">
                <BoardPlanParticular
                  plan={1}
                  ut={2}
                  lockedPlan={true}
                  dashboardTxt={true}
                />
              </div>
              <UniversalTronPlan plan={4} ut={3} lockedPlan={true} /> */}
            </div>
            <div className="user_based_search mt-5">
              <div className="fst_sec">
                <span className="preview_id">Preview ID</span>
                <span className="d-flex">
                  <Form.Control type="text" value={search} onChange={(e) => setSearch(e?.target?.value)} />
                  <Link to={`/user-dashboard/${search}`}>
                    <button className="go_btn common_yellow_btn" disabled={!search ? true : false}>Go</button>
                  </Link>
                </span>
              </div>
              <div className="snd_sec">
                <img src={logo} alt="logo" className="img-fluid logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
