import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { useDispatch } from "react-redux";
import { FaUsers } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";
import lockIcon from "../Assets/lock_icon.svg";
import { Button, Toast } from "react-bootstrap";
import Lottie from "lottie-react";
import lock from "../Assets/Json/lockk.json";
import { Link } from "react-router-dom";
import userGroup from "../Assets/user_group.svg";
import reload from "../Assets/reload.svg";
import coin from "../Assets/coin.svg";
import { usersDetails } from "../Hooks/UseWallet";
import { Getuseraddress } from "../Hooks/UseContract";
import Buy from "./Modals/Buymodal";
import { toast } from "react-hot-toast";
import SkeletonLoadingPlan from "./SkeletonLoadingPlan";
import singleUser from "../Assets/single-user.svg";
function UniversalTronPlan({
  plan,
  ut,
  lockedPlan,
  first,
  matrixx2,
  levelstatus,
  refresh,
  loader,
  userplan,
  key,
}) {
  // const PlanLevel = [
  //   {
  //     level: 1,
  //     totalNo: 500,
  //     user: 1,
  //     count: 0,
  //     locked: false,
  //   },
  //   {
  //     level: 2,
  //     totalNo: 500,
  //     user: 2,
  //     count: 2,
  //     locked: false,
  //   },
  //   {
  //     level: 3,
  //     totalNo: 500,
  //     user: 3,
  //     count: 3,
  //     locked: true,
  //   },
  //   {
  //     level: 4,
  //     totalNo: 500,
  //     user: 4,
  //     count: 4,
  //     locked: true,
  //   },
  //   {
  //     level: 5,
  //     totalNo: 500,
  //     user: 5,
  //     count: 5,
  //     locked: true,
  //   },
  //   {
  //     level: 6,
  //     totalNo: 500,
  //     user: 6,
  //     count: 6,
  //     locked: true,
  //   },
  // ];
  const [PlanLevel, setPlanlevel] = useState([]);
  const [userdetail, setUserdetail] = useState({});
  const [startbuy, setStartbuy] = useState(false);
  const [singlelevel, setSinglelevel] = useState({});
  const [singlelevelstatus, setSinglelevelstatus] = useState({});
  const [level, setLevel] = useState(0);
  const dispatch = useDispatch();

  //new scope
  const [singleplan, setSingleplan] = useState({});

  const fetchdata = async () => {
    let user = Getuseraddress();
    if (user) {
      let userdetail = await usersDetails(user);
      setUserdetail(userdetail);
    }
  };

  useEffect(() => {
    fetchdata();
    console.log("universal tronmm", matrixx2 , userplan);
    if (matrixx2?.length > 0)
      setPlanlevel(matrixx2)
  }, [matrixx2])

  const Loader = () => {
    for (let index = 0; index < 6; index++) {
      return <SkeletonLoadingPlan />;
    }
  };

  return (
    <div className="plan_card_sec mt-5" id={first}>
      <div className="head_section">
        <h6 className="sub_heding">
          Plan {plan} - UT x {ut}
        </h6>
        {/* <h6 className="sub_heding_val">0 TRX</h6> */}
      </div>
      <div className="plan_collection mt-4">
        <Card className="">
          <Card.Body className="card_custom_pad">
            <div className={lockedPlan ? "blur_card_sec" : ""}>
              <div className="level_collection">
                <div className="common_level_card">
                  {!loader && PlanLevel?.length > 0 && PlanLevel?.map((item, i) => {
                    return (
                      <>
                        <Card className="level_card">
                          <Card.Body className="position-relative custom_card_bg_clr">
                            <div className={userplan && !userplan?.purchasedLevels?.includes(item?._id) &&  userplan?.levelUsers[i]?.missedReward?.length > 0 ? "custom_card_bg" :  !userplan?.purchasedLevels?.includes(item?._id) ? "blur_card_sec custom_card_bg" : "custom_card_bg"}>
                              <Link
                                to={`/plan/${plan}/${i + 1}`}
                                state={{ plan: plan, count: 2, level: PlanLevel, levelstatus: levelstatus, userdetail: userdetail }}
                                className = "plan_custom_navi"
                              >
                               
                                  <div className="head_sec d-flex justify-content-between">
                                    <span>Level {i + 1}</span>
                                    <div className="d-flex align-items-center">
                                      {/* <span className="sml_circle me-1"></span> */}
                                      <img
                                        src={coin}
                                        className="coin_img me-1"
                                      />
                                      <span>
                                        {item?.planAmount
                                          ? parseFloat(item?.planAmount)
                                          : 0}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="body_sec">
                                    <span
                                      className={
                                        userplan?.levelUsers[i]?.referral
                                          ?.length > 0
                                          ? userplan?.levelUsers[i]?.missedReward?.includes(userplan?.levelUsers[i]?.referral[0]) ? "cmn_bg_color red_bg me-3" :"cmn_bg_color completed me-3"
                                          : "cmn_bg_color in_completed me-3"
                                      }
                                    ></span>
                                    <span
                                      className={
                                        userplan?.levelUsers[i]?.referral
                                          ?.length > 1
                                          ? userplan?.levelUsers[i]?.missedReward?.includes(userplan?.levelUsers[i]?.referral[1]) ? "cmn_bg_color red_bg" :"cmn_bg_color completed"
                                          : "cmn_bg_color in_completed"
                                      }
                                    ></span>
                                  </div>
                                  <div className="foot_sec d-flex justify-content-between">
                                    <span className="d-flex align-items-center">
                                      <img
                                        src={singleUser}
                                        alt="singleUser"
                                        className="img-fluid user_icon me-1"
                                      />
                                      {console.log(
                                        "userplan?.levelUsers[i]?.referral?.length userplan?.levelUsers[i]?.referral?.length ",
                                        userplan?.levelUsers[i]
                                      )}
                                      {userplan?.levelUsers[i]?.referral?.length
                                        ? userplan?.levelUsers[i]?.referral
                                            ?.length
                                        : 0}
                                      {/* {levelstatus[i]?.partnercount} */}
                                      {/* {userdetail?.partnersCount} */}
                                    </span>
                                    <span>
                                      <img
                                        src={userGroup}
                                        alt="reload"
                                        className="img-fluid reload_icon pe-2"
                                      />
                                      {/* {userplan?.levelUsers[i]?.maxpartner} */}
                                      {2**(i+1)}
                                    </span>
                                  </div>
                                </Link>
                              </div>
                              {(!userplan?.purchasedLevels?.includes(
                                item?._id
                              ) &&
                                userplan?.levelUsers[i]?.missedReward?.length <=
                                  0) ||
                              !userplan ? (
                                <>
                                  <div
                                    className="locked_plan"
                                    onClick={() => {
                                      if (
                                        window.location.href.split("/")[4] ==
                                          1 ||
                                        plan == 1
                                      ) {
                                        if(userplan?.purchasedLevels?.includes(PlanLevel[i-1]?._id)){
                                          setSinglelevel(item);
                                          setSinglelevelstatus(levelstatus[i]);
                                          setLevel(i + 1);
                                          setStartbuy(true);
                                        }
                                        else{
                                          toast.error("You should unlock previous plans!")
                                        }
                                      }
                                      if (
                                        window.location.href.split("/")[4] ==
                                          2 ||
                                        plan == 2
                                      ) {
                                        toast.error(
                                          "You can't buy plan2 It will automatically upgrade"
                                        );
                                      }
                                    }}
                                  >
                                    <p className="mb-2 plan_level">
                                      Level {i + 1}
                                    </p>

                                    <div className="d-flex align-items-center mb-3 text-center">
                                      {/* <span className="sml_circle me-1"></span> */}
                                      <img
                                        src={coin}
                                        className="coin_img me-1"
                                      />
                                      <span className="mb-0 plan_level small amount_val_sm">
                                      {item?.planAmount}
                                      </span>
                                    </div>
                                    <img
                                      src={lockIcon}
                                      alt="plan 2"
                                      className="img-fluid lock_logo"
                                    />
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              {!userplan?.purchasedLevels?.includes(
                                item?._id
                              ) &&
                              userplan?.levelUsers[i]?.missedReward?.length >
                                0 ? (
                                <>
                                  <div className="cus_bg_card">
                                    <div
                                      className="locked_plann"
                                      onClick={() => {
                                        if (
                                          window.location.href.split("/")[4] ==
                                            1 ||
                                          plan == 1
                                        ) {
                                          if(userplan?.purchasedLevels?.includes(PlanLevel[i-1]?._id)){
                                            setSinglelevel(item);
                                          // setSingleplan(levelstatus[i]);
                                          setLevel(i + 1);
                                          setStartbuy(true);
                                          }
                                          else{
                                            toast.error("You should unlock previous plans!")
                                          }
                                        }
                                        if (
                                          window.location.href.split("/")[4] ==
                                            2 ||
                                          plan == 2
                                        ) {
                                          toast.error(
                                            "You can't buy plan2 It will automatically upgrade"
                                          );
                                        }
                                        if (
                                          window.location.href.split("/")[4] ==
                                            3 ||
                                          plan == 1
                                        ) {
                                          if(userplan?.purchasedLevels?.includes(PlanLevel[i-1]?._id)){
                                            setSinglelevel(item);
                                            setSinglelevelstatus(levelstatus[i]);
                                            setLevel(i + 1);
                                            setStartbuy(true);
                                          }
                                          else{
                                            toast.error("You should unlock previous plans!")
                                          }
                                          
                                        }
                                      }}
                                    >
                                      <div className="custom_missed_details">
                                        <p className="mb-0">
                                          Missed reward :{" "}
                                          {
                                            userplan?.levelUsers[i]
                                              ?.missedReward?.length
                                          }
                                        </p>
                                        {/* <p className="mb-0">Missed amount : {levelstatus?.length > 0 && ((parseFloat(levelstatus[i]?.price?.toString()) / 10 ** 6) / 100) * 10}</p> */}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </Card.Body>
                          </Card>
                        </>
                      );
                    })}
                  {loader &&
                    [...Array(6)].map((e, i) => <SkeletonLoadingPlan />)}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <div className="foot_partner_level_collec">
                  <div className="d-flex align-items-center">
                    <span className="direct_partner me-2"></span>
                    <span>Direct Partner</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span>
                      <img
                        src={singleUser}
                        alt="usergroup"
                        className="img-fluid partner_level_icon pe-2"
                      />
                    </span>
                    <span>Partners Count</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span>
                      <img
                        src={userGroup}
                        alt="reload"
                        className="img-fluid level_cycle_icon pe-2"
                      />
                    </span>
                    <span>Max Partners</span>
                  </div>
                </div>
              </div>
            </div>
            {lockedPlan == true ? (
              <>
                <div className="locked_plan">
                  <p className="mb-2 plan_level">Plan {plan}</p>
                  <Lottie
                    className="lock_lottie"
                    animationData={lock}
                    loop={true}
                  />
                  <Button className="unlock_btn common_yellow_btn">
                    Unlock
                  </Button>
                </div>
              </>
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
      </div>
      {startbuy && (
        <Buy
          level={singlelevel}
          user={userplan}
          lvl={level}
          onDismiss={() => setStartbuy(false)}
          refresh={refresh}
        />
      )}
    </div>
  );
}

export default UniversalTronPlan;
