import React, { useEffect, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import Adminsidebar from "./AdminSidebar";
import AdminHeader from "./AdminHeader";
import Card from "react-bootstrap/Card";
import { FaArrowUp } from "react-icons/fa6";
import DashTotalCounts from "../Components/DashTotalCounts";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Getuseraddress } from "../Hooks/UseContract";
import { usersDetails } from "../Hooks/UseWallet";
import { showToast } from "../Components/Toast";
import { GetUserX3hooks } from "../Hooks/usebackendX3";
import { GetLevelProfitHooks } from "../Hooks/usebackend";

export default function Adminlinks() {
  const [value, setValue] = React.useState("8uyt76bihidalashihCd");
  const [copied, setCopied] = React.useState(false);
  const [userdata , setUserdata] = React.useState({});

  const fetchdata = async() => {
    let usersadress = Getuseraddress();
    if(usersadress){
      // let usrdata = await GetUserX3hooks({walletAddress : Getuseraddress()})
      // setUserdata(usrdata?.data?.record);
      let income = await GetLevelProfitHooks({walletAddress : Getuseraddress()});
      setUserdata(income?.data?.user)
    }
  }

  useEffect(() => {
    fetchdata();
  },[])
  useEffect(() => {
    if(!Getuseraddress()){
      window.location.href = window.location.origin;
    }
  },[])

  const onCopy = React.useCallback(() => {
    setCopied(true);
    showToast("success" , "Copied!")
  }, []);

  return (
    <>
      <div id="dashboard" className="showsidebar admin_link_section">
        <div id="sidebar">
          <Adminsidebar />
        </div>

        <div className="headandcont">
          <div className="adminheader">
            <AdminHeader />
          </div>

          <div className="cont p-sm-5 p-4 cont cont_padding">
            <h5>
              Links
              <Button className="partner_id_btn ms-3">ID {userdata?.id}</Button>
            </h5>
            <DashTotalCounts usr = {userdata}/>
            <div className="mt-4">
              <div className="snd_sec">
                <div>
                  <p className="mb-1 personal_link_txt">Referral Link</p>
                  <p className="mb-0 link">{`${window.location.origin}/?utref=${userdata?.id}`}</p>
                </div>
                {/* <Button className="copy_link_btn">Copy Link</Button> */}
                <CopyToClipboard onCopy={onCopy} text={`${window.location.origin}/?utref=${userdata?.id}`}>
                  <Button className="copy_link_btn">Copy Link</Button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
