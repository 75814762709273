export const CHAINS = [
    // {
    //     NAME: "TRON",
    //     CHAIN_ID: "0x2b6653dc",
    //     RPC : "https://api.trongrid.io"
    // },
    {
        NAME: "TRON-NILE",
        CHAIN_ID: "0xcd8690dc",
    },
    {
        NAME: "TRON",
        CHAIN_ID: 'tron:0x2b6653dc',
        RPC : "https://api.trongrid.io" 
    }
]

// export const API_URL = "http://localhost:9095/";
export const API_URL = "https://backend-universaltron.maticz.in/"

// export const API_URL = "https://api.universaltron.com/";

export const TRANSACTION_URL = "https://nile.tronscan.org/#/transaction/";

export const PROJECT_ID = "fb3f447fa6416dec832ec196072edd7b"

export const CHAIN__ID = "0x2b6653dc";
export const WALLET_CONNECT_CHAIN_ID = 1

// export const RPC = "https://api.trongrid.io";

// export const CHAIN__ID = "tron:0xcd8690dc";

export const RPC = "https://nile.trongrid.io";



