import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { useDispatch } from "react-redux";
import { FaUsers } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";
import lockIcon from "../../Assets/lock_icon.svg";
import { Button, Toast } from "react-bootstrap";
import Lottie from "lottie-react";
import lock from "../../Assets/Json/lockk.json";
import { Link } from "react-router-dom";
import userGroup from "../../Assets/user_group.svg";
import reload from "../../Assets/reload.svg";
import coin from "../../Assets/coin.svg";
import { usersDetails } from "../../Hooks/UseWallet";
import { Convertaddress, Getidtoaddress, Getuseraddress, GetuserX3matrixplan1, GetuserX3matrixplan2, GetuserX3matrixplan3, GetuserX3matrixplan4 } from "../../Hooks/UseContract";
import Buy from ".././Modals/Buymodal";
import { toast } from "react-hot-toast";
import SkeletonLoadingPlan from "../SkeletonLoadingPlan";
import LoaderBoardPlanTwo from "../LoaderBoardPlanTwo";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function Boardx3planlist({ plan, ut, lockedPlan, first , matrixx3 , levelstatus , refresh , loader , user , keyval}) {
  const [PlanLevel , setPlanlevel] = useState([]);
  const [userdetail , setUserdetail] = useState({});
  const [startbuy , setStartbuy] = useState(false);
  const [singlelevel , setSinglelevel] = useState({});
  const [singlelevelstatus , setSinglelevelstatus] = useState({});
  const [level , setLevel] = useState(0);
  const dispatch = useDispatch();

//   const [board1, setBoard1] = useState([]);
//   const [board1status, setBoard1status] = useState([]);
//   const [board2, setBoard2] = useState([]);
//   const [board2status, setBoard2status] = useState([]);
//   const [board3, setBoard3] = useState([]);
//   const [board3status, setBoard3status] = useState([]);
//   const [board4, setBoard4] = useState([]);
//   const [board4status, setBoard4status] = useState([]);
//   const [board1loader, setBoard1loader] = useState(true);
//   const [board2loader, setBoard2loader] = useState(true);
//   const [board3loader, setBoard3loader] = useState(true);
//   const [board4loader, setBoard4loader] = useState(true);

//   const fetchboardplan1 = async () => {
//     setBoard1loader(true);
//     let usrid = await Getidtoaddress(window?.location?.href?.split("/")[4]);
//     let address = Convertaddress(usrid);
//     let user = Getuseraddress();
//     let matrixx3 = await GetuserX3matrixplan1(address);
//     console.log("matrix x3 level", matrixx3);
//     setBoard1(matrixx3?.matrix);
//     setBoard1status(matrixx3?.levelstatus);
//     setBoard1loader(false);
//   };
//   const fetchboardplan2 = async () => {
//     setBoard2loader(true);
//     let usrid = await Getidtoaddress(window?.location?.href?.split("/")[4]);
//     let address = Convertaddress(usrid);
//     let user = Getuseraddress();
//     let matrixx3 = await GetuserX3matrixplan2(address);
//     setBoard2(matrixx3?.matrix);
//     setBoard2status(matrixx3?.levelstatus);
//     setBoard2loader(false);
//   };
//   const fetchboardplan3 = async () => {
//     setBoard3loader(true);
//     let usrid = await Getidtoaddress(window?.location?.href?.split("/")[4]);
//     let address = Convertaddress(usrid);
//     let user = Getuseraddress();
//     let matrixx3 = await GetuserX3matrixplan3(address);
//     setBoard3(matrixx3?.matrix);
//     setBoard3status(matrixx3?.levelstatus);
//     setBoard3loader(false);
//   };
//   const fetchboardplan4 = async () => {
//     setBoard4loader(true);
//     let usrid = await Getidtoaddress(window?.location?.href?.split("/")[4]);
//     let address = Convertaddress(usrid);
//     let user = Getuseraddress();
//     let matrixx3 = await GetuserX3matrixplan4(address);
//     setBoard4(matrixx3?.matrix);
//     setBoard4status(matrixx3?.levelstatus);
//     setBoard4loader(false);
//   };

  const fetchdata = async()=> {
    let user = Getuseraddress();
    let userdetail = await usersDetails(user);
    setUserdetail(userdetail);
  }

  

  useEffect(() => {
    fetchdata();
    console.log("universal tronmm" , matrixx3);
    if(matrixx3?.length > 0)
    setPlanlevel(matrixx3)
  }, [matrixx3])

  const settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      
      {
        breakpoint: 380,
        settings: {
          // arrows: false,
          slidesToShow: 2,
        },
      },
    ],
  };
  
  return (
    <div className="plan_card_sec mt-5 board_plan_custom_parti" id={first}>
      <div className="head_section">
        <h6 className="sub_heding">
          Board Plan {plan} - UT x 3
        </h6>
        <h6 className="sub_heding_val">0 TRX</h6>
      </div>
      <div className="plan_collection mt-4">
        <Card className="">
          <Card.Body className="card_custom_pad">
            <div className={lockedPlan ? "blur_card_sec" : ""}>
              <div className="level_collection">
                <div className="common_level_card">
                  {!loader && PlanLevel?.length > 0 && PlanLevel?.map((item , i) => {
                    return (
                      <>
                        <Card className="level_card">
                          <Card.Body className="position-relative p-3">
                            <div className={!user?.purchasedLevels.includes(item?._id) ? "blur_card_sec h-100" : "h-100"}>
                              <Link
                                to={`/board-plan/${plan}/${user?.id}`}
                                state={{ plan : plan , count: 2 , level : PlanLevel , levelstatus : levelstatus , userdetail : userdetail}}
                                className="plan_custom_navi board_plan_x3_single"
                              >
                                <div className="head_sec d-flex justify-content-between">
                                  <span>Level {i+1}</span>
                                  <div className="d-flex align-items-center">
                                    {/* <span className="sml_circle me-1"></span> */}
                                    <img src={coin} className="coin_img me-1" />
                                    <span>{item?.planAmount}</span>
                                  </div>
                                </div>
                                {console.log("Checking in map" , item[3]?.toString())} 
                                <div className="body_sec board_plan_custom_ht">
                                {/* <div className="body_secc board_plan_custom_ht"> */}
                                <Slider {...settings} className="custom_slider">
                                  <span className={user?.levelUsers[keyval+i]?.referral?.length> 0 ?user?.levelUsers[keyval+i]?.missedReward?.includes(user?.levelUsers[i]?.referral[0]) ? "cmn_bg_color red_bg me-3" : "cmn_bg_color completed" : "cmn_bg_color in_completed"}></span>
                                  <span className={user?.levelUsers[keyval+i]?.referral?.length> 1 ?user?.levelUsers[keyval+i]?.missedReward?.includes(user?.levelUsers[i]?.referral[0]) ? "cmn_bg_color red_bg me-3" : "cmn_bg_color completed" : "cmn_bg_color in_completed"}></span>
                                  <span className={user?.levelUsers[keyval+i]?.referral?.length> 2 ? "cmn_bg_color completed" : "cmn_bg_color in_completed"}></span>
                                  {i == 1 && <span className={user?.levelUsers[keyval+i]?.referral?.length> 3 ?user?.levelUsers[keyval+i]?.missedReward?.includes(user?.levelUsers[i]?.referral[0]) ? "cmn_bg_color red_bg me-3" : "cmn_bg_color completed" : "cmn_bg_color in_completed"}></span>}
                                  {i == 1 && <span className={user?.levelUsers[keyval+i]?.referral?.length> 4 ?user?.levelUsers[keyval+i]?.missedReward?.includes(user?.levelUsers[i]?.referral[0]) ? "cmn_bg_color red_bg me-3" : "cmn_bg_color completed" : "cmn_bg_color in_completed"}></span>}
                                  {i == 1 && <span className={user?.levelUsers[keyval+i]?.referral?.length> 5 ? user?.levelUsers[keyval+i]?.missedReward?.includes(user?.levelUsers[i]?.referral[0]) ? "cmn_bg_color red_bg me-3" :"cmn_bg_color completed" : "cmn_bg_color in_completed"}></span>}
                                  {i == 1 && <span className={user?.levelUsers[keyval+i]?.referral?.length> 6 ?user?.levelUsers[keyval+i]?.missedReward?.includes(user?.levelUsers[i]?.referral[0]) ? "cmn_bg_color red_bg me-3" : "cmn_bg_color completed" : "cmn_bg_color in_completed"}></span>}
                                  {i == 1 && <span className={user?.levelUsers[keyval+i]?.referral?.length> 7 ? user?.levelUsers[keyval+i]?.missedReward?.includes(user?.levelUsers[i]?.referral[0]) ? "cmn_bg_color red_bg me-3" :"cmn_bg_color completed" : "cmn_bg_color in_completed"}></span>}
                                  {i == 1 && <span className={user?.levelUsers[keyval+i]?.referral?.length> 8 ?user?.levelUsers[keyval+i]?.missedReward?.includes(user?.levelUsers[i]?.referral[0]) ? "cmn_bg_color red_bg me-3" : "cmn_bg_color completed" : "cmn_bg_color in_completed"}></span>}
                                  </Slider>
                                </div>
                                <div className="foot_sec d-flex justify-content-between">
                                  <span>
                                    <img
                                      src={userGroup}
                                      alt="usergroup"
                                      className="img-fluid user_icon pe-2"
                                    />
                                    {console.log("ser?.levelUsers[key+i]?.referral?.length" , user?.levelUsers[keyval+i]?.referral?.length , user , keyval)}
                                    {user?.levelUsers[parseInt(keyval)+i]?.referral?.length}
                                    {/* {levelstatus[i]?.partnercount} */}
                                    {/* {userdetail?.partnersCount} */}
                                  </span>
                                  <span>
                                    <img
                                      src={userGroup}
                                      alt="reload"
                                      className="img-fluid reload_icon pe-2"
                                    />
                                    {user?.levelUsers[keyval+i]?.maxpartner}
                                  </span>
                                </div>
                              </Link>
                            </div>
                            {!user?.purchasedLevels?.includes(item?._id) ? (
                              <>
                                <div className="locked_plan" onClick={() => {
                                  if(i == 0 && plan == 1){
                                    setSinglelevel(item);
                                    setSinglelevelstatus(levelstatus[i]);
                                    setLevel(i+1);
                                    setStartbuy(true);
                                  }
                                  else{
                                    toast.error("You can't buy plan2 It will automatically upgrade")
                                  }
                                }}>
                                  <p className="mb-2 plan_level">
                                    Level {i+1}
                                  </p>
                                  <img
                                    src={lockIcon}
                                    alt="plan 2"
                                    className="img-fluid lock_logo"
                                  />
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </Card.Body>
                        </Card>
                      </>
                    );
                  })}

                {loader &&   
                  <LoaderBoardPlanTwo/>
                              }
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <div className="foot_partner_level_collec">
                  <div className="d-flex align-items-center">
                    <span className="direct_partner me-2"></span>
                    <span>Direct Partner</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span>
                      <img
                        src={userGroup}
                        alt="usergroup"
                        className="img-fluid partner_level_icon pe-2"
                      />
                    </span>
                    <span>Partner Level</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span>
                      <img
                        src={reload}
                        alt="reload"
                        className="img-fluid level_cycle_icon pe-2"
                      />
                    </span>
                    <span>level Cycles</span>
                  </div>
                </div>
              </div>
            </div>
            {lockedPlan == true ? (
              <>
                <div className="locked_plan">
                  <p className="mb-2 plan_level">Plan {plan}</p>
                  <Lottie
                    className="lock_lottie"
                    animationData={lock}
                    loop={true}
                  />
                  <Button className="unlock_btn common_yellow_btn">
                    Unlock
                  </Button>
                </div>
              </>
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
      </div>
      {startbuy && <Buy level = {singlelevel} levelstatus = {singlelevelstatus} lvl = {level}
      data = {"board"}
      onDismiss = {() => setStartbuy(false)}
      refresh = {refresh}
      />}
    </div>
  );
}

export default Boardx3planlist;
