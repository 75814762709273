import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import Adminsidebar from "./AdminSidebar";
import AdminHeader from "./AdminHeader";
import Table from "react-bootstrap/Table";
import {
  BoardLevelProfotHooks,
  GetDirectTransactionHooks,
  GetLevelProfitHooks,
  GetLevelTransactionHooks,
  Getpartnerlisthooks,
  GetPlan1DirectTransactionHooks,
  GetPlan1LevelTransactionHooks,
  GetX2Plan2Hooks,
  IdtoAddressHooksX2,
  UserX2Plan1Hooks,
} from "../Hooks/usebackend";
import {
  Convertaddress,
  Getpartnersview,
  GetPartnerX2,
  GetTransactionX2,
  Getuseraddress,
} from "../Hooks/UseContract";
import { usersDetails } from "../Hooks/UseWallet";
import { toFixedNumber } from "../Hooks/helper";
import { GetTransactionHook, GetUserX3hooks, GetX3BoardplanHooks } from "../Hooks/usebackendX3";

export default function PlanOne() {
  useEffect(() => {
    if(!Getuseraddress()){
      window.location.href = window.location.origin;
    }
  },[])
  // const PartnerDetails = [
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  // ];

  const [PartnerDetails, setPartnerdetails] = useState([]);
  const [userdetail, setUserdetail] = useState({});
  const [partneradd, setPartneradd] = useState([]);
  const [data, setData] = useState([
    "Direct Referal",
    "Level Income",
    "Admin Fee",
    "eligible upliner",
  ]);
  const [loader, setLoader] = useState(false);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [button, setButton] = useState(false);
  const [transaction, setTransaction] = useState([]);

  const [diretskip, setDirectskip] = useState(0);
  const [diretlimit, setDirectlimit] = useState(10);
  const [diretcount, setDirectcount] = useState(0);
  const [diretbutton, setDirectbutton] = useState(false);
  const [directtransaction, setDirecttransaction] = useState([]);
  const [user, setUSer] = useState({});
  const [directloader, setDirectloader] = useState(false);
  const [tabs, setTabs] = useState("level");
  const [load , setLoad] = useState(0);
  const [x2plan1 , setX2plan1] = useState([])
  const [x2plan2 , setX2plan2] = useState([])
  const [x1user , setX1user] = useState({});
  const [x2user , setX2user] = useState({});
  const [boardprofit , setBoardprofit] = useState([]);
  const [boardloader , setBoardloader] = useState(false);
  const [board1 , setBoard1] = useState([]);
  const [board2 , setBoard2] = useState([]);
  const [board3 , setBoard3] = useState([]);
  const [board4 , setBoard4] = useState([]);
  const [userplanx3 , setUserplanx3] = useState({});

  const handleTabs = (val) => {
    setTabs(val);
    setTimeout(() => {
      setLoad(load+1);
    },1000)
  };

  const loadmore = async () => {
    if (parseInt(count) > PartnerDetails?.length) {
      setButton(true);
      let payload = {
        skip: skip,
        limit: 10,
        walletAddress: Getuseraddress(),
      };
      let partners = await GetPlan1LevelTransactionHooks(payload);
      setTransaction(transaction?.concat(partners?.data?.record));
      console.log("partners", partners);
      setSkip(skip + limit);
      setButton(false);
    }
  };

  const fetchPlans = async () => {
    setLoader(true);
    var addres;
    if (window.location.href.split("/")[6]) {
      let idpayload = {
        id: window?.location?.href?.split("/")[4],
      };
      let idtoadd = await IdtoAddressHooksX2(idpayload);
      setUSer(idtoadd?.data?.record);
      addres = idtoadd?.data?.record?.walletAddress;
    }
    let payload = {
      walletAddress: window.location.href.split("/")[6]
        ? addres
        : Getuseraddress(),
      skip: diretskip,
      limit: diretlimit,
    };
    let result = await GetPlan1LevelTransactionHooks(payload);
    console.log("REsult in x2plan1", result);
    if (result?.data?.type == "Success") {
      setTransaction(result?.data?.record);
      setCount(result?.data?.count);
      //   setX2plan1(result?.data?.plan);
      setLoader(false);
    }
  };

  const loadmoredirect = async () => {
    if (parseInt(count) > directtransaction?.length) {
      setDirectbutton(true);
      let payload = {
        skip: diretskip,
        limit: 10,
        walletAddress: Getuseraddress(),
      };
      let partners = await GetPlan1DirectTransactionHooks(payload);
      setDirecttransaction(directtransaction?.concat(partners?.data?.record));
      console.log("partners", partners);
      setDirectskip(skip + limit);
      setDirectbutton(false);
    }
  };

  const fetchPlansdirect = async () => {
    setDirectloader(true);
    var addres;
    if (window.location.href.split("/")[6]) {
      let idpayload = {
        id: window?.location?.href?.split("/")[4],
      };
      let idtoadd = await IdtoAddressHooksX2(idpayload);
      setUSer(idtoadd?.data?.record);
      addres = idtoadd?.data?.record?.walletAddress;
    }
    let payload = {
      walletAddress: window.location.href.split("/")[6]
        ? addres
        : Getuseraddress(),
      skip: skip,
      limit: limit,
    };
    let result = await GetPlan1DirectTransactionHooks(payload);
    console.log("REsult in x2plan1", result);
    if (result?.data?.type == "Success") {
      setDirecttransaction(result?.data?.record);
      //   setX2plan1(result?.data?.plan);
      setDirectloader(false);
    }
  };

  const levelincome = async() => {
    let payload = {
      walletAddress : Getuseraddress()
    }
    let income = await GetLevelProfitHooks(payload);
    console.log("income : " , income);
    setTransaction(income?.data?.user?.levelUsers);
    setDirecttransaction(income?.data?.userv2?.levelUsers ?  income?.data?.userv2?.levelUsers : []);
  }
  const boardincome = async() => {
    let profit = await BoardLevelProfotHooks({walletAddress : Getuseraddress()});
      console.log("user profitss" , profit);
      setBoardprofit(profit?.data?.user?.levelUsers);
  }
  const fetchBoardPlans = async() => {
    setBoardloader(true);
    let payload = {
      walletAddress : Getuseraddress()
    }
    let board = await GetX3BoardplanHooks(payload);
    console.log("boardpan result " , board);
    setBoard1(board?.data?.board1);
    setBoard2(board?.data?.board2);
    setBoard3(board?.data?.board3);
    setBoard4(board?.data?.board4);
    setUserplanx3(board?.data?.userplans ? board?.data?.userplans : {});
    setBoardloader(false);
  }

  const fetchplan1 = async() => {
    setLoader(true);
    let payload = {
      walletAddress : Getuseraddress()
    }
    let result = await UserX2Plan1Hooks(payload);
    // console.log("REsult in x2plan1" , result);
    if(result?.data?.type == "Success"){
      setX2plan1(result?.data?.plan);
      setX1user(result?.data?.user)
    }
    setLoader(false);
  }
  const fetchplan2 = async() => {
    setDirectloader(true);
    let payload = {
      walletAddress : Getuseraddress()
    }
    let result = await GetX2Plan2Hooks(payload);
    setX2plan2(result?.data?.plan);
    setX2user(result?.data?.user)
    setDirectloader(false);
  }
  useEffect(() => {
    if(!Getuseraddress()){
      window.location.href = window.location.origin;
    }
  },[])
  useEffect(() => {
  levelincome()
  fetchplan1();
  fetchplan2();
  boardincome();
    // fetchPlans();
    // fetchPlansdirect();
  }, []);
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" className="rct_cust_tltp" {...props}>
      {props}
    </Tooltip>
  );

  return (
    <>
      <div id="dashboard" className="showsidebar partners_side_section">
        <div id="sidebar">
          <Adminsidebar />
        </div>
        <div className="headandcont">
          <div className="adminheader">
            <AdminHeader />
          </div>

          <div className="cont p-sm-5 p-4 cont cont_padding">
            <div className="plan_trans_custom_tabs">
              <Button
                className={tabs === "level" ? "active_btn" : "inactive_btn"}
                onClick={() => handleTabs("level")}
              >
                Plan 1{" "}
              </Button>
              <Button
                className={tabs === "direct" ? "active_btn" : "inactive_btn"}
                onClick={() => handleTabs("direct")}
              >
                Plan 2{" "}
              </Button>
            </div>
           { console.log("tabs == ", tabs == "level")}
            {tabs == "level" ? (
              <>
                <div className="table_sec mt-4 level_tab">
                  <Table responsive>
                    <thead>
                      <tr className="t_head">
                        {/* <th>Date</th> */}
                        <th>Level</th>
                        <th>Total User</th>
                        {/* <th>Amount</th> */}
                        <th>Income</th>
                        {/* <th>X2</th>
                    <th>X3</th> */}
                        {/* <th>Transaction type</th> */}

                        {/* <th className="profits_th">Profits</th> */}
                      </tr>
                    </thead>
                    {!loader ? (
                      <tbody>
                        {transaction?.length > 0 &&
                          transaction?.map((item, index) => {
                            return (
                              <>{console.log("item in map" , item)}
                                <tr className="t_body">
                                  <td>LEVEL {index+1}</td>
                                  <td>{x1user ? x1user?.levelUsers[index]?.referral?.length : 0}/{2**(index+1)}</td>
                                  {console.log("type ", item)}
                                  {/* <td>{x2plan1[index]?.planAmount}</td> */}
                                  {/* <td>{`${Convertaddress(item?.from)?.substring(0, 5)}...${Convertaddress(item?.from)?.substring(31,34)}`}</td> */}
                                  {/* <td>{`${Convertaddress(item?.to)?.substring(0, 5)}...${Convertaddress(item?.to)?.substring(31,34)}`}</td> */}
                                  <td className="partner_id_td">
                                    <Button className="partner_id">
                                      {item?.totalProfit}
                                    </Button>
                                  </td>
                                  {/* <td>{item.x2}</td>
                          <td>{item.x3}</td> */}
                                  {/* <td>{item?.purpose}</td> */}
                                  {/* <td>{parseFloat(item?.amount?.toString()).toFixed(3)}</td> */}
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    ) : (
                      <tbody>
                        <td colSpan={5}>
                          <p className="mb-0 data_not_found_txt">Loading...</p>
                        </td>
                      </tbody>
                    )}

                    {!loader && transaction?.length <= 0 && (
                      <tbody>
                        <td colSpan={5}>
                          <p className="mb-0 data_not_found_txt">
                            No Transaction Found
                          </p>
                        </td>
                      </tbody>
                    )}
                  </Table>
                  <div className="d-flex justify-content-center align-items-center">
                    {count > transaction?.length && (
                      <button onClick={loadmore} className="common_yellow_btn">
                        {button ? "Loading..." : "Loadmore"}
                      </button>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="table_sec mt-4 direct_tab">
                  <Table responsive>
                    <thead>
                      <tr className="t_head">
                        {/* <th>Date</th> */}
                        <th>Level</th>
                        <th>Total User</th>
                        {/* <th>Amount</th> */}
                        <th>Income</th>

                        {/* <th className="profits_th">Profits</th> */}
                      </tr>
                    </thead>
                    {!loader ? (
                      <tbody>
                        {directtransaction?.length > 0 &&
                          directtransaction?.map((item, index) => {
                            return (
                              <>
                                <tr className="t_body">
                                  <td>LEVEL {index+1}</td>
                                  <td>{x2user ? x2user?.levelUsers[index]?.referral?.length : 0}/{2**(index+1)}</td>
                                  {console.log("type ", item)}
                                  {/* <td>{x2plan1[index]?.planAmount}</td> */}
                                  {/* <td>{`${Convertaddress(item?.from)?.substring(0, 5)}...${Convertaddress(item?.from)?.substring(31,34)}`}</td> */}
                                  {/* <td>{`${Convertaddress(item?.to)?.substring(0, 5)}...${Convertaddress(item?.to)?.substring(31,34)}`}</td> */}
                                  <td className="partner_id_td">
                                    <Button className="partner_id">
                                      {item?.totalProfit}
                                    </Button>
                                  </td>
                                  {/* <td>{item.x2}</td>
                          <td>{item.x3}</td> */}
                                  {/* <td>{item?.purpose}</td> */}
                                  {/* <td>{parseFloat(item?.amount?.toString()).toFixed(3)}</td> */}
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    ) : (
                      <tbody>
                        <td colSpan={5}>
                          <p className="mb-0 data_not_found_txt">Loading...</p>
                        </td>
                      </tbody>
                    )}

                    {!loader && directtransaction?.length <= 0 && (
                      <tbody>
                        <td colSpan={3}>
                          <p className="mb-0 data_not_found_txt">
                            User not registered the plan
                          </p>
                        </td>
                      </tbody>
                    )}
                  </Table>
                  <div className="d-flex justify-content-center align-items-center">
                    {diretcount > directtransaction?.length && (
                      <button
                        onClick={loadmoredirect}
                        className="common_yellow_btn"
                      >
                        {diretbutton ? "Loading..." : "Loadmore"}
                      </button>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
