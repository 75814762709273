import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { useDispatch } from "react-redux";
import { FaUsers } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";
import lockIcon from "../Assets/lock_icon.svg";
import { Button, Toast } from "react-bootstrap";
import Lottie from "lottie-react";
import lock from "../Assets/Json/lockk.json";
import { Link } from "react-router-dom";
import userGroup from "../Assets/user_group.svg";
import reload from "../Assets/reload.svg";
import coin from "../Assets/coin.svg";
import { usersDetails } from "../Hooks/UseWallet";
import { Getuseraddress } from "../Hooks/UseContract";
import Buy from "./Modals/Buymodal";
import { toast } from "react-hot-toast";
import SkeletonLoadingPlan from "./SkeletonLoadingPlan";
import LoaderBoardPlanTwo from "./LoaderBoardPlanTwo";
import { showToast } from "./Toast";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import singleUser from "../Assets/single-user.svg";

function Boardx3plan({
  plan,
  ut,
  lockedPlan,
  first,
  matrixx3,
  levelstatus,
  refresh,
  loader,
  user,
  keyval,
}) {
  const [PlanLevel, setPlanlevel] = useState([]);
  const [userdetail, setUserdetail] = useState({});
  const [startbuy, setStartbuy] = useState(false);
  const [singlelevel, setSinglelevel] = useState({});
  const [singlelevelstatus, setSinglelevelstatus] = useState({});
  const [level, setLevel] = useState(0);
  const dispatch = useDispatch();

  const fetchdata = async () => {
    let user = Getuseraddress();
    if (user) {
      let userdetail = await usersDetails(user);
      setUserdetail(userdetail);
    }
  };

  useEffect(() => {
    fetchdata();
    console.log("universal tronmm props", user, keyval);
    if (matrixx3?.length > 0) setPlanlevel(matrixx3);
  }, [matrixx3]);

  const settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };

  return (
    <div className="plan_card_sec mt-5 board_plan_custom_parti" id={first}>
      <div className="head_section">
        <h6 className="sub_heding">Board Plan {plan} - UT x 3</h6>
        {/* <h6 className="sub_heding_val">0 TRX</h6> */}
      </div>
      <div className="plan_collection mt-4">
        <Card className="">
          <Card.Body className="card_custom_pad">
            <div className={lockedPlan ? "blur_card_sec" : ""}>
              <div className="level_collection">
                <div className="common_level_card">
                  {console.log("planlevel", PlanLevel)}
                  {!loader &&
                    PlanLevel?.length > 0 &&
                    PlanLevel?.map((item, i) => {
                      return (
                        <>
                          <Card className="level_card">
                            <Card.Body className="position-relative p-3">
                              <div
                                className={
                                  !user?.purchasedLevels?.includes(item?._id)
                                    ? "blur_card_sec m-3 h-100"
                                    : "m-0 h-100"
                                }
                              >
                                <Link
                                  to={`/board-plan/${plan}`}
                                  state={{
                                    plan: plan,
                                    count: 2,
                                    level: PlanLevel,
                                    levelstatus: levelstatus,
                                    userdetail: user,
                                  }}
                                  className="plan_custom_navi board_plan_x3_single_particular"
                                >
                                  <div className="head_sec d-flex justify-content-between">
                                    <span>Level {i + 1}</span>
                                    <div className="d-flex align-items-center">
                                      <img
                                        src={coin}
                                        className="coin_img me-1"
                                      />
                                      <span>{item?.planAmount}</span>
                                    </div>
                                  </div>
                                  <div className="body_sec board_plan_custom_ht">
                                    {i == 0 && (
                                      <div className="dash_plan_fst_level">
                                        <span
                                          className={
                                            user?.levelUsers[
                                              parseInt(item?.level) - 1
                                            ]?.referral?.length > 0
                                              ?user?.levelUsers[parseInt(item?.level) - 1]?.missedReward?.includes(user?.levelUsers[parseInt(item?.level) - 1]?.referral[0]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                              : "cmn_bg_color in_completed"
                                          }
                                        ></span>
                                        <span
                                          className={
                                            user?.levelUsers[
                                              parseInt(item?.level) - 1
                                            ]?.referral?.length > 1
                                              ? user?.levelUsers[parseInt(item?.level) - 1]?.missedReward?.includes(user?.levelUsers[parseInt(item?.level) - 1]?.referral[1]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                              : "cmn_bg_color in_completed"
                                          }
                                        ></span>
                                        <span
                                          className={
                                            user?.levelUsers[
                                              parseInt(item?.level) - 1
                                            ]?.referral?.length > 2
                                              ?user?.levelUsers[parseInt(item?.level) - 1]?.missedReward?.includes(user?.levelUsers[parseInt(item?.level) - 1]?.referral[2]) ? "cmn_bg_color red_bg" :  "cmn_bg_color completed"
                                              : "cmn_bg_color in_completed"
                                          }
                                        ></span>
                                      </div>
                                    )}

                                    {i == 1 && (
                                      <>
                                        <Slider
                                          {...settings}
                                          className="custom_slider"
                                        >
                                          <span
                                            className={
                                              user?.levelUsers[
                                                parseInt(item?.level) - 1
                                              ]?.referral?.length > 0
                                                ? user?.levelUsers[parseInt(item?.level) - 1]?.missedReward?.includes(user?.levelUsers[parseInt(item?.level) - 1]?.referral[0]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                                : "cmn_bg_color in_completed"
                                            }
                                          ></span>
                                          <span
                                            className={
                                              user?.levelUsers[
                                                parseInt(item?.level) - 1
                                              ]?.referral?.length > 1
                                                ?user?.levelUsers[parseInt(item?.level) - 1]?.missedReward?.includes(user?.levelUsers[parseInt(item?.level) - 1]?.referral[1]) ? "cmn_bg_color red_bg" :  "cmn_bg_color completed"
                                                : "cmn_bg_color in_completed"
                                            }
                                          ></span>
                                          <span
                                            className={
                                              user?.levelUsers[
                                                parseInt(item?.level) - 1
                                              ]?.referral?.length > 2
                                                ?user?.levelUsers[parseInt(item?.level) - 1]?.missedReward?.includes(user?.levelUsers[parseInt(item?.level) - 1]?.referral[2]) ? "cmn_bg_color red_bg" :  "cmn_bg_color completed"
                                                : "cmn_bg_color in_completed"
                                            }
                                          ></span>
                                          <span
                                            className={
                                              user?.levelUsers[
                                                parseInt(item?.level) - 1
                                              ]?.referral?.length > 3
                                                ?user?.levelUsers[parseInt(item?.level) - 1]?.missedReward?.includes(user?.levelUsers[parseInt(item?.level) - 1]?.referral[3]) ? "cmn_bg_color red_bg" :  "cmn_bg_color completed"
                                                : "cmn_bg_color in_completed"
                                            }
                                          ></span>
                                          <span
                                            className={
                                              user?.levelUsers[
                                                parseInt(item?.level) - 1
                                              ]?.referral?.length > 4
                                                ? user?.levelUsers[parseInt(item?.level) - 1]?.missedReward?.includes(user?.levelUsers[parseInt(item?.level) - 1]?.referral[4]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                                : "cmn_bg_color in_completed"
                                            }
                                          ></span>
                                          <span
                                            className={
                                              user?.levelUsers[
                                                parseInt(item?.level) - 1
                                              ]?.referral?.length > 5
                                                ? user?.levelUsers[parseInt(item?.level) - 1]?.missedReward?.includes(user?.levelUsers[parseInt(item?.level) - 1]?.referral[5]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                                : "cmn_bg_color in_completed"
                                            }
                                          ></span>
                                          <span
                                            className={
                                              user?.levelUsers[
                                                parseInt(item?.level) - 1
                                              ]?.referral?.length > 6
                                                ? user?.levelUsers[parseInt(item?.level) - 1]?.missedReward?.includes(user?.levelUsers[parseInt(item?.level) - 1]?.referral[6]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                                : "cmn_bg_color in_completed"
                                            }
                                          ></span>
                                          <span
                                            className={
                                              user?.levelUsers[
                                                parseInt(item?.level) - 1
                                              ]?.referral?.length > 7
                                                ? user?.levelUsers[parseInt(item?.level) - 1]?.missedReward?.includes(user?.levelUsers[parseInt(item?.level) - 1]?.referral[7]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                                : "cmn_bg_color in_completed"
                                            }
                                          ></span>
                                          <span
                                            className={
                                              user?.levelUsers[
                                                parseInt(item?.level) - 1
                                              ]?.referral?.length > 8
                                                ?user?.levelUsers[parseInt(item?.level) - 1]?.missedReward?.includes(user?.levelUsers[parseInt(item?.level) - 1]?.referral[8]) ? "cmn_bg_color red_bg" :  "cmn_bg_color completed"
                                                : "cmn_bg_color in_completed"
                                            }
                                          ></span>
                                        </Slider>
                                      </>
                                    )}
                                  </div>
                                  <div className="foot_sec d-flex justify-content-between">
                                    <span className="d-flex align-items-center">
                                      <img
                                        // src={userGroup}
                                        // alt="usergroup"
                                        // className="img-fluid user_icon pe-2"
                                        src={singleUser}
                                        alt="singleUser"
                                        className="img-fluid user_icon me-1"
                                      />
                                      {
                                        user?.levelUsers[keyval + i]?.referral
                                          ?.length
                                      }
                                    </span>
                                    <span>
                                      <img
                                        src={userGroup}
                                        alt="reload"
                                        className="img-fluid reload_icon pe-2"
                                      />
                                      {i <= 0 ? 3 : 9}
                                      {/* {user?.levelUsers[keyval + i]?.maxpartner} */}
                                    </span>
                                  </div>
                                </Link>
                              </div>
                              {console.log(
                                "user?.levelUsers[i]?.missedReward?.lengt",
                                user?.levelUsers[keyval + i]?.missedReward
                                  ?.length
                              )}
                              {/*    */}
                              {/* && user?.levelUsers[keyval + i]?.missedReward?.length <= 0 */}
                              {!user?.purchasedLevels?.includes(item?._id) ? (
                                <>
                                  <div
                                    className="locked_plan"
                                    onClick={() => {
                                      if (i == 0 && plan == 1) {
                                        setSinglelevel(item);
                                        setSinglelevelstatus(levelstatus[i]);
                                        setLevel(i + 1);
                                        setStartbuy(true);
                                      } else {
                                        showToast(
                                          "error",
                                          "You can't buy plan2 It will automatically upgrade"
                                        );
                                      }
                                    }}
                                  >
                                    <p className="mb-2 plan_level">
                                      Level {i + 1}
                                    </p>
                                    <div className="d-flex align-items-center mb-3 text-center">
                                      {/* <span className="sml_circle me-1"></span> */}
                                      <img
                                        src={coin}
                                        className="coin_img me-1"
                                      />
                                      <span className="mb-0 plan_level small amount_val_sm">
                                      {item?.planAmount}
                                      </span>
                                    </div>
                                    <img
                                      src={lockIcon}
                                      alt="plan 2"
                                      className="img-fluid lock_logo"
                                    />
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              {!user?.purchasedLevels?.includes(item?._id) &&
                              user?.levelUsers[keyval + i]?.missedReward
                                ?.length > 0 ? (
                                <>
                                  <div className="card_bg_red">
                                    <div
                                      className="locked_plann"
                                      onClick={() => {
                                        if (i == 0 && plan == 1) {
                                          setSinglelevel(item);
                                          setSinglelevelstatus(levelstatus[i]);
                                          setLevel(i + 1);
                                          setStartbuy(true);
                                        } else {
                                          showToast(
                                            "error",
                                            "You can't buy plan2 It will automatically upgrade"
                                          );
                                        }
                                      }}
                                    >
                                      <p className="mb-2 plan_level">
                                        Level {i + 1}
                                      </p>
                                      <p className="mb-0">
                                        Missed reward :{" "}
                                        {
                                          user?.levelUsers[keyval + i]
                                            ?.missedReward?.length
                                        }
                                      </p>
                                      {/* <p className="mb-0">
                                        Missed amount :{" "}
                                        {levelstatus?.length > 0 &&
                                          (parseFloat(
                                            levelstatus[i]?.price?.toString()
                                          ) /
                                            10 ** 6 /
                                            100) *
                                            10}
                                      </p> */}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </Card.Body>
                          </Card>
                        </>
                      );
                    })}

                  {loader && <LoaderBoardPlanTwo />}
                </div>
              </div>
              {/* <LoaderBoardPlanTwo /> */}
              <div className="d-flex align-items-center justify-content-center">
                <div className="foot_partner_level_collec">
                  <div className="d-flex align-items-center">
                    <span className="direct_partner me-2"></span>
                    <span>Direct Partner</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span>
                      <img
                        src={singleUser}
                        alt="usergroup"
                        className="img-fluid partner_level_icon pe-2"
                      />
                    </span>
                    <span>Partners Count</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span>
                      <img
                        src={userGroup}
                        alt="reload"
                        className="img-fluid level_cycle_icon pe-2"
                      />
                    </span>
                    <span>Max Partners</span>
                  </div>
                </div>
              </div>
            </div>
            {lockedPlan == true ? (
              <>
                <div className="locked_plan">
                  <p className="mb-2 plan_level">Plan {plan}</p>
                  <Lottie
                    className="lock_lottie"
                    animationData={lock}
                    loop={true}
                  />
                  <Button className="unlock_btn common_yellow_btn">
                    Unlock
                  </Button>
                </div>
              </>
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
      </div>
      {startbuy && (
        <Buy
          level={singlelevel}
          levelstatus={singlelevelstatus}
          lvl={level}
          data={"board"}
          onDismiss={() => setStartbuy(false)}
          refresh={refresh}
        />
      )}
    </div>
  );
}

export default Boardx3plan;
