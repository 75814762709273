import React from "react";

function SkeletonLoadingDash() {
  return (
    <div className="skeletondash">
      <p className="total_cnts"></p>
    </div>
  );
}

export default SkeletonLoadingDash;
