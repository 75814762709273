import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { useDispatch } from "react-redux";
import { FaUsers } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";
import lockIcon from "../../Assets/lock_icon.svg";
import { Button, OverlayTrigger, Toast, Tooltip } from "react-bootstrap";
import Lottie from "lottie-react";
import lock from "../../Assets/Json/lockk.json";
import { Link } from "react-router-dom";
import userGroup from "../../Assets/user_group.svg";
import reload from "../../Assets/reload.svg";
import coin from "../../Assets/coin.svg";
import { usersDetails } from "../../Hooks/UseWallet";
import { Convertaddress, Getidtoaddress, Getuseraddress, GetuserX3matrixplan1, GetuserX3matrixplan2, GetuserX3matrixplan3, GetuserX3matrixplan4, Userreferlist, UserreferlistX3 } from "../../Hooks/UseContract";
import Buy from ".././Modals/Buymodal";
import { toast } from "react-hot-toast";
import SkeletonLoadingPlan from "../SkeletonLoadingPlan";
import X3singleplan from "./x3plansingle";
import AdminHeader from "../../Admin/AdminHeader";
import AdminSidebar from "../../Admin/AdminSidebar";
import userLogo from "../../Assets/userNot.svg";
import { GetBoardplan1, GetBoardplan2, GetBoardplan3, GetBoardplan4, GetIdtoAddressX3hooks, GetPartnersHook, GetUserX3hooks } from "../../Hooks/usebackendX3";
import { IdtoAddressHooksX2 } from "../../Hooks/usebackend";

function Boardx3planlist({ plan, ut, lockedPlan, first, matrixx3, levelstatus, refresh, loader }) {
  const [PlanLevel, setPlanlevel] = useState([]);
  const [userdetail, setUserdetail] = useState({});
  const [startbuy, setStartbuy] = useState(false);
  const [singlelevel, setSinglelevel] = useState({});
  const [singlelevelstatus, setSinglelevelstatus] = useState({});
  const [level, setLevel] = useState(0);
  const dispatch = useDispatch();

  const [board1, setBoard1] = useState([]);
  const [board1status, setBoard1status] = useState([]);
  const [board2, setBoard2] = useState([]);
  const [board2status, setBoard2status] = useState([]);
  const [board3, setBoard3] = useState([]);
  const [board3status, setBoard3status] = useState([]);
  const [board4, setBoard4] = useState([]);
  const [board4status, setBoard4status] = useState([]);
  const [board1loader, setBoard1loader] = useState(true);
  const [board2loader, setBoard2loader] = useState(true);
  const [board3loader, setBoard3loader] = useState(true);
  const [board4loader, setBoard4loader] = useState(true);
  const [address, setAddress] = useState([]);
  const [referals1, setReferals1] = useState([]);
  const [address1, setAddress1] = useState([]);
  const [referals, setReferals] = useState([]);
  const [userplanx3 , setUserdatax3]= useState({});

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [button, setButton] = useState(false);
  const [loaders , setLoaders] = useState(false);
  const [PartnerDetails, setPartnerdetails] = useState([]);
  // const [userdetail, setUserdetail] = useState({});

  const fetchboardplan1 = async () => {
    setBoard1loader(true);
    let usrid = await Getidtoaddress(window?.location?.href?.split("/")[5]);
    let address = Convertaddress(usrid);
    let user = Getuseraddress();
    let matrixx3 = await GetuserX3matrixplan1(address);
    console.log("matrix x3 level", matrixx3);
    setBoard1(matrixx3?.matrix);
    setBoard1status(matrixx3?.levelstatus);
    setBoard1loader(false);
  };
  const fetchboardplan2 = async () => {
    setBoard2loader(true);
    let usrid = await Getidtoaddress(window?.location?.href?.split("/")[5]);
    let address = Convertaddress(usrid);
    let user = Getuseraddress();
    let matrixx3 = await GetuserX3matrixplan2(address);
    setBoard2(matrixx3?.matrix);
    setBoard2status(matrixx3?.levelstatus);
    setBoard2loader(false);
  };
  const fetchboardplan3 = async () => {
    setBoard3loader(true);
    let usrid = await Getidtoaddress(window?.location?.href?.split("/")[5]);
    let address = Convertaddress(usrid);
    let user = Getuseraddress();
    let matrixx3 = await GetuserX3matrixplan3(address);
    setBoard3(matrixx3?.matrix);
    setBoard3status(matrixx3?.levelstatus);
    setBoard3loader(false);
  };
  const fetchboardplan4 = async () => {
    setBoard4loader(true);
    let usrid = await Getidtoaddress(window?.location?.href?.split("/")[5]);
    let address = Convertaddress(usrid);
    let user = Getuseraddress();
    let matrixx3 = await GetuserX3matrixplan4(address);
    setBoard4(matrixx3?.matrix);
    setBoard4status(matrixx3?.levelstatus);
    setBoard4loader(false);
  };

  const fetchpartnerx3 = async () => {
    if (window?.location?.href?.split("/")[5]) {
      let usrid = await Getidtoaddress(window?.location?.href?.split("/")[5]);
      let address = Convertaddress(usrid);
      let partner = await UserreferlistX3(address);
      console.log("partner in user referlist x3", partner);
    }
  }

  const fetchdata = async () => {
    let usrid = await Getidtoaddress(window?.location?.href?.split("/")[5]);
    let address = Convertaddress(usrid);
    let userdetail = await usersDetails(address);
    setUserdetail(userdetail);
    let usr = await Getidtoaddress(window?.location?.href?.split("/")[4]);
    let list = await Userreferlist(usr);
    let list1 = await UserreferlistX3(usr);
    console.log("final result", list);
    setReferals(list?.referal);
    setAddress(list?.address);
    setReferals1(list1?.referal);
    setAddress1(list1?.address);
  }

  const fetchboard1 = async() => {
    setBoard1loader(true);
    var address = "";
    if(window?.location?.href?.split("/")[5]){
      let paload = {
        id : window?.location?.href?.split("/")[5]
      }
      let usrid = await GetIdtoAddressX3hooks(paload);
      address = usrid?.data?.record?.walletAddress;
    }
    else{
      address = Getuseraddress();
    }
    let matrixx3 = await GetBoardplan1(address);
    console.log("matrix x3 level", matrixx3);
    setBoard1(matrixx3?.data?.record);
    // setBoard1status(matrixx3?.levelstatus);
    setBoard1loader(false);
  }
  const fetchboard2 = async() => {
    setBoard2loader(true);
    var address = "";
    if(window?.location?.href?.split("/")[5]){
      let paload = {
        id : window?.location?.href?.split("/")[5]
      }
      let usrid = await GetIdtoAddressX3hooks(paload);
      address = usrid?.data?.record?.walletAddress;
    }
    else{
      address = Getuseraddress();
    }
    let matrixx3 = await GetBoardplan2(address);
    console.log("matrix x3 level", matrixx3);
    setBoard2(matrixx3?.data?.record);
    // setBoard1status(matrixx3?.levelstatus);
    setBoard2loader(false);
  }
  const fetchboard3 = async() => {
    setBoard3loader(true);
    var address = "";
    if(window?.location?.href?.split("/")[5]){
      let paload = {
        id : window?.location?.href?.split("/")[5]
      }
      let usrid = await GetIdtoAddressX3hooks(paload);
      address = usrid?.data?.record?.walletAddress;
    }
    else{
      address = Getuseraddress();
    }
    let matrixx3 = await GetBoardplan3(address);
    console.log("matrix x3 level", matrixx3);
    setBoard3(matrixx3?.data?.record);
    // setBoard1status(matrixx3?.levelstatus);
    setBoard3loader(false);
  }
  const fetchboard4 = async() => {
    setBoard4loader(true);
    var address = "";
    if(window?.location?.href?.split("/")[5]){
      let paload = {
        id : window?.location?.href?.split("/")[5]
      }
      let usrid = await GetIdtoAddressX3hooks(paload);
      address = usrid?.data?.record?.walletAddress;
    }
    else{
      address = Getuseraddress();
    }
    let matrixx3 = await GetBoardplan4(address);
    console.log("matrix x3 level", matrixx3);
    setBoard4(matrixx3?.data?.record);
    // setBoard1status(matrixx3?.levelstatus);
    setBoard4loader(false);
  }
  const fetchdatauser = async() => {
    if(window?.location?.href?.split("/")[5]){
      let paload = {
        id : window?.location?.href?.split("/")[5]
      }
      let usrid = await GetIdtoAddressX3hooks(paload);
      setUserdatax3(usrid?.data?.record)
    }
    else{
      let payload = {
        walletAddress : Getuseraddress()
      }
      let result = await GetUserX3hooks(payload);
      setUserdatax3(result?.data?.record);
    }
  }

  const fetchdatapartner = async () => {
    if (window?.location?.href?.split("/")[5]) {
      setLoaders(true);
      let idpayload = {
        id : window?.location?.href?.split("/")[5]
      };
      let idtoadd = await IdtoAddressHooksX2(idpayload);
      setUserdetail(idtoadd?.data?.record);
      console.log("idtadd" , idtoadd);
      let payload = {
        skip: 0,
        limit: 10,
        user: idtoadd?.data?.record?.walletAddress
      }
      let partners = await GetPartnersHook(payload);
      // let usr = await GetUserX3hooks({ walletAddress: Getuseraddress() })
      // setUserdetail(usr?.data?.record);

      console.log("partners", partners);
      setPartnerdetails(partners?.data?.record);
      setCount(partners?.data?.count);
      setSkip(skip + limit);
      // setPartneradd(partners?.partnersadd);
      setLoaders(false);
    }
  };

  const loadmore = async () => {
    if (parseInt(count) > PartnerDetails?.length) {
      setButton(true);
      let payload = {
        skip: 0,
        limit: 10,
        user: userdetail?.walletAddress
      }
      let partners = await GetPartnersHook(payload);
      setPartnerdetails(PartnerDetails?.concat(partners?.data?.record));
      console.log("partners", partners);
      setSkip(skip+limit);
      setButton(false);
    }
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" className="rct_cust_tltp" {...props}>
      {props}
    </Tooltip>
  );



  useEffect(() => {
    fetchdatauser();
    fetchboard1();
    fetchboard2();
    fetchboard3();
    fetchboard4();
    fetchdatapartner();
    // fetchdata();
    // fetchboardplan1();
    // fetchboardplan2();
    // fetchboardplan3();
    // fetchboardplan4();
    // fetchpartnerx3();
    // console.log("universal tronmm" , matrixx3);
    // if(matrixx3?.length > 0)
    // setPlanlevel(matrixx3)
  }, [])


  return (
    <div id="dashboard" className="showsidebar dashboard_sec_content ">
      <div id="sidebar">
        <AdminSidebar />
      </div>


      <div className="headandcont">
        <div className="adminheader">
          <AdminHeader />
        </div>
        <div className="cont p-sm-5 p-4 cont cont_padding">
          {window?.location?.href?.split("/")[4] == 1 && <X3singleplan
            plan={1}
            keyval = {0}
            lockedPlan={false}
            levelstatus={board1status}
            matrixx3={board1}
            refresh={fetchboard1}
            loader={board1loader}
            user = {userplanx3}
            
          />}
          {window?.location?.href?.split("/")[4] == 2 && <X3singleplan
            plan={2}
            lockedPlan={false}
            levelstatus={board2status}
            matrixx3={board2}
            refresh={fetchboard2}
            loader={board2loader}
            user = {userplanx3}
            keyval = {2}
          />}
          {window?.location?.href?.split("/")[4] == 3 && <X3singleplan
            plan={3}
            lockedPlan={false}
            levelstatus={board3status}
            matrixx3={board3}
            refresh={fetchboard3}
            loader={board3loader}
            user = {userplanx3}
            keyval = {4}
          />}
          {window?.location?.href?.split("/")[4] == 4 && <X3singleplan
            plan={4}
            lockedPlan={false}
            levelstatus={board4status}
            matrixx3={board4}
            refresh={fetchboard4}
            loader={board4loader}
            user = {userplanx3}
            keyval = {6}
          />}

          <div className="partners_side_section">
            <div className="mt-5 table_sec">
              <Table responsive>
                <thead>
                <tr className="t_head">
                    {/* <th>Date</th> */}
                    <th>Address</th>
                    <th>Referer</th>
                    {/* <th>Plan</th> */}
                    <th>ID</th>
                    {/* <th>X2</th>
                    <th>X3</th> */}
                    {/* <th>Partners</th> */}
                    {/* <th className="profits_th">Profits</th> */}
                  </tr>
                </thead>
                {!loaders ?  <tbody>
                  
                  {PartnerDetails && PartnerDetails?.map((item, index) => {
                    return (
                      <>
                        <tr className="t_body">
                          {/* <td>
                          <div>{new Date(parseFloat(item.timeStamp)).toLocaleDateString()}</div>
                        </td> */}

                          <td>
                            {" "}
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip(
                                item?.walletAddress
                                // Convertaddress(partneradd[index])
                              )}
                              name="hariharan"
                            >
                              <p className="mb-0">
                                {`${item?.walletAddress
                                  ?.substring(0, 5)}...${item?.walletAddress?.substring(31, 34)}`}
                              </p>
                            </OverlayTrigger>
                          </td>
                          <td>
                            {" "}
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip(
                                item?.originalReferrer
                              )}
                              name="hariharan"
                            >
                              <p className="mb-0">
                                {`${item?.originalReferrer?.substring(
                                  0,
                                  5
                                )}...${item?.originalReferrer?.substring(31, 34)}`}
                              </p>
                            </OverlayTrigger>
                          </td>
                          {/* <td>{item?.type}</td> */}
                          {/* <td>{`${Convertaddress(partneradd[index])?.substring(0, 5)}...${Convertaddress(partneradd[index])?.substring(31,34)}`}</td>
                        <td>{`${Convertaddress(item?.referrer)?.substring(0, 5)}...${Convertaddress(item?.referrer)?.substring(31,34)}`}</td> */}
                          <td className="partner_id_td">
                            <Button className="partner_id">{item?.id}</Button>
                          </td>
                          {/* <td>{item.x2}</td>
                        <td>{item.x3}</td> */}
                          {/* <td>{item?.partnersCount}</td> */}
                          {/* <td>{item?.x3profit ? item?.x3profit : item?.x2profit}</td> */}
                        </tr>
                      </>
                    );
                  })}
                </tbody>:
                <tbody>
                <td colSpan={3}>
                  <p className="mb-0 data_not_found_txt">Loading...</p>
                </td>
              </tbody>
                }

                {!loaders && PartnerDetails?.length <= 0 && <tbody>
                <td colSpan={3}>
                  <p className="mb-0 data_not_found_txt">No Partner Found</p>
                </td>
              </tbody>}
              </Table>
              <div className="d-flex align-items-center justify-content-center mt-2">
                {count > PartnerDetails?.length && <button onClick={loadmore} className="common_yellow_btn">{button ? "Loading..." : "Loadmore"}</button>}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Boardx3planlist;
