import React, { useEffect } from "react";
import { toast } from "react-hot-toast";

export const showToast = (status, message) => {
  toast[status](message, {
    duration: 2000,
    position: "top-center",
    className: "custom_toast_theme",
  });
};
