import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import Adminsidebar from "./AdminSidebar";
import AdminHeader from "./AdminHeader";
import Table from "react-bootstrap/Table";
import { Getpartnerlisthooks } from "../Hooks/usebackend";
import {
  Convertaddress,
  Getpartnersview,
  GetPartnerX2,
  GetTransactionX2,
  Getuseraddress,
} from "../Hooks/UseContract";
import { usersDetails } from "../Hooks/UseWallet";
import { toFixedNumber } from "../Hooks/helper";
import { GetTransactionHook, GetUserX3hooks } from "../Hooks/usebackendX3";

export default function Admintransaction() {
  // const PartnerDetails = [
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  // ];

  const [PartnerDetails, setPartnerdetails] = useState([]);
  const [userdetail, setUserdetail] = useState({});
  const [partneradd, setPartneradd] = useState([]);
  const [data, setData] = useState([
    "Direct Referal",
    "Level Income",
    "Admin Fee",
    "eligible upliner",
  ]);
  const [loader, setLoader] = useState(false);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [button, setButton] = useState(false);

  // const fetchdata = async () => {
  //   if (Getuseraddress()) {
  //     setLoader(true);
  //     let partners = await GetTransactionX2(Getuseraddress());
  //     console.log("partners", partners);
  //     setPartnerdetails(partners);
  //     setLoader(false);
  //     //   setPartneradd(partners?.partnersadd)
  //   }
  //   // let payload = {
  //   //   address : Getuseraddress()
  //   // }
  //   // let result = await Getpartnerlisthooks(payload);
  //   // console.log("result in partnerlist" , result);
  //   // let res = await Getpartnersview(result?.data?.data);
  //   // setPartnerdetails(res);
  //   let usr = await usersDetails(Getuseraddress());
  //   setUserdetail(usr);
  // };

  const loadmore = async () => {
    if (parseInt(count) > PartnerDetails?.length) {
      setButton(true);
      let payload = {
        skip: skip,
        limit: limit,
        walletAddress: Getuseraddress()
      }
      let partners = await GetTransactionHook(payload);
      setPartnerdetails(PartnerDetails?.concat(partners?.data?.record));
      console.log("partners", partners);
      setSkip(skip + limit);
      setButton(false);
    }
  }

  const fetchdata = async () => {
    if (Getuseraddress()) {
      setLoader(true);
      let payload = {
        skip: 0,
        limit: 10,
        walletAddress: Getuseraddress()
      }
      let partners = await GetTransactionHook(payload);
      let usr = await GetUserX3hooks({ walletAddress: Getuseraddress() })
      setUserdetail(usr?.data?.record);

      console.log("partners", partners);
      setPartnerdetails(partners?.data?.record);
      setCount(partners?.data?.count);
      setSkip(skip + limit);
      // setPartneradd(partners?.partnersadd);
      setLoader(false);
    }
    // let payload = {
    //   address : Getuseraddress()
    // }
    // let result = await Getpartnerlisthooks(payload);
    // console.log("result in partnerlist" , result);
    // let res = await Getpartnersview(result?.data?.data);
    // setPartnerdetails(res);
    // let usr = await usersDetails(Getuseraddress());
  };

  useEffect(() => {
    fetchdata();
  }, []);
  useEffect(() => {
    if(!Getuseraddress()){
      window.location.href = window.location.origin;
    }
  },[])
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" className="rct_cust_tltp" {...props}>
      {props}
    </Tooltip>
  );

  return (
    <>
      <div id="dashboard" className="showsidebar partners_side_section">
        <div id="sidebar">
          <Adminsidebar />
        </div>
        <div className="headandcont">
          <div className="adminheader">
            <AdminHeader />
          </div>

          <div className="cont p-sm-5 p-4 cont cont_padding">
            <h5>
              Transactions
              <Button className="partner_id_btn ms-3">
                ID {userdetail?.id}
              </Button>
            </h5>
            <div className="table_sec mt-4">
              <Table responsive>
                <thead>
                  <tr className="t_head">
                    {/* <th>Date</th> */}
                    <th>From</th>
                    <th>To</th>
                    <th>Plan</th>
                    <th>Amount</th>
                    {/* <th>X2</th>
                    <th>X3</th> */}
                    <th>Transaction type</th>

                    {/* <th className="profits_th">Profits</th> */}
                  </tr>
                </thead>
                {!loader ? (
                  <tbody>
                    {PartnerDetails?.length > 0 &&
                      PartnerDetails?.map((item, index) => {
                        return (
                          <>
                            <tr className="t_body">
                              {/* <td>
                                {" "}
                                <OverlayTrigger
                                  placement="bottom"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip(
                                    Convertaddress(item?.from)
                                  )}
                                  name="hariharan"
                                >
                                  <a target= "_blank" className="custom_a_wht" href = {window.location.origin +"/user-dashboard/"+item?.from}>

                                  <p className="mb-0">
                                    {`${Convertaddress(item?.from)?.substring(
                                      0,
                                      5
                                    )}...${Convertaddress(
                                      item?.from
                                    )?.substring(31, 34)}`}
                                  </p>
                                  </a>
                                  
                                </OverlayTrigger>
                              </td>
                              <td>
                                {" "}
                                <OverlayTrigger
                                  placement="bottom"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip(
                                    Convertaddress(item?.to)
                                  )}
                                  name="hariharan"
                                >
                                   <a target= "_blank" className="custom_a_wht" href = {window.location.origin +"/user-dashboard/"+item?.to}>
                                  <p className="mb-0">
                                    {`${Convertaddress(item?.to)?.substring(
                                      0,
                                      5
                                    )}...${Convertaddress(item?.to)?.substring(
                                      31,
                                      34
                                    )}`}
                                  </p></a>
                                </OverlayTrigger>
                              </td> */}
                              <td>
                                <a target="_blank" className="custom_a_wht" href={window.location.origin + "/user-dashboard/" + item?.fromId}>

                                  <p className="mb-0">
                                    {item?.fromId}
                                  </p>
                                </a>
                                </td>
                              <td><a target="_blank" className="custom_a_wht" href={item?.to != "UPLINER" && window.location.origin + "/user-dashboard/" + item?.toId}>

<p className="mb-0">
  {item?.toId}
</p>
</a></td>
                              {console.log("type ", item)}
                              <td>{item?.type}</td>
                              {/* <td>{`${Convertaddress(item?.from)?.substring(0, 5)}...${Convertaddress(item?.from)?.substring(31,34)}`}</td> */}
                              {/* <td>{`${Convertaddress(item?.to)?.substring(0, 5)}...${Convertaddress(item?.to)?.substring(31,34)}`}</td> */}
                              <td className="partner_id_td">
                                <Button className="partner_id">
                                  {parseFloat(item?.amount?.toString())}
                                </Button>
                              </td>
                              {/* <td>{item.x2}</td>
                          <td>{item.x3}</td> */}
                              <td>{item?.purpose}</td>
                              {/* <td>{parseFloat(item?.amount?.toString()).toFixed(3)}</td> */}
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                ) : (
                  <tbody>
                    <td colSpan={5}>
                      <p className="mb-0 data_not_found_txt">Loading...</p>
                    </td>
                  </tbody>
                )}

                {!loader && PartnerDetails?.length <= 0 && <tbody>
                  <td colSpan={5}>
                    <p className="mb-0 data_not_found_txt">No Transaction Found</p>
                  </td>
                </tbody>}


              </Table>
              <div className="d-flex justify-content-center align-items-center">
                {count > PartnerDetails?.length && <button onClick={loadmore} className="common_yellow_btn">{button ? "Loading..." : "Loadmore"}</button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
