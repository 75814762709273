import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { useDispatch } from "react-redux";
import { FaUsers } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";
import lockIcon from "../Assets/lock_icon.svg";
import { Button, Toast } from "react-bootstrap";
import Lottie from "lottie-react";
import lock from "../Assets/Json/lockk.json";
import { Link } from "react-router-dom";
import userGroup from "../Assets/user_group.svg";
import reload from "../Assets/reload.svg";
import coin from "../Assets/coin.svg";
// import { usersDetails } from "../Hooks/UseWallet";
// import { Getuseraddress } from "../Hooks/UseContract";
// import Buy from "./Modals/Buymodal";

function SkeletonLoadingPlan({}) {
  return (
    <div className="plan_card_sec mt-0 skeleton_card_one">
      <div className="common_level_card">
        <Card className="level_card">
          <Card.Body className="position-relative p-3">
            <div>
              <Link to="" className="plan_custom_navi">
                <div className="head_sec d-flex justify-content-between">
                  <span className="level"></span>
                  <div className="d-flex align-items-center coin_detail"></div>
                </div>

                <div className="body_sec">
                  <span className="cmn_bg_color in_completed me-3 circle"></span>
                  <span className="cmn_bg_color in_completed me-3 circle"></span>
                </div>
                <div className="foot_sec d-flex justify-content-between">
                  <span className="users"></span>
                  <span className="reload"></span>
                </div>
              </Link>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default SkeletonLoadingPlan;
