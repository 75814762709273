import React, { useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import info from "../../Assets/info_logo.svg";
import plangrp from "../../Assets/plan_grp.svg";
import lockIcon from "../../Assets/lock_icon.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Rederr from "../../Assets/red_err.svg";
import { Link, useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import SkeletonLoadingUserDash from "../SkeletonLoadingUserDash";
import LoaderBoardPlanTwo from "../LoaderBoardPlanTwo";

function X3CurrentPlan(props) {
  const navigate = useNavigate();
  const options = {
    loop: true,
    margin: 40,
    nav: false,
    dots: false,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        // nav: true,
      },
      380: {
        items: 1,
        // nav: true,
      },
      480: {
        items: 1,
      },
      576: {
        items: 2,
        // nav: false,
      },
      767: {
        items: 2,
        // nav: false,
      },
      991: {
        items: 2,
        // nav: true,
        // loop: false,
      },
      1200: {
        items: 3,
        // nav: true,
        // loop: false,
      },
    },
  };

  // const CurrentPlan = [
  //   {
  //     plan: "Plan 1",
  //     trx: 0,
  //     active: 4,
  //     locked: false,
  //     locked_icon: true,
  //     url: "/universal-plan/2",
  //     count: 2,
  //   },
  //   {
  //     plan: "Plan 2",
  //     trx: 3,
  //     active: 2,
  //     locked: false,
  //     url: "/universal-plan/2",
  //     count: 3,
  //   },
  //   {
  //     plan: "Board Plan",
  //     trx: 3,
  //     active: 2,
  //     locked: false,
  //     url: "/board-plan/2",
  //     count: 2,
  //   },
  //   {
  //     plan: "Plan 4",
  //     trx: 4,
  //     active: 4,
  //     locked: true,
  //     url: "/universal-plan/2",
  //     count: 2,
  //   },
  //   {
  //     plan: "Plan 5",
  //     trx: 5,
  //     active: 5,
  //     locked: true,
  //     url: "/universal-plan/2",
  //     count: 2,
  //   },
  //   {
  //     plan: "Plan 6",
  //     trx: 6,
  //     active: 6,
  //     locked: true,
  //     url: "/universal-plan/2",
  //     count: 2,
  //   },
  // ];

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} className="my_current_plan_tooltip">
      My Current Plan
    </Tooltip>
  );

  useState(() => {
    console.log("props data", props);
  }, [])

  return (
    <div>
      <div className="my_current_plan mt-5">
        {/* <h5 className="heading">
          Universal Tron Plans{" "}X3
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <img
              src={info}
              alt="info"
              className="img-fluid ps-2 tooltip_img_cursor"
            />
          </OverlayTrigger>
        </h5> */}
        <div className="container custom_contai_overflow">
          {/* <OwlCarousel className="owl-theme" {...options}> */}
          {/* {CurrentPlan.map((item, index) => {
              return (
                <> */}
          <div className="row">
            <div className="col-xl-12 col-12 col-lg-12 col-md-12">
              <div className="card_section mt-4">
                <Card className="cmn_card">
                  <Card.Body>
                    <div
                      className={""}
                    >
                      {/* item.locked == true ? "blur_card_sec" :  */}
                      <Link
                        to={`/board/${props?.board}/${window?.location?.href?.split("/")[4]}`}
                        // state={{ count: item.count }}
                        // {{
                        //   pathname: `${item.url}`,
                        //   state:{ query : ' your-query ' },
                        // }}
                        className="text-decoration-none"
                      >
                        <div
                          className="header"
                        // onClick={()=>{
                        //   navigate(`${item.url}`, { state : { query : ' your-query ' }})
                        // }}
                        >
                          <span>Board {props?.board} - UT x3</span>
                          {/* <span>{"item.trx"} TRX</span> */}
                        </div>
                        {!props?.loader1 && <div className="content_sec">
                          <div className="plan_completed_sec">

                            {/* {props && props?.x2matrix?.map((item, index) => {
                              // props?.levelstatus[index]?.status
                              console.log("item", item);
                              if (item[5]?.toString() == 0) {
                                return ( */}
                                <span
                                  className={
                                    props?.l1status
                                      ? "completed cmn_plan_count"
                                      : "in_completed cmn_plan_count"
                                  }
                                ></span>
                                <span
                                  className={
                                    props?.l2status
                                      ? "completed cmn_plan_count"
                                      : "in_completed cmn_plan_count"
                                  }
                                ></span>
                                {/* )
                              }
                              else {
                                return ( */}
                                {/* <span
                                  className="completed cmn_plan_count locked_err"
                                >
                                  <img
                                    src={Rederr}
                                    alt="locked"
                                    className="img-fluid"
                                  />

                                </span> */}
                                {/* )
                              }
                            })} */}
                            {/* <span
                                  className={
                                    item.active >= 1
                                      ? "completed cmn_plan_count"
                                      : "in_completed cmn_plan_count"
                                  }
                                ></span>
                                <span
                                  className={
                                    item.active >= 2
                                      ? "completed cmn_plan_count"
                                      : "in_completed cmn_plan_count"
                                  }
                                ></span>
                                <span
                                  className={
                                    item.active >= 3
                                      ? "completed cmn_plan_count"
                                      : "in_completed cmn_plan_count"
                                  }
                                ></span> */}
                            {/* <span
                                  className={
                                    item.active >= 4
                                      ? "completed cmn_plan_count"
                                      : "in_completed cmn_plan_count"
                                  }
                                ></span> */}
                            {/* <span
                                  className=
                                  {
                                    item.active >= 5
                                      ? "completed cmn_plan_count"
                                      : "in_completed cmn_plan_count"
                                  }
                                >
                                  {item.locked_icon ? (
                                    <img
                                      src={Rederr}
                                      alt="locked"
                                      className="img-fluid"
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </span>
                                <span
                                  className={
                                    item.active >= 6
                                      ? "completed cmn_plan_count locked_err"
                                      : "in_completed cmn_plan_count locked_err"
                                  }
                                >
                                  {item.locked_icon ? (
                                    <img
                                      src={Rederr}
                                      alt="locked"
                                      className="img-fluid"
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </span> */}
                          </div>
                          <div className="btn_sec">
                            <Button className="preview_btn common_yellow_secondary_btn">
                              Preview
                            </Button>
                            <img
                              src={plangrp}
                              alt="plan"
                              className="img-fluid plangrp"
                            />
                          </div>
                        </div>}

                       {props?.loader1 && <LoaderBoardPlanTwo/>}
                      </Link>
                    </div>
                    {/* {item.locked == true ? (
                          <div className="locked_plan_content">
                            <p className="mb-2 plan_level">
                              Plan {item.planNo}
                            </p>
                            <img
                              src={lockIcon}
                              alt="plan 2"
                              className="img-fluid lock_logo"
                            />
                          </div>
                        ) : (
                          <></>
                        )} */}
                  </Card.Body>
                </Card>
              </div>
            </div>
            
          </div>
          {/* </>
              );
            })} */}
          {/* </OwlCarousel> */}
        </div>
      </div>
    </div>
  );
}

export default X3CurrentPlan;
