import React, { useEffect, useState } from "react";
import { Button, Container, Row, Navbar, Nav, Form } from "react-bootstrap";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import io from "socket.io-client";
import dashlight from "../Assets/admin/dash-light.svg";
import dashactive from "../Assets/admin/dash-active.svg";
import partnerlight from "../Assets/admin/partner-light.svg";
import partneractive from "../Assets/admin/partner-active.svg";
import linkslight from "../Assets/admin/links-light.svg";
import linksactive from "../Assets/admin/links-active.svg";

import Transactionlight from "../Assets/admin/transaction-light.svg";
import Transactionactive from "../Assets/admin/transaction-active.svg";

import settinglight from "../Assets/admin/setting-light.svg";
import settingactive from "../Assets/admin/setting-active.svg";
import Headerlogos from "../Assets/universal_logo.png";
import tron from "../Assets/admin/tron-trx-logo.png";
import tron1 from "../Assets/admin/tron-trx-logored.png";
import wallets from "../Assets/admin/wallet.png";
import { FiSearch } from "react-icons/fi";
import { CiSearch } from "react-icons/ci";
import { IoIosClose } from "react-icons/io";
import { IoMdNotificationsOutline } from "react-icons/io";
import { IoLogOutOutline } from "react-icons/io5";
import Dropdown from "react-bootstrap/Dropdown";
import { BsTwitterX } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import { getBalance, Getuseraddress } from "../Hooks/UseContract";
import { FaArrowRight } from "react-icons/fa";
import { MdHistory } from "react-icons/md";
import { ConnectwalletHooks, GetNotificationHooks, ReadAllHooks } from "../Hooks/usebackend";
import { API_URL } from "../Config/config";
import { ConnectTrustwallet, WalletConnect } from "../Hooks/UseWallet";
export default function Adminheader() {
  const navigate = useNavigate();
  const socket = io(API_URL);
  const [search, setSearch] = useState(false);
  const [balance, setBalance] = useState("");
  const [searchdata, setSearchdata] = useState("");
  const [SearchContent, setSearchcontent] = useState(
    localStorage.getItem("SEARCH")
      ? JSON.parse(localStorage.getItem("SEARCH"))
      : []
  );

  const [notification, setNotification] = useState([]);

  const fetchdata = async () => {
    let usr = Getuseraddress();
    if (usr) {
      let balance = await getBalance(usr);
      // console.log("balance inuss" , balance);
      setBalance((parseFloat(balance) / 10 ** 6).toFixed(3));
      let token = await ConnectwalletHooks({walletAddress : usr});
    }

  };

  const getnotification = async () => {
    let payload = {
      walletAddress: Getuseraddress(),
    };
    let result = await GetNotificationHooks(payload);
    setNotification(result?.data?.record);
  };

  const readallnotification = async () => {
    let payload = {
      walletAddress: "aa", //Getuseraddress()
    };
    let result = await ReadAllHooks(payload);
    setNotification(result?.data?.record);
  };



  useEffect(() => {
    setTimeout(() => {
      fetchdata();
    } , 5000)
    
    getnotification();
    if (Getuseraddress()) {
      socket.emit("CREATEROOM", Getuseraddress());
    }
  }, []);

  useEffect(() => {
    socket.on("NOTIFICATION", () => {
      getnotification();
    });
  }, [socket]);

  // const SearchContent = [
  //   {
  //     txt: "Lorem Ipsum simply dummy",
  //   },
  //   {
  //     txt: "Lorem Ipsum simply dummy",
  //   },
  //   {
  //     txt: "Lorem Ipsum simply dummy",
  //   },
  //   {
  //     txt: "Lorem Ipsum simply dummy",
  //   },
  //   {
  //     txt: "Lorem Ipsum simply dummy",
  //   },
  //   {
  //     txt: "Lorem Ipsum simply dummy",
  //   },
  //   {
  //     txt: "Lorem Ipsum simply dummy",
  //   },
  //   {
  //     txt: "Lorem Ipsum simply dummy",
  //   },
  //   {
  //     txt: "Lorem Ipsum simply dummy",
  //   },
  //   {
  //     txt: "Lorem Ipsum simply dummy",
  //   },
  //   {
  //     txt: "Lorem Ipsum simply dummy",
  //   },
  //   {
  //     txt: "Lorem Ipsum simply dummy",
  //   },
  //   {
  //     txt: "Lorem Ipsum simply dummy",
  //   },
  //   {
  //     txt: "Lorem Ipsum simply dummy",
  //   },
  // ];

  const NotificationContent = [
    {
      time: "now",
      txt: "Login Successfully",
    },
    {
      time: "1d ago",
      txt: "Login Successfully",
    },
    {
      time: "2d ago",
      txt: "Login Successfully",
    },
    {
      time: "3d ago",
      txt: "Login Successfully",
    },
    {
      time: "4d ago",
      txt: "Login Successfully",
    },
    {
      time: "5d ago",
      txt: "Login Successfully",
    },
    {
      time: "6d ago",
      txt: "Login Successfully",
    },
  ];

  const location = useLocation();
  const locPath = location.pathname;
  return (
    <>
      <div id="homeheader" className="admin_header_sec">
        <Navbar expand="lg" className="pb-0">
          <Container fluid className="custom_navbar_moveto_top">
            <NavLink to="/dashboard">
              <Navbar.Brand className="pb-0">
                <img src={Headerlogos} className="adminmobilelogo" />{" "}
              </Navbar.Brand>
            </NavLink>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="align-items-center menu_mobilalign ms-auto sidebar">
                <div className="allheaderlist">
                  <Button className="tronbtn">
                    <img src={tron} className="tron_bg_ywl" /> Tron
                  </Button>
{/* <button className="get-started-btn mr-2 text-uppercase" onClick={WalletConnect}>WalletConnect</button> */}

                  <div className="wallet_border">
                    <img src={tron1} className="tron_red me-2" />{" "}
                    <p className="mb-0 trx trxtxt">{balance} TRX</p>
                    <img src={wallets} className="tron me-2 ps-2" />{" "}
                    <p className="mb-0 trxtxt">{`${Getuseraddress().substring(
                      0,
                      5
                    )}...${Getuseraddress().substring(31, 34)}`}</p>
                  </div>

                  {/* {search ? (
                    <>
                      <div className="header_search">
                        <CiSearch className="search_icon" />
                        <input
                          type="text"
                          placeholder="Search here..."
                          className="header_sbar"
                        />
                        <IoIosClose
                          className="close_icon"
                          onClick={() => setSearch(false)}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )} */}

                  <div className="header_rgt_search_sec">
                    {/* <span
                      className="search_btn"
                      onClick={() => setSearch((prev) => !prev)}
                    >
                      <CiSearch size={22} className="header_rgt_icon" />
                    </span> */}

                    <Dropdown className="custom_search_drop">
                      <Dropdown.Toggle id="dropdown-basic">
                        <CiSearch size={22} className="header_rgt_icon" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="custom_suggestion_drop_list">
                          <div className="header_search">
                            <CiSearch className="search_icon" />
                            {/* <input
                              type="text"
                              placeholder="Search here..."
                              className="header_sbar"
                            /> */}
                            <Form.Control
                              type="email"
                              placeholder="Search..."
                              className="header_sbar"
                              onChange={(e) => {
                                setSearchdata(e?.target?.value);
                                if (e?.target?.value?.length > 0) {
                                  var arr = localStorage.getItem("SEARCH")
                                    ? JSON.parse(localStorage.getItem("SEARCH"))
                                    : [];
                                  let filter = arr.filter((a) =>
                                    a.includes(e?.target?.value)
                                  );
                                  setSearchcontent(filter ? filter : []);
                                  console.log("filtered", filter);
                                } else {
                                  setSearchcontent(
                                    localStorage.getItem("SEARCH")
                                      ? JSON.parse(
                                        localStorage.getItem("SEARCH")
                                      )
                                      : []
                                  );
                                }
                              }}
                            />
                            {/* <IoIosClose
                              className="close_icon"
                              onClick={() => setSearch(false)}
                            /> */}
                            <Button
                              className="search_custom_btn"
                              onClick={() => {
                                var arr = localStorage.getItem("SEARCH")
                                  ? JSON.parse(localStorage.getItem("SEARCH"))
                                  : [];
                                if (!arr.includes(searchdata)) {
                                  arr.push(searchdata);
                                }
                                setSearchcontent(arr);
                                console.log("searchdata", arr);
                                localStorage.setItem(
                                  "SEARCH",
                                  JSON.stringify(arr)
                                );
                                window.location.href =
                                  window?.location?.origin +
                                  "/user-dashboard/" +
                                  searchdata;
                              }}
                              disabled={!searchdata ? true : false}
                            >
                              <FaArrowRight className="enter_arrow" />
                            </Button>
                          </div>
                          {console.log("searchcontent", SearchContent)}
                          <div className="list_items mt-2 mb-2">
                            {/* <Button className="list w-100">aaaaaaaaaaaa</Button> */}
                            {SearchContent && SearchContent?.length > 0 ? (
                              SearchContent?.map((item) => {
                                return (
                                  <p
                                    className="list w-100 mb-1 d-flex align-items-center justify-content-between"
                                    onClick={() => {
                                      var arr = localStorage.getItem("SEARCH")
                                        ? JSON.parse(
                                          localStorage.getItem("SEARCH")
                                        )
                                        : [];
                                      if (!arr.includes(item)) {
                                        arr.push(item);
                                      }
                                      setSearchcontent(arr);
                                      console.log("searchdata", arr);
                                      localStorage.setItem(
                                        "SEARCH",
                                        JSON.stringify(arr)
                                      );
                                      window.location.href =
                                        window?.location?.origin +
                                        "/user-dashboard/" +
                                        item;
                                    }}
                                  >
                                    <span>{item}</span>
                                    <span>
                                      <MdHistory size={17} />
                                    </span>
                                  </p>
                                );
                              })
                            ) : (
                              <p>No Search found...</p>
                            )}
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>

                   

                    <Dropdown className="login_out_btn">
                      <Dropdown.Toggle id="dropdown-basic">
                        <IoLogOutOutline
                          size={22}
                          className="header_rgt_icon"
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            localStorage.clear();
                            navigate("/");
                          }}
                        >
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>

                <div className="mobileadmin menus">
                  <NavLink
                    activeClassName="active"
                    to="/dashboard"
                    className="nounder"
                  >
                    <img src={dashlight} className="active lights" />
                    <img src={dashactive} className="active darks" />
                    Dashboard
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    to="/partners"
                    className="nounder"
                  >
                    <img src={partnerlight} className="active lights" />
                    <img src={partneractive} className="active darks" />
                    Partners
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    to="/linkspage"
                    className="nounder"
                  >
                    <img src={linkslight} className="active lights" />
                    <img src={linksactive} className="active darks" />
                    Links
                  </NavLink>
                  {/* <NavLink
                    activeClassName="active"
                    to="/transaction"
                    className="nounder"
                  >
                    <img src={Transactionlight} className="active lights" />
                    <img src={Transactionactive} className="active darks" />
                    Transaction
                  </NavLink> */}
                  <div className="menus custom_transaction_drop">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src={Transactionlight} className="active lights" />
                        <span>Transaction</span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Link
                          to="/plan2-list"
                          className={
                            locPath === "/plan1-list"
                              ? "custom_url active-item"
                              : "custom_url"
                          }
                        >
                          Plan 4
                        </Link>

                        <Link
                          to="/plan1-list"
                          className={
                            locPath === "/plan2-list"
                              ? "custom_url active-item"
                              : "custom_url"
                          }
                        >
                          Plan Reward
                        </Link>

                        <Link
                          to="/transaction"
                          className={
                            locPath === "/plan3-list"
                              ? "custom_url active-item"
                              : "custom_url"
                          }
                        >
                          All Transaction
                        </Link>

                        {/* <Link
                          to="/transaction"
                          className={
                            locPath === "/transaction"
                              ? "custom_url active-item"
                              : "custom_url"
                          }
                        >
                          Transaction
                        </Link> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>

                <div className="sidebar_ad_div w-100 custom_header_social">
                  <div className="socialmedia d-flex justify-content-center">
                    <a
                      target="_blank"
                      className="me-3"
                      href="https://telegram.org/"
                    >
                      <FaTelegramPlane />
                    </a>
                    <a target="_blank" href="https://twitter.com/">
                      <BsTwitterX />
                    </a>
                  </div>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}
