import React, { useEffect, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Headerlogos from "../Assets/universal_logo.png";
import { SlMenu } from "react-icons/sl";
import dashlight from "../Assets/admin/dash-light.svg";
import dashactive from "../Assets/admin/dash-active.svg";
import partnerlight from "../Assets/admin/partner-light.svg";
import partneractive from "../Assets/admin/partner-active.svg";
import linkslight from "../Assets/admin/links-light.svg";
import linksactive from "../Assets/admin/links-active.svg";
import Transactionlight from "../Assets/admin/transaction-light.svg";
import Transactionactive from "../Assets/admin/transaction-active.svg";
import settinglight from "../Assets/admin/setting-light.svg";
import settingactive from "../Assets/admin/setting-active.svg";
import { BsTwitterX } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import Dropdown from "react-bootstrap/Dropdown";

export default function Adminsidebar() {
  const sidetoggle = () => {
    if (
      document.getElementById("dashboard").classList.contains("showsidebar")
    ) {
      document.getElementById("dashboard").classList.remove("showsidebar");
      document.getElementById("dashboard").classList.add("hidesidebar");
    } else if (
      document.getElementById("dashboard").classList.contains("hidesidebar")
    ) {
      document.getElementById("dashboard").classList.remove("hidesidebar");
      document.getElementById("dashboard").classList.add("showsidebar");
    }
  };

  let url = new URL(window.location.href);
  const UrlName = url.pathname.substring(1);

  const location = useLocation();
  const locPath = location.pathname;

  // const [isOpen, setIsOpen] = useState(
  //   locPath == "/plan1-list" ||
  //     locPath == "/plan2-list" ||
  //     locPath == "/plan3-list" ||
  //     locPath == "/transaction"
  //     ? true
  //     : false
  // );

  // const handleToggle = (val) => {
  //   setIsOpen(!isOpen);
  // };

  return (
    <>
      <div className="sidebar adminside">
        <div className="d-flex align-items-center logo_sec_sidebar">
          <Link to="/">
            <img src={Headerlogos} className="adminheaderlogo" />
          </Link>
          <div>
            <Button
              onClick={() => sidetoggle()}
              variant="link"
              className="text-dark mobiletog ps-0 pe-0"
            >
              <SlMenu />
            </Button>
          </div>
        </div>

        <div className="sidelist">
          <div className="menus">
            <NavLink
              activeClassName="active"
              to="/dashboard"
              // className="nounder"
              className={
                UrlName == "user-dashboard" ? "active nounder" : "nounder"
              }
            >
              <img src={dashlight} className="active lights" />
              <img src={dashactive} className="active darks" />
              Dashboard
            </NavLink>
          </div>
          <div className="menus">
            <NavLink
              activeClassName="active"
              to="/partners"
              className="nounder"
            >
              <img src={partnerlight} className="active lights" />
              <img src={partneractive} className="active darks" />
              Partners
            </NavLink>
          </div>
          <div className="menus">
            <NavLink
              activeClassName="active"
              to="/linkspage"
              className="nounder"
            >
              <img src={linkslight} className="active lights" />
              <img src={linksactive} className="active darks" />
              Links
            </NavLink>
          </div>
          <div className="menus custom_transaction_drop">
            <Dropdown
              // show={isOpen}
              // onToggle={() => handleToggle("clicked")}
              // autoClose={false}
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={Transactionlight} className="active lights" />
                <span>Transaction</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {/* <Dropdown.Item href="#" eventKey="1"> */}
                <Link
                  to="/plan2-list"
                  className={
                    locPath === "/plan2-list"
                      ? "custom_url active-item"
                      : "custom_url"
                  }
                >
                  Plan 4
                </Link>
                {/* </Dropdown.Item> */}
                {/* <Dropdown.Item href="#" eventKey="2"> */}
                {/* <Link
                  to="/plan2-list"
                  className={
                    locPath === "/plan2-list"
                      ? "custom_url active-item"
                      : "custom_url"
                  }
                >
                  Plan 2
                </Link> */}
                {/* </Dropdown.Item> */}
                {/* <Dropdown.Item href="#" eventKey="3"> */}
                {/* <Link
                  to="/plan3-list"
                  className={
                    locPath === "/plan3-list"
                      ? "custom_url active-item"
                      : "custom_url"
                  }
                >
                  Plan 3
                </Link> */}
                {/* </Dropdown.Item> */}
                {/* <Dropdown.Item href="#" eventKey="4"> */}
                <Link
                  to="/plan1-list"
                  className={
                    locPath === "/transaction"
                      ? "custom_url active-item"
                      : "custom_url"
                  }
                >
                Plan Reward
                </Link>
                <Link
                  to="/transaction"
                  className={
                    locPath === "/transaction"
                      ? "custom_url active-item"
                      : "custom_url"
                  }
                >
                 All Transaction
                </Link>
                {/* </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className="sidebar_ad_div">
          <div className="socialmedia d-flex justify-content-center">
            <a target="_blank" className="me-3" href="https://telegram.org/">
              <FaTelegramPlane />
            </a>
            <a target="_blank" href="https://twitter.com/">
              <BsTwitterX />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
