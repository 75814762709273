import React, { useEffect, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Adminsidebar from "./AdminSidebar";
import AdminHeader from "./AdminHeader";
import Card from "react-bootstrap/Card";
import { Link, useNavigate } from "react-router-dom";
import { FaCheckDouble } from "react-icons/fa6";
import { GetAllNotificationHooks, ReadAllHooks, ReadOneMessageHooks } from "../Hooks/usebackend";
import { Getuseraddress } from "../Hooks/UseContract";
import { data } from "jquery";
export default function Notifications() {
  const [marked, setMarked] = useState(true);
  const [notification , setNotification] = useState([]);
  const [loader , setLoader] = useState(false);
  const [count , setCount] = useState(0);
  const [limit , setLimit] = useState(10);
  const [skip , setSkip] = useState(0);

  const handleMarked = () => {
    setMarked((prev) => !prev);
  };
  
  const fetchdata = async()=>{
    setLoader(true);
    let payload = {
      walletAddress : Getuseraddress()
    }
    let result = await GetAllNotificationHooks(payload);
    setNotification(result?.data?.record);
    setCount(result?.data?.count);
    setSkip(0+limit);
    setLoader(false);
  }

  const loadmore = async() => {
    setLoader(true);
    let payload = {
      walletAddress : Getuseraddress()
    }
    let result = await GetAllNotificationHooks(payload);
    setNotification(result?.data?.record);
    setSkip(skip+limit);
    setLoader(false);
  }

  const readallnotification = async () => {
    let payload = {
      walletAddress: "aa", //Getuseraddress()
    };
    let result = await ReadAllHooks(payload);
    // setNotification(result?.data?.record);
    fetchdata()
  };

  const readonemsg = async(ID) => {
    let result = await ReadOneMessageHooks({id : ID});
    fetchdata();
  }

  useEffect(()=>{
    fetchdata();
  },[])
  
  return (
    <>
      <div id="dashboard" className="showsidebar dashboard_sec_content">
        <div id="sidebar">
          <Adminsidebar />
        </div>

        <div className="headandcont">
          <div className="adminheader">
            <AdminHeader />
          </div>

          <div className="cont p-sm-5 p-4 cont cont_padding">
            {/* <h5>Notifications</h5> */}
            <div className="notifications">
              <div className="card px-3 py-3">
                <div className="card-body p-0">
                  <h5 className="card-title">Notifications</h5>
                  <div className="d-flex justify-content-end">
                    <a onClick={readallnotification} className="mark_all_read">
                      Mark All As Read
                    </a>
                  </div>
                </div>

                {!loader && notification?.length > 0 ? notification?.map((data , i) => <div
                  className={
                    !data?.isseen
                      ? "noti-content d-flex mt-4"
                      : "noti-content_marked d-flex mt-4"
                  }
                >
                  <span className="side_bg"></span>
                  <div className="d-flex justify-content-between align-items-center py-2 px-3 w-100">
                    <div>
                      <p className="login_success_txt mb-0">
                       {data?.message}
                      </p>
                      <p className="date_time mb-0">{new Date(
                                          data?.createdAt
                                        ).toLocaleDateString()}</p>
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                      <a
                        onClick = {()=>readonemsg(data?._id)}
                        className="mark_read_btn"
                        // onClick={handleMarked}
                      >
                        <FaCheckDouble className="mark_us_read_icon" />{" "}
                        <span className="mark_as_read">Mark as read</span>
                      </a>
                    </div>
                  </div>
                </div>) : <p>No Notification Found</p>}

                {!loader &&<div className="d-flex justify-content-center align-items-center">
                    {count > notification?.length && (
                      <button
                        onClick={loadmore}
                        className="common_yellow_btn"
                      >
                        {loader ? "Loading..." : "Loadmore"}
                      </button>
                    )}
                  </div>}

                {/* <div
                  className={
                    marked
                      ? "noti-content_marked d-flex mt-4"
                      : "noti-content d-flex mt-4"
                  }
                >
                  <span className="side_bg"></span>
                  <div className="d-flex justify-content-between align-items-center py-2 px-3 w-100">
                    <div>
                      <p className="login_success_txt mb-0">
                        Login Successfully
                      </p>
                      <p className="date_time mb-0">2023-11-02 20:19</p>
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                      <a
                        href="#"
                        className="mark_read_btn"
                        onClick={handleMarked}
                      >
                        <FaCheckDouble className="mark_us_read_icon" />{" "}
                        <span className="mark_as_read">Mark as read</span>
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
