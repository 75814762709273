import axios from 'axios';
import { API_URL } from '../Config/config';
import *as AdminRoute from "../Route/Adminroutefront";

export const GetAdminPlanX3Hooks = async(data) => {
    let res = await axios.get(API_URL + AdminRoute.GetAdminPlanX3Api, data);
        return res;
}

export const EditPlanX2Hooks = async(data) => {
    let res = await axios.post(API_URL + AdminRoute.EditPlanX2Api, data);
        return res;
}

export const EditPlanX3Hooks = async(data) => {
    let res = await axios.post(API_URL + AdminRoute.EditPlanX3Api, data);
        return res;
}