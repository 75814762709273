import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import Adminsidebar from "./AdminSidebar";
import AdminHeader from "./AdminHeader";
import Table from "react-bootstrap/Table";
import { GetLevelProfitHooks, Getpartnerlisthooks } from "../Hooks/usebackend";
import {
  Convertaddress,
  Getpartnersview,
  GetPartnerX2,
  Getuseraddress,
} from "../Hooks/UseContract";
import { usersDetails } from "../Hooks/UseWallet";
import { toFixedNumber } from "../Hooks/helper";
import { GetPartnersHook, GetUserX3hooks } from "../Hooks/usebackendX3";

export default function Adminpartners() {
  // const PartnerDetails = [
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  // ];

  const [PartnerDetails, setPartnerdetails] = useState([]);
  const [userdetail, setUserdetail] = useState({});
  const [partneradd, setPartneradd] = useState([]);
  const [loader, setLoader] = useState(false);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [button, setButton] = useState(false);

  const fetchdata = async () => {
    if (Getuseraddress()) {
      setLoader(true);
      let payload = {
        skip: 0,
        limit: 10,
        user: Getuseraddress()
      }
      let partners = await GetPartnersHook(payload);
      // let usr = await GetUserX3hooks({ walletAddress: Getuseraddress() })
      let income = await GetLevelProfitHooks({walletAddress : Getuseraddress()});
      setUserdetail(income?.data?.user)
      // setUserdetail(usr?.data?.record);

      console.log("partners", partners);
      setPartnerdetails(partners?.data?.record);
      setCount(partners?.data?.count);
      setSkip(skip + limit);
      // setPartneradd(partners?.partnersadd);
      setLoader(false);
    }
  };

  const loadmore = async () => {
    if (parseInt(count) > PartnerDetails?.length) {
      setButton(true);
      let payload = {
        skip: 0,
        limit: 10,
        user: Getuseraddress()
      }
      let partners = await GetPartnersHook(payload);
      setPartnerdetails(PartnerDetails?.concat(partners?.data?.record));
      console.log("partners", partners);
      setSkip(skip + limit);
      setButton(false);
    }
  }

  useEffect(() => {
    fetchdata();
  }, []);

  useEffect(() => {
    if(!Getuseraddress()){
      window.location.href = window.location.origin;
    }
  },[])

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" className="rct_cust_tltp" {...props}>
      {props}
    </Tooltip>
  );

  return (
    <>
      <div id="dashboard" className="showsidebar partners_side_section">
        <div id="sidebar">
          <Adminsidebar />
        </div>
        <div className="headandcont">
          <div className="adminheader">
            <AdminHeader />
          </div>

          <div className="cont p-sm-5 p-4 cont cont_padding">
            <h5>
              Parterns
              <Button className="partner_id_btn ms-3">
                ID {userdetail?.id}
              </Button>
            </h5>
            <div className="table_sec mt-4">
              <Table responsive>
                <thead>
                  <tr className="t_head">
                    {/* <th>Date</th> */}
                    
                    <th>Referer</th>
                    <th>ID</th>
                    <th>Address</th>
                    {/* <th>Plan</th> */}
                    
                    {/* <th>X2</th>
                    <th>X3</th> */}
                    {/* <th>Partners</th> */}
                    {/* <th className="profits_th">Profits</th> */}
                  </tr>
                </thead>
                {!loader ? (
                  <tbody>
                    {PartnerDetails.map((item, index) => {
                      return (
                        <>
                          <tr className="t_body">
                            {/* <td>
                            <div>{new Date(parseFloat(item.timeStamp)).toLocaleDateString()}</div>
                          </td> */}
 <td>
                              {" "}
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip(
                                  userdetail?.id
                                )}
                                name="hariharan"
                              >
                                <a target="_blank" className="custom_a_wht" href={window.location.origin + "/user-dashboard/" + userdetail?.id}>

                                  <p className="mb-0">
                                    {userdetail?.id}
                                  </p>
                                </a>

                              </OverlayTrigger>
                            </td>
                            <td className="partner_id_td">
                              <Button className="partner_id">{item?.id}</Button>
                            </td>
                            <td>
                              {" "}
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip(
                                  item?.walletAddress
                                  // Convertaddress(partneradd[index])
                                )}
                                name="hariharan"
                              >
                                <a target="_blank" className="custom_a_wht" href={window.location.origin + "/user-dashboard/" + item?.id}>

                                  <p className="mb-0">
                                    {`${item?.walletAddress
                                      ?.substring(0, 5)}...${item?.walletAddress?.substring(31, 34)}`}
                                  </p>
                                </a>
                               
                              </OverlayTrigger>
                            </td>
                           
                            {/* <td>{item?.type}</td> */}
                            {/* <td>{`${Convertaddress(partneradd[index])?.substring(0, 5)}...${Convertaddress(partneradd[index])?.substring(31,34)}`}</td>
                          <td>{`${Convertaddress(item?.referrer)?.substring(0, 5)}...${Convertaddress(item?.referrer)?.substring(31,34)}`}</td> */}
                           
                            {/* <td>{item.x2}</td>
                          <td>{item.x3}</td> */}
                            {/* <td>{item?.partnersCount}</td> */}
                            {/* <td>{item?.x3profit ? item?.x3profit : item?.x2profit}</td> */}
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <td colSpan={3}>
                      <p className="mb-0 data_not_found_txt">Loading...</p>
                    </td>
                  </tbody>
                )}
                {!loader && PartnerDetails?.length <= 0 && <tbody>
                  <td colSpan={3}>
                    <p className="mb-0 data_not_found_txt">No Partner Found</p>
                  </td>
                </tbody>}

              </Table>
              <div className="d-flex align-items-center justify-content-center mt-2">
                {count > PartnerDetails?.length && <button onClick={loadmore} className="common_yellow_btn">{button ? "Loading..." : "Loadmore"}</button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
