import React from "react";
import { Button, Container, Row } from "react-bootstrap";
import plangrp from "../Assets/plan_grp.svg";

function SkeletonLoadingUserDash() {
  return (
    <div className="userdash_skeleton content_sec">
      <div className="plan_completed_sec">
        <span className="circle"></span>
        <span className="circle"></span>
        <span className="circle"></span>
        <span className="circle"></span>
        <span className="circle"></span>
        <span className="circle"></span>
      </div>
      <div className="btn_sec">
        {/* <Button className="preview_btn common_yellow_secondary_btn">
          Preview
        </Button>
        <img src={plangrp} alt="plan" className="img-fluid plangrp " /> */}
        <span className="btn_sec_loader"></span>
        <span className="img_logo"></span> 
      </div>
    </div>
  );
}

export default SkeletonLoadingUserDash;
