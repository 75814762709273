import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./Pages/Home";
import Dashboard from "./Admin/AdminDashboard";
import Partners from "./Admin/AdminPartners";
import Linkspage from "./Admin/AdminLinks";
import Settings from "./Admin/Adminsettings";
import "./App.css";
import PlanParticular from "./Components/PlanParticular";
import PlanParticularOne from "./Components/PlanParticularOne";
import UniversalTronPlanParticular from "./Components/UniversalTronPlanParticular";
import OUTotalPlan from "./Components/OtherUser/OUTotalPlanList";
import OUTotalPlanList from "./Components/OtherUser/OUTotalPlanList";
import OUDashboard from "./Components/OtherUser/OUDashboard";
import BoardPlan from "./Components/BoardPlan";
import Admintransaction from "./Admin/Admintransaction";
/** Redex */
import { Provider } from "react-redux";
import store from "./Regex/store";
import * as buffer from "buffer";
import Boardx3planlist from "./Components/OtherUser/x3planlist";
import PlanTransaction from "./Admin/PlanTransaction";
import Notifications from "./Admin/Notifications";
import PlanOne from "./Admin/PlanOne";
import PlanTwo from "./Admin/PlanTwo";
import PlanThree from "./Admin/PlanThree";
import AdminEditPlan from "./Admin/AdminPlanEdit"
import { GetLevelProfitHooks } from "./Hooks/usebackend";
import { Wallet } from "./Pages/Wallet";
window.Buffer = buffer.Buffer;
// import { getBalance } from "./Hooks/UseContract";

export default function App() {

  const [userdetail , setUserdetail] = useState({})

  // const fetchdata = async() => {
  //   let income = await GetLevelProfitHooks({walletAddress : Getuseraddress()});
  //   setUserdetail(income?.data?.user)
  // }
  useEffect(()=>{
    // fetchdata();
    // getBalance()
  },[])

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/:id" element={<Homepage />} />
          {/* admin */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/linkspage" element={<Linkspage />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/plan/:id" element={<PlanParticular />} />
          <Route path="/plan/:id/:id" element={<PlanParticular />} />
          <Route path="/plan/:id/:id/:id" element={<PlanParticular />} />
          {/* <Route path="/plan/:id/:id" element={<PlanParticularOne />} /> */}
          <Route path="/universal-plan/:id" element={<OUTotalPlanList />} />
          <Route
            path="/universal-plan/:id/:id/"
            element={<OUTotalPlanList />}
          />
          {/* <Route path="/user-dashboard" element={<OUDashboard />} /> */}
          <Route path="/user-dashboard/:id" element={<OUDashboard />} />
          <Route path="/board-plan/:id" element={<BoardPlan />} />
          <Route path="/board-plan/:id/:id" element={<BoardPlan />} />
          <Route path="/transaction" element={<Admintransaction />} />
          <Route path="/board/:id/:id" element={<Boardx3planlist />} />
          <Route
            path="/plan-transactions/:id/:id"
            element={<PlanTransaction />}
          />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/plan1-list" element={<PlanOne />} />
          <Route path="/plan2-list" element={<PlanTwo />} />
          <Route path="/plan3-list" element={<PlanThree />} />
          <Route path = "/edit-plan" element = {<AdminEditPlan/>}/>
          <Route path = "/wallet" element = {<Wallet/>}/>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}
