import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import Adminsidebar from "./AdminSidebar";
import AdminHeader from "./AdminHeader";
import Table from "react-bootstrap/Table";
import {
  GetDirectTransactionHooks,
  GetLevelTransactionHooks,
  Getpartnerlisthooks,
  GetPlan3DirectTransactionHooks,
  GetPlan3LevelTransactionHooks,
  IdtoAddressHooksX2,
} from "../Hooks/usebackend";
import {
  Convertaddress,
  Getpartnersview,
  GetPartnerX2,
  GetTransactionX2,
  Getuseraddress,
} from "../Hooks/UseContract";
import { usersDetails } from "../Hooks/UseWallet";
import { toFixedNumber } from "../Hooks/helper";
import { GetTransactionHook, GetUserX3hooks } from "../Hooks/usebackendX3";

export default function PlanThree() {
  // const PartnerDetails = [
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  // ];

  const [PartnerDetails, setPartnerdetails] = useState([]);
  const [userdetail, setUserdetail] = useState({});
  const [partneradd, setPartneradd] = useState([]);
  const [data, setData] = useState([
    "Direct Referal",
    "Level Income",
    "Admin Fee",
    "eligible upliner",
  ]);
  const [loader, setLoader] = useState(false);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [button, setButton] = useState(false);
  const [transaction, setTransaction] = useState([]);

  const [diretskip, setDirectskip] = useState(0);
  const [diretlimit, setDirectlimit] = useState(10);
  const [diretcount, setDirectcount] = useState(0);
  const [diretbutton, setDirectbutton] = useState(false);
  const [directtransaction, setDirecttransaction] = useState([]);
  const [user, setUSer] = useState({});
  const [directloader, setDirectloader] = useState(false);
  const [tabs, setTabs] = useState("level");
  const [load, setLoad] = useState(0);

  const handleTabs = (val) => {
    setTabs(val);
    setTimeout(() => {
      setLoad(load + 1);
    }, 1000);
  };

  const loadmore = async () => {
    if (parseInt(count) > PartnerDetails?.length) {
      setButton(true);
      let payload = {
        skip: skip,
        limit: 10,
        walletAddress: Getuseraddress(),
      };
      let partners = await GetPlan3LevelTransactionHooks(payload);
      setTransaction(transaction?.concat(partners?.data?.record));
      console.log("partners", partners);
      setSkip(skip + limit);
      setButton(false);
    }
  };

  const fetchPlans = async () => {
    setLoader(true);
    var addres;
    if (window.location.href.split("/")[6]) {
      let idpayload = {
        id: window?.location?.href?.split("/")[4],
      };
      let idtoadd = await IdtoAddressHooksX2(idpayload);
      setUSer(idtoadd?.data?.record);
      addres = idtoadd?.data?.record?.walletAddress;
    }
    let payload = {
      walletAddress: window.location.href.split("/")[6]
        ? addres
        : Getuseraddress(),
      skip: diretskip,
      limit: diretlimit,
    };
    let result = await GetPlan3LevelTransactionHooks(payload);
    console.log("REsult in x2plan1", result);
    if (result?.data?.type == "Success") {
      setTransaction(result?.data?.record);
      setCount(result?.data?.count);
      //   setX2plan1(result?.data?.plan);
      setLoader(false);
    }
  };

  const loadmoredirect = async () => {
    if (parseInt(count) > directtransaction?.length) {
      setDirectbutton(true);
      let payload = {
        skip: diretskip,
        limit: 10,
        walletAddress: Getuseraddress(),
      };
      let partners = await GetPlan3DirectTransactionHooks(payload);
      setDirecttransaction(directtransaction?.concat(partners?.data?.record));
      console.log("partners", partners);
      setDirectskip(skip + limit);
      setDirectbutton(false);
    }
  };

  const fetchPlansdirect = async () => {
    setDirectloader(true);
    var addres;
    if (window.location.href.split("/")[6]) {
      let idpayload = {
        id: window?.location?.href?.split("/")[4],
      };
      let idtoadd = await IdtoAddressHooksX2(idpayload);
      setUSer(idtoadd?.data?.record);
      addres = idtoadd?.data?.record?.walletAddress;
    }
    let payload = {
      walletAddress: window.location.href.split("/")[6]
        ? addres
        : Getuseraddress(),
      skip: skip,
      limit: limit,
    };
    let result = await GetPlan3DirectTransactionHooks(payload);
    console.log("REsult in x2plan1", result);
    if (result?.data?.type == "Success") {
      setDirecttransaction(result?.data?.record);
      //   setX2plan1(result?.data?.plan);
      setDirectloader(false);
    }
  };

  useEffect(() => {
    fetchPlans();
    fetchPlansdirect();
  }, []);
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" className="rct_cust_tltp" {...props}>
      {props}
    </Tooltip>
  );

  return (
    <>
      <div id="dashboard" className="showsidebar partners_side_section">
        <div id="sidebar">
          <Adminsidebar />
        </div>
        <div className="headandcont">
          <div className="adminheader">
            <AdminHeader />
          </div>

          <div className="cont p-sm-5 p-4 cont cont_padding">
            <div className="plan_trans_custom_tabs">
              <Button
                className={tabs === "level" ? "active_btn" : "inactive_btn"}
                onClick={() => handleTabs("level")}
              >
                Level{" "}
              </Button>
              <Button
                className={tabs === "direct" ? "active_btn" : "inactive_btn"}
                onClick={() => handleTabs("direct")}
              >
                Direct{" "}
              </Button>
            </div>
            {tabs == "level" ? (
              <>
                <div className="table_sec mt-4 level_tab">
                  <Table responsive>
                    <thead>
                      <tr className="t_head">
                        {/* <th>Date</th> */}
                        <th>From</th>
                        <th>To</th>
                        <th>Plan</th>
                        <th>Amount</th>
                        {/* <th>X2</th>
                    <th>X3</th> */}
                        <th>Transaction type</th>

                        {/* <th className="profits_th">Profits</th> */}
                      </tr>
                    </thead>
                    {!loader ? (
                      <tbody>
                        {transaction?.length > 0 &&
                          transaction?.map((item, index) => {
                            return (
                              <>
                                <tr className="t_body">
                                  <td>
                                    {" "}
                                    <OverlayTrigger
                                      placement="bottom"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={renderTooltip(
                                        Convertaddress(item?.from)
                                      )}
                                      name="hariharan"
                                    >
                                      <p className="mb-0">
                                        {`${Convertaddress(
                                          item?.from
                                        )?.substring(0, 5)}...${Convertaddress(
                                          item?.from
                                        )?.substring(31, 34)}`}
                                      </p>
                                    </OverlayTrigger>
                                  </td>
                                  <td>
                                    {" "}
                                    <OverlayTrigger
                                      placement="bottom"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={renderTooltip(
                                        Convertaddress(item?.to)
                                      )}
                                      name="hariharan"
                                    >
                                      <p className="mb-0">
                                        {`${Convertaddress(item?.to)?.substring(
                                          0,
                                          5
                                        )}...${Convertaddress(
                                          item?.to
                                        )?.substring(31, 34)}`}
                                      </p>
                                    </OverlayTrigger>
                                  </td>
                                  {console.log("type ", item)}
                                  <td>{item?.type}</td>
                                  {/* <td>{`${Convertaddress(item?.from)?.substring(0, 5)}...${Convertaddress(item?.from)?.substring(31,34)}`}</td> */}
                                  {/* <td>{`${Convertaddress(item?.to)?.substring(0, 5)}...${Convertaddress(item?.to)?.substring(31,34)}`}</td> */}
                                  <td className="partner_id_td">
                                    <Button className="partner_id">
                                      {parseFloat(item?.amount)}
                                    </Button>
                                  </td>
                                  {/* <td>{item.x2}</td>
                          <td>{item.x3}</td> */}
                                  <td>{item?.purpose}</td>
                                  {/* <td>{parseFloat(item?.amount?.toString()).toFixed(3)}</td> */}
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    ) : (
                      <tbody>
                        <td colSpan={5}>
                          <p className="mb-0 data_not_found_txt">Loading...</p>
                        </td>
                      </tbody>
                    )}

                    {!loader && transaction?.length <= 0 && (
                      <tbody>
                        <td colSpan={5}>
                          <p className="mb-0 data_not_found_txt">
                            No Transaction Found
                          </p>
                        </td>
                      </tbody>
                    )}
                  </Table>
                  <div className="d-flex justify-content-center align-items-center">
                    {count > transaction?.length && (
                      <button onClick={loadmore} className="common_yellow_btn">
                        {button ? "Loading..." : "Loadmore"}
                      </button>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="table_sec mt-4 direct_tab">
                  <Table responsive>
                    <thead>
                      <tr className="t_head">
                        {/* <th>Date</th> */}
                        <th>From</th>
                        <th>To</th>
                        <th>Plan</th>
                        <th>Amount</th>
                        {/* <th>X2</th>
                    <th>X3</th> */}
                        <th>Transaction type</th>

                        {/* <th className="profits_th">Profits</th> */}
                      </tr>
                    </thead>
                    {!loader ? (
                      <tbody>
                        {directtransaction?.length > 0 &&
                          directtransaction?.map((item, index) => {
                            return (
                              <>
                                <tr className="t_body">
                                  <td>
                                    {" "}
                                    <OverlayTrigger
                                      placement="bottom"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={renderTooltip(
                                        Convertaddress(item?.from)
                                      )}
                                      name="hariharan"
                                    >
                                      <p className="mb-0">
                                        {`${Convertaddress(
                                          item?.from
                                        )?.substring(0, 5)}...${Convertaddress(
                                          item?.from
                                        )?.substring(31, 34)}`}
                                      </p>
                                    </OverlayTrigger>
                                  </td>
                                  <td>
                                    {" "}
                                    <OverlayTrigger
                                      placement="bottom"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={renderTooltip(
                                        Convertaddress(item?.to)
                                      )}
                                      name="hariharan"
                                    >
                                      <p className="mb-0">
                                        {`${Convertaddress(item?.to)?.substring(
                                          0,
                                          5
                                        )}...${Convertaddress(
                                          item?.to
                                        )?.substring(31, 34)}`}
                                      </p>
                                    </OverlayTrigger>
                                  </td>
                                  {console.log("type ", item)}
                                  <td>{item?.type}</td>
                                  {/* <td>{`${Convertaddress(item?.from)?.substring(0, 5)}...${Convertaddress(item?.from)?.substring(31,34)}`}</td> */}
                                  {/* <td>{`${Convertaddress(item?.to)?.substring(0, 5)}...${Convertaddress(item?.to)?.substring(31,34)}`}</td> */}
                                  <td className="partner_id_td">
                                    <Button className="partner_id">
                                      {parseFloat(item?.amount)}
                                    </Button>
                                  </td>
                                  <td>{item?.purpose}</td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    ) : (
                      <tbody>
                        <td colSpan={5}>
                          <p className="mb-0 data_not_found_txt">Loading...</p>
                        </td>
                      </tbody>
                    )}

                    {!loader && directtransaction?.length <= 0 && (
                      <tbody>
                        <td colSpan={5}>
                          <p className="mb-0 data_not_found_txt">
                            No Transaction Found
                          </p>
                        </td>
                      </tbody>
                    )}
                  </Table>
                  <div className="d-flex justify-content-center align-items-center">
                    {diretcount > directtransaction?.length && (
                      <button
                        onClick={loadmoredirect}
                        className="common_yellow_btn"
                      >
                        {diretbutton ? "Loading..." : "Loadmore"}
                      </button>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
