import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Row,
  Nav,
  NavDropdown,
  Navbar,
  Modal,
} from "react-bootstrap";
import Lottie from "lottie-react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Headerlogo from "../Assets/Json/logoas - 1.json";
import Walletmodal from "../Components/walletmodal";
import Headerlogos from "../Assets/universal_logo.png";
import Register from "../Components/Modals/Register";
import { isEmpty } from "../Hooks/UseValidation";
import { useDispatch } from "react-redux";
import {
  Extregistration,
  userPayPrice,
  checkRegister,
  disconnectWallet,
} from "../Hooks/UseWallet";
import { Getuseraddress } from "../Hooks/UseContract";
import {
  CheckUserRegisterHooks,
  ConnectwalletHooks,
} from "../Hooks/usebackend";

export default function Header() {
  // const {walletAddress} = useSelector(state => state.User);
  const dispatch = useDispatch();
  const { walletData } = useSelector((state) => state.User);
  const [walletAddress, setWalletAddress] = useState("");
  const [Walletmodals, setWalletmodals] = useState(false);
  const [register, setRegister] = useState(false);
  const [registration, setRegistration] = useState(true);

  console.log("data_redux", walletAddress, walletData);

  const address = localStorage.getItem("address");

  const fetchdata = async () => {
    let payload = {
      walletAddress: address,
    };
    let result = await CheckUserRegisterHooks(payload);

    let token = await ConnectwalletHooks(payload);

    console.log("Result in check user register hooks", result);
    if (result?.data?.type == "Success") {
      setRegistration(true);
    } else if (result?.data?.type == "failed") {
      setRegistration(false);
    }
  };

  useEffect(() => {
    console.log("useEffect");
    setWalletAddress(Getuseraddress() ? Getuseraddress() : "");
    // isAlreadRegisterCheck();
    fetchdata();
  }, [walletData]);

  const isAlreadRegisterCheck = async () => {
    try {
      const isRegistered = await checkRegister(address);
      console.log(
        isRegistered,
        "isRegisteredisRegisteredisAlreadRegisterCheck"
      );
      if (isRegistered) {
        console.log("IFF");

        setRegistration(false);
      } else {
        console.log("ELSE");
        setRegistration(true);
      }
    } catch (err) {
      console.log(err, "err__pay");
    }
  };

  const disconnectTronWallet = async () => {
    try {
      const disConnect = await disconnectWallet(dispatch);
      if (disConnect) {
        localStorage.removeItem("address");
        localStorage.removeItem("balance");
        setWalletAddress();
        // props.onDismiss();
      }
    } catch (e) {
      console.log("connectTronWallet_err", e);
    }
  };

  useEffect(() => {
    if(!isNaN(parseInt(window.location.href.split("utref=")[1]))){
      setRegister(true);
    }
    // if(!localStorage.getItem("address")){
    //   setWalletmodals(true);
    // }
  } , [])

  return (
    <>
      {Walletmodals && <Walletmodal onDismiss={() => setWalletmodals(false)} onReg = {() => setRegister(true)}/>}
      {/* {register && <Register onDismiss={() => setRegister(false)} />} */}
      {register && (
        <Register
          onDismiss={() => {
            setRegister(false);
          }}
          onLogin={() => {
            setRegister(false);
            setWalletmodals(true);
          }}
          register={register}
          setRegister={setRegister}
        />
      )}
      <div className="allheader">
        <Navbar fixed="top" expand="sm">
          <Container>
            <Navbar.Brand
              className="py-0"
              href={window.location.origin + "/dashboard"}
            >
              {/* <Lottie className="headerlogo" animationData={Headerlogo} loop={false} /> */}
              <img src={Headerlogos} className="img-fluid headerlogo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse
              className="justify-content-end header_nav"
              id="navbarScroll"
            >
              <Button
                className="textclr me-5 mb-0 f-14 register"
                style={{ cursor: "pointer" }}
                onClick={() => setWalletmodals(true)}
              >
                {isEmpty(walletAddress)
                  ? "Connect Wallet"
                  : walletAddress?.substring(0, 9)}
              </Button>

              <p
                className="textclr me-5 mb-0"
                style={{ cursor: "pointer" }}
              >
                {isEmpty(walletAddress) ? (
                  ""
                ) : (
                  <Button
                    className="register"
                    onClick={() => disconnectTronWallet()}
                  >
                    Logout
                  </Button>
                )}
              </p>

              {Getuseraddress() && registration && (
                <Button className="register" onClick={() => setRegister(true)}>
                  Register
                </Button>
              )}
              {!registration && (
                <Link to="/dashboard">
                  <Button className="register">Dashboard</Button>
                </Link>
              )}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}
