// const Cryptr = require('cryptr');
const Crypto = require("crypto-js");
// const cryptr = new Cryptr('MATICZ-UNIVERSAL-TRON');

export const EncryptString = (value , secret) => {
    console.log("Before encruypted" , value);
    const encryptedString = Crypto.AES.encrypt(value , secret)?.toString(); //atob( localStorage.getItem("USRDT"))
    console.log("Encrypted string" , encryptedString);
    return encryptedString;
}

export const DecryptString = (value , secret) => {
    const decryptedString = Crypto.AES.decrypt(value , secret);
    let decryptedData = JSON.parse(decryptedString.toString(Crypto.enc.Utf8));
    return decryptedData;
}