/** Packages */
// import { TronLinkAdapter } from "@tronweb3/tronwallet-adapters";
import { BitKeepAdapter, OkxWalletAdapter, TokenPocketAdapter, TronLinkAdapter } from '@tronweb3/tronwallet-adapters';
import { EthereumProvider } from '@walletconnect/ethereum-provider'
// import { TokenPocketAdapter } from '@tronweb3/tronwallet-adapter-tokenpocket';
import TronWeb from "tronweb";
import Web3 from 'web3'; 
// import TronWeb from "tronweb";

/** Config-Files */
import { CHAINS, CHAIN_ID, CHAIN__ID, PROJECT_ID, RPC, WALLET_CONNECT_CHAIN_ID } from "../Config/config";

/**Dispatch-Files */
import * as Dispatch from "./UseDispatch";

import { getBalance } from "../Hooks/UseContract";

import {
  UniversalMLMContractABI,
  UniversalMLMcontractAddress,
} from "../contracts/contract";
import { UseWeb3 } from "./UseAccount";
import { ConnectwalletHooks } from "./usebackend";
import { toast } from "react-hot-toast";

const tronWeb = new TronWeb({
  fullHost: "https://api.trongrid.io",
});
// Connect-TRON-Wallet
export const connectWallet = async (dispatch) => {
  try {console.log("ConnectWallet__tron");

    if(window.tronWeb){
      if(!window.tronWeb.defaultAddress.base58){
        let data = await window.tronWeb.address.fromHex((await tronWeb.trx.getAccount()).address.toString());  //window.tronWeb.defaultAddress.base58;
        let address =  window.tronWeb.defaultAddress.base58;
        console.log("address" , address);
        const Balance = await getBalance(address);
        await Dispatch.SetWalletAddress(
          dispatch,
          address,
          Balance
        );
        let payload = {walletAddress : address}
        let result = await ConnectwalletHooks(payload);
        return true;
      }
      else if(window.tronWeb.defaultAddress.base58){
        let address =  window.tronWeb.defaultAddress.base58;
        console.log("address" , address);
        const Balance = await getBalance(address);
        await Dispatch.SetWalletAddress(
          dispatch,
          address,
          Balance
        );
        let payload = {walletAddress : address}
        let result = await ConnectwalletHooks(payload);
        return true;
      }
      
    //  await window.tronWeb.setAddress();
      
    }

    // const tronlinkAdapter = new TronLinkAdapter();
    // await tronlinkAdapter.switchChain(CHAINS[0].CHAIN_ID);
    // await tronlinkAdapter.connect();
    // console.log("ConnectWallet__tron", tronlinkAdapter);
    // if (tronlinkAdapter.address != "") {
      
    // }
  } catch (e) {
    console.log("connectWallet_err", e);
  }
};
// Disconnect-TRON-Wallet
export const disconnectWallet = async (dispatch) => {
  try {
    const tronlinkAdapter = new TronLinkAdapter();
    await tronlinkAdapter.disconnect();
    console.log("ConnectWallet__tron", tronlinkAdapter);
    // if (tronlinkAdapter.address != "") {
    await Dispatch.SetWalletAddress(dispatch, "", "");
    // }
  } catch (e) {
    console.log("connectWallet_err", e);
  }
};

export const checkRegister = async (address) => {
  try {
  
    const web3 = await UseWeb3();
    var contract = await web3.contract(
      UniversalMLMContractABI,
      UniversalMLMcontractAddress
    );
    let isUserExists = await contract.isUserExists(address).call();
    console.log(isUserExists, "isUserExists");
    return isUserExists;
  } catch (err) {
    console.log(err, "err_contract");
  }
};

// export const Extregistration = async (address, id, value) => {
//   try {
//     console.log("Address : ", address , id , value);
//     var contract = await web3.contract(
//       UniversalMLMContractABI,
//       UniversalMLMcontractAddress
//     );
//     address = web3.address.toHex(address);
//     let idToAddress = await contract.idToAddress(id).call();
//     console.log(idToAddress, "idToAddressidToAddress");
//     console.log(tronWeb.address.fromHex(idToAddress),"idToAddressidToAddress");
//     let transaction = await contract.registrationExt(idToAddress).send({
//       callValue: tronWeb.toSun(value), 
//       feeLimit: 2_000_000_000, 
//     });
//     console.log(transaction, "transactiontransaction");
//     return transaction;
//   } catch (err) {
//     console.log(err, "err_contract");
//   }
// };

export const Extregistration = async (address, id, value) => {
  try {
    const web3 = await UseWeb3();
    var contract = await web3.contract(
      UniversalMLMContractABI,
      UniversalMLMcontractAddress
    );
    // let check = await contract.isUserExists(address).call();
    let lastuserid = await contract?. lastUserId ().call();
    console.log("Address Extregistration : ", address , id , value , lastuserid?.toString() , parseFloat(id) <= parseFloat(lastuserid?.toString()));

    if(parseFloat(id) <= parseFloat(lastuserid?.toString())){
      address = web3.address.toHex(address);
      let idToAddress = await contract.idToAddress(id).call();
      console.log(idToAddress, "idToAddressidToAddress");
      console.log(tronWeb.address.fromHex(idToAddress),"idToAddressidToAddress");
      const options = {
        callValue: tronWeb.toSun(value), 
        feeLimit: 3_000_000_000, // Replace with the desired fee limit
      };
      let trans = await contract.registrationExt(idToAddress).send(options);
      return {type : "Success" , data : trans}
    }
    else{
      address = web3.address.toHex(address);
      let idToAddress = await contract.idToAddress(1).call();
      console.log(idToAddress, "idToAddressidToAddresselse");
      console.log(tronWeb.address.fromHex(idToAddress),"idToAddressidToAddresselse");
      const options = {
        callValue: tronWeb.toSun(value), 
        feeLimit: 3_000_000_000, // Replace with the desired fee limit
      };
      let trans = await contract.registrationExt(idToAddress).send(options);
      return {type : "Success" , data : trans}
    }
    // .catch((e) => {
    //   return {type : "Failed" , data : e}
    // });
    // console.log(transaction, "transactiontransaction");
    // return transaction;
  } catch (err) {
    console.log(err, "err_contract");
  }
};

export const userPayPrice = async (id) => {
  try {
    const web3 = await UseWeb3();
    var contract = await web3.contract(
      UniversalMLMContractABI,
      UniversalMLMcontractAddress
    );
    let BASIC_PRICE = await contract.BASIC_PRICE().call();
    let levelPrice = await contract.levelPrice(id).call();

    let BASIC = web3.toDecimal(BASIC_PRICE._hex);
    let level = web3.toDecimal(levelPrice._hex);
    console.log(level / 1000000, level, "BASICBASIC");
    let total = BASIC / 10 ** 6 + level / 10 ** 6;
    return total;
  } catch (err) {
    console.log(err, "err_contract");
  }
};

export const usersDetails = async (address) => {
  try {
    const web3 = await UseWeb3()
    var contract = await web3.contract(
      UniversalMLMContractABI,
      UniversalMLMcontractAddress
    );
    let userDetail = await contract.users(address).call();
    // console.log(userDetail,"userDetailuserDetail")
    let userDetailss = {
      id: web3.toDecimal(userDetail[0]._hex),
      referrer: web3.address.fromHex(userDetail[1]),
      partnersCount: web3.toDecimal(userDetail[2]._hex),
      balance: web3.toDecimal(userDetail[3]._hex),
    };
    console.log(userDetailss, "userDetailuserDetail");
    return userDetailss;
  } catch (err) {
    console.log(err, "err_contract");
  }
};


//Wallet connect

export const WalletConnect = async (e) => {
  try {
    const RPC_URL = RPC;
    const CHAIN_ID = CHAIN__ID;
    console.log("Wallet connect");
    const RPC_DATA = {};
    RPC_DATA[CHAIN_ID] = RPC_URL
    const provider = await EthereumProvider.init({
      projectId: PROJECT_ID, // required
      chains: [WALLET_CONNECT_CHAIN_ID], // required
      showQrModal: true // requires @walletconnect/modal
    })
    await provider.connect()
    .then(function (error, result) {
      console.log('error: ' + error);
      console.log(result);

    })
      .catch(e => {
        toast.error(`Error : ${e}`,);
      });
    const accounts = await provider.request({
      method: 'eth_requestAccounts',
    });
    console.log("Account : ", accounts[0]);
    const account = accounts[0].toString();
    const Balance = await getBalance(account);
    localStorage.setItem("address", accounts[0])
    localStorage.setItem("balance", Balance);
    localStorage.setItem("walletconnect" , true)
    console.log("user", account.toLowerCase());
    return provider;
  } catch (err) {
    console.log(err, "WalletConnect__err")
  }
}

export const ConnectTrustwallet = async() => {
  try{
    // if (window.ethereum){
      // await tronWeb.setTronWeb(window.tronWeb);
      const tronWebInstance = new TronWeb({
        fullHost: 'https://www.trongrid.io' // Use appropriate full node URL
      });
      let account = await tronWebInstance.defaultAddress.base58;
      const Balance = await getBalance(account);
      console.log('Connected to Trust Wallet:', account , Balance);

      localStorage.setItem("address", account)
      localStorage.setItem("balance", Balance);
    // }
  }
  catch(e){
    console.log("Error on connect trust wallet" , e);
  }
}

export const ConnectTokenPocket = async() => {
  try{
    // debugger
    // const adapter = new TokenPocketAdapter();
    // await adapter.connect();
    // let address = await adapter.address();
  //   window.tronWeb
  // .connect()
  // .then((tronWeb) => {
    console.log('Connected to TronLink:',  window.tronWeb.defaultAddress.base58);
  // })
   
  }
  catch(e){
    console.log("Error on connect token pocket" , e);
  }
}



