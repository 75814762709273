import React, { useEffect, useState, useRef, useCallback } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Adminsidebar from "../Admin/AdminSidebar";
import AdminHeader from "../Admin/AdminHeader";
import { Link, useLocation } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Virtual,
} from "swiper/modules";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import cardIcon from "../Assets/card_logo.svg";

import Card from "react-bootstrap/Card";
import { IoIosArrowRoundUp } from "react-icons/io";
import { FaArrowUp } from "react-icons/fa6";
import Form from "react-bootstrap/Form";
import info from "../Assets/info_logo.svg";
import plangrp from "../Assets/plan_grp.svg";
import logo from "../Assets/universal_logo.png";
import lockIcon from "../Assets/lock_icon.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import UniversalTronPlan from "../Components/UniversalTronPlan";
import { FaUsers } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";
import coin from "../Assets/coin.svg";
import dummy from "../Assets/metamask.png";
import userGroup from "../Assets/user_group.svg";
import reload from "../Assets/reload.svg";
// SwiperCore.use([Navigation, Pagination, Autoplay, Virtual]);

import RedInfo from "../Assets/red_err.svg";

export default function SkeletonLoadingParticular() {
  return (
    <div className="SkeletonLoading_Particular">
      <Swiper
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={50}
        slidesPerView={1}
      >
        <SwiperSlide>
          <div className="row d-flex laign-items-center justify-content-center">
            <div className="col-xl-5 col-lg-7 col-md-6 col-sm-8 col-8">
              <div className="SkeletonLoading_Particular level_details">
                <div className="fst_sec">
                  <div className="fst_row">
                    <span className="level"></span>
                    <span className="id"></span>
                    <span className="d-flex align-items-center coin"></span>
                  </div>
                  <div className="snd_row">
                    <div className="inside_cnt">
                      <span className="circle"></span>
                      <span className="circle"></span>
                    </div>
                  </div>
                </div>
                <div className="partner_total_profits new_party_profit">
                  <div className="content_grp">
                    <p className="header mb-2 profit_heading"></p>
                    <div className="d-flex justify-content-center align-items-center content">
                      <span className="profit_value"></span>
                    </div>
                  </div>
                  <div className="content_grp">
                    <p className="header mb-2 profit_heading"></p>
                    <div className="d-flex justify-content-center align-items-center content">
                      <span className="profit_value"></span>
                    </div>
                  </div>
                  <div className="content_grp">
                    <p className="header mb-2 profit_heading"></p>
                    <div className="d-flex justify-content-center align-items-center content">
                      <span className="profit_value"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <div className="custom_prev_slide">
          <div className="content">
            <span></span>
          </div>
        </div>
        <div className="custom_next_slide">
          <div className="content">
            <span></span>
          </div>
        </div>
      </Swiper>
    </div>
  );
}
