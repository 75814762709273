
import TronWeb from "tronweb";
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import { CHAIN__ID, PROJECT_ID, RPC, WALLET_CONNECT_CHAIN_ID } from "../Config/config";

export const UseWeb3 = async()=>{
    const tronWeb = new TronWeb({
        fullHost: RPC,
      });
    tronWeb.setAddress("TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t");

    if(localStorage.getItem('address') && (localStorage.getItem("walletconnect") == true || localStorage.getItem("walletconnect") == "true")){
        const provider = await EthereumProvider.init({
            projectId: PROJECT_ID, // required
            chains: [WALLET_CONNECT_CHAIN_ID], // required
            showQrModal: true // requires @walletconnect/modal
          })
          await provider.enable();
          return new TronWeb(provider);
    }else 
    if(localStorage.getItem('address')){
        return window.tronWeb;
    }
    else{
        return tronWeb;
    }
}