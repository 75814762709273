import React, { useEffect, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import Adminsidebar from "../Admin/AdminSidebar";
import AdminHeader from "../Admin/AdminHeader";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { IoIosArrowRoundUp } from "react-icons/io";
import { FaArrowUp } from "react-icons/fa6";
import Form from "react-bootstrap/Form";
import userLogo from "../Assets/userNot.svg";
import info from "../Assets/info_logo.svg";
import plangrp from "../Assets/plan_grp.svg";
import lockIcon from "../Assets/lock_icon.svg";
import Coin from "../Assets/coin.svg";
import { FaUsers } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";

import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Virtual,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import userGroup from "../Assets/user_group.svg";
import reload from "../Assets/reload.svg";
import BoardPlanParticular from "./BoardPlanParticular";
import { Getuseraddress, GetuserX3matrixplan1, GetuserX3matrixplan2, GetuserX3matrixplan3, GetuserX3matrixplan4 } from "../Hooks/UseContract";
import { usersDetails } from "../Hooks/UseWallet";

export default function BoardPlan() {
  const options = {
    // loop: true,
    margin: 10,
    items: 1,
    // autoplay: true,
    nav: true,
    // pagination: false,
    dots: false,
    dotsData: true,
    dotsEach: true,
    // responsiveClass: true,
    // autoplayTimeout: 7000,
    // smartSpeed: 800,
    // responsive: {
    //   0: {
    //     items: 1,
    //   },

    //   600: {
    //     items: 3,
    //   },

    //   1024: {
    //     items: 4,
    //   },

    //   1366: {
    //     items: 1,
    //   },
    // },
  };
  const [userdata , setUserdata] = useState({});

  const fetchdata = async() => {
    if(Getuseraddress()){
      let usr = await usersDetails(Getuseraddress());
      setUserdata(usr);
    }
  } 

  useEffect(() => {
    fetchdata();
  } , [])

  

  return (
    <>
      <div id="dashboard" className="showsidebar board_plan_sec">
        <div id="sidebar">
          <Adminsidebar />
        </div>

        <div className="headandcont">
          <div className="adminheader">
            <AdminHeader />
          </div>

          <div className="cont p-sm-5 p-4 cont cont_padding plan_particular_content">
            <h5 className="mb-4 heading">
              <div>
                ID {window?.location?.href?.split("/")[5]? window?.location?.href?.split("/")[5] : userdata?.id} / Universal Tron x3{" "}
                <span className="level"> ( 8 Out of 8 levels )</span>
              </div>
              <span className="back_btn">
                <Link to={window?.location?.href?.split("/")[5] ? `/board/${window?.location?.href?.split("/")[5]}/${window.location.href.split("/")[4]}` : "/dashboard"}>Back</Link>
              </span>
            </h5>
            <div className="head_section">
              <h6 className="sub_heding mb-0">Board Plan</h6>
              {/* <h6 className="sub_heding_val mb-0">24 TRX</h6> */}
            </div>
            <BoardPlanParticular />
          </div>
        </div>
      </div>
    </>
  );
}
