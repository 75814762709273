import React, { useEffect, useState } from "react";
import { Button, Container, Row } from 'react-bootstrap';
import Adminsidebar from "./AdminSidebar"


const Adminsettings = () => {


    return (

        <>

            <div id="dashboard" className='showsidebar'>
                <div id='sidebar'>
                    <Adminsidebar />
                </div>


            </div>



        </>
    );
}     

export default Adminsettings