import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { IoIosArrowRoundUp } from "react-icons/io";
import { FaArrowUp } from "react-icons/fa6";
import Form from "react-bootstrap/Form";
import userLogo from "../Assets/userNot.svg";
import info from "../Assets/info_logo.svg";
import plangrp from "../Assets/plan_grp.svg";
import lockIcon from "../Assets/lock_icon.svg";
import Coin from "../Assets/coin.svg";
import { FaUsers } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";
import cardIcon from "../Assets/card_logo.svg";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Virtual,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Button } from "react-bootstrap";
import Lottie from "lottie-react";
import lock from "../Assets/Json/lockk.json";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import userGroup from "../Assets/user_group.svg";
import reload from "../Assets/reload.svg";
import {
  Getuseraddress,
  GetuserX3matrixplan1,
  GetuserX3matrixplan2,
  GetuserX3matrixplan3,
  GetuserX3matrixplan4,
} from "../Hooks/UseContract";
import BoardPlanFooter from "./BoardPlanFooter";

function LoadingBoardPlanOne() {
  const options = {
    // loop: true,
    margin: 10,
    items: 1,
    // autoplay: true,
    nav: true,
    // pagination: false,
    dots: false,
    dotsData: true,
    dotsEach: true,
    // responsiveClass: true,
    // autoplayTimeout: 7000,
    // smartSpeed: 800,
    // responsive: {
    //   0: {
    //     items: 1,
    //   },

    //   600: {
    //     items: 3,
    //   },

    //   1024: {
    //     items: 4,
    //   },

    //   1366: {
    //     items: 1,
    //   },
    // },
  };
  return (
    <div className="Skeleton_boardplan">
      <div className="board_collection" data-dot="<button>01</button>">
        <div>
          <h6 className="heading_txt mb-0 d-flex align-items-center justify-content-center">
            <div className="level"></div>
          </h6>
          <div className="my-4">
            <Card className="level_card">
              <Card.Body className="position-relative">
                <div>
                  <div className="plan_custom_navi">
                    <div className="head_sec d-flex justify-content-between">
                      <span className="level"></span>
                      <div className="d-flex align-items-center coin"></div>
                    </div>
                    <div className="body_sec">
                      <div className="list">
                        <span
                          className={
                            false
                              ? "cmn_bg_color completed"
                              : "cmn_bg_color in_completed circle"
                          }
                        ></span>
                        <span
                          className={
                            false
                              ? "cmn_bg_color completed"
                              : "cmn_bg_color in_completed circle"
                          }
                        ></span>
                        <span
                          className={
                            false
                              ? "cmn_bg_color completed"
                              : "cmn_bg_color in_completed circle"
                          }
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <Card className="level_card mt-5">
              <Card.Body className="position-relative">
                <div>
                  <div className="plan_custom_navi">
                    <div className="head_sec d-flex justify-content-between">
                      <span className="level"></span>
                      <div className="d-flex align-items-center coin"></div>
                    </div>
                    <div className="body_sec">
                      <div className="list">
                        <span
                          className={
                            false
                              ? "cmn_bg_color completed"
                              : "cmn_bg_color in_completed circle"
                          }
                        ></span>
                        <span
                          className={
                            false
                              ? "cmn_bg_color completed"
                              : "cmn_bg_color in_completed circle"
                          }
                        ></span>
                        <span
                          className={
                            false
                              ? "cmn_bg_color completed"
                              : "cmn_bg_color in_completed circle"
                          }
                        ></span>
                        <span
                          className={
                            false
                              ? "cmn_bg_color completed"
                              : "cmn_bg_color in_completed circle"
                          }
                        ></span>
                        <span
                          className={
                            false
                              ? "cmn_bg_color completed"
                              : "cmn_bg_color in_completed circle"
                          }
                        ></span>
                        <span
                          className={
                            false
                              ? "cmn_bg_color completed"
                              : "cmn_bg_color in_completed circle"
                          }
                        ></span>
                        <span
                          className={
                            false
                              ? "cmn_bg_color completed"
                              : "cmn_bg_color in_completed circle"
                          }
                        ></span>
                        <span
                          className={
                            false
                              ? "cmn_bg_color completed"
                              : "cmn_bg_color in_completed circle"
                          }
                        ></span>
                        <span
                          className={
                            false
                              ? "cmn_bg_color completed"
                              : "cmn_bg_color in_completed circle"
                          }
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <div className="partner_total_profits new_party_profit">
              <div className="content_grp">
                <p className="header mb-2 profit_heading"></p>
                <div className="d-flex justify-content-center align-items-center content">
                  <span className="profit_value"></span>
                </div>
              </div>
              <div className="content_grp">
                <p className="header mb-2 profit_heading"></p>
                <div className="d-flex justify-content-center align-items-center content">
                  <span className="profit_value"></span>
                </div>
              </div>
              <div className="content_grp">
                <p className="header mb-2 profit_heading"></p>
                <div className="d-flex justify-content-center align-items-center content">
                  <span className="profit_value"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadingBoardPlanOne;
