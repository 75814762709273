import axios from "axios";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import TronWeb from "tronweb";
import { RPC, TRANSACTION_URL } from "../Config/config";
import {
  UniversalMLMContractABI,
  UniversalMLMcontractAddress,
} from "../contracts/contract";
import { UNIVERSALTRONX3ABI, UNIVERSALTRONX3ADDRESS } from "../contracts/Universalmlmx3";
import { TestCreateX3userSaveHooks } from "./TestBackend";
import { UseWeb3 } from "./UseAccount";
import { BuyNewLevelSaveHooks, CreateUserSaveHooks, Getpartnercounthooks, GetUserHooks } from "./usebackend";
import { CreateX3userSaveHooks } from "./usebackendX3";
import * as Dispatch from "./UseDispatch";
import { usersDetails } from "./UseWallet";
// const navigate = useNavigate();

const tronWeb = new TronWeb({
  fullHost:   "https://nile.trongrid.io" //RPC                     
});
tronWeb.setAddress("TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t");
export const getBalance = async (address) => {
  try {
    address = tronWeb.address.fromHex(address);
    console.log("address" , address);
    const Balance = await tronWeb.trx.getBalance(address);
    console.log("Get balance" , Balance);
    return Balance;
  } catch (err) {
    console.log(err, "err_getBalance");
  }
};

export const Extregistration = async (address) => {
  try {
    //  console.log(UniversalMLMContractABI[0],"UniversalMLMContractABIUniversalMLMContractABI")
    console.log("Address : ", address);
    var contract = await tronWeb.contract(
      UniversalMLMContractABI,
      UniversalMLMcontractAddress
    );
    console.log("Contract  :", contract);
    address = tronWeb.address.toHex(address);
    let transaction = await contract.registrationExt(address).send({
      feeLimit: 3000000000,
    shouldPollResponse:true
  }).then((trans) => {
    return {type : "Success" , data : trans}
  }).catch((e) => {
    return {type : "Failed" , data : e}
  });
    // console.log(transaction, "transactiontransaction");
    // return transaction;
  } catch (err) {
    console.log(err, "err_contract");
  }
};


//matrix x2
export const Getuseraddress = () => {
  return localStorage.getItem("address")?localStorage.getItem("address") : "";
}

export const UseuniversalMLM = async() => {
  try{
    const web3 = await UseWeb3();
    if(web3){
      var contract = await web3.contract(
        UniversalMLMContractABI,
        UniversalMLMcontractAddress
      );
      return contract;
    }
    else{
      var contract = await tronWeb.contract(
        UniversalMLMContractABI,
        UniversalMLMcontractAddress
      );
      return contract;
    }
    
  }
  catch(e){
    console.log("Error on useuniversalMLM" , e);
    var contract = await tronWeb.contract(
      UniversalMLMContractABI,
      UniversalMLMcontractAddress
    );
    return contract;
  }
}

export const GetuserX2matrix = async(address , dispatch) => {
  try{
    let contract = await UseuniversalMLM();
    let matrix = [];
    let levelstatusarr = [];
    let ids = []
    console.log("address" , address , contract?.methods , dispatch);
    for(var i=1 ; i<=6 ; i++){
      let matrixx2 = await contract.methods.usersX2Matrix(address.toString() , i).call();
      matrix.push(matrixx2)
      let levelstatus = await contract.methods.usersActiveX2Levels(address.toString() , i).call();
      let levelprice = await contract.methods.levelPrice(i).call();
      console.log("Checking" , matrixx2);
      let matrixx2referer = await Getreferalsid(matrixx2[1]);
      let userprofit = (parseFloat(levelprice)/100)*70;
      var refpercent = (parseFloat(levelprice)/100)*13;
      var eligible60 =(parseFloat(levelprice)/100)*60;
      var referals = []

      console.log("Before calculation" , refpercent , eligible60);
      // if(matrixx2[5]?.toString() <= 0){
        let nousrforref = matrixx2[1]?.length + (matrixx2[3].toString()*2);
        let nousrforeligible60 = (matrixx2[1]?.length + (matrixx2[3].toString()*2)) - matrixx2[5].toString();
        refpercent = refpercent * nousrforref;
        eligible60 = eligible60 * nousrforeligible60
      // let payload = {
      //   "level":i,
      //   "address":Getuseraddress()
      // }
      // let partnercount = await Getpartnercounthooks(payload);
      // console.log("partnercount in hooks" , partnercount);
      for(var j =1 ; j<= parseFloat(matrixx2[3].toString());j++){
        let level = await contract?.methods?.usersCycleX2Matrix(address , i , j).call();
        console.log("levels" , level );
        for (var k = 0; k < level[1]?.length; k++) {
          let usr = await usersDetails(level[1][k]);
          let appnd = {...usr , ["type"] : "X2"}
          referals.push(appnd);
          // adress.push(level[1][k]);
          console.log("i, j , k" ,  i, j , k);
        }
      }

      let obj = {
        status : levelstatus,
        price : levelprice,
        referal : matrixx2referer,
        profit : userprofit*parseFloat(matrixx2[4].toString()),
        levelprofit : ((refpercent + eligible60)/10**6) > 0 ? (refpercent + eligible60)/10**6 : 0,
        referals : referals
        // partnercount : partnercount?.data?.count
      }
      levelstatusarr.push(obj);
    }
    console.log("matrix" , matrix , levelstatusarr);
    let rdata = {matrix : matrix , levelstatus : levelstatusarr}
    // MATRIXX2LEVEL
    await Dispatch.SetMatrixx2level(
      dispatch,
      rdata
    );
    return {matrix : matrix , levelstatus : levelstatusarr};
  }
  catch(e){
    console.log("Error on get userx2matrix" , e);
    return {matrix : [] , levelstatus : []};
  }
}

export const Getreferalsid = async(address) => {
  try{
    console.log("AddressGetreferalsid" , address);
    let array = []
    for(var i = 0; i< address?.length ; i++){
       let usr =  await usersDetails(address[i]);
       array.push(usr?.id);
    }
    return array;
  }
  catch(e){
    console.log("Error on get referid" , e);
  }
}

export const Getpartnersview = async(data) => {
  try{
    for(let i =0 ; i<data?.length ; i++){
      let usr = await usersDetails(data[i]?.userAddress);
      data[i].userdetail = usr;
    }
    return data;
  }
  catch(e){
    console.log("Error on get partners view" , e);
  }
}

export const Buynewlevel = async(matrix , level) => {
  try{
    console.log("buy new level" , matrix , level);
    let contract = await UseuniversalMLM();
    let price = await contract.methods.levelPrice(level).call();
    const options = {
      callValue: price, 
      feeLimit: 3_000_000_000, // Replace with the desired fee limit
    };
    let data = await contract.methods.buyNewLevel(matrix , level).send(options)
    // .then((data) => {
    //   return {type : "Success" , data : data};
    // }).catch((E) => {
    //   return {type : "Failed" , data : E};
    // });
    return {type : "Success" , data : data};
    console.log("buy successfully");
  }
  catch(e){
    console.log("Error on buynewlevel" , e);
    return {type : "Failed" , data : e};
  }
}

export const Buynewlevelfor = async(address , matrix , level) => {
  try{
    let contract = await UseuniversalMLM();
    let buy = await contract.methods.buyNewLevelFor(address , matrix , level).send();
    return buy;
  }
  catch(e){
    console.log("Error on buy new levelfor" , e);
  }
}

export const SearchuserX2level = async(id) => {
  try{
    let contract = await UseuniversalMLM();
    let address = await contract.methods.userIds(id).call();
    console.log("Id to address" , address);
    let matrix = [];
    let levelstatusarr = [];
    let ids = []
    console.log("address" , address , contract.methods);
    for(var i=1 ; i<=6 ; i++){
      let matrixx2 = await contract.methods.usersX2Matrix(address.toString() , i).call();
      matrix.push(matrixx2)
      let levelstatus = await contract.methods.usersActiveX2Levels(address.toString() , i).call();
      let levelprice = await contract.methods.levelPrice(i).call();
      console.log("Checking" , matrixx2);
      let matrixx2referer = await Getreferalsid(matrixx2[1]);
      let userprofit = (parseFloat(levelprice)/100)*70;
      var refpercent = (parseFloat(levelprice)/100)*13;
      var eligible60 =(parseFloat(levelprice)/100)*60;
      console.log("Before calculation" , refpercent , eligible60);
      // if(matrixx2[5]?.toString() <= 0){
        let nousrforref = matrixx2[1]?.length + (matrixx2[3].toString()*2);
        let nousrforeligible60 = (matrixx2[1]?.length + (matrixx2[3].toString()*2)) - matrixx2[5].toString();
        refpercent = refpercent * nousrforref;
        eligible60 = eligible60 * nousrforeligible60
      // let payload = {
      //   "level":i,
      //   "address":Getuseraddress()
      // }
      // let partnercount = await Getpartnercounthooks(payload);
      // console.log("partnercount in hooks" , partnercount);
      console.log("After calculation" , refpercent , eligible60);

      let obj = {
        status : levelstatus,
        price : levelprice,
        referal : matrixx2referer,
        profit : userprofit*parseFloat(matrixx2[4].toString()),
        levelprofit : ((refpercent + eligible60)/10**6)>0 ? (refpercent + eligible60)/10**6 : 0
        // partnercount : partnercount?.data?.count
      }
      
      levelstatusarr.push(obj);
    }
    return {matrix : matrix , levelstatus : levelstatusarr};
  }
  catch(e){
    console.log("Error on search user x2 matrix" , e);
  }
}

export const Getidtoaddress = async(id) => {
  try{
    let contract = await UseuniversalMLM();
    let address = await contract.methods.userIds(id?.toString()).call();
    return address;
  }
  catch(e){
    console.log("Error on get id to address" , e);
  }
}

export const Userreferlist = async(address) =>{
  try{
    var referals = [];
    var adress = [];
    let contract = await UseuniversalMLM();
    for(var i =1 ; i<=6 ; i++){
      let x2matrix = await contract?.methods?. usersX2Matrix(address , i).call();
      console.log("levels in x2" , x2matrix , parseFloat(x2matrix[3].toString()));
      for(var j =1 ; j<= parseFloat(x2matrix[3].toString());j++){
        let level = await contract?.methods?.usersCycleX2Matrix(address , i , j).call();
        console.log("levels" , level );
        for (var k = 0; k < level[1]?.length; k++) {
          let usr = await usersDetails(level[1][k]);
          let appnd = {...usr , ["type"] : "X2"}
          referals.push(appnd);
          adress.push(level[1][k]);
          console.log("i, j , k" ,  i, j , k);
        }
      }
    }
    return {referal : referals , address : adress};
  }
  catch(e){
    console.log("Error on user referlist" , e);
    return [];
  }
}

export const GetuserX2matrixplan2 = async(address , dispatch) => {
  try{
    let contract = await UseuniversalMLM();
    let matrix = [];
    let levelstatusarr = [];
    let ids = []
    console.log("address" , address , contract?.methods , dispatch);
    for(var i=7 ; i<=12 ; i++){
      let matrixx2 = await contract.methods.usersX2Matrix(address.toString() , i).call();
      matrix.push(matrixx2)
      let levelstatus = await contract.methods.usersActiveX2Levels(address.toString() , i).call();
      let levelprice = await contract.methods.levelPrice(i).call();
      console.log("Checking" , matrixx2);
      let matrixx2referer = await Getreferalsid(matrixx2[1]);
      let userprofit = (parseFloat(levelprice)/100)*70;
      var refpercent = (parseFloat(levelprice)/100)*13;
      var eligible60 =(parseFloat(levelprice)/100)*60;
      console.log("Before calculation" , refpercent , eligible60);
      // if(matrixx2[5]?.toString() <= 0){
        let nousrforref = matrixx2[1]?.length + (matrixx2[3].toString()*2);
        let nousrforeligible60 = (matrixx2[1]?.length + (matrixx2[3].toString()*2)) - matrixx2[5].toString();
        refpercent = refpercent * nousrforref;
        eligible60 = eligible60 * nousrforeligible60
        console.log("After calculation" , refpercent , eligible60);
      // }
      // let payload = {
      //   "level":i,
      //   "address":Getuseraddress()
      // }
      // let partnercount = await Getpartnercounthooks(payload);
      // console.log("partnercount in hooks" , partnercount);

      let obj = {
        status : levelstatus,
        price : levelprice,
        referal : matrixx2referer,
        profit : userprofit*parseFloat(matrixx2[4].toString()),
        // partnercount : partnercount?.data?.count
        levelprofit : ((refpercent + eligible60)/10**6)>0 ? (refpercent + eligible60)/10**6 : 0
      }
      
      levelstatusarr.push(obj);
    }
    console.log("matrix" , matrix , levelstatusarr);
    let rdata = {matrix : matrix , levelstatus : levelstatusarr}
    // MATRIXX2LEVEL
    // await Dispatch.SetMatrixx2level(
    //   dispatch,
    //   rdata
    // );
    return {matrix : matrix , levelstatus : levelstatusarr};
  }
  catch(e){
    console.log("Error on get userx2matrix" , e);
    return {matrix : [] , levelstatus : []};
  }
}

export const SearchuserX2levelplan2 = async(id) => {
  try{
    let contract = await UseuniversalMLM();
    let address = await contract.methods.userIds(id).call();
    console.log("Id to address" , address);
    let matrix = [];
    let levelstatusarr = [];
    let ids = []
    console.log("address" , address , contract.methods);
    for(var i=7 ; i<=12 ; i++){
      let matrixx2 = await contract.methods.usersX2Matrix(address.toString() , i).call();
      matrix.push(matrixx2)
      let levelstatus = await contract.methods.usersActiveX2Levels(address.toString() , i).call();
      let levelprice = await contract.methods.levelPrice(i).call();
      console.log("Checking" , matrixx2);
      let matrixx2referer = await Getreferalsid(matrixx2[1]);
      let userprofit = (parseFloat(levelprice)/100)*70;
      var refpercent = (parseFloat(levelprice)/100)*13;
      var eligible60 =(parseFloat(levelprice)/100)*60;
      console.log("Before calculation" , refpercent , eligible60);
      // if(matrixx2[5]?.toString() <= 0){
        let nousrforref = matrixx2[1]?.length + (matrixx2[3].toString()*2);
        let nousrforeligible60 = (matrixx2[1]?.length + (matrixx2[3].toString()*2)) - matrixx2[5].toString();
        refpercent = refpercent * nousrforref;
        eligible60 = eligible60 * nousrforeligible60
        console.log("After calculation" , refpercent , eligible60);
      // }
      // let payload = {
      //   "level":i,
      //   "address":Getuseraddress()
      // }
      // let partnercount = await Getpartnercounthooks(payload);
      // console.log("partnercount in hooks" , partnercount);

      let obj = {
        status : levelstatus,
        price : levelprice,
        referal : matrixx2referer,
        profit : userprofit*parseFloat(matrixx2[4].toString()),
        // partnercount : partnercount?.data?.count
        levelprofit : ((refpercent + eligible60)/10**6) > 0 ? (refpercent + eligible60)/10**6 : 0
      }
      
      levelstatusarr.push(obj);
    }
    return {matrix : matrix , levelstatus : levelstatusarr};
  }
  catch(e){
    console.log("Error on search user x2 matrix" , e);
  }
}

//x3

export const UseuniversalMLMX3 = async() => {
  try{
    const web3 = await UseWeb3();
    if(web3){
      var contract = await web3.contract(
        UNIVERSALTRONX3ABI,
        UNIVERSALTRONX3ADDRESS
      );
      return contract;
    }
    else{
      var contract = await tronWeb.contract(
        UNIVERSALTRONX3ABI,
        UNIVERSALTRONX3ADDRESS
      );
      return contract;
    }
  }
  catch(e){
    console.log("Error on useuniversalMLM" , e);
    var contract = await tronWeb.contract(
      UNIVERSALTRONX3ABI,
      UNIVERSALTRONX3ADDRESS
    );
    return contract;
  }
}

export const ExtregistrationX3 = async (address) => {
  try {
    //console.log(UniversalMLMContractABI[0],"UniversalMLMContractABIUniversalMLMContractABI")
    console.log("Address : ", address);
    var contract = await UseuniversalMLMX3();
    var x2contract = await UseuniversalMLM();
    let status = await x2contract.methods.usersActiveX2Levels(address.toString() , 3).call();
    let user = await usersDetails(address?.toString());
    console.log("Contract  :", contract , status);
    if(status == true){
      address = await contract.methods.userIds(1).call(); //tronWeb.address.toHex(address);
      console.log("address in ext regeist" , address);
      let transaction = await contract.methods.registrationExt(user?.referrer?.toString()).send({
        feeLimit: 3_000_000_000,
        callValue: 0
      })
      // .then((data)=>{
      //   return {type : "Success" , data : data}
      // }).catch((e)=> {
      //   return {type : "Failed" , data : ""}
      // });
    console.log(transaction, "transactiontransaction");
    return {type : "Success" , data : transaction}
    }
    else{
      toast.error("please complete x2 level 3!")
    }
  } catch (err) {
    return {type : "Failed" , data : ""}
    console.log(err, "err_contract");
  }
};

// export const BuynewlevelX3 = async(matrix , level) => {
//   try{
//     let contract = await UseuniversalMLMX3();
//     let buy = await contract.methods.buyNewLevel(matrix , level).send();
//     console.log("buy successfully");
//     return buy;
//   }
//   catch(e){
//     console.log("Error on buynewlevel" , e);
//   }
// }

// export const BuynewlevelforX3 = async(address , matrix , level) => {
//   try{
//     let contract = await UseuniversalMLMX3();
//     let buy = await contract.methods.buyNewLevelFor(address , matrix , level).send();
//     return buy;
//   }
//   catch(e){
//     console.log("Error on buy new levelfor" , e);
//   }
// }

export const GetuserX3matrixplan1 = async(address , dispatch) => {
  try{
    let contract = await UseuniversalMLMX3();
    let matrix = [];
    let levelstatusarr = [];
    let ids = []
    console.log("address in matrixx3" , address , contract?.methods , dispatch);
    for(var i=1 ; i<=1 ; i++){
      let matrixx2 = await contract.methods.usersX3Matrix(address.toString() , i).call({from : address});
      // let matrixx21 = await contract.methods.usersX3Matrix(address.toString() , i+1).call({from : address});
      matrix.push(matrixx2)
      matrix.push(matrixx2)
      console.log("matrixx3 in" , matrixx2);
      let levelstatus = await contract.methods.usersActiveX3Levels(address.toString() , i).call();
      let levelprice = await contract.methods.x3levelPrice(i).call();
      let levelstatus1 = await contract.methods.usersActiveX3Levels(address.toString() , i+1).call();
      let levelprice1 = await contract.methods.x3levelPrice(i+1).call();
      console.log("Checking" , matrixx2);
      let matrixx2refererl1 = await GetreferalsidX3(matrixx2[1]);
      let matrixx2refererl2 = await GetreferalsidX3(matrixx2[2]);
      let userprofit = (parseFloat(levelprice)/100)*70;

      var refpercent = 0;
      // var eligible60 =0;
      var refpercent1 = 0;
      // var eligible60 =0;
      if(matrixx2[1]?.length > 2){
        refpercent = levelprice;
      }
      if(matrixx2[2]?.length > 4){
        refpercent1 = levelprice*5;
      }
      // console.log("Before calculation" , refpercent , eligible60);
      // if(matrixx2[5]?.toString() <= 0){
      // let payload = {
      //   "level":i,
      //   "address":Getuseraddress()
      // }
      // let partnercount = await Getpartnercounthooks(payload);
      // console.log("partnercount in hooks" , partnercount);

      let obj = {
        status : levelstatus,
        price : levelprice,
        referal : matrixx2refererl1,
        // referal : matrixx2refererl2,
        profit : userprofit*parseFloat(matrixx2[4].toString()),
        levelprofit : refpercent/10**6
        // partnercount : partnercount?.data?.count
      }
      let obj2 = {
        status : levelstatus1,
        price : levelprice1,
        // referal : matrixx2refererl1,
        referal : matrixx2refererl2,
        profit : userprofit*parseFloat(matrixx2[4].toString()),
        // partnercount : partnercount?.data?.count
        levelprofit : refpercent1/10**6
      }
      levelstatusarr.push(obj);
      levelstatusarr.push(obj2);
    }
    console.log("matrix" , matrix , levelstatusarr);
   
    return {matrix : matrix , levelstatus : levelstatusarr};
  }
  catch(e){
    console.log("Error on get user x3 matrix" , e);
    return {matrix : [] , levelstatus : []};
  }
}

export const GetuserX3matrixplan2 = async(address , dispatch) => {
  try{
    let contract = await UseuniversalMLMX3();
    let matrix = [];
    let levelstatusarr = [];
    let ids = []
    console.log("address in matrixx3" , address , contract?.methods , dispatch);
    for(var i=2 ; i<=2 ; i++){
      let matrixx2 = await contract.methods.usersX3Matrix(address.toString() , i).call({from : address});
      matrix.push(matrixx2)
      matrix.push(matrixx2)
      console.log("matrixx3 in" , matrixx2);
      let levelstatus = await contract.methods.usersActiveX3Levels(address.toString() , i).call();
      let levelprice = await contract.methods.x3levelPrice(3).call();
      let levelstatus1 = await contract.methods.usersActiveX3Levels(address.toString() , i+1).call();
      let levelprice1 = await contract.methods.x3levelPrice(4).call();
      console.log("Checking" , matrixx2);
      let matrixx2refererl1 = await GetreferalsidX3(matrixx2[1]);
      let matrixx2refererl2 = await GetreferalsidX3(matrixx2[2]);
      let userprofit = (parseFloat(levelprice)/100)*70;

      var refpercent = 0;
      // var eligible60 =0;
      var refpercent1 = 0;
      // var eligible60 =0;
      if(matrixx2[1]?.length > 2){
        refpercent = levelprice;
      }
      if(matrixx2[2]?.length > 4){
        refpercent1 = levelprice*5;
      }
      // let payload = {
      //   "level":i,
      //   "address":Getuseraddress()
      // }
      // let partnercount = await Getpartnercounthooks(payload);
      // console.log("partnercount in hooks" , partnercount);

      let obj = {
        status : levelstatus,
        price : levelprice,
        referal : matrixx2refererl1,
        // referal : matrixx2refererl2,
        profit : userprofit*parseFloat(matrixx2[4].toString()),
        levelprofit : refpercent/10**6
        // partnercount : partnercount?.data?.count
      }
      let obj2 = {
        status : levelstatus1,
        price : levelprice1,
        // referal : matrixx2refererl1,
        referal : matrixx2refererl2,
        profit : userprofit*parseFloat(matrixx2[4].toString()),
        levelprofit : refpercent1/10**6
        // partnercount : partnercount?.data?.count
      }
      levelstatusarr.push(obj);
      levelstatusarr.push(obj2);
    }
    console.log("matrix" , matrix , levelstatusarr);
   
    return {matrix : matrix , levelstatus : levelstatusarr};
  }
  catch(e){
    console.log("Error on get user x3 matrix" , e);
    return {matrix : [] , levelstatus : []};
  }
}

export const GetuserX3matrixplan3 = async(address , dispatch) => {
  try{
    let contract = await UseuniversalMLMX3();
    let matrix = [];
    let levelstatusarr = [];
    let ids = []
    console.log("address in matrixx3" , address , contract?.methods , dispatch);
    for(var i=3 ; i<=3 ; i++){
      let matrixx2 = await contract.methods.usersX3Matrix(address.toString() , i).call({from : address});
      matrix.push(matrixx2)
      matrix.push(matrixx2)
      console.log("matrixx3 in" , matrixx2);
      let levelstatus = await contract.methods.usersActiveX3Levels(address.toString() , i).call();
      let levelprice = await contract.methods.x3levelPrice(5).call();
      let levelstatus1 = await contract.methods.usersActiveX3Levels(address.toString() , i+1).call();
      let levelprice1 = await contract.methods.x3levelPrice(6).call();
      console.log("Checking" , matrixx2);
      let matrixx2refererl1 = await GetreferalsidX3(matrixx2[1]);
      let matrixx2refererl2 = await GetreferalsidX3(matrixx2[2]);
      let userprofit = (parseFloat(levelprice)/100)*70;

      var refpercent = 0;
      // var eligible60 =0;
      var refpercent1 = 0;
      // var eligible60 =0;
      if(matrixx2[1]?.length > 2){
        refpercent = levelprice;
      }
      if(matrixx2[2]?.length > 4){
        refpercent1 = levelprice*5;
      }
      // let payload = {
      //   "level":i,
      //   "address":Getuseraddress()
      // }
      // let partnercount = await Getpartnercounthooks(payload);
      // console.log("partnercount in hooks" , partnercount);

      let obj = {
        status : levelstatus,
        price : levelprice,
        referal : matrixx2refererl1,
        // referal : matrixx2refererl2,
        profit : userprofit*parseFloat(matrixx2[4].toString()),
        levelprofit : refpercent/10**6
        // partnercount : partnercount?.data?.count
      }
      let obj2 = {
        status : levelstatus1,
        price : levelprice1,
        // referal : matrixx2refererl1,
        referal : matrixx2refererl2,
        profit : userprofit*parseFloat(matrixx2[4].toString()),
        levelprofit : refpercent1/10**6
        // partnercount : partnercount?.data?.count
      }
      levelstatusarr.push(obj);
      levelstatusarr.push(obj2);
    }
    console.log("matrix" , matrix , levelstatusarr);
   
    return {matrix : matrix , levelstatus : levelstatusarr};
  }
  catch(e){
    console.log("Error on get user x3 matrix" , e);
    return {matrix : [] , levelstatus : []};
  }
}

export const GetuserX3matrixplan4 = async(address , dispatch) => {
  try{
    let contract = await UseuniversalMLMX3();
    let matrix = [];
    let levelstatusarr = [];
    let ids = []
    console.log("address in matrixx3" , address , contract?.methods , dispatch);
    for(var i=4 ; i<=4 ; i++){
      let matrixx2 = await contract.methods.usersX3Matrix(address.toString() , i).call({from : address});
      matrix.push(matrixx2)
      matrix.push(matrixx2)
      console.log("matrixx3 in" , matrixx2);
      let levelstatus = await contract.methods.usersActiveX3Levels(address.toString() , i).call();
      let levelprice = await contract.methods.x3levelPrice(7).call();
      let levelstatus1 = await contract.methods.usersActiveX3Levels(address.toString() , i+1).call();
      let levelprice1 = await contract.methods.x3levelPrice(8).call();
      console.log("Checking" , matrixx2);
      let matrixx2refererl1 = await GetreferalsidX3(matrixx2[1]);
      let matrixx2refererl2 = await GetreferalsidX3(matrixx2[2]);
      let userprofit = (parseFloat(levelprice)/100)*70;

      var refpercent = 0;
      // var eligible60 =0;
      var refpercent1 = 0;
      // var eligible60 =0;
      if(matrixx2[1]?.length > 2){
        refpercent = levelprice;
      }
      if(matrixx2[2]?.length > 4){
        refpercent1 = levelprice*5;
      }
      // let payload = {
      //   "level":i,
      //   "address":Getuseraddress()
      // }
      // let partnercount = await Getpartnercounthooks(payload);
      // console.log("partnercount in hooks" , partnercount);

      let obj = {
        status : levelstatus,
        price : levelprice,
        referal : matrixx2refererl1,
        // referal : matrixx2refererl2,
        profit : userprofit*parseFloat(matrixx2[4].toString()),
        levelprofit : refpercent/10**6
        // partnercount : partnercount?.data?.count
      }
      let obj2 = {
        status : levelstatus1,
        price : levelprice1,
        // referal : matrixx2refererl1,
        referal : matrixx2refererl2,
        profit : userprofit*parseFloat(matrixx2[4].toString()),
        levelprofit : refpercent1/10**6
        // partnercount : partnercount?.data?.count
      }
      levelstatusarr.push(obj);
      levelstatusarr.push(obj2);
    }
    console.log("matrix" , matrix , levelstatusarr);
   
    return {matrix : matrix , levelstatus : levelstatusarr};
  }
  catch(e){
    console.log("Error on get user x3 matrix" , e);
    return {matrix : [] , levelstatus : []};
  }
}

export const GetuserdetailX3 = async(address) => {
  try{
    const web3 = await UseWeb3();
    let contract = await UseuniversalMLMX3();
    let userDetail = await contract.users(address).call();
    // console.log(userDetail,"userDetailuserDetail")
    let userDetailss = {
      id: web3.toDecimal(userDetail[0]._hex),
      referrer: web3.address.fromHex(userDetail[1]),
      partnersCount: web3.toDecimal(userDetail[2]._hex),
      balance: web3.toDecimal(userDetail[3]._hex),
    };
    console.log(userDetailss, "userDetailuserDetail");
    return userDetailss;
  }
  catch(e){
    console.log("Error on get user detailx3" , e);
  }
}

export const GetreferalsidX3 = async(address) => {
  try{
    console.log("AddressGetreferalsid" , address);
    let array = []
    for(var i = 0; i< address?.length ; i++){
       let usr =  await GetuserdetailX3(address[i]);
       array.push(usr?.id);
    }
    return array;
  }
  catch(e){
    console.log("Error on get referid" , e);
  }
}

export const GetTransactionX2 = async(address) => {
  try{
    let contract = await UseuniversalMLM();
    let contractx3 = await UseuniversalMLMX3();
    var x2trans = [];
    var x3trans = []
    let x2transaction = await contract?.methods?.getUserTxs(address).call();
    
    x2transaction?.map((data) => {
      data = {...data , ["type"] : "X2"}
      x2trans.push(data);
    })
    let x3transaction = await contractx3?.methods?.getUserTxs(address).call();
    console.log("x3transaction" , x3transaction);
    x3transaction?.map((data) => {
      data = {...data , ["type"] : "X3"}
      x3trans.push(data);
    })
    let transaction = x2trans.concat(x3trans);
    return transaction;
  }
  catch(e){
    console.log("Error on get transaction" , e);
  }
}

// export const GetTransactionX3 = async(address) => {
//   try{
//     let contract = await UseuniversalMLM();
//     let contractx3 = await UseuniversalMLMX3();
//     let x2transaction = await contract?.methods?.getUserTxs(address).call();
//     let x3transaction = await contractx3?.methods?.getUserTxs(address).call();
//     let transaction = x2transaction.concat(x3transaction);
//     return transaction;
//   }
//   catch(e){
//     console.log("Error on get transaction" , e);
//   }
// }

export const GetPartnerX2 = async(address) => {
  try{
    let contract = await UseuniversalMLM();
    let contractx3 = await UseuniversalMLMX3();
    var users = []
    let partners = await contract?.methods?. getUserPartners(address).call();
    let partnersx3 = await contractx3?.methods?.getUserPartners(address).call();
   for(var i = 0 ; i < partners?.length ; i ++) {
      let usr =  await usersDetails(partners[i]);
      usr = {...usr , ["type"] : "X2"}
      users.push(usr);
    }
    for(var i = 0 ; i < partnersx3?.length ; i ++) {
      let usr =  await usersDetails(partnersx3[i]);
      usr = {...usr , ["type"] : "X3"}
      users.push(usr);
    }
    // return partners;
    return {partners : users , partnersadd : partners?.concat(partnersx3)};
  }
  catch(e){
    console.log("Error on get transaction" , e);
  }
}

export const GetPartnerX3 = async(address) => {
  try{
    let contract = await UseuniversalMLMX3();
    var users = []
    let partners = await contract?.methods?. getUserPartners(address).call();
   for(var i = 0 ; i < partners?.length ; i ++) {
      let usr =  await usersDetails(partners[i]);
      users.push(usr);
    }
    // return partners;
    return {partners : users , partnersadd : partners};
  }
  catch(e){
    console.log("Error on get transaction" , e);
  }
}

export const Convertaddress = (address) => {
  try{
    var newaddress = ""
    if(window?.tronWeb){
      newaddress =tronWeb.address.fromHex(address);
    }
    else{
      newaddress = tronWeb.address.fromHex(address);
    }
    console.log("Address in convert" , newaddress);
    return newaddress;
  }
  catch(E){
    console.log("Error on convert address" , E);
  }
}

export const GetX3levelstatus = async(address) => {
  try{
    let contract = await UseuniversalMLMX3();
    var status = [];
    for(var i = 1 ; i<= 8 ; i++){
      let levelstatus = await contract.methods.usersActiveX3Levels(address.toString() , i).call();
      status.push(levelstatus);
    }
    return status;
  }
  catch(e){
    console.log("error on getx3levelstatus" , e);
  }
}

export const UserreferlistX3 = async(address) =>{
  try{
    var referals = [];
    var adress = [];
    let contract = await UseuniversalMLMX3();
    for(var i =1 ; i<=6 ; i++){
      let x2matrix = await contract?.methods?.usersX3Matrix(address , i).call();
      console.log("levels in x2" , x2matrix , parseFloat(x2matrix[3].toString()));
      for(var j =1 ; j<= parseFloat(x2matrix[3].toString());j++){
        let level = await contract?.methods?.usersCycleX2Matrix(address , i , j).call();
        console.log("levels" , level );
        for (var k = 0; k < level[1]?.length; k++) {
          let usr = await GetuserdetailX3(level[1][k]);
          let appnd = {...usr , ["type"] : "X3"}
          referals.push(appnd);
          adress.push(level[1][k]);
          console.log("i, j , k" ,  i, j , k);
        }

        for (var l = 0; l < level[2]?.length; l++) {
          let usr = await GetuserdetailX3(level[1][l]);
          let appnd = {...usr , ["type"] : "X3"}
          referals.push(appnd);
          adress.push(level[1][l]);
          console.log("i, j , k" ,  i, j , l);
        }
      }
    }
    return {referal : referals , address : adress};
  }
  catch(e){
    console.log("Error on user referlist" , e);
    return [];
  }
}

export const GetRecenttransaction = async(address) => {
  try{
    // let datRegistrationa = await axios.get("https://api.trongrid.io/v1/contracts/${contractAddress}/events?block_number=${blockNumber}&only_confirmed=true");
    const web3 = await UseWeb3();
    if(web3){
      var getEventResult = [];
      var data = await web3.getEventResult(address, {
        eventName: "NewUserPlace",
      });
      if(data?.length > 3){
        data = data.slice(0 , 3);
      }
      for(var i =0 ; i< data?.length ; i++){
        let singlsobj = data[i]
        let address = tronWeb.address.fromHex(singlsobj?.result?.user);
        let usr = await GetUserHooks({walletAddress : address});
        singlsobj = {...singlsobj , ["user"] : usr?.data?.record}
        getEventResult.push(singlsobj);
      }
     
      var eventregister = await web3.getEventResult(UniversalMLMcontractAddress, {
        eventName: "Registration",
      });
      if(eventregister?.length > 3){
        eventregister = eventregister.slice(0 , 3);
      }
      for(var i =0 ; i< eventregister?.length ; i++){
        let singlsobj = eventregister[i]
        let address = tronWeb.address.fromHex(singlsobj?.result?.user);
        let usr = await GetUserHooks({walletAddress : address});
        singlsobj = {...singlsobj , ["user"] : usr?.data?.record}
        eventregister[i] = singlsobj;
      }
      console.log("event result" , getEventResult , eventregister);
      var result = getEventResult.concat(eventregister);
      return result;
    }
    else{
      let getEventResult = await tronWeb.getEventResult(address, {
        eventName: "NewUserPlace",
      });
      let eventregister = await tronWeb.getEventResult(UniversalMLMcontractAddress, {
        eventName: "Registration",
      })
      let result = getEventResult.concat(eventregister);
      return result;
    }
  }
  catch(e){
    console.log("Error on get recent transaction" , e);
  }
}

export const GetlevelpriceX2 = async(level , matrix) => {
  try{
    let contractX2 = await UseuniversalMLM();
    let price = await contractX2.methods.levelPrice(level).call();
    return price/10**6
  }
  catch(e){
    console.log("error on get level price" , e);
  }
}


// const GetlastuserX2 = async() => {
//   try{}
//   catch(e){
//     console.log("error on get lastuserX2" , e);
//   }
// }

export const ClaimReward = async(address , amount , plan) => {
  try{
    console.log("Claim reward in user contract" , address , amount , plan);
    let contractX2 = await UseuniversalMLM();
    let contractX3 = await UseuniversalMLMX3();
    if(plan == "X2"){
      let claimx2 = await contractX2?.methods?.claim(address , amount).send({
        callValue: amount, 
        feeLimit: 3_000_000_000,
      });
    }
    if(plan == "X3"){
      let claimx2 = await contractX3?.methods?.claim(address , amount).send({
        callValue: amount, 
        feeLimit: 3_000_000_000,
      });
    }
  }
  catch(e){
    console.log("Error on claim reward" , e);
  }
}

export const DepositReward = async(address , amount , plan) => {
  try{
    console.log("DEposit reward" , address , amount , plan , plan == "X2");
    let contractX2 = await UseuniversalMLM();
    let contractX3 = await UseuniversalMLMX3();
    if(plan == "X2"){
      let claimx2 = await contractX2?.methods?.deposit(address , amount).send({
        callValue: (amount), 
        feeLimit: 3_000_000_000,
      });
      return claimx2;
    }
    if(plan == "X3"){
      let claimx3 = await contractX3?.methods?.deposit(address , amount).send({
        callValue: (amount), 
        feeLimit: 3_000_000_000,
      });
      return claimx3;
    }
  }
  catch(e){
    console.log("Error on claim reward" , e);
  }
}

export const Checkuserexist = async(address) => {
  try{
    let contract = await UseuniversalMLM();
    let check = await contract?.methods?.isUserExists(address).call();
    console.log("checking" , check);
    return check;
  }
  catch(e){
    console.log("check user exist" , e);
    return false;
  }
}

export const Buynewleveldirectreferal = async(matrix , level) => {
  try{
    
    let contract = await UseuniversalMLM();
    let price = await contract.methods.levelPrice(matrix , level).call();
    const options = {
      callValue: price, 
      feeLimit: 3_000_000_000, // Replace with the desired fee limit
    };
    let status = await contract.methods.usersActiveX2Levels(Getuseraddress() , 7).call();
    if(status == true){
      let data = await contract.methods.buyNewLevel(matrix , level).send(options)
      return {type : "Success" , data : data};
    }else{
      toast.error("please complete x2 level 6!")
    }
    // .then((data) => {
    //   return {type : "Success" , data : data};
    // }).catch((E) => {
    //   return {type : "Failed" , data : E};
    // });
    console.log("buy successfully");
  }
  catch(e){
    console.log("Error on buynewlevel" , e);
    return {type : "Failed" , data : e};
  }
}


//X2 plan 3

export const GetuserX2matrixplan3 = async(address , dispatch) => {
  try{
    let contract = await UseuniversalMLM();
    let matrix = [];
    let levelstatusarr = [];
    let ids = []
    console.log("address" , address , contract?.methods , dispatch);
    for(var i=13 ; i<=18 ; i++){
      let matrixx2 = await contract.methods.usersX2Matrix(address.toString() , i).call();
      matrix.push(matrixx2)
      let levelstatus = await contract.methods.usersActiveX2Levels(address.toString() , i).call();
      let levelprice = await contract.methods.levelPrice(i).call();
      console.log("Checking" , matrixx2);
      let matrixx2referer = await Getreferalsid(matrixx2[1]);
      let userprofit = (parseFloat(levelprice)/100)*70;
      var refpercent = (parseFloat(levelprice)/100)*13;
      var eligible60 =(parseFloat(levelprice)/100)*60;
      console.log("Before calculation" , refpercent , eligible60);
      // if(matrixx2[5]?.toString() <= 0){
        let nousrforref = matrixx2[1]?.length + (matrixx2[3].toString()*2);
        let nousrforeligible60 = (matrixx2[1]?.length + (matrixx2[3].toString()*2)) - matrixx2[5].toString();
        refpercent = refpercent * nousrforref;
        eligible60 = eligible60 * nousrforeligible60
        console.log("After calculation" , refpercent , eligible60);
      // }
      // let payload = {
      //   "level":i,
      //   "address":Getuseraddress()
      // }
      // let partnercount = await Getpartnercounthooks(payload);
      // console.log("partnercount in hooks" , partnercount);

      let obj = {
        status : levelstatus,
        price : levelprice,
        referal : matrixx2referer,
        profit : userprofit*parseFloat(matrixx2[4].toString()),
        // partnercount : partnercount?.data?.count
        levelprofit : ((refpercent + eligible60)/10**6)>0 ? (refpercent + eligible60)/10**6 : 0
      }
      
      levelstatusarr.push(obj);
    }
    console.log("matrix" , matrix , levelstatusarr);
    let rdata = {matrix : matrix , levelstatus : levelstatusarr}
    // MATRIXX2LEVEL
    // await Dispatch.SetMatrixx2level(
    //   dispatch,
    //   rdata
    // );
    return {matrix : matrix , levelstatus : levelstatusarr};
  }
  catch(e){
    console.log("Error on get userx2matrix" , e);
    return {matrix : [] , levelstatus : []};
  }
}

export const SearchuserX2levelplan3 = async(id) => {
  try{
    let contract = await UseuniversalMLM();
    let address = await contract.methods.userIds(id).call();
    console.log("Id to address" , address);
    let matrix = [];
    let levelstatusarr = [];
    let ids = []
    console.log("address" , address , contract.methods);
    for(var i=13 ; i<=18 ; i++){
      let matrixx2 = await contract.methods.usersX2Matrix(address.toString() , i).call();
      matrix.push(matrixx2)
      let levelstatus = await contract.methods.usersActiveX2Levels(address.toString() , i).call();
      let levelprice = await contract.methods.levelPrice(i).call();
      console.log("Checking" , matrixx2);
      let matrixx2referer = await Getreferalsid(matrixx2[1]);
      let userprofit = (parseFloat(levelprice)/100)*70;
      var refpercent = (parseFloat(levelprice)/100)*13;
      var eligible60 =(parseFloat(levelprice)/100)*60;
      console.log("Before calculation" , refpercent , eligible60);
      // if(matrixx2[5]?.toString() <= 0){
        let nousrforref = matrixx2[1]?.length + (matrixx2[3].toString()*2);
        let nousrforeligible60 = (matrixx2[1]?.length + (matrixx2[3].toString()*2)) - matrixx2[5].toString();
        refpercent = refpercent * nousrforref;
        eligible60 = eligible60 * nousrforeligible60
        console.log("After calculation" , refpercent , eligible60);
      // }
      // let payload = {
      //   "level":i,
      //   "address":Getuseraddress()
      // }
      // let partnercount = await Getpartnercounthooks(payload);
      // console.log("partnercount in hooks" , partnercount);

      let obj = {
        status : levelstatus,
        price : levelprice,
        referal : matrixx2referer,
        profit : userprofit*parseFloat(matrixx2[4].toString()),
        // partnercount : partnercount?.data?.count
        levelprofit : ((refpercent + eligible60)/10**6) > 0 ? (refpercent + eligible60)/10**6 : 0
      }
      
      levelstatusarr.push(obj);
    }
    return {matrix : matrix , levelstatus : levelstatusarr};
  }
  catch(e){
    console.log("Error on search user x2 matrix" , e);
  }
}


//new scope

export const Checktransaction = async(data) => {
  var count = 0
  do {count++
    try {
      const tron = await UseWeb3();

      var transaction = await tron.trx?.getTransaction(data);
      console.log("Transactio;n" , transaction);
      if (transaction?.ret[0]?.contractRet == "SUCCESS") {
        return true
      }

      if(count == 15 && transaction?.ret[0]?.contractRet != "SUCCESS"){
        return false
      }
      // console.log("Transaction" , transaction);
      // if(transaction?.ret[0]?.contractRet == "SUCCESS"){
      //   return true;
      // }
      // else{
      //   return false;
      // }
    }
    catch (e) {
      console.log("Error on check transaction", e);
      if(count == 15 && transaction?.ret[0]?.contractRet != "SUCCESS"){
        return false
      }
    }
  } while (true)

}

export const RegisterX2 = async(address , amount , payload) => {
  try{
    const contract = await UseuniversalMLM();
    contract.methods.NewRegistration(address , amount).send({
      callValue: amount.reduce((ac, cu) => ac + cu,0,), 
      feeLimit: 3_000_000_000, // Replace with the desired fee limit
    }).then(async(register) => {
    // let validate =   await Checktransaction(register);
      payload.txHash =  register
      let checking = await Checktransaction(register);
      if(checking){
        CreateUserSaveHooks(payload).then((result) => {
          console.log("REsult in create user save hooks" , result , result?.data?.type == "Success" , register);
          if(result?.data?.type == "Success"){
            window.location.href = window.location.origin +"/dashboard"
          }
          else{
            toast.error(result?.data?.message);
            return {type : "Success" , data : register}
          }
         });
      }
      //  else{
      //   toast.error("")
      //  }
    });
  }
  catch(e){
    console.log("Error on registerX2" , e);
  }
}

export const UseBuyPlan1 = async(address , amount , payload) => {
  try{
    const contract = await UseuniversalMLM();
    const buyresult = await contract.methods.buyLevel(address , amount).send({
      callValue: amount.reduce((ac, cu) => ac + cu,0,), //amount[0], 
      feeLimit: 3_000_000_000, // Replace with the desired fee limit
    });
    payload.txHash =  buyresult
    let checking = await Checktransaction(buyresult);
    if(checking){
    let result = await BuyNewLevelSaveHooks(payload);
      console.log("result in buy new level save hooks" , result , result?.data?.type == "Success");
      if(result?.data?.type == "Success"){
        console.log("success");
        return {type : "Success" , data : result?.data}
      }
      else{
        return {type : "Failed" , data : result?.data}
      }
    }
  }
  catch(e){
    console.log("Error on use buyplan" , e);
    return {type : "Failed" , data : "Transaction Rejected."}
  }
}

export const RegisterX3 = async(payload) => {
  try{
    const contract = await UseuniversalMLM();
    let register = await contract.methods.buyLevel(payload?.address , payload?.amount).send({
      callValue: payload?.amount?.reduce((ac, cu) => ac + cu,0,), //amount[0], 
      feeLimit: 3_000_000_000, // Replace with the desired fee limit
    })
    // console.log("Registerx3" , register);
    payload.txHash =  register
    let checking = await Checktransaction(register);
    if(checking){
      let result = await CreateX3userSaveHooks(payload)
      // debugger
      // .then((result) => {
        console.log("REsult in create user save hooks" , result , result?.data?.type == "Success");
        if(result?.data?.type == "Success"){
          window.location.href = window.location.origin +"/dashboard"
        }
        else{
          return {type : "error" , data : result?.data?.message}
        }
    }
      
    // });
  }
  catch(e){
    console.log("Error on registerX2" , e);
    return {type : "error" , data : "Transaction Rejected."}
  }
}

export const Dummycreate = async(address , amount ) => {
  try{
    console.log("addresss" , address , amount);
    const contract = await UseuniversalMLM();
    let trans = await contract.methods.buyLevel(address , amount).send({
      callValue: 2000000, //amount[0], 
      feeLimit: 3_000_000_000, // Replace with the desired fee limit
    })
    console.log("trans" , trans);
  }
  catch(E){
    console.log("Error on dummy" , E);
  }
}

export const TestRegisterX3 = async(payload) => {
  try{
    let result = await TestCreateX3userSaveHooks(payload);
    return result;
  }
  catch(E){
    console.log("Error on Test Register" , E);
  }
}