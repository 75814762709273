import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { IoIosArrowRoundUp } from "react-icons/io";
import { FaArrowUp } from "react-icons/fa6";
import Modal from 'react-bootstrap/Modal';
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import userLogo from "../Assets/userNot.svg";
import info from "../Assets/info_logo.svg";
import plangrp from "../Assets/plan_grp.svg";
import lockIcon from "../Assets/lock_icon.svg";
import Coin from "../Assets/coin.svg";
import { FaUsers } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";
import cardIcon from "../Assets/card_logo.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Virtual,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Button } from "react-bootstrap";
import Lottie from "lottie-react";
import lock from "../Assets/Json/lockk.json";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import userGroup from "../Assets/user_group.svg";
import reload from "../Assets/reload.svg";
import {
  Convertaddress,
  Getidtoaddress,
  Getuseraddress,
  GetuserX3matrixplan1,
  GetuserX3matrixplan2,
  GetuserX3matrixplan3,
  GetuserX3matrixplan4,
} from "../Hooks/UseContract";
import BoardPlanFooter from "./BoardPlanFooter";
import LoadingBoardPlanOne from "./LoaderBoardPlanOne";
import { showToast } from "./Toast";
import {
  GetBoardplan1,
  GetBoardplan2,
  GetBoardplan3,
  GetBoardplan4,
  GetIdtoAddressX3hooks,
  GetUserX3hooks,
} from "../Hooks/usebackendX3";
import { BoardLevelProfotHooks, GetBoardProfitHooks, GetMissedhistoryHooks, GetpaidnextlevelhistoryHooks, GetUserX3profitHooks, IdtoAddressHooksX2 } from "../Hooks/usebackend";

function BoardPlanParticular({ plan, ut, lockedPlan, dashboardTxt }) {
  const PartnerDetails = [
    {
      type: userLogo,
      date: "21/10/2023",
      partnerId: "ID 253253",
      level: 1,
      wallet: "0x86221bsw855",
      trx: "0 TRX",
    },
    {
      type: userLogo,
      date: "21/10/2023",
      partnerId: "ID 253253",
      level: 1,
      wallet: "0x86221bsw855",
      trx: "0 TRX",
    },
    {
      type: userLogo,
      date: "21/10/2023",
      partnerId: "ID 253253",
      level: 1,
      wallet: "0x86221bsw855",
      trx: "0 TRX",
    },
    {
      type: userLogo,
      date: "21/10/2023",
      partnerId: "ID 253253",
      level: 1,
      wallet: "0x86221bsw855",
      trx: "0 TRX",
    },
    {
      type: userLogo,
      date: "21/10/2023",
      partnerId: "ID 253253",
      level: 1,
      wallet: "0x86221bsw855",
      trx: "0 TRX",
    },
    {
      type: userLogo,
      date: "21/10/2023",
      partnerId: "ID 253253",
      level: 1,
      wallet: "0x86221bsw855",
      trx: "0 TRX",
    },
    {
      type: userLogo,
      date: "21/10/2023",
      partnerId: "ID 253253",
      level: 1,
      wallet: "0x86221bsw855",
      trx: "0 TRX",
    },
    {
      type: userLogo,
      date: "21/10/2023",
      partnerId: "ID 253253",
      level: 1,
      wallet: "0x86221bsw855",
      trx: "0 TRX",
    },
  ];

  const Level = [
    {
      level: 1,
      levelId: "2532531",
      coin: "500",
      value: 1602561,
    },
    {
      level: 2,
      levelId: "2532532",
      coin: "600",
      value: 1602562,
    },
    {
      level: 3,
      levelId: "2532533",
      coin: "700",
      value: 1602563,
      missed: [
        {
          status: true,
          // logo: RedInfo,
          txt: "Missed Partners",
          count: 1,
        },
      ],
    },
  ];

  const options = {
    // loop: true,
    margin: 10,
    items: 1,
    // autoplay: true,
    nav: true,
    // pagination: false,
    dots: false,
    dotsData: true,
    dotsEach: true,
    // responsiveClass: true,
    // autoplayTimeout: 7000,
    // smartSpeed: 800,
    // responsive: {
    //   0: {
    //     items: 1,
    //   },

    //   600: {
    //     items: 3,
    //   },

    //   1024: {
    //     items: 4,
    //   },

    //   1366: {
    //     items: 1,
    //   },
    // },
  };
  const [userdata, setUserdata] = useState({});
  const [board1, setBoard1] = useState([]);
  const [board1status, setBoard1status] = useState([]);
  const [board2, setBoard2] = useState([]);
  const [board2status, setBoard2status] = useState([]);
  const [board3, setBoard3] = useState([]);
  const [board3status, setBoard3status] = useState([]);
  const [board4, setBoard4] = useState([]);
  const [board4status, setBoard4status] = useState([]);
  const [board1loader, setBoard1loader] = useState(null);
  const [board2loader, setBoard2loader] = useState(null);
  const [board3loader, setBoard3loader] = useState(null);
  const [board4loader, setBoard4loader] = useState(null);
  const [userdatax3, setUserdatax3] = useState({});
  const [boardstatus, setBoardstatus] = useState(false);

  const [finalx3data, setFinalx3data] = useState([]);
  const [boardprofit, setBoardprofit] = useState({});
  const [bonus , setBonus] = useState([]);
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [paidhistory , setPaidhistory] = useState([]);
  const [amount , setAmount] = useState(0);
  const [boardprofitX3 , setBoardprofitx3] = useState([]);
  const [topic , setTopic] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = async(data , data1) => {
    let missed =data?.concat(data1); 
    // await getpaidnxtlevelhistory(missed) ; 
    setPaidhistory(missed);
    setShow(true)
    setTimeout(() => {} , 200) };

    const handleShowpaid = async(data) => {
      await getpaidnxtlevelhistory(data) ; 
      // setPaidhistory(data);
      // setShow(true);
      setTimeout(() => {} , 200) };

  const getpaidnxtlevelhistory = async(level) => {
    var address = "";
    if (window?.location?.href?.split("/")[5]) {
      let paload = {
        id: window?.location?.href?.split("/")[5],
      };
      let usrid = await IdtoAddressHooksX2(paload);
      address = usrid?.data?.record?.walletAddress;
    } else {
      address = Getuseraddress();
    }
    let payload = {
      level : level+1,
      type : "X3",
      walletAddress : address,
      missed : JSON.stringify(level)
    }
    let payload1 = {
      level : level+2,
      type : "X3",
      walletAddress : address
    }
    let result = await GetpaidnextlevelhistoryHooks(payload);
    let res = await GetpaidnextlevelhistoryHooks(payload1);
    setPaidhistory(result?.data?.record.concat(res?.data?.record)); //?.concat(res?.data?.record)
    // setRefresh(refresh+1);
    setShow(true)
  }

  const fetchdata = async () => {
    var final = [];
    setBoard1loader(true);
    let user = Getuseraddress();
    let matrixx3 = await GetuserX3matrixplan1(user);
    // setFinalx3data([...finalx3data , matrixx3])
    final.push(matrixx3);
    console.log("matrix x3 level", matrixx3);
    setBoard1(matrixx3?.matrix);
    setBoard1status(matrixx3?.levelstatus);
    setBoard1loader(false);

    setBoard2loader(true);
    let user1 = Getuseraddress();
    let matrixx31 = await GetuserX3matrixplan2(user1);
    // setFinalx3data([...finalx3data , matrixx31])
    final.push(matrixx31);
    setBoard2(matrixx31?.matrix);
    setBoard2status(matrixx31?.levelstatus);
    setBoard2loader(false);

    setBoard3loader(true);
    let user2 = Getuseraddress();
    let matrixx32 = await GetuserX3matrixplan3(user2);
    final.push(matrixx32);
    // setFinalx3data([...finalx3data , matrixx32])
    setBoard3(matrixx32?.matrix);
    setBoard3status(matrixx32?.levelstatus);
    setBoard3loader(false);

    setBoard4loader(true);
    let user3 = Getuseraddress();
    let matrixx33 = await GetuserX3matrixplan4(user3);
    // setFinalx3data([...finalx3data , matrixx33])
    final.push(matrixx33);
    setBoard4(matrixx33?.matrix);
    setBoard4status(matrixx33?.levelstatus);
    setBoard4loader(false);
    setFinalx3data(final);
  };

  const fetchboardplan1 = async () => {
    setBoard1loader(true);
    let paload = {
      id: window?.location?.href?.split("/")[5],
    };
    let usrid = await GetIdtoAddressX3hooks(
      window?.location?.href?.split("/")[5]
    );
    let address = window?.location?.href?.split("/")[5]
      ? Convertaddress(usrid)
      : Getuseraddress();
    let user = Getuseraddress();
    let matrixx3 = await GetuserX3matrixplan1(address);
    console.log("matrix x3 level", matrixx3);
    setBoard1(matrixx3?.matrix);
    setBoard1status(matrixx3?.levelstatus);
    setBoard1loader(false);
  };
  const fetchboardplan2 = async () => {
    setBoard2loader(true);
    let usrid = await Getidtoaddress(window?.location?.href?.split("/")[5]);
    let address = window?.location?.href?.split("/")[5]
      ? Convertaddress(usrid)
      : Getuseraddress();
    let user = Getuseraddress();
    let matrixx3 = await GetuserX3matrixplan2(address);
    setBoard2(matrixx3?.matrix);
    setBoard2status(matrixx3?.levelstatus);
    setBoard2loader(false);
  };
  const fetchboardplan3 = async () => {
    setBoard3loader(true);
    let usrid = await Getidtoaddress(window?.location?.href?.split("/")[5]);
    let address = window?.location?.href?.split("/")[5]
      ? Convertaddress(usrid)
      : Getuseraddress();
    let user = Getuseraddress();
    let matrixx3 = await GetuserX3matrixplan3(address);
    setBoard3(matrixx3?.matrix);
    setBoard3status(matrixx3?.levelstatus);
    setBoard3loader(false);
  };
  const fetchboardplan4 = async () => {
    setBoard4loader(true);
    let usrid = await Getidtoaddress(window?.location?.href?.split("/")[5]);
    let address = window?.location?.href?.split("/")[5]
      ? Convertaddress(usrid)
      : Getuseraddress();
    let user = Getuseraddress();
    let matrixx3 = await GetuserX3matrixplan4(address);
    setBoard4(matrixx3?.matrix);
    setBoard4status(matrixx3?.levelstatus);
    setBoard4loader(false);
  };

  const fetchboard1 = async () => {
    setBoard1loader(true);
    var address = "";
    if (window?.location?.href?.split("/")[5]) {
      let paload = {
        id: window?.location?.href?.split("/")[5],
      };
      let usrid = await IdtoAddressHooksX2(paload);
      address = usrid?.data?.record?.walletAddress;
    } else {
      address = Getuseraddress();
    }
    let matrixx3 = await GetBoardplan1(address);

    console.log("matrix x3 level", matrixx3);
    setBoard1(matrixx3?.data?.record);
    // setBoard1status(matrixx3?.levelstatus);
    setTimeout(() => {
      setBoard1loader(false);
    }, 5000);
  };
  const fetchboard2 = async () => {
    setBoard2loader(true);
    var address = "";
    if (window?.location?.href?.split("/")[5]) {
      let paload = {
        id: window?.location?.href?.split("/")[5],
      };
      let usrid = await IdtoAddressHooksX2(paload);
      address = usrid?.data?.record?.walletAddress;
    } else {
      address = Getuseraddress();
    }
    let matrixx3 = await GetBoardplan2(address);
    console.log("matrix x3 level", matrixx3);
    setBoard2(matrixx3?.data?.record);
    // setBoard1status(matrixx3?.levelstatus);
    setTimeout(() => {
      setBoard2loader(false);
    }, 5000);
  };
  const fetchboard3 = async () => {
    setBoard3loader(true);
    var address = "";
    if (window?.location?.href?.split("/")[5]) {
      let paload = {
        id: window?.location?.href?.split("/")[5],
      };
      let usrid = await IdtoAddressHooksX2(paload);
      address = usrid?.data?.record?.walletAddress;
    } else {
      address = Getuseraddress();
    }
    let matrixx3 = await GetBoardplan3(address);
    console.log("matrix x3 level", matrixx3);
    setBoard3(matrixx3?.data?.record);
    setTimeout(() => {
      setBoardstatus(true);
    }, 2000);
    // setBoard1status(matrixx3?.levelstatus);
    setTimeout(() => {
      setBoard3loader(false);
    }, 5000);
  };
  const fetchboard4 = async () => {
    setBoard4loader(true);
    var address = "";
    if (window?.location?.href?.split("/")[5]) {
      let paload = {
        id: window?.location?.href?.split("/")[5],
      };
      let usrid = await IdtoAddressHooksX2(paload);
      address = usrid?.data?.record?.walletAddress;
    } else {
      address = Getuseraddress();
    }
    let matrixx3 = await GetBoardplan4(address);
    console.log("matrix x3 level", matrixx3);
    setBoard4(matrixx3?.data?.record);
    setTimeout(() => {
      setBoard4loader(false);
    }, 5000);
    // setBoard1status(matrixx3?.levelstatus);
    // setBoard4loader(false);
  };
  const fetchdatauser = async () => {
    if (window?.location?.href?.split("/")[5]) {
      let paload = {
        id: window?.location?.href?.split("/")[5],
      };
      let usr = await IdtoAddressHooksX2(paload);
      let usrid = await GetUserX3hooks({
        walletAddress: usr?.data?.record?.walletAddress,
      });
      setUserdatax3(usrid?.data?.record);
      let profit = await BoardLevelProfotHooks({
        walletAddress: usrid?.data?.record?.walletAddress,
      });
      console.log("user profitss", profit);
      setBoardprofit(profit?.data?.user);
    } else {
      let payload = {
        walletAddress: Getuseraddress(),
      };
      let result = await GetUserX3hooks(payload);
      setUserdatax3(result?.data?.record);
      let profit = await BoardLevelProfotHooks({
        walletAddress: Getuseraddress(),
      });
      console.log("user profitss", profit);
      setBoardprofit(profit?.data?.user);
    }
  };
  const fetchprofit = async() => {
    let result = await GetUserX3profitHooks({walletAddress : Getuseraddress()});
    console.log("result in progit" , result);
    setBonus(result?.data?.record);
  }

  const getcalculation = (missed, referal) => {
    console.log("missed , referal", missed, referal);
    var length = referal?.length;
    for (var i = 0; i < missed?.length; i++) {
      if (referal?.includes(missed[i])) {
        length = length - 1;
      }
    }
    console.log("length", length);
    return length;
  }

 const getdata = (board , nextboard , index) => {
    let referalmissed = getcalculation(userdatax3?.levelUsers[index]?.missedid , userdatax3?.levelUsers[index]?.referral);
    let referal = nextboard?.planAmount/board?.planAmount
    let adminfee = ((board?.planAmount*5)/100  )*(referalmissed-referal);
    let paidnextlevel = nextboard ?   nextboard?.planAmount / board?.planAmount : 0;
    let res = (parseFloat(board?.planAmount)*referalmissed) - adminfee - (paidnextlevel * parseFloat(board?.planAmount));
    // console.log();
    if(userdatax3?.id == 1){
      return (parseFloat(board?.planAmount)*referalmissed)
    }
    else{
      return res>=0 ? res : 0;
    }
  }

  const getcalculationmissed = (missed, referal) => {
    console.log("missed , referal", missed, referal);
    var length = referal?.length;
    // for (var i = 0; i < missed?.length; i++) {
    //   if (referal?.includes(missed[i])) {
    //     length = length - 1;
    //   }
    // }
    console.log("length", length);
    return missed?.length;
  }

  const getdatamissed = (board , nextboard , index) => {
    if(userdatax3?.levelUsers[index]?.missedid?.length > 0){
      let referalmissed = getcalculationmissed(userdatax3?.levelUsers[index]?.missedid , userdatax3?.levelUsers[index]?.referral);
      let referal = nextboard?.planAmount/board?.planAmount
      let adminfee = ((board?.planAmount*5)/100)*(referalmissed-referal);
    let paidnextlevel = nextboard ?   nextboard?.planAmount / board?.planAmount : 0;
    let res = (parseFloat(board?.planAmount)*referalmissed) - adminfee - (paidnextlevel * parseFloat(board?.planAmount));
    // console.log();
    if(userdatax3?.id == 1){
      return (parseFloat(board?.planAmount)*referalmissed)
    }
    else{
      return res>=0 ? res : 0;
    }
    }
    else{
      return 0
    }
  }

  const GetBoardlevelprofit = async() => {
    var address = "";
    if (window?.location?.href?.split("/")[5]) {
      let paload = {
        id: window?.location?.href?.split("/")[5],
      };
      let usrid = await IdtoAddressHooksX2(paload);
      address = usrid?.data?.record?.walletAddress;
    } else {
      address = Getuseraddress();
    }
    let result = await GetBoardProfitHooks({walletAddress : address});
    console.log("Board profit result in profit" , result);
    setBoardprofitx3(result?.data?.record);
  }

  useEffect(() => {
    fetchdatauser();
    fetchprofit();
    GetBoardlevelprofit();
    setTimeout(() => {
      fetchboard1();
      fetchboard2();
      fetchboard3();
      fetchboard4();
    }, 2000);

    // fetchboardplan1();
    // fetchboardplan2();
    // fetchboardplan3();
    // fetchboardplan4();
    // fetchdata();
  }, []);

  const settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };

  console.log(
    "dashboardTxt",
    board1,
    board2,
    board3,
    board4,
    board1loader,
    board2loader,
    board3loader,
    board4loader
  );

  const getBonus = (transaction1 ,transaction2 ,  index , index1) => {
    var amount1 = 0
    var amount2 = 0
    var amount = 0
    for(var i =0 ; i<transaction1?.length ; i++){
      if(!userdatax3?.levelUsers[index]?.referral?.includes(transaction1[i]?.from)  || transaction1[i]?.key != "OWN"){
        amount1 = amount1 + parseFloat(transaction1[i]?.amount)
      }
    }
    for(var j =0 ; j<transaction2?.length ; j++){
      if(!userdatax3?.levelUsers[index1]?.referral?.includes(transaction2[i]?.from) || transaction1[i]?.key != "OWN"){
        amount2 = amount2 + parseFloat(transaction2[i]?.amount)
      }
    }
    amount = amount1 + amount2;
    return amount
  }
  return (
    <div>
      {dashboardTxt ? (
        <div className="head_section mt-5">
          <h6 className="sub_heding">Board Plan - UT x {ut}</h6>
          {/* <h6 className="sub_heding_val">0 TRX</h6> */}
        </div>
      ) : (
        <></>
      )}

      <div className="position-relative plan_card_sec">
        <div className="content_sec mt-5">
          <div
            className={lockedPlan ? "border_sec blur_card_sec" : "border_sec"}
          >
            <OwlCarousel
              className="owl-theme"
              {...options}
              startPosition={parseFloat(window.location.href.split("/")[4]) - 1}
            >
              {/* {finalx3data && finalx3data.map((e, i) => */}
              {/* {!board1loader ? ( */}
              <div className="board_collection" data-dot="<button>01</button>">
                {board1?.length > 0 && (
                  <div>
                    <h6 className="heading_txt mb-0">Board 1</h6>
                    <div className="my-4">
                      <Card className="level_card">
                        <Card.Body className="position-relative">
                          <div
                            className={
                              !userdatax3?.purchasedLevels?.includes(
                                board1[0]?._id
                              )
                                ? "blur_card_sec"
                                : ""
                            }
                          >
                            <div className="plan_custom_navi">
                              <div className="head_sec d-flex justify-content-between">
                                <span>Level 1</span>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={Coin}
                                    className="coin_logo me-1"
                                    alt="coin"
                                  />
                                  <span>
                                    {parseFloat(board1[0]?.planAmount)}
                                  </span>
                                </div>
                              </div>
                              <div className="body_sec">
                                <div className="list">
                                  <span
                                    className={
                                      userdatax3?.levelUsers[0]?.referralid
                                        ?.length > 0
                                        ?userdatax3?.levelUsers[0]?.missedid?.includes(userdatax3?.levelUsers[0]?.referralid[0]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                        : "cmn_bg_color in_completed"
                                    }
                                    onClick={()=> userdatax3?.levelUsers[0]?.referralid
                                      ?.length > 0 &&window.open("/user-dashboard/" + userdatax3?.levelUsers[0]?.referralid[0], '_blank')}
                                  > 
                                  {userdatax3?.levelUsers[0]?.referralid[0]}
                                  {/* {userdatax3?.levelUsers[0]?.referralid
                                        ?.length > 0 && (`${userdatax3?.levelUsers[0]?.referral[0]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[0]?.referral[0]?.substring(32, 34)}`)} */}
                                                 
                                  </span>
                                  <span
                                    className={
                                      userdatax3?.levelUsers[0]?.referralid
                                        ?.length > 1
                                        ?userdatax3?.levelUsers[0]?.missedid?.includes(userdatax3?.levelUsers[0]?.referralid[1]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                        : "cmn_bg_color in_completed"
                                    }
                                    onClick={()=> userdatax3?.levelUsers[0]?.referralid
                                      ?.length > 1 && window.open("/user-dashboard/" + userdatax3?.levelUsers[0]?.referralid[1], '_blank')}
                                  >
                                    {userdatax3?.levelUsers[0]?.referralid[1]}
                                    {/* {userdatax3?.levelUsers[0]?.referralid
                                        ?.length > 1 && (`${userdatax3?.levelUsers[0]?.referralid[1]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[0]?.referral[1]?.substring(32, 34)}`)} */}
                                  </span>
                                  <span
                                    className={
                                      userdatax3?.levelUsers[0]?.referralid
                                        ?.length > 2
                                        ? userdatax3?.levelUsers[0]?.missedid?.includes(userdatax3?.levelUsers[0]?.referralid[2]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                        : "cmn_bg_color in_completed"
                                    }
                                    onClick={()=> userdatax3?.levelUsers[0]?.referralid
                                      ?.length > 2 &&  window.open("/user-dashboard/" + userdatax3?.levelUsers[0]?.referralid[2])}
                                  >
                                    {userdatax3?.levelUsers[0]?.referralid[2]}
                                    {/* {userdatax3?.levelUsers[0]?.referralid
                                        ?.length > 2 && (`${userdatax3?.levelUsers[0]?.referral[2]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[0]?.referral[2]?.substring(32, 34)}`)} */}
                                  </span>
                                </div>
                              </div>
                              {/* <div className="foot_sec">
                                <span className="d-flex align-items-center">
                                  <img
                                    src={userGroup}
                                    alt="usergroup"
                                    className="img-fluid user_icon pe-2"
                                  />
                                  {1}
                                </span>
                                <span className="d-flex align-items-center">
                                  <img
                                    src={reload}
                                    alt="reload"
                                    className="img-fluid reload_icon pe-2"
                                  />
                                  0
                                </span>
                              </div> */}
                            </div>
                          </div>
                          {!userdatax3?.purchasedLevels?.includes(
                            board1[0]?._id
                          ) ? (
                            <>
                              <div className="locked_plan">
                                <p className="mb-2 plan_level">Level 1</p>
                                <img
                                  src={lockIcon}
                                  alt="plan 2"
                                  className="img-fluid lock_logo"
                                />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </Card.Body>
                      </Card>
                      {/* board1[0][5]?.toString() > 0 && */}
                      {!userdatax3?.purchasedLevels?.includes(board1[0]?._id) &&
                        userdatax3?.levelUsers[0]?.missedid?.length > 0 && (
                          <div className="board_plan_card_bg">
                            <Card className="level_card">
                              <Card.Body className="position-relative">
                                <div className="">
                                  <div className="plan_custom_navi">
                                    <div className="head_sec d-flex justify-content-between">
                                      <span>Level 1</span>
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={Coin}
                                          className="coin_logo me-1"
                                          alt="coin"
                                        />
                                        <span>{board1[1]?.planAmount}</span>
                                      </div>
                                    </div>
                                    <div className="body_sec">
                                      <div className="list">
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                            <div className="card_missed">
                              <div className="locked_plann">
                                <p className="mb-2 plan_level">Level 1</p>
                                <p className="mb-0">
                                  Missed reward :{" "}
                                  {
                                    userdatax3?.levelUsers[0]?.missedid
                                      ?.length
                                  }
                                </p>
                                <p className="mb-0">Missed amount : {}</p>
                              </div>
                            </div>
                          </div>
                        )}

                      <Card className="level_card mt-5">
                        <Card.Body className="position-relative">
                          <div
                            className={
                              !userdatax3?.purchasedLevels?.includes(
                                board1[1]?._id
                              )
                                ? "blur_card_sec"
                                : ""
                            }
                          >
                            <div className="plan_custom_navi">
                              <div className="head_sec d-flex justify-content-between">
                                <span>Level 2</span>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={Coin}
                                    className="coin_logo me-1"
                                    alt="coin"
                                  />
                                  <span>{board1[1]?.planAmount}</span>
                                </div>
                              </div>
                              <div className="body_sec custom_board_plan_parti_slider">
                                <div className="list">
                                  <div className="custom_slider_sec">
                                    <Slider
                                      {...settings}
                                      className="custom_slider"
                                    >
                                      <span
                                        className={
                                          userdatax3?.levelUsers[1]?.referralid
                                            ?.length > 0
                                            ? userdatax3?.levelUsers[1]?.missedid?.includes(userdatax3?.levelUsers[1]?.referralid[0]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[1]?.referralid
                                          ?.length > 0 && window.open("/user-dashboard/" + userdatax3?.levelUsers[1]?.referralid[0] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[1]?.referralid[0]}
                                        {/* {userdatax3?.levelUsers[1]?.referral
                                        ?.length > 0 && (`${userdatax3?.levelUsers[1]?.referral[0]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[1]?.referral[0]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[1]?.referralid
                                            ?.length > 1
                                            ?userdatax3?.levelUsers[1]?.missedid?.includes(userdatax3?.levelUsers[1]?.referralid[1]) ? "cmn_bg_color red_bg" :  "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[1]?.referralid
                                          ?.length > 1 && window.open("/user-dashboard/" + userdatax3?.levelUsers[1]?.referralid[1] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[1]?.referralid[1]}
                                  {/* {userdatax3?.levelUsers[1]?.referralid
                                        ?.length > 1 && (`${userdatax3?.levelUsers[1]?.referralid[1]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[1]?.referral[1]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[1]?.referralid
                                            ?.length > 2
                                            ?userdatax3?.levelUsers[1]?.missedid?.includes(userdatax3?.levelUsers[1]?.referralid[2]) ? "cmn_bg_color red_bg" :  "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[1]?.referralid
                                          ?.length > 2 && window.open("/user-dashboard/" + userdatax3?.levelUsers[1]?.referralid[2] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[1]?.referralid[2]}
                                        {/* {userdatax3?.levelUsers[1]?.referral
                                        ?.length > 2 && (`${userdatax3?.levelUsers[1]?.referral[2]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[1]?.referral[2]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[1]?.referralid
                                            ?.length > 3
                                            ? userdatax3?.levelUsers[1]?.missedid?.includes(userdatax3?.levelUsers[1]?.referralid[3]) ?  "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[1]?.referralid
                                          ?.length > 3 && window.open("/user-dashboard/" + userdatax3?.levelUsers[1]?.referralid[3] ,  "_blank")}
                                      >
                                        {userdatax3?.levelUsers[1]?.referralid[3]}
                                    {/* {userdatax3?.levelUsers[1]?.referral
                                        ?.length > 3 && (`${userdatax3?.levelUsers[1]?.referral[3]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[1]?.referral[3]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[1]?.referralid
                                            ?.length > 4
                                            ?userdatax3?.levelUsers[1]?.missedid?.includes(userdatax3?.levelUsers[1]?.referralid[4]) ? "cmn_bg_color red_bg" :  "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[1]?.referralid
                                          ?.length > 4 && window.open("/user-dashboard/" + userdatax3?.levelUsers[1]?.referralid[4] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[1]?.referralid[4]}
                                        {/* {userdatax3?.levelUsers[1]?.referral
                                        ?.length > 4 && (`${userdatax3?.levelUsers[1]?.referral[4]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[1]?.referral[4]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[1]?.referralid
                                            ?.length > 5
                                            ? userdatax3?.levelUsers[1]?.missedid?.includes(userdatax3?.levelUsers[1]?.referralid[5]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[1]?.referralid
                                          ?.length > 5 && window.open("/user-dashboard/" + userdatax3?.levelUsers[1]?.referralid[5] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[1]?.referralid[5]}
                                        {/* {userdatax3?.levelUsers[1]?.referral
                                        ?.length > 5 && (`${userdatax3?.levelUsers[1]?.referral[5]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[1]?.referral[5]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[1]?.referralid
                                            ?.length > 6
                                            ?userdatax3?.levelUsers[1]?.missedid?.includes(userdatax3?.levelUsers[1]?.referralid[6]) ? "cmn_bg_color red_bg" :  "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[1]?.referralid
                                          ?.length > 6 && window.open("/user-dashboard/" + userdatax3?.levelUsers[1]?.referralid[6] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[1]?.referralid[6]}
                                        {/* {userdatax3?.levelUsers[1]?.referral
                                        ?.length > 6 && (`${userdatax3?.levelUsers[1]?.referral[6]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[1]?.referral[6]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[1]?.referralid
                                            ?.length > 7
                                            ? userdatax3?.levelUsers[1]?.missedid?.includes(userdatax3?.levelUsers[1]?.referralid[7]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[1]?.referralid
                                          ?.length > 7 && window.open("/user-dashboard/" + userdatax3?.levelUsers[1]?.referralid[7] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[1]?.referralid[7]}
                                        {/* {userdatax3?.levelUsers[1]?.referral
                                        ?.length > 7 && (`${userdatax3?.levelUsers[1]?.referral[7]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[1]?.referral[7]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[1]?.referralid
                                            ?.length > 8
                                            ? userdatax3?.levelUsers[1]?.missedid?.includes(userdatax3?.levelUsers[1]?.referralid[8]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[1]?.referralid
                                          ?.length > 8 && window.open("/user-dashboard/" + userdatax3?.levelUsers[1]?.referralid[8] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[1]?.referralid[8]}
                                         {/* {userdatax3?.levelUsers[1]?.referral
                                        ?.length > 8 && (`${userdatax3?.levelUsers[1]?.referral[8]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[1]?.referral[8]?.substring(32, 34)}`)} */}
                                      </span>
                                    </Slider>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="foot_sec">
                                <span className="d-flex align-items-center">
                                  <img
                                    src={userGroup}
                                    alt="usergroup"
                                    className="img-fluid user_icon pe-2"
                                  />
                                  1
                                </span>
                                <span className="d-flex align-items-center">
                                  <img
                                    src={reload}
                                    alt="reload"
                                    className="img-fluid reload_icon pe-2"
                                  />
                                  0
                                </span>
                              </div> */}
                            </div>
                          </div>
                          {/* {board1status[1]?.status == false ? (
                              <>
                                <div className="locked_plan">
                                  <p className="mb-2 plan_level">Board Plan</p>
                                  <Lottie
                                    className="lock_lottie"
                                    animationData={lock}
                                    loop={true}
                                  />
                                  <Button className="unlock_btn common_yellow_btn">
                                    Unlock
                                  </Button>
                                </div>
                              </>
                            ) : (
                              <></>
                            )} */}
                          {!userdatax3?.purchasedLevels?.includes(
                            board1[1]?._id
                          ) ? (
                            <>
                              <div
                                className="locked_plan"
                                onClick={() => {
                                  showToast(
                                    "error",
                                    "You can't buy plan2 It will automatically upgrade"
                                  );
                                }}
                              >
                                <p className="mb-2 plan_level">Level 2</p>
                                <img
                                  src={lockIcon}
                                  alt="plan 2"
                                  className="img-fluid lock_logo"
                                />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </Card.Body>
                      </Card>

                      {/* card red bg start */}
                      {!userdatax3?.purchasedLevels?.includes(board1[1]?._id) &&
                        userdatax3?.levelUsers[1]?.missedid?.length > 0 && (
                          <div className="board_plan_card_bg">
                            <Card className="level_card">
                              <Card.Body className="position-relative">
                                <div className="">
                                  <div className="plan_custom_navi">
                                    <div className="head_sec d-flex justify-content-between">
                                      <span>Level 2</span>
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={Coin}
                                          className="coin_logo me-1"
                                          alt="coin"
                                        />
                                        <span>10000</span>
                                      </div>
                                    </div>
                                    <div className="body_sec">
                                      <div className="list">
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                            <div className="card_missed">
                              <div className="locked_plann">
                                <p className="mb-2 plan_level">Level 2</p>
                                <p className="mb-0">
                                  Missed reward :{" "}
                                  {
                                    userdatax3?.levelUsers[1]?.missedid
                                      ?.length
                                  }
                                </p>
                                {/* <p className="mb-0">Missed amount : {(parseFloat(board1status[1]?.price?.toString())/10**6)*10/100}</p> */}
                              </div>
                            </div>
                          </div>
                        )}
                      {/* card red bg end */}

                      <div className="partner_total_profits new_party_profit">
                        <div className="content_grp">
                          <p className="header mb-2">Team Size</p>
                          <div className="d-flex justify-content-center align-items-center content">
                            <span>
                              <img
                                src={userGroup}
                                alt="usergroup"
                                className="img-fluid user_icon pe-2"
                              />
                            </span>
                            <span>
                              {userdatax3?.levelUsers[0]?.referral?.length +
                                userdatax3?.levelUsers[1]?.referral?.length}
                            </span>
                          </div>
                        </div>
                        <div className="content_grp">
                          <p className="header mb-2">Max Team Size</p>
                          <div className="d-flex justify-content-center align-items-center content">
                            <span>
                              <img
                                src={userGroup}
                                alt="reload"
                                className="img-fluid reload_icon pe-2"
                              />
                            </span>
                            <span>{12}</span>
                          </div>
                        </div>
                        <div className="content_grp">
                          <p className="header mb-2">Total Profits</p>
                          <div className="d-flex justify-content-center align-items-center content">
                            <span>
                              <img
                                src={cardIcon}
                                alt="usergroup"
                                className="img-fluid card_icon pe-2"
                              />
                            </span>
                            
                            <span onClick={() => {
                              setAmount(boardprofitX3[0]?.profit + boardprofitX3[1]?.profit);
                              handleShow(boardprofitX3[0]?.totaltransaction, boardprofitX3[1]?.totaltransaction);
                            }}>
                              {/* {parseFloat(board1[0]?.planAmount) *
                                getcalculation(userdatax3?.levelUsers[0]?.missedid , userdatax3?.levelUsers[0]?.referral) -
                                ((parseFloat(board1[0]?.planAmount) *
                                getcalculation(userdatax3?.levelUsers[0]?.missedid , userdatax3?.levelUsers[0]?.referral)) /
                                  100) *
                                  2.5 +
                                (parseFloat(board1[1]?.planAmount) *
                                getcalculation(userdatax3?.levelUsers[1]?.missedid , userdatax3?.levelUsers[1]?.referral) -
                                  ((parseFloat(board1[1]?.planAmount) *
                                  getcalculation(userdatax3?.levelUsers[1]?.missedid , userdatax3?.levelUsers[1]?.referral)) /
                                    100) *
                                    2.5)}+ */}
                                    {/* {getdata(board1[0] , board1[1] , 0) + getdata(board1[1] , board2[0] , 1)}+ */}
                                    {boardprofitX3[0]?.profit + boardprofitX3[1]?.profit - 
                                    (getBonus(boardprofitX3[0]?.totaltransaction, boardprofitX3[1]?.totaltransaction , 0 , 1)
                                    ? getBonus(boardprofitX3[0]?.totaltransaction, boardprofitX3[1]?.totaltransaction , 0 , 1) : 0)
                                    }
                              {/* {boardprofit?.walletAddress && boardprofit?.levelUsers ? parseFloat(boardprofit?.levelUsers[0]?.totalProfit) + parseFloat(boardprofit?.levelUsers[1]?.totalProfit) : 0} TRX */}
                            </span>
                            <span style={{color:"green"}}>+
                         {getBonus(boardprofitX3[0]?.totaltransaction, boardprofitX3[1]?.totaltransaction , 0 , 1)
                         ? getBonus(boardprofitX3[0]?.totaltransaction, boardprofitX3[1]?.totaltransaction , 0 , 1) : 0
                          }
                          </span>
                          <span className="red_err_txt" onClick={() => {
                          setAmount(boardprofitX3[0]?.missed + boardprofitX3[1]?.missed)
                            handleShow(boardprofitX3[0]?.misshistory, boardprofitX3[1]?.misshistory)}}> 
                            {/* + {getdatamissed(board1[0] , board1[1] , 0) + getdatamissed(board1[1] , board2[0] , 1)} */}
                            + {boardprofitX3[0]?.missed + boardprofitX3[1]?.missed}
                            </span>

                            <span style={{ color: "orange" }} 
                                      onClick={() => {setTopic("Paid For NextLevel");handleShowpaid(0)}}
                                      >+
                                      {userdatax3?.levelUsers[0]?.paidfornextlevel + userdatax3?.levelUsers[1]?.paidfornextlevel}
                                      </span>
                          {/* userdatax3?.levelUsers[0]?.paidfornextlevel + userdatax3?.levelUsers[1]?.paidfornextlevel */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* ) : (
                <LoadingBoardPlanOne />
              )}  */}

              {/* {!board2loader ? ( */}
              <div className="board_collection" data-dot="<button>01</button>">
                {board2?.length > 0 && (
                  <div>
                    <h6 className="heading_txt mb-0">Board 2</h6>
                    <div className="my-4">
                      <Card className="level_card">
                        <Card.Body className="position-relative">
                          <div
                            className={
                              !userdatax3?.purchasedLevels?.includes(
                                board2[0]?._id
                              )
                                ? "blur_card_sec"
                                : ""
                            }
                          >
                            <div className="plan_custom_navi">
                              <div className="head_sec d-flex justify-content-between">
                                <span>Level 1</span>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={Coin}
                                    className="coin_logo me-1"
                                    alt="coin"
                                  />
                                  <span>
                                    {parseFloat(board2[0]?.planAmount)}
                                  </span>
                                </div>
                              </div>
                              <div className="body_sec">
                                <div className="list">
                                  <span
                                    className={
                                      userdatax3?.levelUsers[2]?.referralid
                                        ?.length > 0
                                        ?userdatax3?.levelUsers[2]?.missedid?.includes(userdatax3?.levelUsers[2]?.referralid[0]) ? "cmn_bg_color red_bg" :  "cmn_bg_color completed"
                                        : "cmn_bg_color in_completed"
                                    }
                                    onClick={()=> userdatax3?.levelUsers[2]?.referralid
                                      ?.length > 0 && window.open("/user-dashboard/" + userdatax3?.levelUsers[2]?.referralid[0] , "_blank")}
                                  >
                                    {userdatax3?.levelUsers[2]?.referralid[0]}
                                    {/* {userdatax3?.levelUsers[2]?.referral
                                        ?.length > 0 && (`${userdatax3?.levelUsers[2]?.referral[0]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[2]?.referral[0]?.substring(32, 34)}`)} */}
                                  </span>
                                  <span
                                    className={
                                      userdatax3?.levelUsers[2]?.referralid
                                        ?.length > 1
                                        ?userdatax3?.levelUsers[2]?.missedid?.includes(userdatax3?.levelUsers[2]?.referralid[1]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                        : "cmn_bg_color in_completed"
                                    }
                                    onClick={()=> userdatax3?.levelUsers[2]?.referralid
                                      ?.length > 1 && window.open("/user-dashboard/" + userdatax3?.levelUsers[2]?.referralid[1] , "_blank")}
                                  >
                                    {userdatax3?.levelUsers[2]?.referralid[1]}
                                    {/* {userdatax3?.levelUsers[2]?.referral
                                        ?.length > 1 && (`${userdatax3?.levelUsers[2]?.referral[1]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[2]?.referral[1]?.substring(32, 34)}`)} */}
                                  </span>
                                  
                                  <span
                                    className={
                                      userdatax3?.levelUsers[2]?.referralid
                                        ?.length > 2
                                        ?userdatax3?.levelUsers[2]?.missedid?.includes(userdatax3?.levelUsers[2]?.referralid[2]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                        : "cmn_bg_color in_completed"
                                    }
                                    onClick={()=> userdatax3?.levelUsers[2]?.referralid
                                      ?.length > 2 && window.open("/user-dashboard/" + userdatax3?.levelUsers[2]?.referralid[2] , "_blank")}
                                  >
                                    {userdatax3?.levelUsers[2]?.referralid[2]}
                                    {/* {userdatax3?.levelUsers[2]?.referral
                                        ?.length > 2 && (`${userdatax3?.levelUsers[2]?.referral[2]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[2]?.referral[2]?.substring(32, 34)}`)} */}
                                  </span>
                                </div>
                              </div>
                              {/* <div className="foot_sec">
                                <span className="d-flex align-items-center">
                                  <img
                                    src={userGroup}
                                    alt="usergroup"
                                    className="img-fluid user_icon pe-2"
                                  />
                                  {1}
                                </span>
                                <span className="d-flex align-items-center">
                                  <img
                                    src={reload}
                                    alt="reload"
                                    className="img-fluid reload_icon pe-2"
                                  />
                                  0
                                </span>
                              </div> */}
                            </div>
                          </div>
                          {!userdatax3?.purchasedLevels?.includes(
                            board2[0]?._id
                          ) ? (
                            <>
                              <div className="locked_plan">
                                <p className="mb-2 plan_level">Level 1</p>
                                <img
                                  src={lockIcon}
                                  alt="plan 2"
                                  className="img-fluid lock_logo"
                                />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </Card.Body>
                      </Card>
                      {/* board1[0][5]?.toString() > 0 && */}
                      {!userdatax3?.purchasedLevels?.includes(board2[0]?._id) &&
                        userdatax3?.levelUsers[2]?.missedid?.length > 0 && (
                          <div className="board_plan_card_bg">
                            <Card className="level_card">
                              <Card.Body className="position-relative">
                                <div className="">
                                  <div className="plan_custom_navi">
                                    <div className="head_sec d-flex justify-content-between">
                                      <span>Level 1</span>
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={Coin}
                                          className="coin_logo me-1"
                                          alt="coin"
                                        />
                                        <span>{board2[1]?.planAmount}</span>
                                      </div>
                                    </div>
                                    <div className="body_sec">
                                      <div className="list">
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                            <div className="card_missed">
                              <div className="locked_plann">
                                <p className="mb-2 plan_level">Level 1</p>
                                <p className="mb-0">
                                  Missed reward :{" "}
                                  {
                                    userdatax3?.levelUsers[2]?.missedid
                                      ?.length
                                  }
                                </p>
                                <p className="mb-0">Missed amount : {}</p>
                              </div>
                            </div>
                          </div>
                        )}

                      <Card className="level_card mt-5">
                        <Card.Body className="position-relative">
                          <div
                            className={
                              !userdatax3?.purchasedLevels?.includes(
                                board2[1]?._id
                              )
                                ? "blur_card_sec"
                                : ""
                            }
                          >
                            <div className="plan_custom_navi">
                              <div className="head_sec d-flex justify-content-between">
                                <span>Level 2</span>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={Coin}
                                    className="coin_logo me-1"
                                    alt="coin"
                                  />
                                  <span>{board2[1]?.planAmount}</span>
                                </div>
                              </div>
                              <div className="body_sec custom_board_plan_parti_slider">
                                <div className="list">
                                  <div className="custom_slider_sec">
                                    <Slider
                                      {...settings}
                                      className="custom_slider"
                                    >
                                      <span
                                        className={
                                          userdatax3?.levelUsers[3]?.referralid
                                            ?.length > 0
                                            ?userdatax3?.levelUsers[3]?.missedid?.includes(userdatax3?.levelUsers[3]?.referralid[0]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[3]?.referralid
                                          ?.length > 0 && window.open("/user-dashboard/" + userdatax3?.levelUsers[3]?.referralid[0] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[3]?.referralid[0]}
                                        {/* {userdatax3?.levelUsers[3]?.referral
                                        ?.length > 0 && (`${userdatax3?.levelUsers[3]?.referral[0]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[3]?.referral[0]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[3]?.referralid
                                            ?.length > 1
                                            ?userdatax3?.levelUsers[3]?.missedid?.includes(userdatax3?.levelUsers[3]?.referralid[1]) ? "cmn_bg_color red_bg" :  "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[3]?.referralid
                                          ?.length > 1 && window.open("/user-dashboard/" + userdatax3?.levelUsers[3]?.referralid[1] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[3]?.referralid[1]}
                                        {/* {userdatax3?.levelUsers[3]?.referral
                                        ?.length > 1 && (`${userdatax3?.levelUsers[3]?.referral[1]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[3]?.referral[1]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[3]?.referralid
                                            ?.length > 2
                                            ? userdatax3?.levelUsers[3]?.missedid?.includes(userdatax3?.levelUsers[3]?.referralid[2]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[3]?.referralid
                                          ?.length > 2 && window.open("/user-dashboard/" + userdatax3?.levelUsers[3]?.referralid[2] , "_blank")}
                                      >
                                          {userdatax3?.levelUsers[3]?.referralid[2]}
            {/* {userdatax3?.levelUsers[3]?.referral
                                        ?.length > 2 && (`${userdatax3?.levelUsers[3]?.referral[2]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[3]?.referral[2]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[3]?.referralid
                                            ?.length > 3
                                            ? userdatax3?.levelUsers[3]?.missedid?.includes(userdatax3?.levelUsers[3]?.referralid[3]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[3]?.referralid
                                          ?.length > 3 && window.open("/user-dashboard/" + userdatax3?.levelUsers[3]?.referralid[3] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[3]?.referralid[3]}
                                        {/* {userdatax3?.levelUsers[3]?.referral
                                        ?.length > 3 && (`${userdatax3?.levelUsers[3]?.referral[3]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[3]?.referral[3]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[3]?.referralid
                                            ?.length > 4
                                            ? userdatax3?.levelUsers[3]?.missedid?.includes(userdatax3?.levelUsers[3]?.referralid[4]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[3]?.referralid
                                          ?.length > 4 && window.open("/user-dashboard/" + userdatax3?.levelUsers[3]?.referralid[4] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[3]?.referralid[4]}
                                        {/* {userdatax3?.levelUsers[3]?.referral
                                        ?.length > 4 && (`${userdatax3?.levelUsers[3]?.referral[4]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[3]?.referral[4]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[3]?.referralid
                                            ?.length > 5
                                            ? userdatax3?.levelUsers[3]?.missedid?.includes(userdatax3?.levelUsers[3]?.referralid[5]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[3]?.referralid
                                          ?.length > 5 && window.open("/user-dashboard/" + userdatax3?.levelUsers[3]?.referralid[5] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[3]?.referralid[5]}
                                        {/* {userdatax3?.levelUsers[3]?.referral
                                        ?.length > 5 && (`${userdatax3?.levelUsers[3]?.referral[5]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[3]?.referral[5]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[3]?.referralid
                                            ?.length > 6
                                            ? userdatax3?.levelUsers[3]?.missedid?.includes(userdatax3?.levelUsers[3]?.referralid[6]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[3]?.referralid
                                          ?.length > 6 && window.open("/user-dashboard/" + userdatax3?.levelUsers[3]?.referralid[6] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[3]?.referralid[6]}
                                        {/* {userdatax3?.levelUsers[3]?.referral
                                        ?.length > 6 && (`${userdatax3?.levelUsers[3]?.referral[6]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[3]?.referral[6]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[3]?.referralid
                                            ?.length > 7
                                            ?userdatax3?.levelUsers[3]?.missedid?.includes(userdatax3?.levelUsers[3]?.referralid[7]) ? "cmn_bg_color red_bg" :  "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[3]?.referralid
                                          ?.length > 7 && window.open("/user-dashboard/" + userdatax3?.levelUsers[3]?.referralid[7] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[3]?.referralid[7]}
                                        {/* {userdatax3?.levelUsers[3]?.referral
                                        ?.length > 7 && (`${userdatax3?.levelUsers[3]?.referral[7]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[3]?.referral[7]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[3]?.referralid
                                            ?.length > 8
                                            ? userdatax3?.levelUsers[3]?.missedid?.includes(userdatax3?.levelUsers[3]?.referralid[8]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[3]?.referralid
                                          ?.length > 8 && window.open("/user-dashboard/" + userdatax3?.levelUsers[3]?.referralid[8] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[3]?.referralid[8]}
                                        {/* {userdatax3?.levelUsers[3]?.referralid
                                        ?.length > 8 && (`${userdatax3?.levelUsers[3]?.referral[8]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[3]?.referral[8]?.substring(32, 34)}`)} */}
                                      </span>
                                    </Slider>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="foot_sec">
                                <span className="d-flex align-items-center">
                                  <img
                                    src={userGroup}
                                    alt="usergroup"
                                    className="img-fluid user_icon pe-2"
                                  />
                                  1
                                </span>
                                <span className="d-flex align-items-center">
                                  <img
                                    src={reload}
                                    alt="reload"
                                    className="img-fluid reload_icon pe-2"
                                  />
                                  0
                                </span>
                              </div> */}
                            </div>
                          </div>
                          {/* {board1status[1]?.status == false ? (
                              <>
                                <div className="locked_plan">
                                  <p className="mb-2 plan_level">Board Plan</p>
                                  <Lottie
                                    className="lock_lottie"
                                    animationData={lock}
                                    loop={true}
                                  />
                                  <Button className="unlock_btn common_yellow_btn">
                                    Unlock
                                  </Button>
                                </div>
                              </>
                            ) : (
                              <></>
                            )} */}
                          {!userdatax3?.purchasedLevels?.includes(
                            board2[1]?._id
                          ) ? (
                            <>
                              <div
                                className="locked_plan"
                                onClick={() => {
                                  showToast(
                                    "error",
                                    "You can't buy plan2 It will automatically upgrade"
                                  );
                                }}
                              >
                                <p className="mb-2 plan_level">Level 2</p>
                                <img
                                  src={lockIcon}
                                  alt="plan 2"
                                  className="img-fluid lock_logo"
                                />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </Card.Body>
                      </Card>

                      {/* card red bg start */}
                      {!userdatax3?.purchasedLevels?.includes(board2[1]?._id) &&
                        userdatax3?.levelUsers[3]?.missedid?.length > 0 && (
                          <div className="board_plan_card_bg">
                            <Card className="level_card">
                              <Card.Body className="position-relative">
                                <div className="">
                                  <div className="plan_custom_navi">
                                    <div className="head_sec d-flex justify-content-between">
                                      <span>Level 2</span>
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={Coin}
                                          className="coin_logo me-1"
                                          alt="coin"
                                        />
                                        <span>10000</span>
                                      </div>
                                    </div>
                                    <div className="body_sec">
                                      <div className="list">
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                            <div className="card_missed">
                              <div className="locked_plann">
                                <p className="mb-2 plan_level">Level 2</p>
                                <p className="mb-0">
                                  Missed reward :{" "}
                                  {
                                    userdatax3?.levelUsers[3]?.missedid
                                      ?.length
                                  }
                                </p>
                                {/* <p className="mb-0">Missed amount : {(parseFloat(board1status[1]?.price?.toString())/10**6)*10/100}</p> */}
                              </div>
                            </div>
                          </div>
                        )}
                      {/* card red bg end */}

                      <div className="partner_total_profits new_party_profit">
                        <div className="content_grp">
                          <p className="header mb-2">Team Size</p>
                          <div className="d-flex justify-content-center align-items-center content">
                            <span>
                              <img
                                src={userGroup}
                                alt="usergroup"
                                className="img-fluid user_icon pe-2"
                              />
                            </span>
                            <span>
                              {userdatax3?.levelUsers[2]?.referral?.length +
                                userdatax3?.levelUsers[3]?.referral?.length}
                            </span>
                          </div>
                        </div>
                        <div className="content_grp">
                          <p className="header mb-2">Max Team Size</p>
                          <div className="d-flex justify-content-center align-items-center content">
                            <span>
                              <img
                                src={userGroup}
                                alt="reload"
                                className="img-fluid reload_icon pe-2"
                              />
                            </span>
                            <span>{12}</span>
                          </div>
                        </div>
                        <div className="content_grp">
                          <p className="header mb-2">Total Profits</p>
                          <div className="d-flex justify-content-center align-items-center content">
                            <span>
                              <img
                                src={cardIcon}
                                alt="usergroup"
                                className="img-fluid card_icon pe-2"
                              />
                            </span>
                            <span onClick={() => {
                              setTopic("Profit");
                          setAmount(boardprofitX3[2]?.profit + boardprofitX3[3]?.profit)
                            handleShow(boardprofitX3[2]?.totaltransaction, boardprofitX3[3]?.totaltransaction)}}>
                              {(boardprofitX3[2]?.profit + boardprofitX3[3]?.profit) - (getBonus(boardprofitX3[2]?.totaltransaction, boardprofitX3[3]?.totaltransaction , 2 , 3)
                         ? getBonus(boardprofitX3[2]?.totaltransaction, boardprofitX3[3]?.totaltransaction , 2 , 3) : 0)}
                            
                            </span>
                            <span style={{color:"green"}}>+
                         {getBonus(boardprofitX3[2]?.totaltransaction, boardprofitX3[3]?.totaltransaction , 2 , 3)
                         ? getBonus(boardprofitX3[2]?.totaltransaction, boardprofitX3[3]?.totaltransaction , 2 , 3) : 0
                          }
                          </span>
                            {/* <span style={{color:"green"}}> */}
                            {/* {((parseFloat(bonus[2]?.totalProfit) + parseFloat(bonus[3]?.totalProfit)) - 
                            (parseFloat(board2[0]?.planAmount) *
                            getcalculation(userdatax3?.levelUsers[2]?.missedid , userdatax3?.levelUsers[2]?.referral) -
                            ((parseFloat(board2[0]?.planAmount) *
                            getcalculation(userdatax3?.levelUsers[2]?.missedid , userdatax3?.levelUsers[2]?.referral)) /
                              100) *
                              2.5 +
                            (parseFloat(board2[1]?.planAmount) *
                            getcalculation(userdatax3?.levelUsers[3]?.missedid , userdatax3?.levelUsers[3]?.referral) -
                              ((parseFloat(board2[1]?.planAmount) *
                              getcalculation(userdatax3?.levelUsers[3]?.missedid , userdatax3?.levelUsers[3]?.referral)) /
                                100) *
                                2.5))) >= 0 ? ((parseFloat(bonus[2]?.totalProfit) + parseFloat(bonus[3]?.totalProfit)) - 
                                (parseFloat(board2[0]?.planAmount) *
                                getcalculation(userdatax3?.levelUsers[2]?.missedid , userdatax3?.levelUsers[2]?.referral) -
                                ((parseFloat(board2[0]?.planAmount) *
                                getcalculation(userdatax3?.levelUsers[2]?.missedid , userdatax3?.levelUsers[2]?.referral)) /
                                  100) *
                                  2.5 +
                                (parseFloat(board2[1]?.planAmount) *
                                getcalculation(userdatax3?.levelUsers[3]?.missedid , userdatax3?.levelUsers[3]?.referral) -
                                  ((parseFloat(board2[1]?.planAmount) *
                                  getcalculation(userdatax3?.levelUsers[3]?.missedid , userdatax3?.levelUsers[3]?.referral)) /
                                    100) *
                                    2.5))) : 0
                            
                            } */}
                            {/* {((parseFloat(bonus[2]?.totalProfit) + parseFloat(bonus[3]?.totalProfit))
                              -(getdata(board2[0] , board2[1] , 2) + getdata(board2[1] , board3[0] , 3)))>=0 ? 
                              (parseFloat(bonus[2]?.totalProfit) + parseFloat(bonus[3]?.totalProfit))
                              -(getdata(board2[0] , board2[1] , 2) + getdata(board2[1] , board3[0] , 3)) : 0
                            }
                            </span> */}
                            <span className="red_err_txt" onClick={() => 
                            {setTopic("Missed Reward");
                              setAmount(boardprofitX3[2]?.missed + boardprofitX3[3]?.missed)
                              handleShow(boardprofitX3[2]?.misshistory, boardprofitX3[3]?.misshistory)}}>
                               + {boardprofitX3[2]?.missed + boardprofitX3[3]?.missed}
                                 {/* + {getdatamissed(board2[0] , board2[1] , 2) + getdatamissed(board2[1] , board3[0] , 3)} */}
                              </span>

                              <span style={{ color: "orange" }} 
                                      onClick={() => {setTopic("Paid For NextLevel");handleShowpaid(2)}}
                                      >+
                                      {userdatax3?.levelUsers[2]?.paidfornextlevel + userdatax3?.levelUsers[3]?.paidfornextlevel}
                                      </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* ) : (
                <LoadingBoardPlanOne />
              )}  */}

              {/* {!board3loader ? ( */}
              <div className="board_collection" data-dot="<button>01</button>">
                {board2?.length > 0 && (
                  <div>
                    <h6 className="heading_txt mb-0">Board 3</h6>
                    <div className="my-4">
                      <Card className="level_card">
                        <Card.Body className="position-relative">
                          <div
                            className={
                              !userdatax3?.purchasedLevels?.includes(
                                board3[0]?._id
                              )
                                ? "blur_card_sec"
                                : ""
                            }
                          >
                            <div className="plan_custom_navi">
                              <div className="head_sec d-flex justify-content-between">
                                <span>Level 1</span>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={Coin}
                                    className="coin_logo me-1"
                                    alt="coin"
                                  />
                                  <span>
                                    {parseFloat(board3[0]?.planAmount)}
                                  </span>
                                </div>
                              </div>
                              <div className="body_sec">
                                <div className="list">
                                  <span
                                    className={
                                      userdatax3?.levelUsers[4]?.referralid
                                        ?.length > 0
                                        ? userdatax3?.levelUsers[4]?.missedid?.includes(userdatax3?.levelUsers[4]?.referralid[0]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                        : "cmn_bg_color in_completed"
                                    }
                                    onClick={()=> userdatax3?.levelUsers[4]?.referralid
                                      ?.length > 0 && window.open("/user-dashboard/" + userdatax3?.levelUsers[4]?.referralid[0] , "_blank")}
                                  >
                                    {userdatax3?.levelUsers[4]?.referralid[0]}
                                    {/* {userdatax3?.levelUsers[4]?.referral
                                        ?.length > 0 && (`${userdatax3?.levelUsers[4]?.referral[0]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[4]?.referral[0]?.substring(32, 34)}`)} */}
                                  </span>
                                  <span
                                    className={
                                      userdatax3?.levelUsers[4]?.referralid
                                        ?.length > 1
                                        ? userdatax3?.levelUsers[4]?.missedid?.includes(userdatax3?.levelUsers[4]?.referralid[1]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                        : "cmn_bg_color in_completed"
                                    }
                                    onClick={()=> userdatax3?.levelUsers[4]?.referralid
                                      ?.length > 1 && window.open("/user-dashboard/" + userdatax3?.levelUsers[4]?.referralid[1] , "_blank")}
                                  >
                                    {userdatax3?.levelUsers[4]?.referralid[1]}
                                    {/* {userdatax3?.levelUsers[4]?.referral
                                        ?.length > 1 && (`${userdatax3?.levelUsers[4]?.referral[1]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[4]?.referral[1]?.substring(32, 34)}`)} */}
                                  </span>
                                  <span
                                    className={
                                      userdatax3?.levelUsers[4]?.referralid
                                        ?.length > 2
                                        ?userdatax3?.levelUsers[4]?.missedid?.includes(userdatax3?.levelUsers[4]?.referralid[2]) ? "cmn_bg_color red_bg" :  "cmn_bg_color completed"
                                        : "cmn_bg_color in_completed"
                                    }
                                    onClick={()=> userdatax3?.levelUsers[4]?.referralid
                                      ?.length > 2 && window.open("/user-dashboard/" + userdatax3?.levelUsers[4]?.referralid[2] , "_blank")}
                                  >
                                    {userdatax3?.levelUsers[4]?.referralid[2]}
                                    {/* {userdatax3?.levelUsers[4]?.referralid
                                        ?.length > 2 && (`${userdatax3?.levelUsers[4]?.referralid[2]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[4]?.referral[2]?.substring(32, 34)}`)} */}
                                  </span>
                                </div>
                              </div>
                              {/* <div className="foot_sec">
                                <span className="d-flex align-items-center">
                                  <img
                                    src={userGroup}
                                    alt="usergroup"
                                    className="img-fluid user_icon pe-2"
                                  />
                                  {1}
                                </span>
                                <span className="d-flex align-items-center">
                                  <img
                                    src={reload}
                                    alt="reload"
                                    className="img-fluid reload_icon pe-2"
                                  />
                                  0
                                </span>
                              </div> */}
                            </div>
                          </div>
                          {!userdatax3?.purchasedLevels?.includes(
                            board3[0]?._id
                          ) ? (
                            <>
                              <div className="locked_plan">
                                <p className="mb-2 plan_level">Level 1</p>
                                <img
                                  src={lockIcon}
                                  alt="plan 2"
                                  className="img-fluid lock_logo"
                                />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </Card.Body>
                      </Card>
                      {/* board1[0][5]?.toString() > 0 && */}
                      {!userdatax3?.purchasedLevels?.includes(board3[0]?._id) &&
                        userdatax3?.levelUsers[4]?.missedid?.length > 0 && (
                          <div className="board_plan_card_bg">
                            <Card className="level_card">
                              <Card.Body className="position-relative">
                                <div className="">
                                  <div className="plan_custom_navi">
                                    <div className="head_sec d-flex justify-content-between">
                                      <span>Level 1</span>
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={Coin}
                                          className="coin_logo me-1"
                                          alt="coin"
                                        />
                                        <span>{board3[1]?.planAmount}</span>
                                      </div>
                                    </div>
                                    <div className="body_sec">
                                      <div className="list">
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                            <div className="card_missed">
                              <div className="locked_plann">
                                <p className="mb-2 plan_level">Level 1</p>
                                <p className="mb-0">
                                  Missed reward :{" "}
                                  {
                                    userdatax3?.levelUsers[4]?.missedid
                                      ?.length
                                  }
                                </p>
                                <p className="mb-0">Missed amount : {}</p>
                              </div>
                            </div>
                          </div>
                        )}

                      <Card className="level_card mt-5">
                        <Card.Body className="position-relative">
                          <div
                            className={
                              !userdatax3?.purchasedLevels?.includes(
                                board3[1]?._id
                              )
                                ? "blur_card_sec"
                                : ""
                            }
                          >
                            <div className="plan_custom_navi">
                              <div className="head_sec d-flex justify-content-between">
                                <span>Level 2</span>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={Coin}
                                    className="coin_logo me-1"
                                    alt="coin"
                                  />
                                  <span>{board3[1]?.planAmount}</span>
                                </div>
                              </div>
                              <div className="body_sec custom_board_plan_parti_slider">
                                <div className="list">
                                  <div className="custom_slider_sec">
                                    <Slider
                                      {...settings}
                                      className="custom_slider"
                                    >
                                      <span
                                        className={
                                          userdatax3?.levelUsers[5]?.referralid
                                            ?.length > 0
                                            ? userdatax3?.levelUsers[5]?.missedid?.includes(userdatax3?.levelUsers[5]?.referralid[0]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[5]?.referralid
                                          ?.length > 0 && window.open("/user-dashboard/" + userdatax3?.levelUsers[5]?.referralid[0] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[5]?.referralid[0]}
                                        {/* {userdatax3?.levelUsers[5]?.referral
                                        ?.length > 0 && (`${userdatax3?.levelUsers[5]?.referral[0]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[5]?.referral[0]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[5]?.referralid
                                            ?.length > 1
                                            ? userdatax3?.levelUsers[5]?.missedid?.includes(userdatax3?.levelUsers[5]?.referralid[1]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[5]?.referralid
                                          ?.length > 1 && window.open("/user-dashboard/" + userdatax3?.levelUsers[5]?.referralid[1] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[5]?.referralid[1]}
                                        {/* {userdatax3?.levelUsers[5]?.referral
                                        ?.length > 1 && (`${userdatax3?.levelUsers[5]?.referral[1]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[5]?.referral[1]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[5]?.referralid
                                            ?.length > 2
                                            ? userdatax3?.levelUsers[5]?.missedid?.includes(userdatax3?.levelUsers[5]?.referralid[2]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[5]?.referralid
                                          ?.length > 2 && window.open("/user-dashboard/" + userdatax3?.levelUsers[5]?.referralid[2] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[5]?.referralid[2]}
                                        {/* {userdatax3?.levelUsers[5]?.referral
                                        ?.length > 2 && (`${userdatax3?.levelUsers[5]?.referral[2]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[5]?.referral[2]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[5]?.referralid
                                            ?.length > 3
                                            ? userdatax3?.levelUsers[5]?.missedid?.includes(userdatax3?.levelUsers[5]?.referralid[3]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[5]?.referralid
                                          ?.length > 3 && window.open("/user-dashboard/" + userdatax3?.levelUsers[5]?.referralid[3] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[5]?.referralid[3]}
                                        {/* {userdatax3?.levelUsers[5]?.referral
                                        ?.length > 3 && (`${userdatax3?.levelUsers[5]?.referral[3]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[5]?.referral[3]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[5]?.referralid
                                            ?.length > 4
                                            ? userdatax3?.levelUsers[5]?.missedid?.includes(userdatax3?.levelUsers[5]?.referralid[4]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[5]?.referralid
                                          ?.length > 4 && window.open("/user-dashboard/" + userdatax3?.levelUsers[5]?.referralid[4] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[5]?.referralid[4]}
                                        {/* {userdatax3?.levelUsers[5]?.referral
                                        ?.length > 4 && (`${userdatax3?.levelUsers[5]?.referral[4]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[5]?.referral[4]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[5]?.referralid
                                            ?.length > 5
                                            ?userdatax3?.levelUsers[5]?.missedid?.includes(userdatax3?.levelUsers[5]?.referralid[5]) ? "cmn_bg_color red_bg" :  "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[5]?.referralid
                                          ?.length > 5 && window.open("/user-dashboard/" + userdatax3?.levelUsers[5]?.referralid[5] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[5]?.referralid[5]}
                                        {/* {userdatax3?.levelUsers[5]?.referral
                                        ?.length > 5 && (`${userdatax3?.levelUsers[5]?.referral[5]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[5]?.referral[5]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[5]?.referralid
                                            ?.length > 6
                                            ? userdatax3?.levelUsers[5]?.missedid?.includes(userdatax3?.levelUsers[5]?.referralid[6]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[5]?.referralid
                                          ?.length > 6 && window.open("/user-dashboard/" + userdatax3?.levelUsers[5]?.referralid[6] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[5]?.referralid[6]}
                                        {/* {userdatax3?.levelUsers[5]?.referral
                                        ?.length > 6 && (`${userdatax3?.levelUsers[5]?.referral[6]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[5]?.referral[6]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[5]?.referralid
                                            ?.length > 7
                                            ? userdatax3?.levelUsers[5]?.missedid?.includes(userdatax3?.levelUsers[5]?.referralid[7]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[5]?.referralid
                                          ?.length > 7 && window.open("/user-dashboard/" + userdatax3?.levelUsers[5]?.referralid[7] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[5]?.referralid[7]}
                                        {/* {userdatax3?.levelUsers[5]?.referral
                                        ?.length > 7 && (`${userdatax3?.levelUsers[5]?.referral[7]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[5]?.referral[7]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[5]?.referralid
                                            ?.length > 8
                                            ? userdatax3?.levelUsers[5]?.missedid?.includes(userdatax3?.levelUsers[5]?.referralid[8]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[5]?.referralid
                                          ?.length > 8 && window.open("/user-dashboard/" + userdatax3?.levelUsers[5]?.referralid[8] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[5]?.referralid[8]}
                                        {/* {userdatax3?.levelUsers[5]?.referral
                                        ?.length > 8 && (`${userdatax3?.levelUsers[5]?.referral[8]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[5]?.referral[8]?.substring(32, 34)}`)} */}
                                      </span>
                                    </Slider>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="foot_sec">
                                <span className="d-flex align-items-center">
                                  <img
                                    src={userGroup}
                                    alt="usergroup"
                                    className="img-fluid user_icon pe-2"
                                  />
                                  1
                                </span>
                                <span className="d-flex align-items-center">
                                  <img
                                    src={reload}
                                    alt="reload"
                                    className="img-fluid reload_icon pe-2"
                                  />
                                  0
                                </span>
                              </div> */}
                            </div>
                          </div>
                          {/* {board1status[1]?.status == false ? (
                              <>
                                <div className="locked_plan">
                                  <p className="mb-2 plan_level">Board Plan</p>
                                  <Lottie
                                    className="lock_lottie"
                                    animationData={lock}
                                    loop={true}
                                  />
                                  <Button className="unlock_btn common_yellow_btn">
                                    Unlock
                                  </Button>
                                </div>
                              </>
                            ) : (
                              <></>
                            )} */}
                          {!userdatax3?.purchasedLevels?.includes(
                            board3[1]?._id
                          ) ? (
                            <>
                              <div
                                className="locked_plan"
                                onClick={() => {
                                  showToast(
                                    "error",
                                    "You can't buy plan2 It will automatically upgrade"
                                  );
                                }}
                              >
                                <p className="mb-2 plan_level">Level 2</p>
                                <img
                                  src={lockIcon}
                                  alt="plan 2"
                                  className="img-fluid lock_logo"
                                />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </Card.Body>
                      </Card>

                      {/* card red bg start */}
                      {!userdatax3?.purchasedLevels?.includes(board3[1]?._id) &&
                        userdatax3?.levelUsers[5]?.missedid?.length > 0 && (
                          <div className="board_plan_card_bg">
                            <Card className="level_card">
                              <Card.Body className="position-relative">
                                <div className="">
                                  <div className="plan_custom_navi">
                                    <div className="head_sec d-flex justify-content-between">
                                      <span>Level 2</span>
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={Coin}
                                          className="coin_logo me-1"
                                          alt="coin"
                                        />
                                        <span>10000</span>
                                      </div>
                                    </div>
                                    <div className="body_sec">
                                      <div className="list">
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                            <div className="card_missed">
                              <div className="locked_plann">
                                <p className="mb-2 plan_level">Level 2</p>
                                <p className="mb-0">
                                  Missed reward :{" "}
                                  {
                                    userdatax3?.levelUsers[5]?.missedid
                                      ?.length
                                  }
                                </p>
                                {/* <p className="mb-0">Missed amount : {(parseFloat(board1status[1]?.price?.toString())/10**6)*10/100}</p> */}
                              </div>
                            </div>
                          </div>
                        )}
                      {/* card red bg end */}

                      <div className="partner_total_profits new_party_profit">
                        <div className="content_grp">
                          <p className="header mb-2">Team Size</p>
                          <div className="d-flex justify-content-center align-items-center content">
                            <span>
                              <img
                                src={userGroup}
                                alt="usergroup"
                                className="img-fluid user_icon pe-2"
                              />
                            </span>
                            <span>
                              {userdatax3?.levelUsers[4]?.referral?.length +
                                userdatax3?.levelUsers[5]?.referral?.length}
                            </span>
                          </div>
                        </div>
                        <div className="content_grp">
                          <p className="header mb-2">Max Team Size</p>
                          <div className="d-flex justify-content-center align-items-center content">
                            <span>
                              <img
                                src={userGroup}
                                alt="reload"
                                className="img-fluid reload_icon pe-2"
                              />
                            </span>
                            <span>{12}</span>
                          </div>
                        </div>
                        <div className="content_grp">
                          <p className="header mb-2">Total Profits</p>
                          <div className="d-flex justify-content-center align-items-center content">
                            <span>
                              <img
                                src={cardIcon}
                                alt="usergroup"
                                className="img-fluid card_icon pe-2"
                              />
                            </span>
                            
                            <span onClick={() => {
                              setTopic("Profit");
                              setAmount(boardprofitX3[4]?.profit + boardprofitX3[5]?.profit);
                              handleShow(boardprofitX3[4]?.totaltransaction, boardprofitX3[5]?.totaltransaction)
                            }}>
                              {(boardprofitX3[4]?.profit + boardprofitX3[5]?.profit) - 
                              getBonus(boardprofitX3[4]?.totaltransaction, boardprofitX3[5]?.totaltransaction , 4 , 5)
                              ? getBonus(boardprofitX3[4]?.totaltransaction, boardprofitX3[5]?.totaltransaction , 4 , 5) : 0
                              }
                              
                            </span>
                            <span style={{color:"green"}}>+
                         {getBonus(boardprofitX3[4]?.totaltransaction, boardprofitX3[5]?.totaltransaction , 4 , 5)
                         ? getBonus(boardprofitX3[4]?.totaltransaction, boardprofitX3[5]?.totaltransaction , 4 , 5) : 0
                          }
                          </span>
                            {/* <span style={{color:"green"}}> */}
                            {/* {((parseFloat(bonus[4]?.totalProfit) + parseFloat(bonus[5]?.totalProfit)) - 
                            (parseFloat(board3[0]?.planAmount) *
                            getcalculation(userdatax3?.levelUsers[4]?.missedid , userdatax3?.levelUsers[4]?.referral) -
                            ((parseFloat(board3[0]?.planAmount) *
                            getcalculation(userdatax3?.levelUsers[4]?.missedid , userdatax3?.levelUsers[4]?.referral)) /
                              100) *
                              2.5 +
                            (parseFloat(board3[1]?.planAmount) *
                            getcalculation(userdatax3?.levelUsers[5]?.missedid , userdatax3?.levelUsers[5]?.referral) -
                              ((parseFloat(board3[1]?.planAmount) *
                              getcalculation(userdatax3?.levelUsers[5]?.missedid , userdatax3?.levelUsers[5]?.referral)) /
                                100) *
                                2.5))) >= 0 ? ((parseFloat(bonus[4]?.totalProfit) + parseFloat(bonus[5]?.totalProfit)) - 
                                (parseFloat(board3[0]?.planAmount) *
                                getcalculation(userdatax3?.levelUsers[4]?.missedid , userdatax3?.levelUsers[4]?.referral) -
                                ((parseFloat(board3[0]?.planAmount) *
                                getcalculation(userdatax3?.levelUsers[4]?.missedid , userdatax3?.levelUsers[4]?.referral)) /
                                  100) *
                                  2.5 +
                                (parseFloat(board3[1]?.planAmount) *
                                getcalculation(userdatax3?.levelUsers[5]?.missedid , userdatax3?.levelUsers[5]?.referral) -
                                  ((parseFloat(board3[1]?.planAmount) *
                                  getcalculation(userdatax3?.levelUsers[5]?.missedid , userdatax3?.levelUsers[5]?.referral)) /
                                    100) *
                                    2.5))) : 0
                            } */}
                            {/* {((parseFloat(bonus[4]?.totalProfit) + parseFloat(bonus[5]?.totalProfit))
                              -(getdata(board3[0] , board3[1] , 4) + getdata(board3[1] , board4[0] , 5))) >= 0 ? 
                              (parseFloat(bonus[4]?.totalProfit) + parseFloat(bonus[5]?.totalProfit))
                              -(getdata(board3[0] , board3[1] , 4) + getdata(board3[1] , board4[0] , 5))
                            :0
                            }
                            </span> */}
                            <span className="red_err_txt"onClick={() => {
                              setTopic("Missed Reward");
                              setAmount(boardprofitX3[4]?.missed + boardprofitX3[5]?.missed);
                              handleShow(boardprofitX3[4]?.misshistory, boardprofitX3[5]?.misshistory)}}> 
                            {/* {getdatamissed(board3[0] , board3[1] , 4) + getdatamissed(board3[1] , board4[0] , 5)} */}
                            +{boardprofitX3[4]?.missed + boardprofitX3[5]?.missed}
                            </span>

                            <span style={{ color: "orange" }} 
                                      onClick={() => {setTopic("Paid For NextLevel");handleShowpaid(4)}}
                                      >+
                                      {userdatax3?.levelUsers[4]?.paidfornextlevel + userdatax3?.levelUsers[5]?.paidfornextlevel}
                                      </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* ) : (
                <LoadingBoardPlanOne />
              )}  */}

              {/* {!board4loader ? ( */}
              <div className="board_collection" data-dot="<button>01</button>">
                {board4?.length > 0 && (
                  <div>
                    <h6 className="heading_txt mb-0">Board 4</h6>
                    <div className="my-4">
                      <Card className="level_card">
                        <Card.Body className="position-relative">
                          <div
                            className={
                              !userdatax3?.purchasedLevels?.includes(
                                board4[0]?._id
                              )
                                ? "blur_card_sec"
                                : ""
                            }
                          >
                            <div className="plan_custom_navi">
                              <div className="head_sec d-flex justify-content-between">
                                <span>Level 1</span>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={Coin}
                                    className="coin_logo me-1"
                                    alt="coin"
                                  />
                                  <span>
                                    {parseFloat(board4[0]?.planAmount)}
                                  </span>
                                </div>
                              </div>
                              <div className="body_sec">
                                <div className="list">
                                  <span
                                    className={
                                      userdatax3?.levelUsers[6]?.referralid
                                        ?.length > 0
                                        ? userdatax3?.levelUsers[6]?.missedid?.includes(userdatax3?.levelUsers[6]?.referralid[0]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                        : "cmn_bg_color in_completed"
                                    }
                                    onClick={()=> userdatax3?.levelUsers[6]?.referralid
                                      ?.length > 0 && window.open("/user-dashboard/" + userdatax3?.levelUsers[6]?.referralid[0] , "_blank")}
                                  >
                                    {userdatax3?.levelUsers[5]?.referralid[0]}
                                    {/* {userdatax3?.levelUsers[6]?.referral
                                        ?.length > 0 && (`${userdatax3?.levelUsers[5]?.referral[0]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[5]?.referral[0]?.substring(32, 34)}`)} */}
                                  </span>
                                  <span
                                    className={
                                      userdatax3?.levelUsers[6]?.referralid
                                        ?.length > 1
                                        ?userdatax3?.levelUsers[6]?.missedid?.includes(userdatax3?.levelUsers[6]?.referralid[1]) ? "cmn_bg_color red_bg" :  "cmn_bg_color completed"
                                        : "cmn_bg_color in_completed"
                                    }
                                    onClick={()=> userdatax3?.levelUsers[6]?.referralid
                                      ?.length > 1 && window.open("/user-dashboard/" + userdatax3?.levelUsers[6]?.referralid[1] , "_blank")}
                                  >
                                    {userdatax3?.levelUsers[5]?.referralid[1]}
                                    {/* {userdatax3?.levelUsers[6]?.referral
                                        ?.length > 1 && (`${userdatax3?.levelUsers[5]?.referral[1]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[5]?.referral[1]?.substring(32, 34)}`)} */}
                                  </span>
                                  <span
                                    className={
                                      userdatax3?.levelUsers[6]?.referralid
                                        ?.length > 2
                                        ?userdatax3?.levelUsers[6]?.missedid?.includes(userdatax3?.levelUsers[6]?.referralid[2]) ? "cmn_bg_color red_bg" :  "cmn_bg_color completed"
                                        : "cmn_bg_color in_completed"
                                    }
                                    onClick={()=> userdatax3?.levelUsers[6]?.referralid
                                      ?.length > 2 && window.open("/user-dashboard/" + userdatax3?.levelUsers[6]?.referralid[2] , "_blank")}
                                  >
                                    {userdatax3?.levelUsers[5]?.referralid[2]}
                                    {/* {userdatax3?.levelUsers[6]?.referral
                                        ?.length > 2 && (`${userdatax3?.levelUsers[5]?.referral[2]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[5]?.referral[2]?.substring(32, 34)}`)} */}
                                  </span>
                                </div>
                              </div>
                              {/* <div className="foot_sec">
                                <span className="d-flex align-items-center">
                                  <img
                                    src={userGroup}
                                    alt="usergroup"
                                    className="img-fluid user_icon pe-2"
                                  />
                                  {1}
                                </span>
                                <span className="d-flex align-items-center">
                                  <img
                                    src={reload}
                                    alt="reload"
                                    className="img-fluid reload_icon pe-2"
                                  />
                                  0
                                </span>
                              </div> */}
                            </div>
                          </div>
                          {!userdatax3?.purchasedLevels?.includes(
                            board4[0]?._id
                          ) ? (
                            <>
                              <div className="locked_plan">
                                <p className="mb-2 plan_level">Level 1</p>
                                <img
                                  src={lockIcon}
                                  alt="plan 2"
                                  className="img-fluid lock_logo"
                                />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </Card.Body>
                      </Card>
                      {/* board1[0][5]?.toString() > 0 && */}
                      {!userdatax3?.purchasedLevels?.includes(board4[0]?._id) &&
                        userdatax3?.levelUsers[6]?.missedid?.length > 0 && (
                          <div className="board_plan_card_bg">
                            <Card className="level_card">
                              <Card.Body className="position-relative">
                                <div className="">
                                  <div className="plan_custom_navi">
                                    <div className="head_sec d-flex justify-content-between">
                                      <span>Level 1</span>
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={Coin}
                                          className="coin_logo me-1"
                                          alt="coin"
                                        />
                                        <span>{board4[1]?.planAmount}</span>
                                      </div>
                                    </div>
                                    <div className="body_sec">
                                      <div className="list">
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                            <div className="card_missed">
                              <div className="locked_plann">
                                <p className="mb-2 plan_level">Level 1</p>
                                <p className="mb-0">
                                  Missed reward :{" "}
                                  {
                                    userdatax3?.levelUsers[6]?.missedid
                                      ?.length
                                  }
                                </p>
                                {/* <p className="mb-0">Missed amount : {}</p> */}
                              </div>
                            </div>
                          </div>
                        )}

                      <Card className="level_card mt-5">
                        <Card.Body className="position-relative">
                          <div
                            className={
                              !userdatax3?.purchasedLevels?.includes(
                                board4[1]?._id
                              )
                                ? "blur_card_sec"
                                : ""
                            }
                          >
                            <div className="plan_custom_navi">
                              <div className="head_sec d-flex justify-content-between">
                                <span>Level 2</span>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={Coin}
                                    className="coin_logo me-1"
                                    alt="coin"
                                  />
                                  <span>{board4[1]?.planAmount}</span>
                                </div>
                              </div>
                              <div className="body_sec custom_board_plan_parti_slider">
                                <div className="list">
                                  <div className="custom_slider_sec">
                                    <Slider
                                      {...settings}
                                      className="custom_slider"
                                    >
                                      <span
                                        className={
                                          userdatax3?.levelUsers[7]?.referralid
                                            ?.length > 0
                                            ? userdatax3?.levelUsers[7]?.missedid?.includes(userdatax3?.levelUsers[7]?.referralid[0]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[7]?.referralid
                                          ?.length > 0 && window.open("/user-dashboard/" + userdatax3?.levelUsers[7]?.referralid[0] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[7]?.referralid[0]}
                                        {/* {userdatax3?.levelUsers[7]?.referralid
                                        ?.length > 0 && (`${userdatax3?.levelUsers[7]?.referralid[0]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[7]?.referral[0]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[7]?.referralid
                                            ?.length > 1
                                            ?userdatax3?.levelUsers[7]?.missedid?.includes(userdatax3?.levelUsers[7]?.referralid[1]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[7]?.referralid
                                          ?.length > 1 && window.open("/user-dashboard/" + userdatax3?.levelUsers[7]?.referralid[1] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[7]?.referralid[1]}
                                        {/* {userdatax3?.levelUsers[7]?.referral
                                        ?.length > 1 && (`${userdatax3?.levelUsers[7]?.referral[1]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[7]?.referral[1]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[7]?.referralid
                                            ?.length > 2
                                            ? userdatax3?.levelUsers[7]?.missedid?.includes(userdatax3?.levelUsers[7]?.referralid[2]) ? "cmn_bg_color red_bg" :"cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[7]?.referralid
                                          ?.length > 2 && window.open("/user-dashboard/" + userdatax3?.levelUsers[7]?.referralid[2] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[7]?.referralid[2]}
                                        {/* {userdatax3?.levelUsers[7]?.referral
                                        ?.length > 2 && (`${userdatax3?.levelUsers[7]?.referral[2]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[7]?.referral[2]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[7]?.referralid
                                            ?.length > 3
                                            ? userdatax3?.levelUsers[7]?.missedid?.includes(userdatax3?.levelUsers[7]?.referralid[3]) ? "cmn_bg_color red_bg" :"cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[7]?.referralid
                                          ?.length > 3 && window.open("/user-dashboard/" + userdatax3?.levelUsers[7]?.referralid[3] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[7]?.referralid[3]}
                                        {/* {userdatax3?.levelUsers[7]?.referral
                                        ?.length > 3 && (`${userdatax3?.levelUsers[7]?.referral[3]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[7]?.referral[3]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[7]?.referralid
                                            ?.length > 4
                                            ? userdatax3?.levelUsers[7]?.missedid?.includes(userdatax3?.levelUsers[7]?.referralid[4]) ? "cmn_bg_color red_bg" :"cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[7]?.referralid
                                          ?.length > 4 && window.open("/user-dashboard/" + userdatax3?.levelUsers[7]?.referralid[4] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[7]?.referralid[4]}
                                        {/* {userdatax3?.levelUsers[7]?.referral
                                        ?.length > 4 && (`${userdatax3?.levelUsers[7]?.referral[4]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[7]?.referral[4]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[7]?.referralid
                                            ?.length > 5
                                            ? userdatax3?.levelUsers[7]?.missedid?.includes(userdatax3?.levelUsers[7]?.referralid[5]) ? "cmn_bg_color red_bg" :"cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[7]?.referralid
                                          ?.length > 5 && window.open("/user-dashboard/" + userdatax3?.levelUsers[7]?.referralid[5] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[7]?.referralid[5]}
                                        {/* {userdatax3?.levelUsers[7]?.referral
                                        ?.length > 5 && (`${userdatax3?.levelUsers[7]?.referral[5]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[7]?.referral[5]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[7]?.referralid
                                            ?.length > 6
                                            ? userdatax3?.levelUsers[7]?.missedid?.includes(userdatax3?.levelUsers[7]?.referralid[6]) ? "cmn_bg_color red_bg" :"cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[7]?.referralid
                                          ?.length > 6 && window.open("/user-dashboard/" + userdatax3?.levelUsers[7]?.referralid[6] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[7]?.referralid[6]}
                                        {/* {userdatax3?.levelUsers[7]?.referral
                                        ?.length > 6 && (`${userdatax3?.levelUsers[7]?.referral[6]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[7]?.referral[6]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[7]?.referralid
                                            ?.length > 7
                                            ?userdatax3?.levelUsers[7]?.missedid?.includes(userdatax3?.levelUsers[7]?.referralid[7]) ? "cmn_bg_color red_bg" : "cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[7]?.referralid
                                          ?.length > 7 && window.open("/user-dashboard/" + userdatax3?.levelUsers[7]?.referralid[7] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[7]?.referralid[7]}
                                        {/* {userdatax3?.levelUsers[7]?.referral
                                        ?.length > 7 && (`${userdatax3?.levelUsers[7]?.referral[7]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[7]?.referral[7]?.substring(32, 34)}`)} */}
                                      </span>
                                      <span
                                        className={
                                          userdatax3?.levelUsers[7]?.referralid
                                            ?.length > 8
                                            ? userdatax3?.levelUsers[7]?.missedid?.includes(userdatax3?.levelUsers[7]?.referralid[8]) ? "cmn_bg_color red_bg" :"cmn_bg_color completed"
                                            : "cmn_bg_color in_completed"
                                        }
                                        onClick={()=> userdatax3?.levelUsers[7]?.referralid
                                          ?.length > 8 && window.open("/user-dashboard/" + userdatax3?.levelUsers[7]?.referralid[8] , "_blank")}
                                      >
                                        {userdatax3?.levelUsers[7]?.referralid[8]}
                                        {/* {userdatax3?.levelUsers[7]?.referral
                                        ?.length > 8 && (`${userdatax3?.levelUsers[7]?.referral[8]?.substring(
                                                      0,
                                                      2
                                                    )}...${userdatax3?.levelUsers[7]?.referral[8]?.substring(32, 34)}`)} */}
                                      </span>
                                    </Slider>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="foot_sec">
                                <span className="d-flex align-items-center">
                                  <img
                                    src={userGroup}
                                    alt="usergroup"
                                    className="img-fluid user_icon pe-2"
                                  />
                                  1
                                </span>
                                <span className="d-flex align-items-center">
                                  <img
                                    src={reload}
                                    alt="reload"
                                    className="img-fluid reload_icon pe-2"
                                  />
                                  0
                                </span>
                              </div> */}
                            </div>
                          </div>
                          {/* {board1status[1]?.status == false ? (
                              <>
                                <div className="locked_plan">
                                  <p className="mb-2 plan_level">Board Plan</p>
                                  <Lottie
                                    className="lock_lottie"
                                    animationData={lock}
                                    loop={true}
                                  />
                                  <Button className="unlock_btn common_yellow_btn">
                                    Unlock
                                  </Button>
                                </div>
                              </>
                            ) : (
                              <></>
                            )} */}
                          {!userdatax3?.purchasedLevels?.includes(
                            board4[1]?._id
                          ) ? (
                            <>
                              <div
                                className="locked_plan"
                                onClick={() => {
                                  showToast(
                                    "error",
                                    "You can't buy plan2 It will automatically upgrade"
                                  );
                                }}
                              >
                                <p className="mb-2 plan_level">Level 2</p>
                                <img
                                  src={lockIcon}
                                  alt="plan 2"
                                  className="img-fluid lock_logo"
                                />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </Card.Body>
                      </Card>

                      {/* card red bg start */}
                      {!userdatax3?.purchasedLevels?.includes(board4[1]?._id) &&
                        userdatax3?.levelUsers[7]?.missedid?.length > 0 && (
                          <div className="board_plan_card_bg">
                            <Card className="level_card">
                              <Card.Body className="position-relative">
                                <div className="">
                                  <div className="plan_custom_navi">
                                    <div className="head_sec d-flex justify-content-between">
                                      <span>Level 2</span>
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={Coin}
                                          className="coin_logo me-1"
                                          alt="coin"
                                        />
                                        <span>10000</span>
                                      </div>
                                    </div>
                                    <div className="body_sec">
                                      <div className="list">
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                        <span className="cmn_bg_color in_completed"></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                            <div className="card_missed">
                              <div className="locked_plann">
                                <p className="mb-2 plan_level">Level 2</p>
                                <p className="mb-0">
                                  Missed reward :{" "}
                                  {
                                    userdatax3?.levelUsers[7]?.missedid
                                      ?.length
                                  }
                                </p>
                                {/* <p className="mb-0">Missed amount : {(parseFloat(board1status[1]?.price?.toString())/10**6)*10/100}</p> */}
                              </div>
                            </div>
                          </div>
                        )}
                      {/* card red bg end */}

                      <div className="partner_total_profits new_party_profit">
                        <div className="content_grp">
                          <p className="header mb-2">Team Size</p>
                          <div className="d-flex justify-content-center align-items-center content">
                            <span>
                              <img
                                src={userGroup}
                                alt="usergroup"
                                className="img-fluid user_icon pe-2"
                              />
                            </span>
                            <span>
                              {userdatax3?.levelUsers[6]?.referral?.length +
                                userdatax3?.levelUsers[7]?.referral?.length}
                            </span>
                          </div>
                        </div>
                        <div className="content_grp">
                          <p className="header mb-2">Max Team Size</p>
                          <div className="d-flex justify-content-center align-items-center content">
                            <span>
                              <img
                                src={userGroup}
                                alt="reload"
                                className="img-fluid reload_icon pe-2"
                              />
                            </span>
                            <span>{12}</span>
                          </div>
                        </div>
                        <div className="content_grp">
                          <p className="header mb-2">Total Profits</p>
                          <div className="d-flex justify-content-center align-items-center content">
                            <span>
                              <img
                                src={cardIcon}
                                alt="usergroup"
                                className="img-fluid card_icon pe-2"
                              />
                            </span>
                            <span onClick={() => {
                              setTopic("Profit");
                              setAmount(boardprofitX3[6]?.profit + boardprofitX3[7]?.profit)
                              handleShow(boardprofitX3[6]?.totaltransaction + boardprofitX3[7]?.totaltransaction)
                            }}>
                             
                                   
                                    {
                                  (parseFloat(boardprofitX3[6]?.profit) + parseFloat(boardprofitX3[7]?.profit)) - 

                                  (getBonus(boardprofitX3[6]?.totaltransaction, boardprofitX3[7]?.totaltransaction , 6 , 7)
                         ? getBonus(boardprofitX3[6]?.totaltransaction, boardprofitX3[7]?.totaltransaction , 6 , 7) : 0)
                                  }
                             
                            </span>

                            <span style={{color:"green"}}>+
                         {getBonus(boardprofitX3[6]?.totaltransaction, boardprofitX3[7]?.totaltransaction , 6 , 7)
                         ? getBonus(boardprofitX3[6]?.totaltransaction, boardprofitX3[7]?.totaltransaction , 6 , 7) : 0
                          }
                          </span>
                            {/* <span style={{color:"green"}}>  */}
                              {/* {((parseFloat(bonus[6]?.totalProfit) + parseFloat(bonus[7]?.totalProfit))-
                              (parseFloat(board4[0]?.planAmount) *
                              getcalculation(userdatax3?.levelUsers[6]?.missedid , userdatax3?.levelUsers[6]?.referral) -
                              ((parseFloat(board4[0]?.planAmount) *
                              getcalculation(userdatax3?.levelUsers[6]?.missedid , userdatax3?.levelUsers[6]?.referral)) /
                                100) *
                                2.5 +
                              (parseFloat(board4[1]?.planAmount) *
                              getcalculation(userdatax3?.levelUsers[7]?.missedid , userdatax3?.levelUsers[7]?.referral) -
                                ((parseFloat(board4[1]?.planAmount) *
                                getcalculation(userdatax3?.levelUsers[7]?.missedid , userdatax3?.levelUsers[7]?.referral)) /
                                  100) *
                                  2.5))) >=0 ? ((parseFloat(bonus[6]?.totalProfit) + parseFloat(bonus[7]?.totalProfit))-
                                  (parseFloat(board4[0]?.planAmount) *
                                  getcalculation(userdatax3?.levelUsers[6]?.missedid , userdatax3?.levelUsers[6]?.referral) -
                                  ((parseFloat(board4[0]?.planAmount) *
                                  getcalculation(userdatax3?.levelUsers[6]?.missedid , userdatax3?.levelUsers[6]?.referral)) /
                                    100) *
                                    2.5 +
                                  (parseFloat(board4[1]?.planAmount) *
                                  getcalculation(userdatax3?.levelUsers[7]?.missedid , userdatax3?.levelUsers[7]?.referral) -
                                    ((parseFloat(board4[1]?.planAmount) *
                                    getcalculation(userdatax3?.levelUsers[7]?.missedid , userdatax3?.levelUsers[7]?.referral)) /
                                      100) *
                                      2.5))) : 0
                              } */}
  {/* {boardprofitX3[6]?.profit + boardprofitX3[7]?.profit} */}
                              {/* {((parseFloat(bonus[6]?.totalProfit) + parseFloat(bonus[7]?.totalProfit))
                              -(getdata(board4[0] , board4[1] , 6) + getdata(board4[1] , null , 7))) >=0 ? 
                              (parseFloat(bonus[6]?.totalProfit) + parseFloat(bonus[7]?.totalProfit))
                              -(getdata(board4[0] , board4[1] , 6) + getdata(board4[1] , null , 7)) :0
                            } */}
                            {/* </span> */}
                            <span className="red_err_txt" onClick={() => {
                              setTopic("Missed Reward");
                              setAmount(boardprofitX3[6]?.missed + boardprofitX3[7]?.missed)
                              handleShow(boardprofitX3[6]?.misshistory , boardprofitX3[7]?.misshistory)}}> 
                           +{(boardprofitX3[6]?.missed + boardprofitX3[7]?.missed) ? boardprofitX3[6]?.missed + boardprofitX3[7]?.missed : 0}</span>

                           <span style={{ color: "orange" }} 
                                      onClick={() => {setTopic("Paid For NextLevel");handleShowpaid(6)}}
                                      >+
                                      {userdatax3?.levelUsers[6]?.paidfornextlevel + userdatax3?.levelUsers[7]?.paidfornextlevel}
                                      </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* ) : (
                <LoadingBoardPlanOne />
              )}  */}

              {/* <LoadingBoardPlanOne /> */}

              {/* )} */}
            </OwlCarousel>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered size="lg" className="partners_side_section">
        <Modal.Header closeButton>
          <Modal.Title>
            Transaction
            {/* <div className="d-flex gap-3 align-items-center" >
              <h6 className="mb-0">Total Partner Count</h6>
              <Button className="partner_id total_partner_cnt">
                1
              </Button>
            </div> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table_sec">
            <Table responsive>
              <thead>
                <tr className="t_head">
                  <th>From</th>
                  {paidhistory[0]?.to&&  <th>To</th>}
                  {/* <th>Level</th> */} 
                  <th>Income</th>
                </tr>
              </thead>

              <tbody>
                {paidhistory && paidhistory?.length > 0 ? paidhistory?.map((item, i) => {
                  return(
                    <tr className="t_body">
                    <td>
                      {/* <a target="_blank" href = {window.location.origin +"/user-dashboard/"+(item?.walletAddress ? item?.walletAddress : item?.from)} className="custom_a_wht" >{`${(item?.walletAddress ? item?.walletAddress : item?.from)?.substring(
                                      0,
                                      5
                                    )}...${
                                      (item?.walletAddress ? item?.walletAddress : item?.from)
                                    ?.substring(31, 34)}`}</a> */}
                                    {item?.fromId}
                                    </td>
                    { paidhistory[0]?.to&& <td>
                      {/* <a target="_blank" href = {window.location.origin +"/user-dashboard/"+item?.to} className="custom_a_wht" >{`${item?.to?.substring(
                                      0,
                                      5
                                    )}...${
                                      item?.to
                                    ?.substring(31, 34)}`}</a> */}
                                    {item?.toId}
                                    </td>}
                    <td className="partner_id_td custom_plan_partner_id" style={{ width: "unset", minWidth: "unset" }}>
                      <Button className="partner_id">
                        {item?.amount ? item?.amount : (amount/ paidhistory?.length)}
                      </Button>
                    </td>

                  </tr>
                  )
                }) :<td colSpan={2}>
                <p className="mb-0 data_not_found_txt">No data found...</p>
              </td> }
               
              </tbody>

{console.log("paidnextlevel" , paidhistory)}
              
              {/* <tbody>
                <tr>
                  <td colSpan={4} style={{ borderBottom: "none" }}>
                    <p className="mb-0 data_not_found_txt">No Transaction Found</p>
                  </td>
                </tr>
              </tbody> */}




            </Table>
            {/* <div className="d-flex justify-content-center align-items-center">
              <button className="common_yellow_btn">Loading...</button>
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} className="common_yellow_btn btn btn-primary">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BoardPlanParticular;
