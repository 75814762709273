import React, { useEffect, useState } from "react";
import { Button, Container, Row } from 'react-bootstrap';
import Lottie from "lottie-react";
import { Link, NavLink } from "react-router-dom";
import moment from 'moment';
import Header from "./Header";
import Footer from "./Footer";
import World from "../Assets/Json/world.json";
import security from "../Assets/Json/privacy.json";
import Marquee from "react-fast-marquee";
import walletuser from "../Assets/walletuser.png";
import newuser from "../Assets/newuser.png";

import user_friendly from "../Assets/user_friendly.png";
import security_lvl from "../Assets/security_lvl.png";
import most_profit_platform from "../Assets/most_profit_platform.png";
import easy_to_implement from "../Assets/easy_to_implement.png";
import real_time_visibility from "../Assets/real_time_visibility.png";

import planimg1 from "../Assets/1.png";
import planimg2 from "../Assets/2.png";
import planimg3 from "../Assets/3.png";

import plan_1 from "../Assets/plan_1.png";
import plan_2 from "../Assets/plan_2.png";

import arrows from "../Assets/Json/arrow.json";
import income from "../Assets/img.png";
import income1 from "../Assets/img1.png";
import coinani from "../Assets/Json/coin.json";

import AOS from "aos";
import "aos/dist/aos.css";
import { Dummycreate, GetlevelpriceX2, GetRecenttransaction, Getuseraddress } from "../Hooks/UseContract";
import { UniversalMLMcontractAddress } from "../contracts/contract";
import { ClickLinkHooks } from "../Hooks/usebackend";

export default function Homepage() {

    const [parnetcard, setpartnercard] = useState([
        { "id": 1, "image": newuser, "user": "New User Joined", "userno": "", "idno": "ID 452356" }, { "id": 2, "image": walletuser, "user": "+6 TRX", "userno": "*4", "idno": "ID 452356" }, { "id": 3, "image": walletuser, "user": "+2TRX", "userno": "*4", "idno": "ID 452356" }, { "id": 4, "image": newuser, "user": "New User Joined", "userno": "", "idno": "ID 452356" }
    ]);

    const [chooseimg, setChooseimg] = useState([
        { "id": 1, "image": user_friendly, "name": "User Friendly platform" }, { "id": 2, "image": security_lvl, "name": "High Security Level" }, { "id": 3, "image": most_profit_platform, "name": "Most profitable platform" }, { "id": 4, "image": easy_to_implement, "name": "Easy to implement" }, { "id": 5, "image": real_time_visibility, "name": "Real time visibility" }
    ]);

    const [recent , setRecent] = useState([]);

    useEffect(() => {
        // AOS.init();
        AOS.init({disable: 'mobile'});
        console.log("windowlocation" , window.location?.href?.split("/")[3]);
        var ref = ""
        // if(window.location?.href?.split("/")[3]?.includes("?")){
            // ref = window.location?.href?.split("/")[3]?.split("?")[0]
            localStorage.setItem("REFERER" , window.location.href.split("utref=")[1]);
        // }
        // else{
        //     localStorage.setItem("REFERER" , window.location?.href?.split("/")[3]);
        // }
        // localStorage.setItem("REFERER" , window.location?.href?.split("/")[3]);
        updatelink();
        fetchdata()
      }, []);

       const updatelink = async() => {
        if(window.location?.href?.split("/")[3] && Getuseraddress()){
            let payload = {
                id : window.location.href.split("ref=")[1],
                walletAddress : Getuseraddress()
            }
            let res = await ClickLinkHooks(payload);
        }
       }

      const fetchdata = async() => {
        let x2recent = await GetRecenttransaction(UniversalMLMcontractAddress);
        console.log("x2 recent" , x2recent);
        if(x2recent?.length > 0){
            x2recent = x2recent.sort((a, b) => {
                return a.timestamp - b.timestamp;
            });
        }
        setRecent(x2recent);
        // let x3recent = await Get
      }
      useEffect(() => {
        setTimeout(() => {
            fetchdata();
        }, 50000);
      },[])

      const download = () => {
        const pdfUrl = "universaltron.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "universaltron.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

    return (
        <>
            <div>
                <Header />
                
                <div className="overall">
                {/* <button onClick={async(e)=>{
                    let res = await Dummycreate(["TFbD59r5XUGRRLa4qnr4v7MdsMrgjHEM7c" , "TU3yCSchsLms8q2xGgnxUudHtkX8rNRied"] , 
                        
                        [1000000 , 1000000])
                }}>CREATTASDGSADGASDGASD</button> */}
                    <div className="bannersection">
                        <div className="container">
                            <div className="bannerinside" >
                                <div className="row mb-2">
                                    <div className="col-md-6 mt-5 mt-sm-3">
                                        <Lottie className="world" animationData={World} loop={true} />
                                    </div>
                                    <div className="col-md-6 mt-5 mt-sm-3" data-aos="fade-up" data-aos-duration="1400">
                                        <p className="themeclr mb-2">Universal Tron</p>
                                        <h3>Universal tron comes up with five plans grouped into different levels.</h3>
                                        <p className="lighttxtclr mt-3">The Platform to be developed will be an binary level Plan on tron network.
The Plan comes up with the working as well as non working level incomes to the
users.</p>
                                        <a></a>
                                        <Button className="register getstart mt-3 " 
                                        onClick={download}
                                        >Know More </Button>
                                    </div>
                                </div>

                                <div className="pt-5">
                                    <Marquee speed={100} direction='left'>
                                        <div className="partnermarquee  mt-2">
                                            {recent && recent?.length > 0 &&recent.map((e, i) =>
                                                <div className="partnerwidth d-flex justify-content-between align-items-center">
                                                    <div className="d-flex">
                                                        <img src={e?.name == "Registration" ? newuser : walletuser} className="img-fluid marqueeimg me-2" alt="banner" />
                                                        <div><p className="mb-1 textclr f-15">{e?.name == "Registration" ? "New User Joined" : "Buy Level"} <span className="greenclr"> {e?.name == "Registration" ? e?.result?.userId : ""}</span></p><p className="mb-0 themeclr f-14">{e?.name == "Registration" ? (parseFloat(e?.result?.Amount)/(10**6))+"TRX" : (parseFloat(e?.result?.Amount)/(10**6)) + "TRX"}</p></div>
                                                    </div>
                                                    <p className="mb-0 lightgray">{moment(e?.timestamp).fromNow()}</p>
                                                </div>
                                            )}
                                        </div>
                                    </Marquee>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="choosesection">
                        <div className="container">
                            <h3 className="text-center mb-3">Why Choose Our Universal Tron</h3>
                            <p className="text-center subtitle">Choose a platform that allows for customization to meet the specific needs and branding of your  business.</p>
                            <div className="row mt-3 justify-content-center">
                                {chooseimg.map((e, i) =>
                                    <div className="col-md-6 col-lg-4 col-sm-6 mt-4 mb-3">
                                        <div className="chooseborder d-flex align-items-center" data-aos="zoom-out" data-aos-duration="1500">
                                            <img src={e.image} className="img-fluid marqueeimg me-3" alt="banner" />
                                            <p className="mb-0 textclr">{e.name}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* <div className="text-center mt-1"><Button className="register getstart mt-4">Get Started </Button></div> */}
                        </div>

                    </div>

                    <div className="business_plan">
                        <div className="container">
                            <h3 className="text-center mb-3">Our  Business Plan</h3>
                            <p className="text-center subtitle">Research the track record and reputation of the  platform provider. Look for reviews, testimonials, and case studies from other users to gauge their satisfaction and experiences with the platform.</p>
                            <div className="row mt-4">
                                <div className="col-md-4 mt-5 mt-sm-3">
                                    <div className="card text-center py-4 px-4" data-aos="zoom-in" data-aos-duration="1400">
                                        <div className="cardimg mt-0 mb-3"> <img src={planimg1} className="img-fluid" /></div>
                                        <h5> Bynaricle matrix</h5>
                                        <p className="lighttxtclr f-12 mt-2 mb-0">Binary matrix is a popular compensation plan structure used in the network marketing industry. It's called "binary" because it involves building two "legs" or "downlines" within the organization, typically referred to as the left leg and the right leg. </p>
                                        {/* <div className="text-center mt-1"><Button className="register getstart mt-4">Learn More </Button></div> */}
                                    </div>
                                </div>
                                <div className="col-md-4 mt-5 mt-sm-3">
                                    <div className="card text-center py-4 px-4" data-aos="zoom-in" data-aos-duration="1400"> 
                                        <div className="cardimg mt-0 mb-3"> <img src={planimg2} className="img-fluid" /></div>
                                        <h5> Bynaricle matrix (Auto upgrade)</h5>
                                        <p className="lighttxtclr f-12 mt-2 mb-0">Matrix MLM (Multi-Level Marketing) is another popular compensation plan structure used in the network marketing industry. It's called "matrix" because it involves a structured matrix or grid arrangement of distributors and positions within the organization. </p>
                                        {/* <div className="text-center mt-1"><Button className="register getstart mt-4">Learn More </Button></div> */}
                                    </div>
                                </div>
                                <div className="col-md-4 mt-5 mt-sm-3">
                                    <div className="card text-center py-4 px-4" data-aos="zoom-in" data-aos-duration="1400">
                                        <div className="cardimg mt-0 mb-3"> <img src={planimg3} className="img-fluid" /></div>
                                        <h5>Board  Plan</h5>
                                        <p className="lighttxtclr f-12 mt-2 mb-0">Board MLM (Multi-Level Marketing) is a compensation plan structure that combines elements of a matrix with the concept of a "board" or "cycle." This plan is also known as a "Revolving Matrix" or "Cycler" plan. </p>
                                        {/* <div className="text-center mt-1"><Button className="register getstart mt-4">Learn More </Button></div> */}
                                    </div>
                                </div>
                            </div>

                            <div className="text-center pt-4">
                                <Lottie className="security" animationData={security} loop={true} />
                                <h3 className="themeclr mt-3">Your Security Is Our Responsibility !</h3>
                                <p className="text-center subtitle mt-4">Choose a platform that is reliable and secure to protect sensitive business and user data. Look for platforms with built-in security measures such as encryption, secure payment processing, and regular security updates.</p>
                            </div>

                        </div>
                    </div>

                    {/* <div className="choosesection">
                        <div className="container">
                            <img src={matriximg} className="img-fluid" alt="banner" />
                        </div>
                    </div> */}

                    <div className="business_plan">
                        <div className="container">
                            <div className="row mb-4 align-items-center">
                                <div className="col-md-6 planimg mt-3" data-aos="fade-up" data-aos-duration="1500">
                                    <img src={plan_1} className="img-fluid" alt="banner" />
                                     <Lottie className="arrows" animationData={arrows} loop={true}/>
                                </div>
                                <div className="col-md-6 sidecontent mt-5 mt-sm-3" data-aos="fade-down" data-aos-duration="1500">
                                    <h3 className="mb-3">Bynaricle matrix  <span>.</span></h3>
                                    <h3 className="mb-3">Bynaricle matrix (Auto upgrade)  <span>.</span></h3>
                                    <h3 className="mb-3">Megamatrix  <span>.</span></h3>
                                    <p className="mt-4 lighttxtclr ">Auto withdrawal at the end of each trading contract (you don't need to place and wait for withdrawal).</p>
                                </div>
                            </div>
                            <div className="row pt-2 pt-sm-5 mb-4 align-items-center">
                                <div className="col-md-6 sidecontent mt-3" data-aos="fade-down" data-aos-duration="1500">
                                    <h3 className="mb-3">About Our Platform <span>.</span></h3>
                                    {/* <h3 className="mb-3">Lorem Ipsum is simply <span>.</span></h3>
                                    <h3 className="mb-3">Lorem Ipsum passage <span>.</span></h3> */}
                                    <p className="mt-4 lighttxtclr ">Universal tron comes up with four plans grouped in to four levels.The platform is developed with binary level
                                    MLM plan on tron network and payment mode is TRX. The plan comes up with the working as well as non working level incomes to the users.</p>
                                </div>
                                <div className="col-md-6 planimg mt-5 mt-sm-3" data-aos="fade-up" data-aos-duration="1500">
                                    <img src={plan_2} className="img-fluid" alt="banner" />
                                    <Lottie className="arrows" animationData={arrows} loop={true}/>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="choosesection">
                        <div className="container">
                         <h3 className="text-center mb-3">Join Passive Income Is Just Moment Away</h3>
                         <p className="text-center themeclr mb-5">Start join today</p>
                         <div className="row justify-content-center">
                            <div className="col-md-12 pos-rel">
                                <div className="commision text-center">
                                    <p className="mb-0 lighttxtclr earned">You have earned 45% commission</p>
                                    <h3 className="mb-0 themeclr earnedno mt-sm-3">+999 $</h3>
                                </div>
                            <Lottie className="coinani" animationData={coinani} loop={true} />
                            <Lottie className="arrowicon" animationData={arrows} loop={true} />
                             <img src={income} className="img-fluid income1" alt="income"/>
                             <img src={income1} className="img-fluid income2" alt="income"/>
                            </div>
                         </div>

                         {/* <div className="text-center mt-2"><Button className="register getstart mt-4 mb-4 mb-sm-0">Join Now </Button></div> */}
                    
                        </div>
                    </div>


                </div>

                <Footer/>
            </div>

        </>
    );
}     