import React, { useEffect, useState } from "react";
import {
    Button,
    Container,
    Row,
    Nav,
    NavDropdown,
    Navbar,
    Modal,
    Form,
    Col,
    Card,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
// import meta from "../Assets/metamask.png";
// import walletconnect from "../Assets/wallet_connect.png";
// import coinbase from "../Assets/coinbase_wallet.png";
// import trust from "../Assets/trust_wallet.png";
// import tick from "../Assets/Json/tickk.json";
import { isEmpty } from "../../Hooks/UseValidation";
import {
    Extregistration,
    userPayPrice,
    checkRegister,
} from "../../Hooks/UseWallet";
import { BuyNewLevelHooks, Registerhooks } from "../../Hooks/usebackend";
import lockIcon from "../../Assets/lock_icon.svg";
import userGroup from "../../Assets/user_group.svg";
import reload from "../../Assets/reload.svg";
import coin from "../../Assets/coin.svg";
import { Buynewlevel, Buynewleveldirectreferal, ExtregistrationX3, getBalance, Getuseraddress, RegisterX3, TestRegisterX3, UseBuyPlan1 } from "../../Hooks/UseContract";
import { toast } from "react-hot-toast";
import { CreateX3userHooks } from "../../Hooks/usebackendX3";
import { TestCreateX3userHooks } from "../../Hooks/TestBackend";
import { DecryptString } from "../../Hooks/UseSecure";
export default function Buy(props) {
    console.log("modalprops", props);
    const navigate = useNavigate();
    const [register, setRegister] = useState(true);
    const [wallet, setWallet] = useState(false);
    const [network, setNetwork] = useState(true);
    const [registration, setRegistration] = useState(true);
    const [balance, setBalance] = useState(false);
    const [approve, setApprove] = useState(null);
    const [walletAddress, setWalletAddress] = useState("");
    const [id, setid] = useState(localStorage.getItem("REFERER") ? localStorage.getItem("REFERER") : 1);
    const [fee, setFee] = useState("");
    const [disable, setDisable] = useState(false);
    // const [Registered, setRegistered] = useState(true);

    const address = localStorage.getItem("address");

    useEffect(() => {
        console.log("props" , props);
    },[])

    // const Buyplan = async () => {
    //     setDisable(true);
    //     if(props?.data == "board"){
    //         let reg = await ExtregistrationX3(Getuseraddress());
    //         if(reg?.type == "Success"){
    //             props?.refresh();
    //             props?.onDismiss();
    //         }
    //         else{
    //             setDisable(false);
    //             toast.error("Network Error!");
    //         }
    //     }
    //     else if(props?.lvl > 12){
    //         let buy = await Buynewleveldirectreferal("1", props?.lvl);
    //         if(buy?.type == "Success"){
    //             props?.refresh();
    //             props?.onDismiss();
    //         }
    //         else{
    //             setDisable(false);
    //             toast.error("Network Error!");
    //         }
    //     }
    //     else {
    //         Buynewlevel("1", props?.lvl).then((buy) => {
    //             console.log("buy in " , buy);
    //         if(buy?.type == "Success"){
    //             props?.refresh();
    //             props?.onDismiss();
    //         }
    //         else{
    //             setDisable(false);
    //             toast.error("Network Error!");
    //         }
    //         });
            
    //     }
        
    // }

    const Buyplan = async () => {

        var user = Getuseraddress()
        var balance = await getBalance(user);
        var fee = 10
        console.log("Checking address ear" , props?.level?.amount , fee , parseFloat(balance)/10**6);
        if(parseFloat(props?.level?.planAmount)+fee < (parseFloat(balance)/10**6)){
            if(props?.data == "board"){ 
                setDisable(true);
                let payload = {
                    user : Getuseraddress(),
                    level : parseInt(props?.level?.level)
                }
                let resultdata = await CreateX3userHooks(payload);
                let resdata = resultdata?.result
                console.log("Result in buy plan" , resdata);
                var result ;
                if(resdata?.data?.type == "Success"){
                    result = DecryptString(resdata?.data?.record , resultdata?.key);
                }
                else{
                    result = resdata?.data
                }
                // let result = await CreateX3userHooks(payload);
                console.log("result" , result);
                if(resdata?.data?.type == "Success"){
                    let register = await RegisterX3(result);
                    console.log("Register in X3" , register);
                    if(register?.type != "error"){
                        props?.refresh();
                        props?.onDismiss();
                    }
                   else {
                    setDisable(false);
                    toast.error(register?.data);
                   }
                }
                else if(result?.status == false){
                    setDisable(false);
                    toast.error(result?.message ? result?.message : "Network Error!");
                }
                else if(result?.status ==200){
                    let register = await RegisterX3(result?.data);
                    if(register?.type != "error"){
                        props?.refresh();
                        props?.onDismiss();
                   }
                   else {
                        toast.error(register?.data);
                   }
                }
            }
            else{
                setDisable(true);
                let payload = {
                    user : props?.user?.walletAddress,
                    level : props?.level?.level
                }
                let resultdata = await BuyNewLevelHooks(payload);
                console.log("REsult in buy new level hooks" , resultdata);
    
                let resdata = resultdata?.result
                let result = DecryptString(resdata?.data?.record , resultdata?.key);
                if(result){
                    let buy = await UseBuyPlan1(result?.address , result?.amount , result);
                    console.log("Buy result in buy" , buy);
                    if(buy?.type == "Success"){
                         props?.refresh();
                         props?.onDismiss();
                    }
                    else{
                        toast.error(buy?.data ? buy?.data : "Error on contract!");
                        setDisable(false);
                    }
                }
                else{
                    setDisable(false);
                    toast.error("Network Error!");
                }
            }
        }
        else{
            toast.error("Insufficient Balance!..")
        }
    }

//     const buy = async() => {
//         let payload = {
//             "user" : "TH2pgy29ThKt82tXWM8XyUwqq3NMD7Jzm5",
//             "level" : 1
//         }
//         let result = await TestCreateX3userHooks(payload);
//         console.log("Result in test create" , result);
//         if(result?.data)
//         {
//           let res =  await  TestRegisterX3(result?.data);
//           console.log("res" , res);
//         }    
// }

const buy = async() => {
    setDisable(true);
    let payload = {
        "user" : Getuseraddress(),
        "level" : parseInt(props?.level?.level)
    }
    let result = await CreateX3userHooks(payload);
    console.log("result" , result);
    if(result?.data?.status == true || result?.status ==200){
        let register = await RegisterX3(result?.data);
        if(register?.type != "error"){
            props?.refresh();
            props?.onDismiss();
       }
       else {
        toast.error(register?.data);
       }
    }
}

    return (
        <>
            <div>
                <Modal
                    className="walletmodal"
                    onHide={props.onDismiss}
                    show={register}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton onClick={props.onDismiss}>
                        {/* Login or Register */}
                    </Modal.Header>
                    <Modal.Body>
                        <h2 className="text-start mb-4 mdl_heads">
                            Upgrade to Level {props?.lvl}
                        </h2>

                        <Card className="level_card">
                            <Card.Body className="position-relative p-3">
                                <div className="">
                                    <Link
                                        // to="/plan/1"
                                        // state={{ count: 2 , level : PlanLevel , levelstatus : levelstatus , userdetail : userdetail}}
                                        className="plan_custom_navi"
                                    >
                                        <div className="head_sec d-flex justify-content-between">
                                            <span>Level {props?.lvl}</span>
                                            <div className="d-flex align-items-center">
                                                {/* <span className="sml_circle me-1"></span> */}
                                                <img src={coin} className="coin_img me-1" />
                                                <span>{props?.level?.planAmount}</span>
                                            </div>
                                        </div>
                                        {/* {console.log("Checking in map" , props?.level[3]?.toString())} */}
                                        <div className="foot_sec d-flex justify-content-between">
                                            {/* <span>
                                                <img
                                                    src={userGroup}
                                                    alt="usergroup"
                                                    className="img-fluid user_icon pe-2"
                                                />
                                                {2**parseInt(props?.level?.level)}
                                            </span> */}
                                            {/* <span>
                                                <img
                                                    src={reload}
                                                    alt="reload"
                                                    className="img-fluid reload_icon pe-2"
                                                />
                                                {props?.level?.reinvestCount ? props?.level?.reinvestCount : 0}
                                            </span> */}
                                        </div>
                                    </Link>
                                </div>
                            </Card.Body>
                        </Card>



                        <Row  className="d-flex justify-content-center align-items-center mt-4 mb-2">
                            <Col xs={12} sm={6} >
                                {/* <Link
                                    className="rgs_btn d-flex align-items-center justify-content-center"
                                >

                                </Link> */}
                 
                                    <Button className="rgs_btn custom_buy_btn d-flex align-items-center justify-content-center" onClick={async() => {
                                        await Buyplan()
                                    }}
                                    disabled = {disable}
                                    >
                                        {disable ? "Loading..." : "Buy"}
                                    </Button>
                                    {/* <Button onClick={() => buy()}> Buy</Button> */}
                                
                            </Col>

                        </Row>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
}
