import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import Adminsidebar from "./AdminSidebar";
import AdminHeader from "./AdminHeader";
import Table from "react-bootstrap/Table";
import { FaEdit } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import { FaEye } from "react-icons/fa";
import {
  GetDirectTransactionHooks,
  GetLevelTransactionHooks,
  GetpaidnextlevelhistoryHooks,
  Getpartnerlisthooks,
  GetPartnerplan4Hooks,
  GetPlan2DirectTransactionHooks,
  GetPlan2LevelTransactionHooks,
  GetTransactionLevelHooks,
  IdtoAddressHooksX2,
} from "../Hooks/usebackend";
import {
  Convertaddress,
  Getpartnersview,
  GetPartnerX2,
  GetTransactionX2,
  Getuseraddress,
} from "../Hooks/UseContract";
import { usersDetails } from "../Hooks/UseWallet";
import { toFixedNumber } from "../Hooks/helper";
import { GetTransactionHook, GetUserX3hooks } from "../Hooks/usebackendX3";
import { useNavigate } from "react-router";

export default function PlanTwo() {
  // const PartnerDetails = [
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  //   {
  //     date: "21/10/2023",
  //     address: "0x86221bsw855",
  //     partnerId: "ID 253253",
  //     x2: 1,
  //     x3: 0,
  //     partners: 1,
  //     profits: "0 TRX",
  //   },
  // ];

  const [PartnerDetails, setPartnerdetails] = useState([]);
  const [userdetail, setUserdetail] = useState({});
  const [partneradd, setPartneradd] = useState([]);
  const [data, setData] = useState([
    "Direct Referal",
    "Level Income",
    "Admin Fee",
    "eligible upliner",
  ]);
  const [loader, setLoader] = useState(false);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [button, setButton] = useState(false);
  const [transaction, setTransaction] = useState([]);

  const [diretskip, setDirectskip] = useState(0);
  const [diretlimit, setDirectlimit] = useState(10);
  const [diretcount, setDirectcount] = useState(0);
  const [diretbutton, setDirectbutton] = useState(false);
  const [directtransaction, setDirecttransaction] = useState([]);
  const [user, setUSer] = useState({});
  const [directloader, setDirectloader] = useState(false);
  const [tabs, setTabs] = useState("level");
  const [load, setLoad] = useState(0);
  const [currenctrans, setCurrenttrans] = useState([]);
  const [referalcount , setReferalcount] = useState([]);

  const navigate = useNavigate();
  const handleTabs = (val) => {
    setTabs(val);
    setTimeout(() => {
      setLoad(load + 1);
    }, 1000);
  };

  const loadmore = async () => {
    if (parseInt(count) > PartnerDetails?.length) {
      setButton(true);
      let payload = {
        skip: skip,
        limit: 10,
        walletAddress: Getuseraddress(),
      };
      let partners = await GetPlan2LevelTransactionHooks(payload);
      setTransaction(transaction?.concat(partners?.data?.record));
      console.log("partners", partners);
      setSkip(skip + limit);
      setButton(false);
    }
  };

  const fetchPlans = async () => {
    setLoader(true);
    var addres;
    if (window.location.href.split("/")[6]) {
      let idpayload = {
        id: window?.location?.href?.split("/")[4],
      };
      let idtoadd = await IdtoAddressHooksX2(idpayload);
      setUSer(idtoadd?.data?.record);
      addres = idtoadd?.data?.record?.walletAddress;
    }
    let payload = {
      walletAddress: window.location.href.split("/")[6]
        ? addres
        : Getuseraddress(),
      skip: diretskip,
      limit: diretlimit,
    };
    let result = await GetPlan2LevelTransactionHooks(payload);
    console.log("REsult in x2plan1", result);
    if (result?.data?.type == "Success") {
      setTransaction(result?.data?.record);
      setCount(result?.data?.count);
      //   setX2plan1(result?.data?.plan);
      setLoader(false);
    }
  };

  const loadmoredirect = async () => {
    if (parseInt(count) > directtransaction?.length) {
      setDirectbutton(true);
      let payload = {
        skip: diretskip,
        limit: 10,
        walletAddress: Getuseraddress(),
      };
      let partners = await GetPlan2DirectTransactionHooks(payload);
      setDirecttransaction(directtransaction?.concat(partners?.data?.record));
      console.log("partners", partners);
      setDirectskip(skip + limit);
      setDirectbutton(false);
    }
  };

  const fetchPlansdirect = async () => {
    setDirectloader(true);
    var addres;
    if (window.location.href.split("/")[6]) {
      let idpayload = {
        id: window?.location?.href?.split("/")[4],
      };
      let idtoadd = await IdtoAddressHooksX2(idpayload);
      setUSer(idtoadd?.data?.record);
      addres = idtoadd?.data?.record?.walletAddress;
    }
    let payload = {
      walletAddress: window.location.href.split("/")[6]
        ? addres
        : Getuseraddress(),
      skip: skip,
      limit: limit,
    };
    let result = await GetPlan2DirectTransactionHooks(payload);
    console.log("REsult in x2plan1", result);
    if (result?.data?.type == "Success") {
      setDirecttransaction(result?.data?.record);
      //   setX2plan1(result?.data?.plan);
      setDirectloader(false);
    }
  };

  

  const fetchtransaction = async () => {
    setLoader(true);
    let result = await GetTransactionLevelHooks({ walletAddress: Getuseraddress() });
    let userres = await GetPartnerplan4Hooks({walletAddress : Getuseraddress()});
    console.log("Result ijn tansler", result);
    setTransaction(result);
    setReferalcount(userres?.data?.record);
    setLoader(false);
  }

  const removeDuplicates = (arr) => {
    var newArray = [];
    var uniqueObject = {};
    for (let i in arr) {
      var from = arr[i]['from'];
      uniqueObject[from] = arr[i];
    }
    for (let i in uniqueObject) {
      newArray.push(uniqueObject[i]);
    }
    return newArray?.length;
    // return arr.filter((item,
    //     index) => arr.indexOf(item) === index);
  }
  useEffect(() => {
    if(!Getuseraddress()){
      window.location.href = window.location.origin;
    }
  },[])
  useEffect(() => {
    if(!Getuseraddress()){
      window.location.href = window.location.origin;
    }
  },[])
  useEffect(() => {
    fetchtransaction()
    // fetchPlans();
    // fetchPlansdirect();
  }, []);
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" className="rct_cust_tltp" {...props}>
      {props}
    </Tooltip>
  );

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (data) => { setCurrenttrans(data); setShow(true) };

  return (
    <>
      <div id="dashboard" className="showsidebar partners_side_section">
        <div id="sidebar">
          <Adminsidebar />
        </div>
        <div className="headandcont">
          <div className="adminheader">
            <AdminHeader />
          </div>

          <div className="cont p-sm-5 p-4 cont cont_padding">
            <h5>
              Plan - 4
            </h5>



            {/* <div className="plan_trans_custom_tabs">
              <Button
                className={tabs === "level" ? "active_btn" : "inactive_btn"}
                onClick={() => handleTabs("level")}
              >
                Level Reward{" "}
              </Button>
              <Button
                className={tabs === "direct" ? "active_btn" : "inactive_btn"}
                onClick={() => {handleTabs("direct") ; navigate("/plan1-list") }}
              >
                Eligible Reward{" "}
              </Button>
            </div> */}



            <div className="table_sec mt-4">
              <Table responsive>
                <thead>
                  <tr className="t_head">
                    <th>Level</th>
                    <th>Users Count</th>
                    <th>Income</th>

                    <th>Split up</th>
                  </tr>
                </thead>
                {!loader ? (
                  <tbody>
                    {transaction?.length > 0 &&
                      transaction?.map((item, index) => {
                        return (
                          <>
                            <tr className="t_body">
                              <td>Level {item?.level}</td>
                              {/* <td>{2**(index+1)}</td> */}
                              {/* {removeDuplicates(item?.trans) >= 2 ** (index + 1) ? 2 ** (index + 1) : removeDuplicates(item?.trans)} */}
                              <td>{referalcount[index]?.length}</td>
                              <td className="partner_id_td custom_plan_partner_id" style={{ width: "unset", minWidth: "unset" }}>
                                <Button className="partner_id">
                                  {item?.amount ? parseFloat(item?.amount).toFixed(4) : 0}
                                </Button>
                              </td>
                              <td>
                                <Button className="common_yellow_btn btn btn-primary action_edit_btn" onClick={() => handleShow(item?.trans)}>
                                  <FaEye />
                                </Button>
                              </td>
                            </tr>
                          </>
                        );

                      })}
                      <tr className="t_body">
                              <td></td>
                              {/* <td>{2**(index+1)}</td> */}
                              {/* {removeDuplicates(item?.trans) >= 2 ** (index + 1) ? 2 ** (index + 1) : removeDuplicates(item?.trans)} */}
                              <td>Total : {referalcount?.reduce((ac, cu) => ac + cu?.length,0,)}</td>
                              <td className="partner_id_td custom_plan_partner_id" style={{ width: "unset", minWidth: "unset" }}>
                                <Button className="partner_id">
                                  Total : {transaction?.reduce((ac, cu) => ac + cu?.amount,0,)}
                                </Button>
                              </td>
                              <td>
                                
                              </td>
                            </tr>
                  </tbody>
                ) : (
                  <tbody>
                    <td colSpan={4}>
                      <p className="mb-0 data_not_found_txt">Loading...</p>
                    </td>
                  </tbody>
                )}




              </Table>
            </div>
          </div>
        </div>


        <Modal show={show} onHide={handleClose} centered size="lg" className="partners_side_section">
          <Modal.Header closeButton>
            <Modal.Title>
              Transaction
              <div className="d-flex gap-3 align-items-center" >
                <h6 className="mb-0">Total Partners Count</h6>
                <Button className="partner_id total_partner_cnt">
                  {removeDuplicates(currenctrans)}
                </Button>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="table_sec">
              <Table responsive>
                <thead>
                  <tr className="t_head">
                    <th>From</th>
                    <th>To</th>
                    <th>Level</th>
                    <th>Income</th>
                  </tr>
                </thead>
                {currenctrans?.length > 0 &&

                  currenctrans?.map((item, index) => {
                    return (
                      <>
                        <tbody>
                          <tr className="t_body">
                            {/* <td><a target="_blank" className="custom_a_wht" href={window.location.origin + "/user-dashboard/" + item?.from}>{`${item?.from?.substring(
                              0,
                              2
                            )}...${item?.from?.substring(32, 34)}`}</a></td>
                            <td><a target="_blank" className="custom_a_wht" href={window.location.origin + "/user-dashboard/" + item?.to}>{`${item?.to?.substring(
                              0,
                              2
                            )}...${item?.to?.substring(32, 34)}`}</a></td> */}
                            <td>
                              <a target="_blank" className="custom_a_wht" href={window.location.origin + "/user-dashboard/" + item?.fromId}>

                                <p className="mb-0">
                                  {item?.fromId}
                                </p>
                              </a>
                              </td>
                            <td>
                              <a target="_blank" className="custom_a_wht" href={window.location.origin + "/user-dashboard/" + item?.fromId}>

                                <p className="mb-0">
                                  {item?.toId}
                                </p>
                              </a>
                              </td>
                            <td>{item?.level}</td>
                            <td className="partner_id_td custom_plan_partner_id" style={{ width: "unset", minWidth: "unset" }}>
                              <Button className="partner_id">
                                {parseFloat(item?.amount).toFixed(4)}
                              </Button>
                            </td>

                          </tr>
                        </tbody>
                      </>
                    );

                  })}

                {currenctrans?.length <= 0 &&
                  <tbody>
                    <tr>
                      <td colSpan={4} style={{ borderBottom: "none" }}>
                        <p className="mb-0 data_not_found_txt">No Transaction Found</p>
                      </td>
                    </tr>
                  </tbody>}




              </Table>
              <div className="d-flex justify-content-center align-items-center">
                {count > PartnerDetails?.length && <button onClick={loadmore} className="common_yellow_btn">{button ? "Loading..." : "Loadmore"}</button>}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose} className="common_yellow_btn btn btn-primary">
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
