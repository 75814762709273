import React from 'react';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import vConsole from 'vconsole'
// import { ThemeProvider } from '@mui/material/styles';

const root = ReactDOM.createRoot(document.getElementById('root'));
// new vConsole();
root.render(
  <>
  <App />
  <Toaster></Toaster>
  {/* <React.StrictMode>
<ThemeProvider>
    <App />
    <Toaster></Toaster></ThemeProvider>
  </React.StrictMode>  */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

{/* <React.StrictMode>
<ThemeProvider>
    <App />
    <Toaster></Toaster></ThemeProvider>
  </React.StrictMode>  */}