import React, { useEffect, useState } from "react";
import { toHex, fromHex } from "tron-format-address";

import Card from "react-bootstrap/Card";
import { FaArrowUp } from "react-icons/fa6";

import { usersDetails } from "../Hooks/UseWallet";
import { useSelector } from "react-redux";
import {
  Convertaddress,
  Getidtoaddress,
  Getuseraddress,
  GetuserdetailX3,
} from "../Hooks/UseContract";
import SkeletonLoadingDash from "./SkeletonLoadingDash";
import { toFixedNumber } from "../Hooks/helper";
import Withdraw from "./Modals/Withdrawmodal";
import Deposit from "./Modals/Depositmodal";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { GetIdtoAddressX3hooks, GetPartnersHook } from "../Hooks/usebackendX3";
import { Get24hrsHooks, GetDashCountHooks, GetDashProfitHooks, GetTransactionLevelHooks, IdtoAddressHooksX2 } from "../Hooks/usebackend";
import { data } from "jquery";
function DashTotalCounts(props) {
  const address = localStorage.getItem("address");
  const { walletData } = useSelector((state) => state.User);
  const [userDetail, setuserDetail] = useState();
  const [walletAddress, setWalletAddress] = useState();
  const [loader, setLoader] = useState(false);
  const [x3usrdetail, setX3usrdetail] = useState({});

  const [withdraw, setWithdraw] = useState(false);
  const [deposit, setDeposit] = useState(false);
  const [currentplan, setCurrentplan] = useState("");
  const [currentbalance, setCurrentbalance] = useState("");
  const [plan1, setPlan1] = useState({});
  const [plan2, setPlan2] = useState({});
  const [partners, setPartners] = useState("");
  const [partner24, setPartner24] = useState("0");
  const [link24, setLink24] = useState("0");
  const [transaction , setTransaction] = useState([]);

  const [X2plan1 , setX2plan1] = useState([]);
  const [X2plan2 , setX2plan2] = useState([]);
  const [load , setLoad] = useState(false);
  const [plan1comission , setPlan1comission] = useState(0);
  const [plan2comission , setPlan2comission] = useState(0);
  const [plan3comission , setPlan3comission] = useState(0);


  const getlast24hrs = async () => {
    let result = await Get24hrsHooks({ walletAddress: Getuseraddress() });
    console.log("last 24 hrs", result);
    setPartner24(result?.data?.partners);
    setLink24(result?.data?.links);

    let result1 = await GetDashProfitHooks({ walletAddress: Getuseraddress() });
    console.log("result 1 in get dash profit" , result1);
    setX2plan1(result1?.data?.plan1);
    setX2plan2(result1?.data?.plan2);
    setPlan1comission(result1?.data?.plan1comission);
    setPlan2comission(result1?.data?.plan2comission);
    setPlan3comission(result1?.data?.plan3comission);
  }
  const fetchtransaction = async () => {
    setLoad(true);
    let result = await GetTransactionLevelHooks({ walletAddress: Getuseraddress() });
    // let userres = await GetPartnerplan4Hooks({walletAddress : Getuseraddress()});
    console.log("Result ijn tansler", result);
    setTransaction(result);
    setLoad(false)
    // setReferalcount(userres?.data?.record);
    // setLoader(false);
  }

  useEffect(() => {
    fetchtransaction();
    setWalletAddress(walletData.address);
    console.log("dashtotal count", window?.location?.href?.split("/")[4]);
    getUserDetails();
    getlast24hrs();
    
  }, []);

  const getUserDetails = async () => {
    try {
      setLoader(true);
      var addressHex = "";
      if (window?.location?.href?.split("/")[4]) {
        addressHex = await IdtoAddressHooksX2(
          { id: window?.location?.href?.split("/")[4] }
        );
      }
      // const add = fromHex(addressHex);
      setuserDetail(addressHex);
      console.log("dashtotal count", addressHex);
      const address = window?.location?.href?.split("/")[4]
        ? addressHex?.data?.record?.walletAddress
        : Getuseraddress();

      const x3userdetails = await GetDashCountHooks({ walletAddress: address });
      console.log("x3userdetails", x3userdetails);
      setX3usrdetail(x3userdetails?.data?.boardprofit);
      setPlan1(x3userdetails?.data?.plan1);
      setPlan2(x3userdetails?.data?.plan2);
      let payload = {
        user: address
      }
      let part = await GetPartnersHook(payload)
      console.log("partpartpart", part);
      setPartners(part?.data?.count)
      setLoader(false);
    } catch (err) {
      console.log(err, "err__pay");
    }
  };

  const settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      {withdraw && (
        <Withdraw
          onDismiss={() => setWithdraw(false)}
          plan={currentplan}
          balance={currentbalance}
        />
      )}
      {deposit && (
        <Deposit onDismiss={() => setDeposit(false)} plan={currentplan} />
      )}
      <div className="card_section dashboard_card row mt-4">
        <div className="col-xl-4 col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
          <Card className="cmn_card">
            <Card.Body>
              <div className="header">Partners</div>
              <div className="content_sec">
                <div>
                  {!loader && (
                    <p className="total_count">
                      {partners}
                    </p>
                  )}
                  {loader && <SkeletonLoadingDash />}
                  <span className="cnt">
                    <FaArrowUp />
                    <span className="no ps-1">
                      {partner24}
                      {/* {userDetail && userDetail?.partnersCount} */}
                    </span>
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-xl-4 col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
          <Card className="cmn_card">
            <Card.Body>
              <div className="header">Link Clicks</div>
              <div className="content_sec">
                <div>
                  {!loader && <p className="total_count">{plan1 ? plan1?.link : 0}</p>}
                  {loader && <SkeletonLoadingDash />}
                  <span className="cnt">
                    <FaArrowUp />
                    <span className="no ps-1">{link24}</span>
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-xl-4 col-12 col-sm-12 col-md-4 col-lg-4 mb-3">
          <Card className="cmn_card">
            <Card.Body>
              <div className="header">Profits</div>
              {/* <div className="content_sec custom_dash_profits p-0 position-relative">
                <div className="sec_fst">
                  <h5 className="mb-1 dash_profit_heading">X2</h5>
                  {!loader && (
                    <p className="total_count mb-2">
                      {userDetail &&
                        toFixedNumber(
                          parseFloat(userDetail?.balance) / 10 ** 6
                        )}{" "}
                      TRX
                    </p>
                  )}
                  {loader && <SkeletonLoadingDash />}
                  <div className="d-flex flex-wrap">
                    <button
                      className="common_yellow_btn mt-1 me-1"
                      onClick={() => {
                        setCurrentplan("X2");
                        setCurrentbalance(
                          toFixedNumber(
                            parseFloat(userDetail?.balance) / 10 ** 6
                          )
                        );
                        setWithdraw(true);
                      }}
                    >
                      Withdraw
                    </button>
                    <button
                      className="common_yellow_btn mt-1 ms-1"
                      onClick={() => {
                        setCurrentplan("X2");
                        setCurrentbalance(
                          toFixedNumber(
                            parseFloat(x3usrdetail?.balance) / 10 ** 6
                          )
                        );
                        setDeposit(true);
                      }}
                    >
                      Deposit
                    </button>
                  </div>
                </div>
                <div className="sec_snd"></div>
                <div className="sec_trd">
                  <h5 className="mb-1 dash_profit_heading">X3</h5>

                  {!loader && (
                    <p className="total_count mb-2">
                      {userDetail &&
                        toFixedNumber(
                          parseFloat(x3usrdetail?.balance) / 10 ** 6
                        )}{" "}
                      TRX
                    </p>
                  )}
                  {loader && <SkeletonLoadingDash />}
                  <div className="d-flex flex-wrap">
                    <button
                      className="common_yellow_btn mt-1 me-1"
                      onClick={() => {
                        setCurrentplan("X3");
                        setCurrentbalance(
                          toFixedNumber(
                            parseFloat(x3usrdetail?.balance) / 10 ** 6
                          )
                        );
                        setWithdraw(true);
                      }}
                    >
                      Withdraw
                    </button>
                    <button
                      className="common_yellow_btn mt-1 ms-1"
                      onClick={() => {
                        setCurrentplan("X3");
                        setCurrentbalance(
                          toFixedNumber(
                            parseFloat(x3usrdetail?.balance) / 10 ** 6
                          )
                        );
                        setDeposit(true);
                      }}
                    >
                      Deposit
                    </button>
                  </div>
                </div>
              </div> */}

              <div className="content_sec dash_profit_slider">
                <Slider {...settings} className="custom_slider">
                  <div className="dash_profit_owl">
                    <div className="cnt">
                      <h4 className="mb-1 dash_profit_heading"><b>Total Profit</b></h4>
                      <p className="total_count mb-2">{((plan1?.x2profit ? parseFloat(plan1?.x2profit) : 0) +
                        (plan2?.x2profit ? parseFloat(plan2?.x2profit) : 0) +
                        (x3usrdetail?.x3profit ? parseFloat(x3usrdetail?.x3profit) : 0)).toFixed(4)
                      } TRX</p>
                    </div>
                  </div>
                  <div className="dash_profit_owl">
                    <div className="">
                      <h5 className="mb-1 dash_profit_heading">X2- Plan 1</h5>
                      <p className="total_count mb-2">{X2plan1?.reduce((ac, cu) => parseFloat(ac) + parseFloat(cu?.amount),0,) ? (parseFloat(X2plan1?.reduce((ac, cu) => parseFloat(ac) + parseFloat(cu?.amount),0,)) - parseFloat(plan1comission))?.toFixed(3) : 0} TRX</p>
                    </div>
                  </div>
                  <div className="dash_profit_owl">
                    <div className="">
                      <h5 className="mb-1 dash_profit_heading">X2- Plan 2</h5>
                      {console.log("new x2 profit" , X2plan2?.reduce((ac, cu) => parseFloat(ac) + parseFloat(cu?.amount),0,) )}
                      <p className="total_count mb-2">{X2plan2?.reduce((ac, cu) => parseFloat(ac) + parseFloat(cu?.amount),0,) ? (parseFloat(X2plan2?.reduce((ac, cu) => parseFloat(ac) + parseFloat(cu?.amount),0,)) - parseFloat(plan2comission)).toFixed(4) : 0} TRX</p>
                    </div>
                  </div>
                  <div className="dash_profit_owl">
                    <div className="">
                      <h5 className="mb-1 dash_profit_heading">X3</h5>
                      <p className="total_count mb-2">{x3usrdetail?.x3profit ? (parseFloat(x3usrdetail?.x3profit) - parseFloat(plan3comission)).toFixed(4) : 0} TRX</p>
                    </div>
                    
                  </div>
                  <div>
                  <div className="dash_profit_owl">
                    <div className="">
                      <h5 className="mb-1 dash_profit_heading">Plan 4 Profit</h5>
                      {!load ? <p className="total_count mb-2">{parseFloat(transaction?.reduce((ac, cu) => ac + cu?.amount,0,)).toFixed(3)} TRX</p>
                    : <p className="total_count mb-2"> Loading...</p>  
                    }
                    </div>
                  </div>
                  </div>
                  {(plan1comission || plan2comission || plan3comission) &&    <div className="dash_profit_owl">
                    <div className="">
                      <h5 className="mb-1 dash_profit_heading">COMMISSION</h5>
                      <p className="total_count mb-2">{(parseFloat(plan1comission)+parseFloat(plan2comission)+parseFloat(plan3comission)).toFixed(3)} TRX</p>
                    </div>
                  </div> }
                </Slider>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default DashTotalCounts;
