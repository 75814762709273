/** Action-File */
import { MATRIXX2LEVEL, WALLET_DATA } from "../action";

const initialState = {
    walletData: "",
    matrixx2data : {}
};

const frontReducer = (state = initialState, action) => {
    switch (action.type) {
        case WALLET_DATA:
            return { ...state, walletData: action.payload };
        case MATRIXX2LEVEL:
            return {...state , matrixx2data : action.payload};
        default:
            return state;
    }
};


export default frontReducer