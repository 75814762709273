import React, { useEffect, useState } from "react";
import { Button, Container, Row, Nav, NavDropdown, Navbar, Modal } from 'react-bootstrap';
import { Link, NavLink } from "react-router-dom";
import { BsTwitterX,BsInstagram,BsYoutube } from "react-icons/bs";
import { IoMdMailOpen } from "react-icons/io";

export default function Footer() {

    return (
        <>

            <div className="choosesection footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-6 mt-5 mt-sm-3">
                            {/* <p className="textclr mb-3">Stay In The Loop</p>
                            <input type="text" className="form-control" placeholder="Enter your mail id" />
                            <Button className="register mt-3">Signup</Button> */}
                        </div>
                        <div className="col-md-8 col-sm-6 channel mt-5 mt-sm-3">
                            <p className="textclr mb-3">Official Channels</p>
                            <div className="d-flex justify-content-end">
                            <a target="_blank" href="https://twitter.com/"><BsTwitterX /></a>
                            <a target="_blank" href="https://www.instagram.com/"><BsInstagram /></a>
                            <a target="_blank" href="https://youtube.com/"><BsYoutube /></a>
                            <a target="_blank" href="https://mail.google.com/"><IoMdMailOpen/></a>    
                            </div>                     
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}     