import React, { useEffect, useState } from "react";
import {
    Button,
    Container,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import Adminsidebar from "./AdminSidebar";
import AdminHeader from "./AdminHeader";
import Table from "react-bootstrap/Table";
import { FaEdit } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import { FaEye } from "react-icons/fa";
import {
    GetDirectTransactionHooks,
    GetLevelTransactionHooks,
    Getpartnerlisthooks,
    GetPlan2DirectTransactionHooks,
    GetPlan2LevelTransactionHooks,
    GetTransactionLevelHooks,
    GetX2Plan2Hooks,
    IdtoAddressHooksX2,
    UserX2Plan1Hooks,
} from "../Hooks/usebackend";
import {
    Convertaddress,
    Getpartnersview,
    GetPartnerX2,
    GetTransactionX2,
    Getuseraddress,
} from "../Hooks/UseContract";
import { usersDetails } from "../Hooks/UseWallet";
import { toFixedNumber } from "../Hooks/helper";
import { GetTransactionHook, GetUserX3hooks } from "../Hooks/usebackendX3";
import { EditPlanX2Hooks, EditPlanX3Hooks, GetAdminPlanX3Hooks } from "../Hooks/UseBackendAdmin";
import { toast } from "react-hot-toast";

export default function EditPlan() {
    // const PartnerDetails = [
    //   {
    //     date: "21/10/2023",
    //     address: "0x86221bsw855",
    //     partnerId: "ID 253253",
    //     x2: 1,
    //     x3: 0,
    //     partners: 1,
    //     profits: "0 TRX",
    //   },
    //   {
    //     date: "21/10/2023",
    //     address: "0x86221bsw855",
    //     partnerId: "ID 253253",
    //     x2: 1,
    //     x3: 0,
    //     partners: 1,
    //     profits: "0 TRX",
    //   },
    //   {
    //     date: "21/10/2023",
    //     address: "0x86221bsw855",
    //     partnerId: "ID 253253",
    //     x2: 1,
    //     x3: 0,
    //     partners: 1,
    //     profits: "0 TRX",
    //   },
    //   {
    //     date: "21/10/2023",
    //     address: "0x86221bsw855",
    //     partnerId: "ID 253253",
    //     x2: 1,
    //     x3: 0,
    //     partners: 1,
    //     profits: "0 TRX",
    //   },
    //   {
    //     date: "21/10/2023",
    //     address: "0x86221bsw855",
    //     partnerId: "ID 253253",
    //     x2: 1,
    //     x3: 0,
    //     partners: 1,
    //     profits: "0 TRX",
    //   },
    //   {
    //     date: "21/10/2023",
    //     address: "0x86221bsw855",
    //     partnerId: "ID 253253",
    //     x2: 1,
    //     x3: 0,
    //     partners: 1,
    //     profits: "0 TRX",
    //   },
    //   {
    //     date: "21/10/2023",
    //     address: "0x86221bsw855",
    //     partnerId: "ID 253253",
    //     x2: 1,
    //     x3: 0,
    //     partners: 1,
    //     profits: "0 TRX",
    //   },
    //   {
    //     date: "21/10/2023",
    //     address: "0x86221bsw855",
    //     partnerId: "ID 253253",
    //     x2: 1,
    //     x3: 0,
    //     partners: 1,
    //     profits: "0 TRX",
    //   },
    //   {
    //     date: "21/10/2023",
    //     address: "0x86221bsw855",
    //     partnerId: "ID 253253",
    //     x2: 1,
    //     x3: 0,
    //     partners: 1,
    //     profits: "0 TRX",
    //   },
    //   {
    //     date: "21/10/2023",
    //     address: "0x86221bsw855",
    //     partnerId: "ID 253253",
    //     x2: 1,
    //     x3: 0,
    //     partners: 1,
    //     profits: "0 TRX",
    //   },
    //   {
    //     date: "21/10/2023",
    //     address: "0x86221bsw855",
    //     partnerId: "ID 253253",
    //     x2: 1,
    //     x3: 0,
    //     partners: 1,
    //     profits: "0 TRX",
    //   },
    // ];

    const [PartnerDetails, setPartnerdetails] = useState([]);
    const [userdetail, setUserdetail] = useState({});
    const [partneradd, setPartneradd] = useState([]);
    const [data, setData] = useState([
        "Direct Referal",
        "Level Income",
        "Admin Fee",
        "eligible upliner",
    ]);
    const [loader, setLoader] = useState(false);
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);
    const [button, setButton] = useState(false);
    const [transaction, setTransaction] = useState([]);

    const [diretskip, setDirectskip] = useState(0);
    const [diretlimit, setDirectlimit] = useState(10);
    const [diretcount, setDirectcount] = useState(0);
    const [diretbutton, setDirectbutton] = useState(false);
    const [directtransaction, setDirecttransaction] = useState([]);
    const [user, setUSer] = useState({});
    const [directloader, setDirectloader] = useState(false);
    const [tabs, setTabs] = useState("level");
    const [load, setLoad] = useState(0);
    const [currenctrans, setCurrenttrans] = useState([]);

    const [planx2, setPlanx2] = useState([]);
    const [planx3, setPlanx3] = useState([]);
    const [planx2two, setPlanx2two] = useState([]);
    //   const [reflevel , setReflevel] = useState([0 , 2 , 4 , 6])

    const handleTabs = (val) => {
        setTabs(val);
        setTimeout(() => {
            setLoad(load + 1);
        }, 1000);
    };

    const loadmore = async () => {
        if (parseInt(count) > PartnerDetails?.length) {
            setButton(true);
            let payload = {
                skip: skip,
                limit: 10,
                walletAddress: Getuseraddress(),
            };
            let partners = await GetPlan2LevelTransactionHooks(payload);
            setTransaction(transaction?.concat(partners?.data?.record));
            console.log("partners", partners);
            setSkip(skip + limit);
            setButton(false);
        }
    };

    const fetchPlans = async () => {
        setLoader(true);
        var addres;
        if (window.location.href.split("/")[6]) {
            let idpayload = {
                id: window?.location?.href?.split("/")[4],
            };
            let idtoadd = await IdtoAddressHooksX2(idpayload);
            setUSer(idtoadd?.data?.record);
            addres = idtoadd?.data?.record?.walletAddress;
        }
        let payload = {
            walletAddress: window.location.href.split("/")[6]
                ? addres
                : Getuseraddress(),
            skip: diretskip,
            limit: diretlimit,
        };
        let result = await GetPlan2LevelTransactionHooks(payload);
        console.log("REsult in x2plan1", result);
        if (result?.data?.type == "Success") {
            setTransaction(result?.data?.record);
            setCount(result?.data?.count);
            //   setX2plan1(result?.data?.plan);
            setLoader(false);
        }
    };

    const loadmoredirect = async () => {
        if (parseInt(count) > directtransaction?.length) {
            setDirectbutton(true);
            let payload = {
                skip: diretskip,
                limit: 10,
                walletAddress: Getuseraddress(),
            };
            let partners = await GetPlan2DirectTransactionHooks(payload);
            setDirecttransaction(directtransaction?.concat(partners?.data?.record));
            console.log("partners", partners);
            setDirectskip(skip + limit);
            setDirectbutton(false);
        }
    };

    const fetchPlansdirect = async () => {
        setDirectloader(true);
        var addres;
        if (window.location.href.split("/")[6]) {
            let idpayload = {
                id: window?.location?.href?.split("/")[4],
            };
            let idtoadd = await IdtoAddressHooksX2(idpayload);
            setUSer(idtoadd?.data?.record);
            addres = idtoadd?.data?.record?.walletAddress;
        }
        let payload = {
            walletAddress: window.location.href.split("/")[6]
                ? addres
                : Getuseraddress(),
            skip: skip,
            limit: limit,
        };
        let result = await GetPlan2DirectTransactionHooks(payload);
        console.log("REsult in x2plan1", result);
        if (result?.data?.type == "Success") {
            setDirecttransaction(result?.data?.record);
            //   setX2plan1(result?.data?.plan);
            setDirectloader(false);
        }
    };

    const fetchtransaction = async () => {
        setLoader(true);
        let result = await GetTransactionLevelHooks({ walletAddress: Getuseraddress() });
        console.log("Result ijn tansler", result);
        setTransaction(result);
        setLoader(false);
    }

    const fetchAdminplanX2 = async () => {
        let payload = {
            walletAddress: Getuseraddress()
        }
        let result = await UserX2Plan1Hooks(payload);
        let result1 = await GetX2Plan2Hooks(payload);
        let result2 = await GetAdminPlanX3Hooks();
        console.log("result2", result2?.data?.record);
        setPlanx3(result2?.data?.record);
        setPlanx2(result?.data?.plan);
        setPlanx2two(result1?.data?.plan);
    }

    const handleSubmit = async() => {
        if(singlerecord?.type == "X2"){
            let payload = {
                id : singlerecord?._id,
                amount : amount,
                address : Getuseraddress()
            }
            let result = await EditPlanX2Hooks(payload);
            if(result?.data?.type == "error"){
                toast.error(result?.data?.record)
            }
            else{
                setShow(false)
            }
            fetchAdminplanX2()
        }
        if(singlerecord?.type == "X3"){
            let payload = {
                id : singlerecord?._id,
                amount : amount,
                address : Getuseraddress()
            }
            let result = await EditPlanX3Hooks(payload);
            if(result?.data?.type == "error"){
                toast.error(result?.data?.record)
            }
            else{
                setShow(false)
            }
            fetchAdminplanX2()
        }
        
    }

    useEffect(() => {
        fetchAdminplanX2();
        // fetchtransaction()
        // fetchPlans();
        // fetchPlansdirect();
    }, []);
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" className="rct_cust_tltp" {...props}>
            {props}
        </Tooltip>
    );

    const [show, setShow] = useState(false);
    const [singlerecord, setSinglerecord] = useState({});
    const [amount, setAmount] = useState(0);

    const handleClose = () => setShow(false);
    const handleShow = (data) => { setCurrenttrans(data); setShow(true) };

    return (
        <>
            <div id="dashboard" className="showsidebar partners_side_section">
                <div id="sidebar">
                    <Adminsidebar />
                </div>
                <div className="headandcont">
                    <div className="adminheader">
                        <AdminHeader />
                    </div>

                    <div className="cont p-sm-5 p-4 cont cont_padding">
                        <h5>
                            Plan - 4
                        </h5>
                        <div className="table_sec mt-4">
                            <Table responsive>
                                <thead>
                                    <tr className="t_head">
                                        <th>Level</th>
                                        <th>Plan</th>
                                        <th>Type</th>
                                        <th>Amount</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                {!loader ? (
                                    <tbody>
                                        {planx2?.length > 0 &&
                                            planx2?.map((item, index) => {
                                                return (
                                                    <>
                                                        <tr className="t_body">
                                                            <td>Level {item?.level}</td>
                                                            <td>{item?.name}</td>
                                                            <td>{item?.type}</td>
                                                            {/* <td>{item?.amount}</td> */}
                                                            <td className="partner_id_td custom_plan_partner_id" style={{ width: "unset", minWidth: "unset" }}>
                                                                <Button className="partner_id">
                                                                    {item?.planAmount ? parseFloat(item?.planAmount) : 0}
                                                                </Button>
                                                            </td>
                                                            <td>
                                                                <Button className="common_yellow_btn btn btn-primary action_edit_btn" onClick={() => {handleShow(item?.trans);
                                                                 setSinglerecord(item);
                                                                 setAmount(parseFloat(item?.planAmount));
                                                                }}>
                                                                    <FaEdit  />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    </>
                                                );
                                            })}

                                        {planx2two?.length > 0 &&
                                            planx2two?.map((item, index) => {
                                                return (
                                                    <>
                                                        <tr className="t_body">
                                                            <td>Level {parseInt(item?.level) - 6}</td>
                                                            <td>{item?.name}</td>
                                                            <td>{item?.type}</td>
                                                            {/* <td>{item?.amount}</td> */}
                                                            <td className="partner_id_td custom_plan_partner_id" style={{ width: "unset", minWidth: "unset" }}>
                                                                <Button className="partner_id">
                                                                    {item?.planAmount ? parseFloat(item?.planAmount) : 0}
                                                                </Button>
                                                            </td>
                                                            <td>
                                                                <Button className="common_yellow_btn btn btn-primary action_edit_btn"
                                                                onClick={() => {
                                                                    handleShow(item?.trans)
                                                                    setSinglerecord(item);
                                                                    setAmount(parseFloat(item?.planAmount));
                                                                }}
                                                               >
                                                                    <FaEdit  />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    </>
                                                );
                                            })}

                                        {planx3?.length > 0 &&
                                            planx3?.map((item, index) => {
                                                return (
                                                    <>{console.log("result2", item?.name == "board1")}
                                                        <tr className="t_body">
                                                            <td>Level {parseInt(item?.level) - (item?.name == "board1" ? 0 :
                                                                item?.name == "board2" ? 2 : item?.name == "board3" ? 4 : 6
                                                            )}</td>
                                                            <td>{item?.name}</td>
                                                            <td>{item?.type}</td>
                                                            {/* <td>{item?.amount}</td> */}
                                                            <td className="partner_id_td custom_plan_partner_id" style={{ width: "unset", minWidth: "unset" }}>
                                                                <Button className="partner_id">
                                                                    {item?.planAmount ? parseFloat(item?.planAmount) : 0}
                                                                </Button>
                                                            </td>
                                                            <td>
                                                                <Button className="common_yellow_btn btn btn-primary action_edit_btn" 
                                                                onClick={() => {
                                                                    handleShow(item?.trans)
                                                                    setSinglerecord(item);
                                                                    setAmount(parseFloat(item?.planAmount));
                                                                    handleShow(item?.trans)
                                                                }}
                                                                >
                                                                    <FaEdit />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    </>
                                                );
                                            })}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <td colSpan={5}>
                                            <p className="mb-0 data_not_found_txt">Loading...</p>
                                        </td>
                                    </tbody>
                                )}
                            </Table>
                        </div>
                    </div>
                </div>


                <Modal show={show} onHide={handleClose} centered size="md" className="partners_side_section footer">
                    <Modal.Header closeButton>
                        <Modal.Title>Transaction</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12 mb-2">
                                <p className="textclr mb-1 text-start">Type</p>
                                <input type="text" className="form-control" value={singlerecord?.type} readOnly></input>
                            </div>
                            <div className="col-12 mb-2 text-start">
                                <p className="textclr mb-1">Plan</p>
                                <input type="text" className="form-control" value={singlerecord?.name} readOnly></input>
                            </div>
                            <div className="col-12 mb-2 text-start">
                                <p className="textclr mb-1">Level</p>
                                <input type="text" className="form-control" value={parseInt(singlerecord?.level) - (singlerecord?.name == "board1" ? 0 :
                                        singlerecord?.name == "board2" ? 2 : singlerecord?.name == "board3" ? 4 : singlerecord?.name == "board4" ? 6 :
                                        singlerecord?.name == "Plan1" ? 0 : 6
                                )} readOnly></input>
                            </div>
                            <div className="col-12 mb-2 text-start">
                                <p className="textclr mb-1">Plan Amount</p>
                                <input type="text" className="form-control" value={amount}
                                onChange = {(e) => setAmount(e?.target?.value)}
                                ></input>
                            </div>
                            {/* <label>Plan</label>
                            <input type="text" value={singlerecord?.type} readOnly></input>
                            <label>Level</label>
                            <input type="text" value={singlerecord?.type} readOnly></input>
                            <label>Plan Amount</label>
                            <input type="text" value={singlerecord?.planAmount} ></input> */}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleSubmit} className="common_yellow_btn btn btn-primary">
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}
