export const isEmpty = (data) => {
  console.log(typeof data, "datadata");
  try {
    if (typeof data == "string") {
      return data == "" || data == undefined || data == null ? true : false;
    } else if (typeof data == "object") {
      return data == undefined || data == null || Object.keys(data).length > 0
        ? true
        : false;
    } else if (data === undefined || data === null) {
      return true;
    } else {
      return true;
    }
  } catch (e) {
    console.log("isEmpty_vaidationERR", e);
  }
};

// export const isEmpty = (data) => {
//     data === undefined ||
//     data === null ||
//     (typeof data === 'object' && Object.keys(data).length === 0) ||
//     (typeof data === 'string' && data.trim().length === 0);
// }
