import React, { useEffect, useState , useMemo} from "react";

import {
  Button,
  Container,
  Row,
  Nav,
  NavDropdown,
  Navbar,
  Modal,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import Lottie from "lottie-react";
import meta from "../Assets/tron.svg";
import walletconnect from "../Assets/wallet_connect.png";
import coinbase from "../Assets/coinbase_wallet.png";
import trust from "../Assets/trust_wallet.png";
import tokenpkt from "../Assets/tp.png";
import tick from "../Assets/Json/tickk.json";
import { ConnectTokenPocket, ConnectTrustwallet, connectWallet, WalletConnect } from "../Hooks/UseWallet";
import { useDispatch } from "react-redux";
import { isEmpty } from "../Hooks/UseValidation";
import { toast } from "react-hot-toast";
import { PROJECT_ID } from "../Config/config";



export default function Walletmodal(props) {
  const dispatch = useDispatch();
  const [Walletmodals, setWalletmodals] = useState(true);
  const [steps, setSteps] = useState(1);
  const [wallet, setWallet] = useState(false);

  const address = localStorage.getItem("address");

  console.log(isEmpty(address), "addressaddress");
  useEffect(() => {
    console.log("USER EFFECT", address, isEmpty(address));
    if (isEmpty(address)) {
      console.log(!isEmpty(address), "!isEmpty(address!isEmpty(address");
      setWallet(false);
    } else {
      setWallet(true);
    }
  }, []);
  const connectTronWallet = async () => {
    try {
      const connect = await connectWallet(dispatch);
      if(window.location?.href?.split("/")[3]){
        props.onReg()
      }
      if (connect) {
        props.onDismiss();
      }
    } catch (e) {
      console.log("connectTronWallet_err", e);
    }
  };

  const Walletconnect = async() => {
    try{
      const connect = await WalletConnect();
      if (connect) {
        props.onDismiss();
      }
    }
    catch(e){
      console.log("walletconnect error" , e);
    }
  }

  const Connecttrustwallet = async() => {
    try{
      const connect = await ConnectTrustwallet()
      // .then(() => props?.onDismiss());
      // console.log("connect" , connect);
      // if (connect) {
      //   props.onDismiss();
      // }
    }
    catch(e){
      console.log("connect trustwallet error" , e);
    }
  }

  const TokenPocket = async() => {
    try{
       let connect = await ConnectTokenPocket()
    }
    catch(e){
      console.log("Error on " , e);
    }
  }


  return (
    <>
      <div>
        <Modal
          className="walletmodal"
          onHide={props.onDismiss}
          show={Walletmodals}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton onClick={props.onDismiss}>
            {/* Login or Register */}
          </Modal.Header>
          <Modal.Body>
            <h5 className="text-center mb-4">Connect Wallet</h5>
            {!wallet && (
              <>
                <div
                  className="walletborder mb-3"
                  onClick={() => {
                    connectTronWallet();
                  }}
                >
                  <div className="mar_style">
                    <span>
                      <img className="me-3" src={meta} alt="" />
                    </span>
                    <span>TRON Wallet</span>
                  </div>
                </div>
                <div className="walletborder mb-3" 
                 onClick={() => {
                  Walletconnect();
                }}
                >
                  <div className="mar_style">
                    <span>
                      <img className="me-3" src={walletconnect} alt="" />
                    </span>
                    <span>Wallet Connect</span>
                  </div>
                </div>

<div className="walletborder mb-3" 
                 onClick={() => {
                  // connectTronWallet();
                  connectTronWallet();
                }}
                >
                  <div className="mar_style">
                    <span>
                      <img className="me-3" src={tokenpkt} alt="" />
                    </span>
                    <span>Token Pocket</span>
                  </div>
                </div>

                {/* <div className="walletborder mb-3" onClick={() => setSteps(2)}>
                  <div className="mar_style">
                    <span>
                      <img className="me-3" src={coinbase} alt="" />
                    </span>
                    <span>Coinbase Wallet</span>
                  </div>
                </div> */}
                {/* <div className="walletborder mb-3"
                 onClick={() => {
                  // connectTronWallet();
                  Connecttrustwallet();
                }}
                >
                  <div className="mar_style">
                    <span>
                      <img className="me-3" src={trust} alt="" />
                    </span>
                    <span>Trust wallet</span>
                  </div>
                </div>{" "} */}
              </>
            )}

            {wallet && (
              <div className="text-center">
                <Lottie className="tick" animationData={tick} loop={true} />
                <p className="mb-4 text-center mtop lighttxtclr">
                  Wallet Connected Sucessfully !
                </p>
                <Link to="/dashboard">
                  <Button className="register mb-4" onClick={props.onDismiss}>
                    Done
                  </Button>
                </Link>
              </div>
            )}

           
           
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}




