import React, { useEffect, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import Adminsidebar from "../../Admin/AdminSidebar";
import AdminHeader from "../../Admin/AdminHeader";
import Card from "react-bootstrap/Card";
import { FaArrowUp } from "react-icons/fa6";
import OUCurrentPlan from "./OUCurrentPlan";
import userProfile from "../../Assets/user2.jpg";
import userNot from "../../Assets/userNot.svg";
import userBadgeLogo from "../../Assets/usertopLogo.svg";
import { FaCopy } from "react-icons/fa6";
import RecentActivity from "../RecentActivity";
import DashTotalCounts from "../DashTotalCounts";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { usersDetails } from "../../Hooks/UseWallet";
import { useDispatch, useSelector } from "react-redux";
import {
  Convertaddress,
  Getidtoaddress,
  Getuseraddress,
  GetuserX2matrix,
  GetuserX2matrixplan2,
  GetuserX2matrixplan3,
  GetuserX3matrixplan1,
  GetuserX3matrixplan2,
  GetuserX3matrixplan3,
  GetuserX3matrixplan4,
  GetX3levelstatus,
  SearchuserX2level,
  SearchuserX2levelplan2,
} from "../../Hooks/UseContract";
import { toast } from "react-hot-toast";
import X3CurrentPlan from "./x3currentplan";
import { GetX2Plan2Hooks, Getx2plan3Hooks, IdtoAddressHooksX2, UserX2Plan1Hooks } from "../../Hooks/usebackend";
import { GetIdtoAddressX3hooks, GetX3BoardplanHooks } from "../../Hooks/usebackendX3";

export default function OUDashboard() {
  const dispatch = useDispatch();
  const options = {
    loop: true,
    margin: 40,
    nav: false,
    dots: false,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        // nav: true,
      },
      380: {
        items: 1,
        // nav: true,
      },
      480: {
        items: 1,
      },
      576: {
        items: 2,
        // nav: false,
      },
      767: {
        items: 2,
        // nav: false,
      },
      991: {
        items: 2,
        // nav: true,
        // loop: false,
      },
      1200: {
        items: 3,
        // nav: true,
        // loop: false,
      },
    },
  };

  const CurrentPlan = [
    {
      planNo: 1,
      trx: 0,
      active: 1,
      locked: false,
    },
    {
      planNo: 2,
      trx: 2,
      active: 2,
      locked: true,
    },
    {
      planNo: 3,
      trx: 3,
      active: 3,
      locked: true,
    },
    {
      planNo: 4,
      trx: 4,
      active: 4,
      locked: true,
    },
    {
      planNo: 5,
      trx: 5,
      active: 5,
      locked: true,
    },
    {
      planNo: 6,
      trx: 6,
      active: 6,
      locked: true,
    },
  ];
  const PlanDetails = [
    {
      plan: 1,
      ut: 2,
    },
  ];
  const address = localStorage.getItem("address");
  const { walletData } = useSelector((state) => state.User);
  const [userDetail, setuserDetail] = useState();
  const [walletAddress, setWalletAddress] = useState();
  const [value1, setValue1] = React.useState();
  const [copied1, setCopied1] = React.useState(false);
  const [plan1loader, setPlan1loader] = useState(false);
  const [plan2loader, setPlan2loader] = useState(false);
  const [plan3loader, setPlan3loader] = useState(false);
  console.log(walletData, "walletDatawalletData");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setWalletAddress(walletData.address);
    setValue1(walletData.address);
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    try {
      const details = await usersDetails(address);
      console.log("details", details);
      if (details) {
        setuserDetail(details);
      } else {
        setuserDetail("");
      }
    } catch (err) {
      console.log(err, "err__pay");
    }
  };

  const [value, setValue] = React.useState("8uyt76bihidalashihCd");
  const [copied, setCopied] = React.useState(false);
  const [userdata, setUserdata] = useState({});
  const [x2matrix, setX2matrix] = useState([]);
  const [x2matrixstatus, setMatrixstatus] = useState([]);
  const [x2plan2, setX2plan2] = useState([]);
  const [x2plan2status, setX2plan2status] = useState([]);
  const [x2plan3, setX2plan3] = useState([]);
  const [x2plan3status, setX2plan3status] = useState([]);
  const [boardstatus, setBoardstatus] = useState([]);
  const [boardloader, setBoardloader] = useState(true);

  const [board1, setBoard1] = useState([]);
  const [board1status, setBoard1status] = useState([]);
  const [board2, setBoard2] = useState([]);
  const [board2status, setBoard2status] = useState([]);
  const [board3, setBoard3] = useState([]);
  const [board3status, setBoard3status] = useState([]);
  const [board4, setBoard4] = useState([]);
  const [board4status, setBoard4status] = useState([]);
  const [board1loader, setBoard1loader] = useState(true);
  const [board2loader, setBoard2loader] = useState(true);
  const [board3loader, setBoard3loader] = useState(true);
  const [board4loader, setBoard4loader] = useState(true);

  const [iduser , setIdUser] = useState({});

  //newscope
  const [userplans , setUserplans] = useState({});
  const [userplanx3 , setUserplanx3] = useState({});


  //New scope
  const checkuser = async() => {
    let idpayload = {
      id : window?.location?.href?.split("/")[4]
    };
    let idtoadd = await IdtoAddressHooksX2(idpayload);
    // setIdUser(idtoadd?.data?.record);
    if(!idtoadd?.data?.record){
      toast.error("No User Found!...");
      setTimeout(window.location.href = window.location.origin , 8000)
      
    }
  }
  const fetchx2plan1 = async() => {
    setPlan1loader(false);
    var addres;
    if(window.location.href.split("/")[4]){
      let idpayload = {
        id : window?.location?.href?.split("/")[4]
      };
      let idtoadd = await IdtoAddressHooksX2(idpayload);
      setIdUser(idtoadd?.data?.record);
      addres = idtoadd?.data?.record?.walletAddress;
    }
    let payload = {
      walletAddress: window.location.href.split("/")[4] ? addres : Getuseraddress()
    }
    let result = await UserX2Plan1Hooks(payload);
    // console.log("REsult in x2plan1" , result);
    if(result?.data?.type == "Success"){
      setX2matrix(result?.data?.plan);
      setUserplans(result?.data?.user);
      setPlan1loader(false);
    }
  }

  const fetchx2plan2 = async() => {
    setPlan2loader(true);
    var addres;
    if(window.location.href.split("/")[4]){
      let idpayload = {
        id : window?.location?.href?.split("/")[4]
      };
      let idtoadd = await IdtoAddressHooksX2(idpayload);
      addres = idtoadd?.data?.record?.walletAddress;
    }
    let payload = {
      walletAddress: window.location.href.split("/")[4] ? addres : Getuseraddress()
    }
    let result = await GetX2Plan2Hooks(payload);
    setX2plan2(result?.data?.plan);
    setPlan2loader(false);
  }

  const fetchx2plan3 = async() => {
    try{
      var addres;
    if(window.location.href.split("/")[4]){
      let idpayload = {
        id : window?.location?.href?.split("/")[4]
      };
      let idtoadd = await IdtoAddressHooksX2(idpayload);
      addres = idtoadd?.data?.record?.walletAddress;
    }
    let payload = {
      walletAddress: window.location.href.split("/")[4] ? addres : Getuseraddress()
    }
      let result = await Getx2plan3Hooks(payload);
      setX2plan3(result?.data?.plan);
    }
    catch(e){
      console.log("Errro on fetch x2 plan3" , e);
    }
  }

  const fetchBoardPlans = async() => {
    setBoardloader(true);
    var addres;
    if(window.location.href.split("/")[4]){
      let idpayload = {
        id : window?.location?.href?.split("/")[4]
      };
      let idtoadd = await IdtoAddressHooksX2(idpayload);
      addres = idtoadd?.data?.record?.walletAddress;
    }
    let payload = {
      walletAddress: window.location.href.split("/")[4] ? addres : Getuseraddress()
    }
    let board = await GetX3BoardplanHooks(payload);
    console.log("boardpan result " , board);
    setBoard1(board?.data?.board1);
    setBoard2(board?.data?.board2);
    setBoard3(board?.data?.board3);
    setBoard4(board?.data?.board4);
    setUserplanx3(board?.data?.userplans);
    setBoardloader(false)
  }

  useEffect(() => {
    checkuser();
    fetchx2plan1();
    fetchx2plan2();
    fetchx2plan3();
    fetchBoardPlans();
  },[])



  const fetchdata = async () => {
    setPlan1loader(true);
    let address = await Getidtoaddress(window?.location?.href?.split("/")[4]);
    let searchuserdata = await usersDetails(address);
    setUserdata(searchuserdata);
    let x2levels = await SearchuserX2level(
      window?.location?.href?.split("/")[4]
    );
    setX2matrix(x2levels?.matrix);
    setMatrixstatus(x2levels?.levelstatus);
    setPlan1loader(false);
  };
  const fetchplan2 = async () => {
    setPlan2loader(true);
    let address = await Getidtoaddress(window?.location?.href?.split("/")[4]);
    let searchuserdata = await usersDetails(address);
    // let user = Getuseraddress();
    let matrixx2 = await SearchuserX2levelplan2(
      window?.location?.href?.split("/")[4]
    );
    setX2plan2(matrixx2?.matrix);
    setX2plan2status(matrixx2?.levelstatus);
    setPlan2loader(false);
  };
  const fetchplan3 = async() => {
    setPlan3loader(true);
    let user = Getuseraddress();
    let matrixx2 = await GetuserX2matrixplan3(user);
    setX2plan3(matrixx2?.matrix);
    setX2plan3status(matrixx2?.levelstatus);
    setPlan3loader(false);
  }
  const fetchboardplan1 = async () => {
    setBoard1loader(true);
    let usrid = await Getidtoaddress(window?.location?.href?.split("/")[4]);
    let address = Convertaddress(usrid);
    let user = Getuseraddress();
    let matrixx3 = await GetuserX3matrixplan1(address);
    console.log("matrix x3 level", matrixx3);
    setBoard1(matrixx3?.matrix);
    setBoard1status(matrixx3?.levelstatus);
    setBoard1loader(false);
  };
  const fetchboardplan2 = async () => {
    setBoard2loader(true);
    let usrid = await Getidtoaddress(window?.location?.href?.split("/")[4]);
    let address = Convertaddress(usrid);
    let user = Getuseraddress();
    let matrixx3 = await GetuserX3matrixplan2(address);
    setBoard2(matrixx3?.matrix);
    setBoard2status(matrixx3?.levelstatus);
    setBoard2loader(false);
  };
  const fetchboardplan3 = async () => {
    setBoard3loader(true);
    let usrid = await Getidtoaddress(window?.location?.href?.split("/")[4]);
    let address = Convertaddress(usrid);
    let user = Getuseraddress();
    let matrixx3 = await GetuserX3matrixplan3(address);
    setBoard3(matrixx3?.matrix);
    setBoard3status(matrixx3?.levelstatus);
    setBoard3loader(false);
  };
  const fetchboardplan4 = async () => {
    setBoard4loader(true);
    let usrid = await Getidtoaddress(window?.location?.href?.split("/")[4]);
    let address = Convertaddress(usrid);
    let user = Getuseraddress();
    let matrixx3 = await GetuserX3matrixplan4(address);
    setBoard4(matrixx3?.matrix);
    setBoard4status(matrixx3?.levelstatus);
    setBoard4loader(false);
  };

  const fetchboard = async () => {
    let usrid = await Getidtoaddress(window?.location?.href?.split("/")[4]);
    let address = Convertaddress(usrid);
    let status = await GetX3levelstatus(address);
    console.log("Status in X3", status);
    setBoardstatus(status);
    setBoardloader(false);
  };

  useEffect(() => {
    // fetchdata();
    // fetchplan2();
    // fetchboard();
    // fetchplan3();
    // fetchboardplan1();
    // fetchboardplan2();
    // fetchboardplan3();
    // fetchboardplan4();
  }, []);

  const onCopy = React.useCallback(() => {
    setCopied(true);
    toast.success("Copied!");
  }, []);

  const onSelect = React.useCallback(() => {
    setCopied1(true);
    toast.success("Copied!");
  }, []);

  return (
    <>
      {console.log(value1, "value1value1")}
      <div id="dashboard" className="showsidebar dashboard_sec_content">
        <div id="sidebar">
          <Adminsidebar />
        </div>

        <div className="headandcont">
          <div className="adminheader">
            <AdminHeader />
          </div>

          <div className="cont p-sm-5 p-4 cont cont_padding">
            {/* <h5>Dashboard</h5> */}
            <div className="user_profile_info">
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-12 col-sm-12 col-md-5 mb-3 mb-xl-0 mb-lg-0 d-flex align-items-center">
                  <div className="fst_sec">
                    <div className="user_profile">
                      {/* <img
                        src={userProfile}
                        alt="user_logo"
                        className="img-fluid user_logo"
                      /> */}
                      <img
                        src={userNot}
                        alt="user_logo"
                        className="img-fluid user_logo"
                      />
                      <img
                        src={userBadgeLogo}
                        alt="userbadge"
                        className="img-fluid userbadge_logo"
                      />
                    </div>
                    <div className="id_details d-flex align-items-center">
                      <p className="mb-0 user_id">
                        ID {iduser?.id}
                      </p>
                      <p className="hdn_code mb-0">
                        <span>{value1}</span>
                        <CopyToClipboard
                          onCopy={onSelect}
                          text={iduser?.id}
                        >
                          {/* <span>{value1}</span> */}
                          <FaCopy className="ms-2 copy_link_icon" />
                        </CopyToClipboard>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-7 col-6 col-12 col-sm-12 col-md-7 mb-3 mb-xl-0 mb-lg-0 d personal_link_sec">
                  <div className="snd_sec">
                    <div>
                      <p className="mb-2 personal_link_txt">Personal Links</p>
                      <p className="mb-0 link dash_personal_link">
                        {window.location.origin +
                          "/?utref=" +
                          iduser?.id}
                      </p>
                    </div>

                    <CopyToClipboard
                      onCopy={onCopy}
                      text={
                        window.location.origin +
                        "/?utref=" +
                        window?.location?.href?.split("/")[4]
                      }
                    >
                      <Button className="copy_link_btn">Copy Link</Button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>
            <DashTotalCounts usrdata={userdata} />
            <OUCurrentPlan
              x2matrix={x2matrix ? x2matrix : []}
              levelstatus={x2matrixstatus ? x2matrixstatus : []}
              x2plan2={x2plan2 ? x2plan2 : []}
              x2plan2status={x2plan2status ? x2plan2status : []}
              loader1={plan1loader}
              loader2={plan2loader}
              userplan = {userplans}
              plan = {1}
            />
            
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4">
                <X3CurrentPlan
                  l1status={userplanx3 && userplanx3?.purchasedLevels?.includes(board1[0]?._id)}
                  l2status={userplanx3 && userplanx3?.purchasedLevels?.includes(board1[1]?._id)}
                  board={1}
                  loader1={boardloader}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4">
                <X3CurrentPlan
                  l1status={userplanx3 && userplanx3?.purchasedLevels?.includes(board2[0]?._id)}
                  l2status={userplanx3 && userplanx3?.purchasedLevels?.includes(board2[1]?._id)}
                  board={2}
                  loader1={boardloader}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4">
                <X3CurrentPlan
                  l1status={userplanx3 && userplanx3?.purchasedLevels?.includes(board3[0]?._id)}
                  l2status={userplanx3 && userplanx3?.purchasedLevels?.includes(board3[1]?._id)}
                  board={3}
                  loader1={boardloader}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4">
                <X3CurrentPlan
                  l1status={userplanx3 && userplanx3?.purchasedLevels?.includes(board4[0]?._id)}
                  l2status={userplanx3 && userplanx3?.purchasedLevels?.includes(board4[1]?._id)}
                  board={4}
                  loader1={boardloader}
                />
              </div>
            </div>
            
            

            {/* <RecentActivity /> */}
          </div>
        </div>
      </div>
    </>
  );
}
