import React, { useEffect, useState } from "react";
import {
    Button,
    Container,
    Row,
    Nav,
    NavDropdown,
    Navbar,
    Modal,
    Form,
    Col,
    Card,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
// import meta from "../Assets/metamask.png";
// import walletconnect from "../Assets/wallet_connect.png";
// import coinbase from "../Assets/coinbase_wallet.png";
// import trust from "../Assets/trust_wallet.png";
// import tick from "../Assets/Json/tickk.json";
import { isEmpty } from "../../Hooks/UseValidation";
import {
    Extregistration,
    userPayPrice,
    checkRegister,
} from "../../Hooks/UseWallet";
import { Registerhooks } from "../../Hooks/usebackend";
import lockIcon from "../../Assets/lock_icon.svg";
import userGroup from "../../Assets/user_group.svg";
import reload from "../../Assets/reload.svg";
import coin from "../../Assets/coin.svg";
import { Buynewlevel, ClaimReward, ExtregistrationX3, Getuseraddress } from "../../Hooks/UseContract";
import { toast } from "react-hot-toast";
export default function Withdraw(props) {
    console.log("modalprops", props);
    const navigate = useNavigate();
    const [register, setRegister] = useState(true);
    const [wallet, setWallet] = useState(false);
    const [network, setNetwork] = useState(true);
    const [registration, setRegistration] = useState(true);
    const [balance, setBalance] = useState(false);
    const [approve, setApprove] = useState(null);
    const [walletAddress, setWalletAddress] = useState("");
    const [id, setid] = useState(localStorage.getItem("REFERER") ? localStorage.getItem("REFERER") : 1);
    const [fee, setFee] = useState("");
    const [disable, setDisable] = useState(true);
    // const [Registered, setRegistered] = useState(true);

    const address = localStorage.getItem("address");

    const [withdrawamount , setWithdrawamount] = useState("");
    const [withdrawerror , setWithdrawerror] = useState("");
    const [loader , setLoader] = useState(false);

    const Buyplan = async () => {
        setDisable(true);
        if(props?.data == "board"){
            let reg = await ExtregistrationX3(Getuseraddress());
            if(reg?.type == "Success"){
                props?.refresh();
                props?.onDismiss();
            }
            else{
                setDisable(false);
                toast.error("Network Error!");
            }
        }
        else{
            Buynewlevel("1", props?.lvl).then((buy) => {
                console.log("buy in " , buy);
            if(buy?.type == "Success"){
                props?.refresh();
                props?.onDismiss();
            }
            else{
                setDisable(false);
                toast.error("Network Error!");
            }
            });
            
        }
        
    }

    const withdrawbalance = async() => {
        try{
            setDisable(true);
            setLoader(true)
            let withdraw = await ClaimReward(Getuseraddress() , withdrawamount*10**6 , props?.plan);
            setDisable(false);
            setLoader(false);
            props.onDismiss()
        }
        catch(e){
            console.log("error on withdraw balance" , e);
            setDisable(false);
            setLoader(false);
            
        }
    }
   
   
    return (
        <>
            <div>
                <Modal
                    className="walletmodal"
                    onHide={props.onDismiss}
                    show={register}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton onClick={props.onDismiss}>
                        {/* Login or Register */}
                    </Modal.Header>
                    <Modal.Body>
                        <h2 className="text-start mb-4 mdl_heads">
                            Withdraw
                        </h2>

                        <Card className="level_card">
                            <Card.Body className="position-relative p-3">
                                <div className="">
                                    <Link
                                        // to="/plan/1"
                                        // state={{ count: 2 , level : PlanLevel , levelstatus : levelstatus , userdetail : userdetail}}
                                        className="plan_custom_navi"
                                    >
                                        
                                        {/* {console.log("Checking in map" , props?.level[3]?.toString())} */}
                                        <div className="body_sec">
                                            <p>{`Available Balance (${props?.plan})`} : {props?.balance}</p>
                                            {/* <span className={parseFloat(props?.level[1].length) > 0 ? "cmn_bg_color completed me-3" : "cmn_bg_color in_completed me-3"}></span>
                                            <span className={parseFloat(props?.level[1].length) > 1 ? "cmn_bg_color completed" : "cmn_bg_color in_completed"}></span> */}
                                             <p>Enter Withdraw Amount :</p>
                                            <input className="withdraw_custom_input w-100" type="text" onChange={(e) => {console.log("withdraw amount" , e?.target?.value);
                                                if(parseFloat(e?.target?.value) <= 0 || !e?.target?.value){
                                                    setWithdrawerror("Withdraw amount greater than 0");
                                                    setDisable(true)
                                                }
                                                else if(parseFloat(e?.target?.value) > parseFloat(props?.balance)){
                                                    setWithdrawerror("Withdraw amount must be less than avaialable balance!");
                                                    setDisable(true)
                                                }else{
                                                    setWithdrawamount(e?.target?.value);
                                                    setDisable(false)
                                                }
                                            }}/>
                                            {withdrawerror && <span className="text-danger mb-0 mt-1">{withdrawerror}</span>}
                                        </div>
                                        
                                    </Link>
                                </div>
                            </Card.Body>
                        </Card>



                        <Row  className="d-flex justify-content-center align-items-center mt-4 mb-2">
                            <Col xs={12} sm={6} >
                                {/* <Link
                                    className="rgs_btn d-flex align-items-center justify-content-center"
                                >

                                </Link> */}
                 
                                    <Button className="rgs_btn custom_buy_btn d-flex align-items-center justify-content-center" onClick={async() => {
                                        await withdrawbalance()
                                    }}
                                    disabled = {disable}
                                    >
                                        {loader ? "Loading..." : "Withdraw"}
                                    </Button>
                                
                            </Col>

                        </Row>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
}
