/** Packages */

/** Actions */
import { WALLET_DATA , MATRIXX2LEVEL} from "../Regex/action";

export const SetWalletAddress = async (dispatch, address, balance) => {
  try {
    localStorage.setItem("address", address);
    localStorage.setItem("balance", balance);
    localStorage.setItem("walletconnect" , false)
    let data = {
      address: address,
      balance: balance,
    };
    return dispatch({
      type: WALLET_DATA,
      payload: data,
    });
  } catch (e) {
    console.log("SetWalletAddress_err", e);
  }
};

export const SetMatrixx2level = async(dispatch , data) => {
  try{console.log("SetMatrixx2level" , data , dispatch);
    return dispatch({
      type: MATRIXX2LEVEL,
      payload: data,
    });
  }
  catch(e){
    console.log("Error on set matrixx2level" , e);
  }
}
