import axios from 'axios';
import { API_URL } from '../Config/config';
import * as userroute  from "../Route/userroute";
import { EncryptString } from './UseSecure';
import CircularJSON from "circular-json"

const headers = {
    headers: {
        // 'Content-Type': 'application/json',
        'Authorization':  localStorage.getItem("USRDT") ? `Bearer ${atob( localStorage.getItem("USRDT"))}` : ""
        // 'Authorization': `Bearer ${cookies.get('accessToken')}`
    }
}

export const Registerhooks = async(data) => {
    data = EncryptString(JSON.stringify(data));
    await axios.post(API_URL + userroute.registerApi, data , headers).then((res) => {
        return res;
    })
}

export const Getpartnercounthooks = async(data) => {
      let result =  await axios.post(API_URL + userroute.getpartnerApi, data);
      return result;
    //   .then((res) => {console.log("partnercount" , res);
    //     return res;
    // })
    // .catch((e) => console.log("partnercount err" , e))
}

export const Getpartnerlisthooks = async(data) => {
    let result =  await axios.post(API_URL + userroute.getpartnerlistApi, data);
    return result;
    // .then((res) => {
    //     return res;
    // })
}

export const CheckUserRegisterHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.checkuserregisterApi, data);
    return result;
    }
    catch(e){
        console.log("Error on check user register hooks" , e);
    }
}

export const CreateUserHooks = async(data) => {
    try{
        let secret = `Bearer ${atob( localStorage.getItem("USRDT"))}`
        const headers = {
            headers: {
                // 'Content-Type': 'application/json',
                'Authorization':  secret
            }
        }
        var payload = {...data}
        var datas = {}
        datas.input = EncryptString(JSON.stringify(payload) , secret)
        let result =  await axios.post(API_URL + userroute.createuserApi, datas , headers);
        return {result : result , key : secret};
    }
    catch(e){
        console.log("Error on create user hooks" , e);
    }
}

export const CreateUserSaveHooks = async(data) => {
    try{
        let secret = `Bearer ${atob( localStorage.getItem("USRDT"))}`
        const headers = {
            headers: {
                // 'Content-Type': 'application/json',
                'Authorization':  secret
            }
        }
        var payload = {...data}
        var datas = {}
        datas.input = EncryptString(JSON.stringify(payload) , secret)
        let result =  await axios.post(API_URL + userroute.createusersaveApi, datas , headers);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const UserX2Plan1Hooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.userx2plan1Api, data);
    return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const BuyNewLevelHooks = async(data) => {
    try{
        let secret = `Bearer ${atob( localStorage.getItem("USRDT"))}`
        const headers = {
            headers: {
                // 'Content-Type': 'application/json',
                'Authorization':  secret
            }
        }
        var payload = {...data}
        var datas = {}
        datas.input = EncryptString(JSON.stringify(payload) , secret)
        console.log("encrypteddata" , data);
        let result =  await axios.post(API_URL + userroute.buynewlevelApi, datas , headers);
        return {result : result , key : secret};
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const BuyNewLevelSaveHooks = async(data) => {
    try{
    let secret = `Bearer ${atob( localStorage.getItem("USRDT"))}`
        const headers = {
            headers: {
                // 'Content-Type': 'application/json',
                'Authorization':  secret
            }
        }
        var payload = {...data}
        var datas = {}
        datas.input = EncryptString(JSON.stringify(payload) , secret)
        let result =  await axios.post(API_URL + userroute.buynewlevelsaveApi, datas , headers);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetX2Plan2Hooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.x2plan2Api, data);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

 export const IdtoAddressHooksX2 = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.idtoaddressApi, data);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}


export const Getx2plan3Hooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.x2plan3Api, data);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetDashCountHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.GetDashCountApi, data);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}


export const ClickLinkHooks = async(data) => {
    try{
        let ipdata = await axios.get("https://ipapi.co/json/");
        data.details = ipdata
        let result =  await axios.post(API_URL + userroute.ClickLinkApi, data);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}


export const GetPartnersX2V1Hooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.GetPartnersX2V2Api, data);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetPartnersX2V2Hooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.GetPartnersX2V2Api, data);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}


export const GetLevelTransactionHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.GetLevelTransactionApi, data);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetDirectTransactionHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.GetDirectTransactionApi, data);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetLevelProfitHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.GetLevelProfitApi, data);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}


export const GetUserHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.GetUserApi, data);
        console.log("GETuser result" , result);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetNotificationHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.GetNotificationApi, data);
        console.log("GETuser result" , result);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const ReadAllHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.ReadallApi, data);
        console.log("GETuser result" , result);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}


export const GetPlan1LevelTransactionHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.GetPlan1LevelTransactionApi, data);
        console.log("GETuser result" , result);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetPlan1DirectTransactionHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.GetPlan1DirectTransactionApi, data);
        console.log("GETuser result" , result);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetPlan2LevelTransactionHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.GetPlan2LevelTransactionApi, data);
        console.log("GETuser result" , result);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetPlan2DirectTransactionHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.GetPlan2DirectTransactionApi, data);
        console.log("GETuser result" , result);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetPlan3LevelTransactionHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.GetPlan3LevelTransactionApi, data);
        console.log("GETuser result" , result);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetPlan3DirectTransactionHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.GetPlan3DirectTransactionApi, data);
        console.log("GETuser result" , result);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetAllNotificationHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.GetAllNotificationApi, data);
        console.log("GETuser result" , result);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const ReadOneMessageHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.ReadOneMessageApi, data);
        console.log("GETuser result" , result);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const BoardLevelProfotHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.BoardLevelProfitApi, data);
        console.log("GETuser result" , result);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}



export const GetAddresstoIdHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.AddressToIdApi, data);
        console.log("GetAddresstoIdHooks" , result?.data?.record);
        return result?.data?.record;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}


export const GetTransactionLevelHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.GetTransactionLevelApi, data);
        console.log("GetAddresstoIdHooks" , result?.data?.record);
        return result?.data?.record;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const Get24hrsHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.Get24hrsApi, data);
        console.log("GetAddresstoIdHooks" , result?.data?.record);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const ConnectwalletHooks = async(data) => {
    try{
        console.log("Connect wallet hooks" , data);
        let result =  await axios.post(API_URL + userroute.ConnectWalletApi, data);
        console.log("GetAddresstoIdHooks" , result?.data?.token);
        if(result?.data?.type  == "Success"){
            localStorage.setItem("USRDT" , btoa(result?.data?.token));
        }
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetUserX3profitHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.GetX3profitApi, data);
        console.log("GETuser result" , result);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetPartnerplan4Hooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.GetPartnerplan4Api, data);
        console.log("GETuser result" , result);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetpaidnextlevelhistoryHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.GetPaidnextLevelHistoryApi, data);
        console.log("GETuser result" , result);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}


export const GetMissedhistoryHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.GetMissedHistoryApi, data);
        console.log("GETuser result" , result);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetP1ProfitHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.GetPlan1ProfitApi, data);
        console.log("GETuser result" , result);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetP2ProfitHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.Getplan2ProfitApi, data);
        console.log("GETuser result" , result);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetBoardProfitHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.GetBoardProfitApi, data);
        console.log("GETuser result" , result);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetDashProfitHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + userroute.GetDashprofitApi, data);
        console.log("GETuser result" , result);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}
