import axios from "axios";
import { API_URL } from "../Config/config";
import *as X3routes  from "../Route/X3routefront";
import { EncryptString } from "./UseSecure";
const headers = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization':  localStorage.getItem("USRDT") ? `Bearer ${atob( localStorage.getItem("USRDT"))}` : ""
        // 'Authorization': `Bearer ${cookies.get('accessToken')}`
    }
}

export const GetX3BoardplanHooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + X3routes.GetBoardPlansApi, data);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const CreateX3userHooks = async(data) => {
    try{
        let secret = `Bearer ${atob( localStorage.getItem("USRDT"))}`
        const headers = {
            headers: {
                // 'Content-Type': 'application/json',
                'Authorization':  secret
            }
        }
        var payload = { ...data }
        var datas = {}
        datas.input = EncryptString(JSON.stringify(payload), secret)
        let result = await axios.post(API_URL + X3routes.CreateUserApi, datas, headers);
        return {result : result , key : secret};
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const CreateX3userSaveHooks = async(data) => {
    try{
        let secret = `Bearer ${atob( localStorage.getItem("USRDT"))}`
        const headers = {
            headers: {
                // 'Content-Type': 'application/json',
                'Authorization':  secret
            }
        }
        var payload = {...data}
    var datas = {}
    datas.input = EncryptString(JSON.stringify(payload) , secret)
        let result =  await axios.post(API_URL + X3routes.CreateUserSaveApi, datas , headers);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetBoardplan1 = async() => {
    try{
        let result =  await axios.get(API_URL + X3routes.GetBoard1planApi);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetBoardplan2 = async() => {
    try{
        let result =  await axios.get(API_URL + X3routes.GetBoard2planApi);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetBoardplan3 = async() => {
    try{
        let result =  await axios.get(API_URL + X3routes.GetBoard3planApi);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetBoardplan4 = async() => {
    try{
        let result =  await axios.get(API_URL + X3routes.GetBoard4planApi);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}


export const GetIdtoAddressX3hooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + X3routes.GetIdtoAddressX3Api , data);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetUserX3hooks = async(data) => {
    try{
        let result =  await axios.post(API_URL + X3routes.GetuserX3Api , data);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetPartnersHook = async(data) => {
    try{
        let result =  await axios.post(API_URL + X3routes.GetPartnersApi , data);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}

export const GetTransactionHook = async(data) => {
    try{
        let result =  await axios.post(API_URL + X3routes.GetTransactionApi , data);
        return result;
    }
    catch(e){
        console.log("Error on create user save hooks" , e);
    }
}