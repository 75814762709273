const BASE_URL = "api";

const NWBASE_URL = "mlm"

export const registerApi = BASE_URL + "/userRegister";

export const getpartnerApi = BASE_URL + "/userPartnerCount";

export const getpartnerlistApi = BASE_URL + "/userPartnerList";

export const checkuserregisterApi = BASE_URL + "/checkuser-register";

export const createuserApi = NWBASE_URL + "/create-user";

export const createusersaveApi = NWBASE_URL + "/create-user-save";

export const buynewlevelApi = NWBASE_URL + "/Buynewlevel";

export const buynewlevelsaveApi = NWBASE_URL + "/Buynewlevel-save";

export const userx2plan1Api = BASE_URL + "/user-x2-plan1";

export const x2plan2Api = BASE_URL + "/getx2plan2";

export const idtoaddressApi = BASE_URL + "/idtoaddress";

export const x2plan3Api = BASE_URL + "/get-x2-plan3";

export const GetDashCountApi = BASE_URL + "/get-dash-count";

export const ClickLinkApi = BASE_URL + "/click-link";

export const GetPartnersX2V1Api = BASE_URL + "/get-partners-x2v1";

export const GetPartnersX2V2Api = BASE_URL + "/get-partners-x2v2";

export const GetLevelTransactionApi = BASE_URL + "/get-level-transaction";

export const GetDirectTransactionApi = BASE_URL + "/get-direct-transaction";

export const GetLevelProfitApi = BASE_URL + "/get-level-profit";

export const GetUserApi = BASE_URL + "/get-user";

export const GetNotificationApi = BASE_URL + "/get-notification";

export const ReadallApi = BASE_URL + "/read-msg";

export const GetPlan1LevelTransactionApi = BASE_URL + "/get-plan1-level-transaction";

export const GetPlan1DirectTransactionApi = BASE_URL + "/get-plan1-direct-transaction";

export const GetPlan2LevelTransactionApi = BASE_URL + "/get-plan2-level-transaction";

export const GetPlan2DirectTransactionApi = BASE_URL + "/get-plan2-direct-transaction";

export const GetPlan3LevelTransactionApi = BASE_URL + "/get-plan3-level-transaction";

export const GetPlan3DirectTransactionApi = BASE_URL + "/get-plan3-direct-transaction";

export const GetAllNotificationApi = BASE_URL + "/get-all-notification";

export const ReadOneMessageApi = BASE_URL + "/read-one-msg";

export const BoardLevelProfitApi = BASE_URL + "/board-level-profit";

export const AddressToIdApi = BASE_URL + "/address-to-id";

export const GetTransactionLevelApi = BASE_URL + "/get-transaction-level";

export const Get24hrsApi = BASE_URL + "/getlast24hrs";

export const ConnectWalletApi = BASE_URL + "/connect-wallet";

export const GetX3profitApi = BASE_URL + "/get-x3-profit";

export const GetPartnerplan4Api = BASE_URL + "/get-partners-plan4";

export const GetPaidnextLevelHistoryApi = BASE_URL + "/get-paid-nxtlevel-history";

export const GetMissedHistoryApi = BASE_URL + "/get-missed-history";

export const GetPlan1ProfitApi = BASE_URL + "/get-userp1-profit";

export const Getplan2ProfitApi = BASE_URL + "/get-userp2-profit";

export const GetBoardProfitApi = BASE_URL + "/get-board-profit";

export const GetDashprofitApi = BASE_URL + "/dash-profit";